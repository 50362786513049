import React, { Component } from 'react';
import { Utils } from 'Helpers';
import moment from 'moment';

class BetItem extends Component {
  render() {
    const {
      item,
      fixtureSelectHandler,
      showSportName
    } = this.props;

    let isThemeGradient = Utils.getCookie('isThemeGradient') ? Number(Utils.getCookie('isThemeGradient')) == 1 ? true : false : false
    return (
      <div className={"game-event-item mobile-view " + (isThemeGradient ? " bg-gradient-white " : "")}>
        <div className="event-details" onClick={() => fixtureSelectHandler(item)}>
          <div className="title">
            {
              showSportName &&
              <span>{Number(item.sports_id) === 2 ? "Cricket : " : (Number(item.sports_id) === 1 ? "Soccer : " : "Tennis : ")}</span>
            }
            {
              item.event_name
            }

          </div>

        </div>

        <div className={"last-cell betitem " + (isThemeGradient ? "bg-radial-1" : "")} onClick={() => fixtureSelectHandler(item)}>
          <div>
            <span className="mb-0">{moment(item.open_date).isValid() ? Utils.dateTimeFormat(item.open_date, 'DD') : Utils.dateTimeFormat(item.open_date, 'DD')}</span>&nbsp;
            <span className="mb-0">{moment(item.open_date).isValid() ? Utils.dateTimeFormat(item.open_date, 'MMMM') : Utils.dateTimeFormat(item.open_date, 'MMMM')}</span>&nbsp;
            <span className="mb-0">{moment(item.open_date).isValid() ? Utils.dateTimeFormat(item.open_date, 'h:m a') : Utils.dateTimeFormat(item.open_date, 'h:m a')}</span>
          </div>
        </div>
        {
          item.status === 'SUSPENDED' ?
            <div className="game-status-suspended">
              <span>{item.status}</span>
            </div>
            : ""
        }
      </div>
    );
  }
}
BetItem.defaultProps = {
  item: {},
}
export default BetItem;
