/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from "react";
import { Link, NavLink } from "react-router-dom";
import { Images, SocketEvent, CONSTANT } from "Constants";
import { Auth } from "Service";
import { CasinoGameImageModal, ApplyColorThemeModal } from "Modals";
import Drawer from "react-motion-drawer";
import { withTranslation } from "react-i18next";
import GlobalMessage from "./GlobalMessage"

import { connect } from "react-redux";
import {
  loginOpen,
  UpdateProfile,
  SlipToggle,
  OpenBet,
  actionProfileDetail,
  actionMasterData
} from "../../Redux/Reducers";
import { Buzzer, Socket, _, Http, API, Utils } from "Helpers";

var globalThis = null;

class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ref: null,
      // userExpo: 0,
      // userBal: 0,
      userExpo: Auth.currentUserValue ? Auth.currentUserValue.exposure : 0,
      userBal: Auth.currentUserValue ? Auth.currentUserValue.balance : 0,
      color_data: "primary",
      isDrawerOpen: false,
      is_mute: Buzzer.IsMute(),
      Offset: CONSTANT.CURRENT_OFFSET,
      client_msg: '',
      is_mobile_view: false,
      location: window.location.pathname,
      AppMasterData: Utils.getMasterData(),
      CasinoGameImageModalShow: false,
      ApplyColorThemeModalShow: false,
      isThemeGradient: Utils.getCookie('isThemeGradient') ? Number(Utils.getCookie('isThemeGradient')) == 1 ? true : false : false,
    };

  }

  getClientNotification = () => {
    let param = {};

    this.setState({ posting: true });
    Http.post(API.GET_CLIENT_NOTIFICATION, param)
      .then((response) => {
        this.setState({
          posting: false,
          client_msg: response.data.client_msg,
        });
      })
      .catch((error) => {
        if (error.response_code === 401) {
          this.setState({ currentUser: undefined })
          localStorage.removeItem('currentUser')
          this.props.history.push("/login");
          this.props.UpdateProfile();
        }
        this.setState({ posting: false });
      });
  }

  redirectH = () => {
    this.props.history.push({
      pathname: "/home",

    });
  };

  redirectS = () => {
    this.props.history.push({
      pathname: "/schedule",

    });
  };

  toggleDrawer = () => {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
  };

  onChangeDrawer = (e = false) => {
    let LockScreen = "lock-screen";
    this.setState({ isDrawerOpen: e }, () => {
      if (e) {
        document.body.classList.add(LockScreen);
      } else {
        document.body.classList.remove(LockScreen);
      }
    });
  };

  logout = () => {
    Utils.removeCasinoGameImage();
    Auth.logout();
    this.setState({ currentUser: undefined });
    localStorage.removeItem('currentUser');
    localStorage.removeItem('masterData');
    this.props.UpdateProfile();
  };

  MuteToggle = () => {
    this.setState({ is_mute: !this.state.is_mute }, () => {
      Buzzer.Mute(this.state.is_mute);
    });
  };

  SocketGlobalSettingHandler = (res = {}) => {
    if (!_.isEmpty(res)) {
      const { AppMasterData } = this.state;
      this.setState({
        AppMasterData: { ...AppMasterData, ...res }
      }, () => {
        Utils.setMasterData(this.state.AppMasterData);
      });
    }
  }

  SocketSettingSportHandler = (res = {}) => {
    if (!_.isEmpty(res)) {
      const { AppMasterData } = this.state;
      _.map(AppMasterData.sports_list.exchange_betting, (item, index) => {
        if (Number(item.sports_id) === Number(res.sports_id)) {
          if (res.fancy_delay)
            AppMasterData.sports_list.exchange_betting[index].fancy_delay = res.fancy_delay;
          if (res.match_delay)
            AppMasterData.sports_list.exchange_betting[index].match_delay = res.match_delay;
          if (res.bookmaker_delay)
            AppMasterData.sports_list.exchange_betting[index].bookmaker_delay = res.bookmaker_delay;
        }
      })
      this.setState({
        AppMasterData: AppMasterData
      }, () => {
        Utils.setMasterData(this.state.AppMasterData);
      });
    }
  }
  setdataExposure(balance, exposure) {
    // this.setState({ userBal: balance, userExpo: exposure })
  }


  sockeListener = () => {
    const currentUser = Auth.currentUserValue;
    const _this = this;
    Socket.on(SocketEvent.BALANCE_UPDATED, function (res) {
      if (currentUser && currentUser.user_guid === res.user_guid) {
        _this.setdataExposure(res.balance, res.exposure)
        Auth.updateBalance(res.balance, res.exposure);
        _this.props.UpdateProfile();
      }
    });
    Socket.on(SocketEvent.SPORTS_SETTING_UPDATED, function (res) {
      if (currentUser && currentUser.user_guid === res.user_guid) {
        Auth.updateSetting(res.setting_data);
        _this.props.UpdateProfile();
      }
    });
    Socket.on(SocketEvent.ANNOUNCEMENT_UPDATED, function (res) {
      _this.getClientNotification();
    })
    Socket.on(SocketEvent.GLOBAL_SEARCH_UPDATED, function (res) {
      if (!_.isEmpty(res)) {
        Auth.updateSetting(res);
        _this.props.UpdateProfile();
      }
    })
    Socket.on(SocketEvent.SETTING_SPORTS, function (res) {
      if (!_.isEmpty(res)) {
        _this.SocketSettingSportHandler(res)
      }
    })
    Socket.on(SocketEvent.GLOBAL_SETTING, function (res) {
      if (!_.isEmpty(res)) {
        _this.SocketGlobalSettingHandler(res)
      }
    })
    Socket.on(SocketEvent.USER_LOCK_STATUS_UPDATE, function (res) {
      if (!_.isEmpty(res)) {
        if (res.includes(currentUser.user_id)) {
          window.location.reload();
        }
      }
    })

    Socket.on(SocketEvent.MARKET_ROLLBACK, function (res) {
      if (!_.isEmpty(res)) {
        // _this.SocketMarketRollBack(res)
        _this.getCurrentUserBalance()
      }
    })
    Socket.on(SocketEvent.DOMAIN_SETTING_UPDATED, function (res) {
      if (!_.isEmpty(res)) {
        _this.SocketDomainSettingUpdate(res)
        // _this.getCurrentUserBalance();
      }
    })
    Socket.on(SocketEvent.WA_UPDATED, function (res) {
      const { profileDetail } = _this.props
      if (!_.isUndefined(profileDetail) && !_.isNull(profileDetail) &&
        !_.isUndefined(profileDetail.user_guid) && !_.isNull(profileDetail.user_guid) && profileDetail.user_guid == res.user_guid) {
        _this.getCurrentUserBalance();
      }
    })
  };

  SocketDomainSettingUpdate = async (res = {}) => {
    // let hostname = window.location.hostname;
    // let domain = hostname.match(/^(?:.*?\.)?([a-zA-Z0-9\-_]{3,}\.(?:\w{2,8}|\w{2,4}\.\w{2,4}))$/)[1];
    // if (!_.isEmpty(res) && !_.isUndefined(res.website_url) && !_.isNull(res.website_url) && (res.website_url === domain)) {
    let s3_param = [
      API.S3_GET_APP_MASTER_LIST
    ]
    await Http.s3(Utils.s3UrlConvert(s3_param), API.GET_APP_MASTER_LIST).then(response => {
      Utils.setMasterData(response.data);
      localStorage.removeItem('masterData');
      const masterData = response.data
      Utils.setCookie('isThemeGradient', masterData && masterData.domain_detail.is_gradient && masterData.domain_detail.is_gradient, 365)
      this.props.actionMasterData(response.data)
      this.getCurrentUserBalance();
      // window.location.reload();
      //this.setState({ AppMasterData: response.data }, () => { this.setState({NavigationList: Utils.getMasterData().sports_list['exchange_betting']}, () => { window.location.reload(); }) });
    }).catch(error => {
    });
    // }
  }


  removeSockeListener = () => {
    Socket.off(SocketEvent.BALANCE_UPDATED);
    Socket.off(SocketEvent.SPORTS_SETTING_UPDATED);
    Socket.off(SocketEvent.ANNOUNCEMENT_UPDATED);
    Socket.off(SocketEvent.GLOBAL_SETTING);
    Socket.off(SocketEvent.SETTING_SPORTS);
    Socket.off(SocketEvent.DOMAIN_SETTING_UPDATED);
    Socket.off(SocketEvent.WA_UPDATED);
  };

  renderTagMessage = (rowData) => {
    var msg = rowData.message || "";
    var content = rowData.content;
    _.map(Object.keys(content), (key, idx) => {
      msg = msg.replace(
        "{{" + key + "}}",
        '<p className="highlighted-text">' + content[key] + "</p>"
      );
    });
    return msg;
  };

  // LIST API
  getCurrentUserBalance = () => {
    const { AppMasterData } = this.state;
    const currentUser = Auth.currentUserValue;
    const _this = this;
    const { masterData } = this.props

    let param = {};
    this.setState({ posting: true });
    Http.post(API.GET_USER_BALANCE, param)
      .then((response) => {
        if (currentUser && currentUser.user_guid === response.data.user_guid) {
          // this.setState({ userExpo: response.data.exposure, userBal: response.data.balance })
          Auth.updateBalance(response.data.balance, response.data.exposure, response.data.mobile_app_charges, response.data.wa_number, response.data.wa_visible_to, response.data.parent_role);
          _this.props.UpdateProfile();
          this.props.actionProfileDetail(response.data)

          let ThemeData = response.data


          if (!_.isUndefined(ThemeData) && !_.isNull(ThemeData) &&
            !_.isUndefined(ThemeData.theme) && !_.isNull(ThemeData.theme)) {
            let setColor = document.documentElement.style;
            if (!_.isUndefined(ThemeData) && !_.isNull(ThemeData) &&
              !_.isUndefined(ThemeData.theme) && !_.isNull(ThemeData.theme) &&
              !_.isUndefined(ThemeData.theme.primary) && !_.isNull(ThemeData.theme.primary)) {
              setColor.setProperty('--main-color', ThemeData.theme.primary);
              localStorage.setItem('PrimaryColor', ThemeData.theme.primary);
            }
            if (!_.isUndefined(ThemeData) && !_.isNull(ThemeData) &&
              !_.isUndefined(ThemeData.theme) && !_.isNull(ThemeData.theme) &&
              !_.isUndefined(ThemeData.theme.secondary) && !_.isNull(ThemeData.theme.secondary)) {
              setColor.setProperty('--secondary-color', ThemeData.theme.secondary);

            }
          }
          else if (!_.isUndefined(masterData) && !_.isNull(masterData) &&
            !_.isUndefined(masterData.domain_detail) && !_.isNull(masterData.domain_detail) &&
            !_.isUndefined(masterData.domain_detail.theme) && !_.isNull(masterData.domain_detail.theme) &&
            !_.isUndefined(masterData.domain_detail.theme.primary) && !_.isNull(masterData.domain_detail.theme.primary)) {
            let setColor = document.documentElement.style;
            if (!_.isUndefined(masterData) && !_.isNull(masterData) &&
              !_.isUndefined(masterData.domain_detail) && !_.isNull(masterData.domain_detail) &&
              !_.isUndefined(masterData.domain_detail.theme) && !_.isNull(masterData.domain_detail.theme) &&
              !_.isUndefined(masterData.domain_detail.theme.primary) && !_.isNull(masterData.domain_detail.theme.primary)) {
              setColor.setProperty('--main-color', masterData.domain_detail.theme.primary);
              localStorage.setItem('PrimaryColor', masterData.domain_detail.theme.primary);
            }
            if (!_.isUndefined(masterData) && !_.isNull(masterData) &&
              !_.isUndefined(masterData.domain_detail) && !_.isNull(masterData.domain_detail) &&
              !_.isUndefined(masterData.domain_detail.theme) && !_.isNull(masterData.domain_detail.theme) &&
              !_.isUndefined(masterData.domain_detail.theme.secondary) && !_.isNull(masterData.domain_detail.theme.secondary)) {
              setColor.setProperty('--secondary-color', masterData.domain_detail.theme.secondary);
            }
          }
          else {
            let setColor = document.documentElement.style;
            setColor.setProperty('--main-color', "#404A57");
            setColor.setProperty('--secondary-color', "#2D3846");
            localStorage.setItem('PrimaryColor', "#404A57");
          }


        }
        this.setState({
          posting: false,
        });
      })
      .catch((error) => {
        if (error.response_code === 401) {
          this.setState({ currentUser: undefined })
          localStorage.removeItem('currentUser')
          this.props.history.push("/login");
          this.props.UpdateProfile();
        }
        this.setState({ posting: false });
      });
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    globalThis = this;
    const _this = this;
    const currentUser = Auth.currentUserValue;
    this.sockeListener();
    if (currentUser) {
      this.getCurrentUserBalance();
      this.getClientNotification();
      const isCasinoGameImage = Utils.getCasinoGameImage()
      if (isCasinoGameImage) {
        this.modalShow("CasinoGameImageModalShow")
      }
    }
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    this.removeSockeListener();
    this.setState = () => {
      return;
    };
  }

  updateDimensions = () => {
    this.setState({ is_mobile_view: window.innerWidth < 850 });
  }

  gotoPath = (pathname = "/") => {
    this.props.history.push(pathname);
    this.onChangeDrawer();
  };

  gotoBack = () => {
    const { history } = this.props;
    history.goBack();
  }

  changeColor(color) {
    this.setState({ color_data: color, });
    document.body.style.setProperty('--main-color', color);
    // window.location.reload(false);
  }

  modalShow = (name) => {
    this.setState({ [name]: true })

    // if (!Utils.getCasinoGameImage()) {
    //   this.setState({ CasinoGameImageModalShow: true });
    // }
  };

  modalHide = () => {
    this.setState({
      CasinoGameImageModalShow: false,
      ApplyColorThemeModalShow: false
    }, () => { Utils.removeCasinoGameImage() });
  };

  refresh = () => {
    this.modalHide();
  };

  render() {
    // const currentUser = Auth.currentUserValue;
    const {
      OpenBet,
      SlipToggle,
      loginOpen,
      currentUser,
      masterData,
      profileDetail,
      t,
    } = this.props;
    const { isDrawerOpen, is_mute, location, color_data, is_mobile_view, CasinoGameImageModalShow, AppMasterData, ApplyColorThemeModalShow, isThemeGradient } = this.state;
    const DrawerProps = {
      zIndex: 1030,
      noTouchOpen: true,
      handleWidth: 5,
      overlayColor: "rgba(0,0,0,0.78)",
      drawerStyle: {
        boxShadow:
          "rgba(0, 0, 0, 0.188235) 0px 10px 20px, rgba(0, 0, 0, 0.227451) 0px 6px 6px",
      },
    };

    const CasinoGameImageModalProps = {
      isOpen: CasinoGameImageModalShow,
      toggle: this.modalHide,
    };


    const ApplyColorThemeModalProps = {
      isOpen: ApplyColorThemeModalShow,
      UserProps: currentUser,
      toggle: this.modalHide,
      ParentUserProps: currentUser,
      callBack: () => this.refresh(),
    };


    return (
      <Fragment>
        <Drawer
          open={isDrawerOpen}
          className="custom-drawer"
          {...DrawerProps}
          onChange={this.onChangeDrawer}
          aria-drawer="true"
        >
          <div
            className={
              "drawer-header " + (currentUser ? "drawer-header-inner" : "")
            }
          >
            <i className="icon-cancel my-4" onClick={() => this.toggleDrawer()} />
          </div>
          <div className={"drawer-body " + (currentUser ? "lg" : "")}>
            {currentUser && (
              <Fragment>
                <a
                  className={(window.location.pathname == "/home" ? "active" : "") + " nav-item"}
                  onClick={() => this.gotoPath("/home")}
                >
                  {t("Home")}<span className="icon-arrow-right float-right"></span>
                </a>
                <a
                  className={(window.location.pathname == "/exchange" ? "active" : "") + " nav-item"}
                  onClick={() => this.gotoPath("/exchange")}
                >
                  {t("In Play")}<span className="icon-arrow-right float-right"></span>
                </a>
                <a
                  className={(window.location.pathname == "/upcoming" ? "active" : "") + " nav-item"}
                  onClick={() => this.gotoPath("/upcoming")}
                >
                  {t("Upcoming")}<span className="icon-arrow-right float-right"></span>
                </a>

                <a
                  className={(window.location.pathname == "/cup_winner" ? "active" : "") + " nav-item"}
                  onClick={() => this.gotoPath("/cup_winner")}
                >
                  {t("Cup Winner")}<span className="icon-arrow-right float-right"></span>
                </a>

                <a
                  className={(window.location.pathname == "/casino_supernowa" ? "active" : "") + " nav-item"}
                  onClick={() => this.gotoPath("/casino_supernowa?sports=6")}
                >
                  {t("Casino")}<span className="icon-arrow-right float-right"></span>
                </a>
                <a
                  className={(window.location.pathname == "/ledgers" ? "active" : "") + " nav-item"}
                  onClick={() => this.gotoPath("/ledgers")}
                >
                  {t("Ledger")}<span className="icon-arrow-right float-right"></span>
                </a>
                <a
                  className={(window.location.pathname == "/rules" ? "active" : "") + " nav-item"}
                  onClick={() => this.gotoPath("/rules")}
                >
                  {t("Rules")}<span className="icon-arrow-right float-right"></span>
                </a>
                <a
                  className={(window.location.pathname == "/change-password" ? "active" : "") + " nav-item"}
                  onClick={() => this.gotoPath("/change-password")}
                >
                  {t("Password")}<span className="icon-arrow-right float-right"></span>
                </a>
                <a
                  className={(window.location.pathname == "/settings" ? "active" : "") + " nav-item"}
                  onClick={() => this.gotoPath("/settings")}
                >
                  {t("Settings")}<span className="icon-arrow-right float-right"></span>
                </a>
                <span
                  className={(window.location.pathname == "/logout" ? "active" : "") + " nav-item"}
                  onClick={() => this.modalShow('ApplyColorThemeModalShow')}>
                  {t("Theme Color")}<span className="icon-arrow-right float-right"></span>
                </span>
                <a
                  className={(window.location.pathname == "/logout" ? "active" : "") + " nav-item"}
                  onClick={() => this.logout()}>
                  {t("Logout")}<span className="icon-arrow-right float-right"></span>
                </a>

              </Fragment>
            )}
          </div>
        </Drawer>
        <header className={"app-toolbar " + (isThemeGradient ? "bg-gradient-4" : "")} id="AppToolbar">
          <div className="max-width-container">
            <div className="mobile-hidden d-flex align-items-center justify-content-between">
              <div className="d-flex">
                <i
                  className="icon-menu"
                  onClick={() => this.toggleDrawer()}
                />
                <div>
                  <img src={!_.isUndefined(AppMasterData) && !_.isNull(AppMasterData) && !_.isUndefined(AppMasterData.domain_detail) && !_.isNull(AppMasterData.domain_detail.logo) && (AppMasterData.domain_detail.logo != '') ? AppMasterData.domain_detail.logo : ""} alt="" style={{ height: "30px", width: 'auto' }} />
                </div>
              </div>

              <div className="user-profile">
                <Link className="profile-image" to="#">
                  <img src={Images.PLAYER_JRY} alt="DREAMXBET" />
                </Link>
                {currentUser ? (
                  <Fragment>
                    <div className="user_profile_detail">
                      <div className="float-left">
                        {/* <span className="header-user-nm">{currentUser.first_name + " (" + currentUser.username + ")"}</span> */}
                        <span className="header-user-nm">{currentUser.username}</span>
                        {/* <span className="header-user-coins">Total Coins : <strong>{_.round(this.state.userBal, 2)}</strong></span> */}
                        <span className="header-user-coins">Total Coins : <strong>{_.round(Auth.currentUserValue ? Auth.currentUserValue.balance : 0, 2)}</strong></span>
                        {/* <span className="header-user-coins">Exposure : <strong>{this.state.userExpo}</strong></span> */}
                        <span className="header-user-coins">Exposure : <strong>{Auth.currentUserValue ? Auth.currentUserValue.exposure : 0}</strong></span>
                        {/* {!_.isUndefined(currentUser.exposure) && (
                          <span className="header-user-coins">Exposure Limit : <strong>{_.round(currentUser.exposure, 2)}</strong></span>
                        )} */}
                      </div>
                      {/* <i className="icon-logout float-right" onClick={() => this.logout()}></i> */}
                    </div>
                  </Fragment>
                ) : ('')}
              </div>
              {/* <GlobalNav /> */}
            </div>
          </div>

        </header>
        {
          (this.state.client_msg && (this.state.client_msg.length > 0)) &&
          <GlobalMessage isMobileView={is_mobile_view} admin_agent_msg={this.state.client_msg} />
        }



        {
          is_mobile_view &&
          <div className="sub-header">
            <div className="max-width-container">
              <div className={"sub-header-row " + (isThemeGradient ? "bg-gradient-3" : "")}>
                <div className={"sub-header-cell my-auto" + (window.location.pathname == "/exchange" ? " active" : "")} onClick={() => { this.setState({ selectedSport: {} }, () => { this.props.history.push('/exchange'); }) }}>
                  <img src={(window.location.pathname == "/exchange" ? (is_mobile_view ? Images.INPLAY_GREEN_MOBILE : Images.INPLAY_GREEN_WEB) : (is_mobile_view ? Images.INPLAY_WHITE_MOBILE : Images.INPLAY_WHITE_WEB))} alt="Inplay" />
                  <div className="sub-header-item">Inplay</div>
                </div>
                <div className={"sub-header-cell my-auto" + (window.location.pathname == "/upcoming" ? " active" : "")} onClick={() => { this.setState({ selectedSport: {} }, () => { this.props.history.push('/upcoming'); }) }}>
                  <img src={(window.location.pathname == "/upcoming" ? (is_mobile_view ? Images.UPCOMING_GREEN_MOBILE : Images.UPCOMING_GREEN_WEB) : (is_mobile_view ? Images.UPCOMING_WHITE_MOBILE : Images.UPCOMING_WHITE_WEB))} alt="Upcoming" />
                  <div className="sub-header-item">Upcoming</div>
                </div>
                <div className={"sub-header-cell my-auto" + (window.location.pathname == "/casino_supernowa" ? " active" : "")} onClick={() => { this.setState({ selectedSport: {} }, () => { this.props.history.push('/casino_supernowa?sports=6'); }) }}>
                  <img src={(window.location.pathname == "/casino_supernowa" ? (is_mobile_view ? Images.CASINO_GREEN_MOBILE : Images.CASINO_GREEN_WEB) : (is_mobile_view ? Images.CASINO_WHITE_MOBILE : Images.CASINO_WHITE_WEB))} alt="Casino" />
                  <div className="sub-header-item">Casino</div>
                </div>
                {/* <div className={"sub-header-cell my-auto" + (window.location.pathname == "/cup_winner" ? " active" : "")} onClick={() => { this.setState({ selectedSport: {} }, () => { this.props.history.push('/cup_winner?sports=7'); }) }}> */}
                <div className={"sub-header-cell my-auto" + (window.location.pathname == "/cup_winner" ? " active" : "")} onClick={() => { this.setState({ selectedSport: {} }, () => { this.props.history.push('/cup_winner'); }) }}>
                  <div style={{ display: 'inline-flex' }}>
                    <span className={"fs_i " + (window.location.pathname == "/cup_winner" ? (is_mobile_view ? "icon-trophy active" : "icon-trophy active") : (is_mobile_view ? "icon-trophy" : "icon-trophy"))} />
                    <div className="sub-header-item">Cup Winner</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {
          currentUser && CasinoGameImageModalShow &&
          <CasinoGameImageModal {...CasinoGameImageModalProps} />
        }
        {
          ApplyColorThemeModalShow && (
            <ApplyColorThemeModal {...ApplyColorThemeModalProps} />
          )
        }

        {!_.isUndefined(masterData.domain_detail) && !_.isNull(masterData.domain_detail) &&
          !_.isUndefined(masterData.domain_detail.whatsapp_no) && !_.isNull(masterData.domain_detail.whatsapp_no) &&
          (masterData.domain_detail.whatsapp_no !== '') ?
          <div class="whatsapp-badge" id="whatsapp-badge">
            <a href={`https://wa.me/+${masterData.domain_detail.whatsapp_no}?text=hii`}>
              <img src={Images.WHTSAPP} height="70" alt="" />
            </a>

          </div> :

          !_.isUndefined(profileDetail) && !_.isNull(profileDetail) &&
            (!_.isUndefined(profileDetail.wa_number) && !_.isNull(profileDetail.wa_number) &&
              ((profileDetail.wa_number !== '') && Number(profileDetail.parent_role) == 1)) ?
            <div class="whatsapp-badge" id="whatsapp-badge">
              <a href={`https://wa.me/+${profileDetail.wa_number}?text=hello`}>
                <img src={Images.WHTSAPP} height="70" alt="" />
              </a>
            </div> :

            !_.isUndefined(profileDetail) && !_.isNull(profileDetail) &&
              (!_.isUndefined(profileDetail.wa_number) && !_.isNull(profileDetail.wa_number) &&
                !_.isUndefined(profileDetail.wa_number) && !_.isNull(profileDetail.wa_number) &&
                (profileDetail.wa_number !== '') && !_.isUndefined(profileDetail.wa_visible_to) &&
                !_.isNull(profileDetail.wa_visible_to) && (Number(profileDetail.wa_visible_to) == 2 || Number(profileDetail.wa_visible_to) == 1)) ?
              <div class="whatsapp-badge" id="whatsapp-badge">
                <a href={`https://wa.me/+${profileDetail.wa_number}?text=hello`}>
                  <img src={Images.WHTSAPP} height="70" alt="" />
                </a>
              </div>
              : null
        }
      </Fragment>
    );
  }
}

const DefaultHeaderTrans = withTranslation()(DefaultHeader);

function mapStateToProps(state) {
  const { login, app } = state;
  return {
    isOpen: login.isOpen,
    currentUser: app.currentUser,
    masterData: app.masterData,
    profileDetail: app.profileDetail
  };
}
const mapDispatchToProps = (dispatch) => ({
  loginOpen: () => dispatch(loginOpen()),
  UpdateProfile: () => dispatch(UpdateProfile()),
  SlipToggle: () => dispatch(SlipToggle()),
  OpenBet: (option) => dispatch(OpenBet(option)),
  actionProfileDetail: (data) => dispatch(actionProfileDetail(data)),
  actionMasterData: (data) => dispatch(actionMasterData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultHeaderTrans);