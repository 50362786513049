import React, { Component, Fragment } from "react";
import { DefaultHeader, GlobalLoader, PrivateRoute } from "Components";
import { Route, Switch, Redirect } from "react-router-dom";
import { _, Utils } from "Helpers";
import router from "../../Router";

const { UserRoute, DefaultUserRoute } = router;
class UserLayout extends Component {
  render() {
    const { match } = this.props;
    return (
      <Fragment>
        {this.props.location.pathname !== "/login" && this.props.location.pathname !== "/workinprogress" ? (
          <DefaultHeader {...this.props} />
        ) : null}
        <Switch>
          {_.map(UserRoute, (route, idx) => {
            return route.component ? (
              route.isAuthenticate ? (
                <PrivateRoute
                  key={idx}
                  path={match.url + route.path}
                  exact={route.exact}
                  name={route.name}
                  component={route.component}
                />
              ) : (
                <Route
                  key={idx}
                  path={match.url + route.path}
                  exact={route.exact}
                  name={route.name}
                  component={(props) => (
                    <route.component
                      {...props}
                      page_id={route.page_id || Utils.CreateUUID()}
                    />
                  )}
                />
              )
            ) : null;
          })}
          <Redirect from="*" to={match.url + DefaultUserRoute.path} />
        </Switch>
        {/* <DefaultFooter /> */}
        <GlobalLoader />
      </Fragment>
    );
  }
}

export default UserLayout;
