import AccountInformation from './AccountInformation';
import AccountStatement from './AccountStatement';
import Bet365Poc from './Bet365Poc';
import BetHistory from './BetHistory';
import Casino from './Casino';
import ChangePassword from './ChangePassword';
import CommonOffers from './CommonOffers';
import CommonResponsibleGambling from './CommonResponsibleGambling';
import CommonTermsCondition from './CommonTermsCondition';
import Deposit from './Deposit';
import ExchangeHome from './ExchangeHome';
import ExchangeLayout from './ExchangeLayout';
import UpcomingLayout from './UpcomingLayout';
import Home from './Home';
import Rules from './Rules';
import LoadingView from './LoadingView';
import Notification from './Notification';
import Poc from './Poc';
import ProfitLoss from './ProfitLoss';
import Signup from './Signup';
import SportsbookHome from './SportsbookHome';
import SportsbookLayout from './SportsbookLayout';
import Withdraw from './Withdraw';
import Launguage from './Launguage';
import Ledgers from './Ledgers';
import LedgersDetails from './LedgersDetails';
import Games from './Games';
import CasinoSupernowa from './CasinoSupernowa';
import ComingSoon from './ComingSoon';
import WinnerLayout from './WinnerLayout';
export {
  WinnerLayout,
  AccountInformation,
  AccountStatement,
  Bet365Poc,
  BetHistory,
  Casino,
  ChangePassword,
  CommonOffers,
  CommonResponsibleGambling,
  CommonTermsCondition,
  Deposit,
  ExchangeHome,
  ExchangeLayout,
  Home,
  Rules,
  LoadingView,
  Notification,
  Poc,
  ProfitLoss,
  Signup,
  SportsbookHome,
  SportsbookLayout,
  Withdraw,
  Launguage,
  Ledgers,
  LedgersDetails,
  Games,
  UpcomingLayout,
  CasinoSupernowa,
  ComingSoon
};
