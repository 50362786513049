import React, { Component, Fragment } from 'react';
import {
  Row, Col, Collapse, TabContent, TabPane
} from 'reactstrap';
import { _, Utils } from 'Helpers';
import { connect } from 'react-redux';
class MatchScoreBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      showLiveTVnews: false,
      showLiveTV: true,
      activeTab: 'score_1',
      activeTVTab: '',
      TooltipGoals: false,
      TooltipRedCard: false,
      TooltipYellowCard: false,
      TooltipCorners: false,
      isMobileView: false,
      fromDeatail: this.props && this.props.fromDeatail,
      isThemeGradient: Utils.getCookie('isThemeGradient') ? Number(Utils.getCookie('isThemeGradient')) == 1 ? true : false : false,
    }
  }

  showCollapseLiveTVScore = () => {
    this.setState({ showLiveTV: !this.state.showLiveTV })
  }

  showCollapseLiveTVScore = () => {
    this.setState({ showLiveTVnews: !this.state.showLiveTVnews })
  }

  updateDimensions = () => {
    this.setState({
      isMobileView: window.innerWidth < 850
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    document.addEventListener("visibilitychange", this.handleBrowswer);
  }

  componentWillMount() {
    this.updateDimensions();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    document.removeEventListener("visibilitychange", this.handleBrowswer);
    this.setState = (state, callback) => {
      return;
    };
  }

  onFocus = (score, tv) => {
    this.setState({ activeTab: null, activeTVTab: null })
    // this.setState({ activeTab: this.state.activeTab, activeTVTab: this.state.activeTVTab })
    this.setState({ activeTab: score, activeTVTab: tv })
  }

  // brower change tabs and screen
  handleBrowswer = (e) => {
    if (e.type == "visibilitychange") {
      this.onFocus(this.state.activeTab, this.state.activeTVTab)
      // window.location.reload(false);
      this.setState({
        data: true
      }, () => {
        setTimeout(() => { this.setState({ data: false }) }, 100)
      })
    }
  }

  render() {
    const { showLiveTV, activeTab, activeTVTab, isMobileView, showLiveTVnews, data, isThemeGradient } = this.state
    const { EventDetails, masterData } = this.props;
    return (
      <Col className={isMobileView ? "match_score_board mb-2" : "match_score_board mt-4 mb-2"}>
        {
          <Row className={"board_sub_header " + (isThemeGradient ? "bg-gradient-4" : "")}>
            <Col className={activeTab == 'score_1' ? 'active' : ''} onClick={() => { if (this.state.activeTab == "score_1") { this.setState({ showLiveTV: !this.state.showLiveTV, activeTab: '' }) } else if (this.state.activeTab != "score_1") { this.setState({ showLiveTV: true, activeTab: 'score_1' }) } }}>Score 1</Col>
            <Col className={activeTab == 'score_2' ? 'active' : ''} onClick={() => { if (this.state.activeTab == "score_2") { this.setState({ showLiveTV: !this.state.showLiveTV, activeTab: '' }) } else if (this.state.activeTab != "score_2") { this.setState({ showLiveTV: true, activeTab: 'score_2' }) } }}>Score 2</Col>
            <Col className={activeTab == 'score_3' ? 'active' : ''} onClick={() => { if (this.state.activeTab == "score_3") { this.setState({ showLiveTV: !this.state.showLiveTV, activeTab: '' }) } else if (this.state.activeTab != "score_3") { this.setState({ showLiveTV: true, activeTab: 'score_3' }) } }}>Score 3</Col>
            <Col className={activeTVTab == 'livetv1' ? 'active' : ''} onClick={() => { if (this.state.activeTVTab == "livetv1") { this.setState({ showLiveTVnews: !this.state.showLiveTVnews, activeTVTab: '' }) } else if (this.state.activeTVTab != "livetv1") { this.setState({ showLiveTVnews: true, activeTVTab: 'livetv1' }) } }}>Live TV 1</Col>
            <Col className={activeTVTab == 'livetv2' ? 'active' : ''} onClick={() => { if (this.state.activeTVTab == "livetv2") { this.setState({ showLiveTVnews: !this.state.showLiveTVnews, activeTVTab: '' }) } else if (this.state.activeTVTab != "livetv2") { this.setState({ showLiveTVnews: true, activeTVTab: 'livetv2' }) } }}>Live TV 2</Col>
          </Row>
        }
        <Collapse isOpen={showLiveTV}>
          <Row>
            <Col className="p-0">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="score_1">
                  {
                    <Fragment>
                      {(activeTab === 'score_1') && !_.isNull(EventDetails.scoreUrl3) ?
                        <iframe frameBorder="0px" src={data ? this.props.EventDetails.scoreUrl3 : this.props.EventDetails.scoreUrl3} width="100%" title="PD Score card Active" height="170px" />
                        :
                        <div className="text-center noRecord-msg">No Score available</div>
                      }
                    </Fragment>

                  }

                </TabPane>
                {/* <TabPane tabId="score_2">
                  {(activeTab === 'score_2') && !_.isNull(EventDetails.scoreUrl) && !_.isEmpty(EventDetails.scoreUrl) ?
                    <iframe frameBorder="0px" src={EventDetails.scoreUrl} width="100%" title="PD Score card Active" height="170px" />
                    :
                    <div className="text-center noRecord-msg">No Score available</div>
                  }
                </TabPane> */}

                <TabPane tabId="score_2">
                  {(Number(EventDetails.sports_id) == 1 || Number(EventDetails.sports_id) == 3) ?
                    <Fragment>
                      {(!_.isUndefined(EventDetails.scoreUrl2) && !_.isNull(EventDetails.scoreUrl2) && !_.isEmpty(EventDetails.scoreUrl2)) ?
                        <iframe frameBorder="0px" src={EventDetails.scoreUrl2} title="PD Score Card Active" height="170px" width="100%" />
                        :
                        <div className="text-center noRecord-msg">No Score available</div>}
                    </Fragment>

                    :
                    <Fragment>
                      {(!_.isUndefined(EventDetails.scoreUrl) && !_.isNull(EventDetails.scoreUrl) && !_.isEmpty(EventDetails.scoreUrl)) ?
                        <iframe frameBorder="0px" src={EventDetails.scoreUrl} title="PD Score Card Active" height="170px" width="100%" />
                        :
                        <div className="text-center noRecord-msg">No Score available</div>}

                    </Fragment>
                  }
                </TabPane>




                <TabPane tabId="score_3">
                  <Fragment>
                    {
                      (!_.isUndefined(EventDetails.scoreUrl2) && !_.isNull(EventDetails.scoreUrl2) && !_.isEmpty(EventDetails.scoreUrl2)) ? (
                        <iframe frameBorder="0px" src={EventDetails.scoreUrl2} className="w-100" title="score_1" width="100%" height="170px" ></iframe>
                      ) : (<div className="text-center noRecord-msg">No Score available</div>)
                    }
                  </Fragment>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Collapse>


        <Collapse isOpen={showLiveTVnews} >
          <Row>
            <Col className="p-0" style={{ minHeight: isMobileView ? '204px' : '255px' }}>
              <TabContent activeTab={activeTVTab} className={this.props.scrollIframe ? "scoreboard-top" : ""}>
                <TabPane tabId="livetv1">
                  {
                    EventDetails && EventDetails.streamingUrl1 && !_.isEmpty(EventDetails.streamingUrl1) ?
                      (
                        !isMobileView && <div className="live_tv text-center">
                          {
                            activeTVTab == 'livetv1' &&
                            // <iframe frameBorder="0px" src={EventDetails.streamingUrl1} title="live_tv" width="100%" height="255px"></iframe>
                            <iframe src={EventDetails.streamingUrl1}
                              title={`https://${!_.isUndefined(masterData) && !_.isNull(masterData) && !_.isUndefined(masterData.domain_detail) && !_.isNull(masterData.domain_detail) && !_.isUndefined(masterData.domain_detail.domain) && !_.isNull(masterData.domain_detail.domain) && (masterData.domain_detail.domain !== '') ? masterData.domain_detail.domain : "silvarbet.com"}/`}
                            ></iframe>
                          }
                        </div>
                      )
                      : (<div className="text-center noRecord-msg">No Live TV available</div>)
                  }
                  {
                    isMobileView && EventDetails && EventDetails.scoreUrl && !_.isEmpty(EventDetails.scoreUrl) &&
                    <div className="score_1 score-sub-window">
                      {
                        activeTVTab == 'livetv1' &&
                        // <iframe frameBorder="0px" src={EventDetails.streamingUrl1} title="live_tv" width="100%" height="204px"></iframe>
                        <iframe src={EventDetails.streamingUrl1}
                          title={`https://${!_.isUndefined(masterData) && !_.isNull(masterData) && !_.isUndefined(masterData.domain_detail) && !_.isNull(masterData.domain_detail) && !_.isUndefined(masterData.domain_detail.domain) && !_.isNull(masterData.domain_detail.domain) && (masterData.domain_detail.domain !== '') ? masterData.domain_detail.domain : "silvarbet.com"}/`}
                        ></iframe>
                      }
                    </div>
                  }
                </TabPane>
                <TabPane tabId="livetv2">
                  {
                    EventDetails && EventDetails.streamingUrl1 && !_.isEmpty(EventDetails.streamingUrl1) ?
                      (
                        !isMobileView && <div className="live_tv text-center">
                          {
                            activeTVTab == 'livetv2' &&
                            // <iframe frameBorder="0px" src={EventDetails.streamingUrl1} title="live_tv" width="100%" height="255px"></iframe>
                            <iframe src={EventDetails.streamingUrl1}
                              title={`https://${!_.isUndefined(masterData) && !_.isNull(masterData) && !_.isUndefined(masterData.domain_detail) && !_.isNull(masterData.domain_detail) && !_.isUndefined(masterData.domain_detail.domain) && !_.isNull(masterData.domain_detail.domain) && (masterData.domain_detail.domain !== '') ? masterData.domain_detail.domain : "silvarbet.com"}/`}
                            ></iframe>
                          }
                        </div>
                      )
                      : (<div className="text-center noRecord-msg">No Live TV available</div>)
                  }
                  {
                    isMobileView && EventDetails && EventDetails.scoreUrl && !_.isEmpty(EventDetails.scoreUrl) &&
                    <div className="score_1 score-sub-window">
                      {
                        activeTVTab == 'livetv2' &&
                        // <iframe frameBorder="0px" src={EventDetails.streamingUrl1} title="live_tv" width="100%" height="204px"></iframe>
                        <iframe src={EventDetails.streamingUrl1}
                          title={`https://${!_.isUndefined(masterData) && !_.isNull(masterData) && !_.isUndefined(masterData.domain_detail) && !_.isNull(masterData.domain_detail) && !_.isUndefined(masterData.domain_detail.domain) && !_.isNull(masterData.domain_detail.domain) && (masterData.domain_detail.domain !== '') ? masterData.domain_detail.domain : "silvarbet.com"}/`}
                        ></iframe>
                      }
                    </div>
                  }
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Collapse>
      </Col>
    )
  }
}

MatchScoreBoard.defaultProps = {}

function mapStateToProps(state) {
  const { login, app } = state;
  return {
    masterData: app.masterData,
    profileDetail: app.profileDetail
  };
}

// export default MatchScoreBoard;
export default connect(mapStateToProps)(MatchScoreBoard);