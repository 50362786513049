import React, { Component } from 'react';
import { SiteContainer, TitleComponent } from 'Components';
import { Images } from 'Constants';
import { Row, Col, Container } from 'reactstrap';

class Casino extends Component {
  render() {
    return (
      <SiteContainer {...this.props}>
        <TitleComponent title={"Bets History"}/>
        <Container fluid className='gutters-container-7px'>
          <Row>
            <Col className="text-center">
              <img src={Images.CASINO} alt="" className="max-wid"/>
            </Col>
          </Row>

        </Container>
      </SiteContainer>
    );
  }
}

export default Casino;
