import React, { Fragment } from 'react';
//import { NavLink } from "react-router-dom";
// import Select from 'react-select';
import { withTranslation } from 'react-i18next';


//import { SubNavigationScrollbars } from 'Components';

function SiteContainer(props) {
  //const { match, header, staticPage, t  } = props
  // console.log(props)
  // const options = [
  //   { id: 'decimal', name: 'Decimal' },
  //   { id: 'decimal', name: 'Decimal' },
  //   { id: 'decimal', name: 'Decimal' }
  // ]
  // const LangOptions = [
  //   { id: 'en', name: 'Eng' },
  //   { id: 'hi', name: 'हिन्दी' },
  //   { id: 'ben', name: 'বাংলা' }
  // ]
  // const CustomNavLink = ({ to, children }) => {
  //   return (
  //     <NavLink to={to} isActive={(match, location) => {
  //       if (!match) {
  //         return false;
  //       }
  //       let s = location.pathname
  //       s = s.substring(0, s.indexOf('/details'))
  //       return (
  //         location.pathname == match.url || s == match.url
  //       )
  //     }}>
  //       { children }
  //     </NavLink>
  //   )
  // }


  return (
    <Fragment>
      {/* {
        header &&
        <header className="app-toolbar-bottom">
          <div className="max-width-container">

              <div className="right-sub-navigation">
                <div className="cell">
                  <Select
                    className='react-select outline'
                    classNamePrefix="react-select"
                    defaultValue={options[0]}
                    isSearchable={false}
                    options={options}
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ id }) => id}
                    // menuIsOpen={true}
                  />
                </div>
                <div className="cell">
                    <Select
                      className='react-select outline'
                      classNamePrefix="react-select"
                      defaultValue={LangOptions[0]}
                      isSearchable={false}
                      options={LangOptions}
                      getOptionLabel={({ name }) => name}
                      getOptionValue={({ id }) => id}
                      // menuIsOpen={true}
                    />
                </div>
              </div>
              
              <div className="sub-navigation-scroll">
                <SubNavigationScrollbars autoHeight>
                  <nav className="sub-navigation">
                    {
                      staticPage ?
                      <span onClick={() => props.history.goBack()}>{t('home')}</span>
                      :
                      <CustomNavLink to={`${match.url}`}>{t('home')}</CustomNavLink>
                    }
                    {
                      !staticPage &&
                      <Fragment>
                        <CustomNavLink to={`${match.url}`}>{t('home')}</CustomNavLink>
                        <CustomNavLink to={`${match.url}/in-play`}>{t('in play')}</CustomNavLink>
                      </Fragment>
                    }
                    

                    <NavLink to={`/offers`} exact>{t('offers')}</NavLink>
                    <NavLink to={`/responsible-gambling`} exact>{t('responsible gambling')}</NavLink>
                    <NavLink to={`/terms-and-conditions`} exact>{t('terms & conditions')}</NavLink>
                  </nav>
                </SubNavigationScrollbars>
              </div>
          </div>
        </header>
      } */}
      <div className='site-content' id='SiteContent'>
        <div className="max-width-container">
          {props.children}
        </div>
      </div>
    </Fragment>
  )
}

export default withTranslation()(SiteContainer);