import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { API, Http, _, } from 'Helpers';
import { TitleComponent } from "Components";
import { Auth } from 'Service';
import { LoadingView } from 'Views';
class CasinoSupernowa extends Component {

  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      casinoSupernowaURL: '',
    }
  }

  componentDidMount (){
    this.getCasinoSupernowaURL();
  }

  getCasinoSupernowaURL = () => {
    const currentUser = Auth.currentUserValue;
    let param = {
      "user_guid": currentUser.user_guid,
    }
    this.setState({ posting: true })
    Http.post(API.CASINO_SUPERNOWA, param).then(response => {
      if (response && !_.isEmpty(response) && response.data && !_.isEmpty(response.data) && response.data.lobbyURL && !_.isEmpty(response.data.lobbyURL))
        this.setState({ casinoSupernowaURL:  response.data.lobbyURL});

      this.setState({ posting: false })
    }).catch(error => {
      this.setState({ posting: false }, () => {
        const { history } = this.props;
        history.goBack();
      })
    });
  }

  render() {
    const { casinoSupernowaURL, posting } = this.state;
    return (
      <Container className="px-0">
        <TitleComponent title={"Casino Supernowa"}/>
        {posting && <LoadingView />}
        {
          casinoSupernowaURL && !_.isEmpty(casinoSupernowaURL) &&
            <iframe src={casinoSupernowaURL} className="casinosupernowa"></iframe>
        }
      </Container>
    )
  }
}

export default CasinoSupernowa;


