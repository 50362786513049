import React, { Component } from 'react'
import { Modal } from 'reactstrap'
import { Utils, AuthHelp } from 'Helpers';
import { Images } from 'Constants';


class CasinoGameImageModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isOpen, toggle } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle} className="casino_game_images" size={'lg'} backdrop='static'>
        <img src={Images.CASINO_GAME_LIST} alt="Casino Game" onClick={toggle}/>
      </Modal>
    )
  }
}



CasinoGameImageModal.defaultProps = {
  isOpen: true,
  toggle: () => { }
}

export default CasinoGameImageModal;