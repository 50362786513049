/* eslint-disable no-mixed-operators */
// wrapper for localstorage and auth object setting
import React, { Component, Fragment } from 'react';
import Validation from '../Validation'
import { CONSTANT, Images } from 'Constants'
import _ from "lodash";
import moment from 'moment'

import Moment from 'react-moment';
import 'moment-timezone';
import { notify } from 'react-notify-toast';
import { Auth } from 'Service';
import config from 'config';
import { Trans } from 'react-i18next';
import { Modal, Button } from 'reactstrap';
import NavigationPrompt from 'react-router-navigation-prompt';

let LINEUP_MASTER_DATA = []
let HEADER_CONFIG = []
let _this = null;

class NavigationPromptWrap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      when: false
    }
  }

  static Init(when) {
    _this.setState({
      when
    })
  }
  render() {
    _this = this;
    const { when } = this.state;
    return (
      <NavigationPrompt
        renderIfNotActive={true}
        when={when}>
        {({ isActive, onCancel, onConfirm }) => {
          if (isActive) {
            return (
              <Modal isOpen={true} className='alert-dialog' size={'sm'}>
                <div className='title'><Trans>Alert!</Trans></div>
                <div><Trans>This will reset your team, are you sure you want to proceed?</Trans></div>
                <div className="alert-dialog-footer">
                  <Button color="success" onClick={onCancel}><Trans>Cancel</Trans></Button>
                  <Button color="secondary" onClick={onConfirm}><Trans>Continue</Trans></Button>
                </div>
              </Modal>
            );
          }
          return <div />
        }}
      </NavigationPrompt>

    )
  }
}

const Utils = {

  NavigationPrompt: (when) => {
    NavigationPromptWrap.Init(when)
  },

  ordinal_suffix_of: (i) => {
    if (typeof foo !== "number") {
      i = Number(i)
    }
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  },
  getDateYearsOld: () => {
    let date = new Date()
    let year = date.getFullYear() - 21
    date.setFullYear(year)
    return date;
  },

  isValid: (name, state) => {
    if (state === '' || state === null) return false;
    return !Validation.init(name, state)
  },

  isInvalid: (name, state) => {
    return Validation.init(name, state)
  },

  isFormValid: (forms, state) => {
    let arr = []
    _.map(forms, (item) => {
      let itemValidate = Validation.init(item, state[item]) === false;
      if (itemValidate) {
        arr.push(itemValidate);
      }
    })
    return arr.length === forms.length;
  },

  setProfile: (data) => {
    localStorage.setItem('profile', JSON.stringify(data));
  },

  getProfile: () => {
    const profile = localStorage.getItem('profile');
    if (!profile) {
      return null;
    }
    return JSON.parse(profile);
  },

  removeProfile: () => {
    localStorage.removeItem('profile');
  },

  omit: () => (obj, arr) => Object.keys(obj).reduce((res, key) => {
    if (arr.indexOf(key) === -1) {
      res[key] = obj[key]
    }
    return res
  }, {}),

  getUtcToLocal: (date) => {
    let localDate = moment(date).utc(true).local().format();
    return localDate;
  },

  getTodayTimestamp: () => {
    let date = new Date()
    let today = moment(date).utc(true).local().valueOf();
    return today;
  },

  setCookie: (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  },

  getCookie: (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },

  numberWithCommas: (x, symbol = '') => {
    if (x === undefined) return x;
    x = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return symbol + x || symbol + '0';
  },
  onImgError: () => {
    return Images.DEFAULT_IMG
  },
  getMasterData: () => {
    return LINEUP_MASTER_DATA || [];
  },
  setMasterData: (data) => {
    LINEUP_MASTER_DATA = data;
  },
  getHeaderConfig: () => {
    return HEADER_CONFIG || [];
  },
  setHeaderConfig: (data) => {
    HEADER_CONFIG = data;
  },
  getOddMinValue: (data) => {
    return Math.min.apply(null, data) || [];
  },
  getOddMaxValue: (data) => {
    return Math.max.apply(null, data) || [];
  },

  DateFormat: (date, format = CONSTANT.DATE_FORMAT) => {
    return (
      <Moment format={format} tz={CONSTANT.TIMEZONE}>{date}</Moment>
    )
  },
  dateTimeFormat: (date, format = CONSTANT.DATE_FORMAT) => {
    let localDate = moment.utc(date).local().format();
    return (
      <Moment format={format} tz={CONSTANT.TIMEZONE}>{localDate}</Moment>
    )
  },


  checkedGameDateISTodayDate: (game_date) => {
    return moment.utc(new Date()).local().format("MMM DD, YYYY") == moment.utc(game_date).local().format("MMM DD, YYYY");
  },

  checkedGameDateISTomorrowDate: (game_date) => {
    return moment.utc(new Date().getTime() + 24 * 60 * 60 * 1000).local().format("MMM DD, YYYY") == moment.utc(game_date).local().format("MMM DD, YYYY");
  },

  checkedGameDateISPreviousDate: (game_date) => {
    return moment.utc(new Date().getTime() + 24 * 60 * 60 * 1000).local().format("MMM DD, YYYY") > moment.utc(game_date).local().format("MMM DD, YYYY");
  },
  checkedGameDateISFutureDate: (game_date) => {
    return moment.utc(new Date().getTime() + 24 * 60 * 60 * 1000).local().format("MMM DD, YYYY") < moment.utc(game_date).local().format("MMM DD, YYYY");
  },




  DobFormat: (date) => {
    return moment(date).format('MMM DD, YYYY')
  },
  DobFormatView: (date) => {
    return moment(date).format('MMM DD, YYYY')
  },

  Calculate: (type = '', item = {}) => {
    let total_wager = Number(item.total_wager_amt);
    let total_max_win = _.sumBy(item.bets, function (o) { return (Number(o.wager_amt) * Number(o.payout_ratio)); });

    switch (type) {
      case '1': // Winnings
        let Winnings = _.sumBy(item.bets, function (o) {
          return (o.bet_point <= item.total_score) ? (Number(o.wager_amt) * Number(o.payout_ratio)) : 0;
        });
        return Utils.numberWithCommas(Winnings);

      case '2': // Max Pay
        return Utils.numberWithCommas(total_max_win + total_wager)

      case '3': // Tier Count
        let _arr = []
        _.forEach(item.bets, o => {
          if (o.bet_point <= item.total_score) {
            _arr.push(o)
          }
        })
        return _arr.length;

      case '4':
        return Utils.numberWithCommas(item.wager_amt * item.payout_ratio)

      default:
        break;
    }
  },

  notify: (message = "", type = 'success', duration = 2000, customColor = { background: '#0E1717', text: "#FFFFFF" }) => {
    notify.show(message, type, duration, customColor);
  },
  isDev: () => {
    return process.env.NODE_ENV === 'development'
  },

  geti18nextLng: () => {
    // const i18nextLng = localStorage.getItem('i18nextLng');
    const i18nextLng = Utils.getCookie('dreamxbet_lang');
    if (!i18nextLng) {
      return CONSTANT.DEFAULT_LNG;
    }
    return i18nextLng;
  },
  s3UrlConvert: (param) => {
    let s3 = config.s3;
    let url = [
      s3.BUCKET,
      s3.BUCKET_DATA_PREFIX,
      // Utils.geti18nextLng(),
      ...param
    ]
    url = url.join('_')
    url = url.replace("/_", "/")
    return url + '.json'
  },

  SetSessionStorage: (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
  },

  GetSessionStorage: (key) => {
    const data = sessionStorage.getItem(key);
    if (!data) {
      return null;
    }
    return JSON.parse(data);
  },

  RemoveSessionStorage: (key) => {
    sessionStorage.removeItem(key);
  },

  HandleResponse: (response) => {
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if ([401, 403].indexOf(response.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          Auth.logout();
          // location.reload(true);
        }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      return data;
    });
  },

  CreateUUID: () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  },

  setMultiBetSlip: (name, data) => {
    data = data === "" ? "" : Number(data)
    localStorage.setItem(name + '_multi', JSON.stringify(data))
  },
  getMultiBetSlip: (name) => {
    const betSlip = localStorage.getItem(name + '_multi');
    if (!betSlip) {
      return '';
    }
    return JSON.parse(betSlip);
  },
  removeMultiBetSlip: (name) => {
    localStorage.removeItem(name + '_multi');
  },

  setBetSlip: (name, data) => {
    localStorage.setItem(name, JSON.stringify(data))
  },
  getBetSlip: (name) => {
    const betSlip = localStorage.getItem(name);
    if (!betSlip) {
      return {};
    }
    return JSON.parse(betSlip);
  },
  removeBetSlip: (name) => {
    localStorage.removeItem(name);
  },
  getMarketName: (selectionId, runners) => {
    // let MarketSet = ['1X', 'X2', '12', '1/X', '1/1', '1/2', '2/2', '2/1', '2/X', 'X/1', 'X/2', 'X/X']
    // let Name = name;

    // if(_.includes(MarketSet, name)) {
    //   let _arr = []
    //   _.forEach(name, obj => {
    //     _.forEach(participants, item => {
    //       if(obj === item.Position) {
    //         _arr.push(item.Name)
    //       }           
    //     })
    //     if(obj === '/') {
    //       _arr.push(obj)
    //     }
    //     if( obj && obj.toLowerCase() === 'x') {
    //       _arr.push(CONSTANT.LABEL_DRAW)
    //     } 
    //   })
    //   Name = name.length === 2 ? _arr.join(' or ') : _arr.join('')
    // } else {
    // }
    let Name = ''
    _.forEach(JSON.parse(runners), item => {
      if (selectionId === item.selectionId) {
        Name = item.runnerName
      }
    })
    return Name
  },
  TwoDecimalVal: (value) => {
    var num = Number(value)
    return num.toFixed(2)
  },
  ThreeDecimalVal: (value) => {
    var num = Number(value)
    return num.toFixed(3)
  },
  RemovedDecimalVal: (value) => {
    var num = Number(value)
    return num.toFixed(0)
  },

  getDateTimeFormat: (date, format = CONSTANT.DATE_FORMAT) => {
    let localDate = moment.utc(date).local().format(format);
    return localDate;
  },

  getSelectedOdds: (odds, type) => {
    return type === 1 ? odds.ex.availableToBack : odds.ex.availableToLay
  },
  containsObject: (obj, list) => {
    var x;
    for (x in list) {
      if (list[x].selection_id === obj.selection_id) {
        return true;
      }
    }
    return false;
  },

  //Casino game image modal
  setCasinoGameImage: () => {
    localStorage.setItem('show_casino_game_image', true)
  },
  getCasinoGameImage: () => {
    const show_casino_game_image = localStorage.getItem('show_casino_game_image');
    if (!show_casino_game_image) {
      return false;
    }
    return show_casino_game_image;
  },
  removeCasinoGameImage: () => {
    localStorage.removeItem('show_casino_game_image');
  },

}


class Spinner extends Component {
  render() {
    return (
      <svg className={`spinner ${this.props.light ? 'light' : ''} ${this.props.className ? this.props.className : ''}`} viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="3"></circle>
      </svg>
    )
  }
}
class Posting extends Component {
  render() {
    return (
      <svg className='spinner-posting' viewBox="0 0 128 128"><g><path d="M59.6 0h8v40h-8V0z" fill="#dbdbdb" fill-opacity="1" /><path d="M59.6 0h8v40h-8V0z" fill="#f8f8f8" fill-opacity="0.2" transform="rotate(30 64 64)" /><path d="M59.6 0h8v40h-8V0z" fill="#f8f8f8" fill-opacity="0.2" transform="rotate(60 64 64)" /><path d="M59.6 0h8v40h-8V0z" fill="#f8f8f8" fill-opacity="0.2" transform="rotate(90 64 64)" /><path d="M59.6 0h8v40h-8V0z" fill="#f8f8f8" fill-opacity="0.2" transform="rotate(120 64 64)" /><path d="M59.6 0h8v40h-8V0z" fill="#f4f4f4" fill-opacity="0.3" transform="rotate(150 64 64)" /><path d="M59.6 0h8v40h-8V0z" fill="#f1f1f1" fill-opacity="0.4" transform="rotate(180 64 64)" /><path d="M59.6 0h8v40h-8V0z" fill="#ededed" fill-opacity="0.5" transform="rotate(210 64 64)" /><path d="M59.6 0h8v40h-8V0z" fill="#e9e9e9" fill-opacity="0.6" transform="rotate(240 64 64)" /><path d="M59.6 0h8v40h-8V0z" fill="#e6e6e6" fill-opacity="0.7" transform="rotate(270 64 64)" /><path d="M59.6 0h8v40h-8V0z" fill="#e2e2e2" fill-opacity="0.8" transform="rotate(300 64 64)" /><path d="M59.6 0h8v40h-8V0z" fill="#dfdfdf" fill-opacity="0.9" transform="rotate(330 64 64)" /><animateTransform attributeName="transform" type="rotate" values="0 64 64;30 64 64;60 64 64;90 64 64;120 64 64;150 64 64;180 64 64;210 64 64;240 64 64;270 64 64;300 64 64;330 64 64" calcMode="discrete" dur="1080ms" repeatCount="indefinite"></animateTransform></g></svg>
    )
  }
}
class BoxLoader extends Component {
  render() {
    return (
      <div className='page-loading relative'>
        <Spinner />
        <div className='page-loading-title'><Trans>Loading...</Trans></div>
      </div>
    )
  }
}

class FlexboxLoader extends Component {
  render() {
    const { style, init, className } = this.props;
    return (
      <div className={`flexbox-loader-wrapper ${className ? className : ''} ${init ? 'show' : ''}`} style={style}>
        <div className="flexbox-loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    )
  }
}
class TriangleLoader extends Component {
  render() {
    return (
      <Fragment>
        <div className="loader-field">
          <svg viewBox="0 0 80 80">
            <circle id="test" cx="40" cy="40" r="32"></circle>
          </svg>
        </div>
        <div className="loader-field triangle">
          <svg viewBox="0 0 86 80">
            <polygon points="43 8 79 72 7 72"></polygon>
          </svg>
        </div>
        <div className="loader-field">
          <svg viewBox="0 0 80 80">
            <rect x="8" y="8" width="64" height="64"></rect>
          </svg>
        </div>
      </Fragment>
    )
  }
}


export default { ...Utils, Spinner, Posting, BoxLoader, FlexboxLoader, TriangleLoader, NavigationPromptWrap };