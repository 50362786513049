import { BehaviorSubject } from 'rxjs';
import { API, AuthHelp, Http, Utils, _ } from 'Helpers';
import { CONSTANT } from 'Constants';
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
const AppMasterData = new BehaviorSubject(JSON.parse(localStorage.getItem('masterData')));

function login(username, password, location, isp) {
  return new Promise((resolve, reject) => {
    let param = {
      username,
      password,
      device_type: CONSTANT.DEVICE_TYPE,
      device_id: CONSTANT.DEVICE_ID,
      location,
      isp,
      token: "",
    }
    Http.post(API.LOGIN, param).then(response => {
      AuthHelp.setAuth(response.data.Sessionkey)
      localStorage.setItem('currentUser', JSON.stringify(response.data.user_profile));
      let LoginData = response.data.user_profile

      if (!_.isUndefined(LoginData) && !_.isNull(LoginData) && !_.isUndefined(LoginData.theme) && !_.isNull(LoginData.theme)) {
        let setColor = document.documentElement.style;
        if (!_.isUndefined(LoginData) && !_.isNull(LoginData) &&
          !_.isUndefined(LoginData.theme) && !_.isNull(LoginData.theme) &&
          !_.isUndefined(LoginData.theme.primary) && !_.isNull(LoginData.theme.primary)) {
          setColor.setProperty('--main-color', LoginData.theme.primary);
          localStorage.setItem('PrimaryColor', LoginData.theme.primary);
        }
        if (!_.isUndefined(LoginData) && !_.isNull(LoginData) &&
          !_.isUndefined(LoginData.theme) && !_.isNull(LoginData.theme) &&
          !_.isUndefined(LoginData.theme.secondary) && !_.isNull(LoginData.theme.secondary)) {
          setColor.setProperty('--secondary-color', LoginData.theme.secondary);
        }
      }
      else if (!_.isUndefined(AppMasterData) && !_.isNull(AppMasterData) &&
        !_.isUndefined(AppMasterData.domain_detail) && !_.isNull(AppMasterData.domain_detail) &&
        !_.isUndefined(AppMasterData.domain_detail.theme) && !_.isNull(AppMasterData.domain_detail.theme) &&
        !_.isUndefined(AppMasterData.domain_detail.theme.primary) && !_.isNull(AppMasterData.domain_detail.theme.primary)
      ) {
        let setColor = document.documentElement.style;
        if (!_.isUndefined(AppMasterData) && !_.isNull(AppMasterData) &&
          !_.isUndefined(AppMasterData.domain_detail) && !_.isNull(AppMasterData.domain_detail) &&
          !_.isUndefined(AppMasterData.domain_detail.theme) && !_.isNull(AppMasterData.domain_detail.theme) &&
          !_.isUndefined(AppMasterData.domain_detail.theme.primary) && !_.isNull(AppMasterData.domain_detail.theme.primary)) {
          setColor.setProperty('--main-color', AppMasterData.domain_detail.theme.primary);
          localStorage.setItem('PrimaryColor', AppMasterData.domain_detail.theme.primary);
        }
        if (!_.isUndefined(AppMasterData) && !_.isNull(AppMasterData) &&
          !_.isUndefined(AppMasterData.domain_detail) && !_.isNull(AppMasterData.domain_detail) &&
          !_.isUndefined(AppMasterData.domain_detail.theme) && !_.isNull(AppMasterData.domain_detail.theme) &&
          !_.isUndefined(AppMasterData.domain_detail.theme.secondary) && !_.isNull(AppMasterData.domain_detail.theme.secondary)) {
          setColor.setProperty('--secondary-color', AppMasterData.domain_detail.theme.secondary);
        }
      }
      else {
        let setColor = document.documentElement.style;
        setColor.setProperty('--main-color', "#404A57");
        setColor.setProperty('--secondary-color', "#2D3846");
        localStorage.setItem('PrimaryColor', "#404A57");

      }




      currentUserSubject.next(response.data.user_profile);
      return resolve(response);
    }).catch(error => {
      return reject(error);
    });
  })
}

// function login(username, password, location) {
//   const requestOptions = {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/json' },
//     body: JSON.stringify({ 
//       username, 
//       password,
//       device_type: CONSTANT.DEVICE_TYPE,
//       device_id: CONSTANT.DEVICE_ID,
//       location
//     })
//   };
//   return fetch(API.LOGIN, requestOptions)
//     .then(status)
//     .then(user => {
//       console.log(user)
//       // store user details and jwt token in local storage to keep user logged in between page refreshes
//       AuthHelp.setAuth(user.data.Sessionkey)
//       localStorage.setItem('currentUser', JSON.stringify(user.data.user_profile));
//       currentUserSubject.next(user.data.user_profile);
//       return user;
//     }).catch(error => {
//       console.log(error)
//       Utils.notify(error.message != '' ? error.message :  error.global_error , "error", 2500);
//     });
// }

function logout() {
  // remove user from local storage to log user out
  let param = {
    Sessionkey: AuthHelp.getAuth()
  }
  Http.post(API.LOGOUT, param).then(response => {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('masterData');
    AuthHelp.removeAuth()
    currentUserSubject.next(null);
    window.location = '/login';
  }).catch(error => {

  });
}

function updateBalance(balance, exposure, mobile_app_charges, wa_number, wa_visible_to, parent_role) {
  let user = { ...currentUserSubject.value, balance, exposure, mobile_app_charges, wa_number, wa_visible_to, parent_role }
  localStorage.setItem('currentUser', JSON.stringify(user));
  currentUserSubject.next(user);
}
function updateSetting(sport_settings) {
  let user = { ...currentUserSubject.value, sport_settings }
  localStorage.setItem('currentUser', JSON.stringify(user));
  currentUserSubject.next(user);
}
function updateUserStackSetting(type, stack) {
  let user = { ...currentUserSubject.value, [type]: stack }
  console.log(user)
  localStorage.setItem('currentUser', JSON.stringify(user));
  currentUserSubject.next(user);
}

export default {
  login,
  logout,
  updateBalance,
  updateSetting,
  updateUserStackSetting,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() { return currentUserSubject.value }
}