import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { TitleComponent } from 'Components';

class ComingSoon extends Component {
  constructor(props) {
    super(props);
  };

  render() {
    return (
      <div className="row mx-0 vh-100 workinprogress">
        <TitleComponent title={""} />
        <h1 className="middle">Coming Soon</h1>
      </div>
    )
  }
}

export default ComingSoon;
