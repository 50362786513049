import React, { Component } from 'react';
import Scrollbars from 'react-custom-scrollbars';

class SubNavigationScrollbars extends Component {

  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = { top: 0 };
    this.renderThumb = this.renderThumb.bind(this);
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = {
      height: 2,
      backgroundColor: `rgba(0, 0, 0, 0)`
    };
    return (
      <div
        style={{ ...style, ...thumbStyle }}
        {...props} />
    );
  }

  render() {
    return (
      <Scrollbars
        renderThumbHorizontal={this.renderThumb}
        {...this.props} />
    );
  }
}

export default SubNavigationScrollbars;
