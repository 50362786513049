import React, { Component } from 'react';

class DummyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fixture_lastupdate: ''
    }
  }
  
  componentDidMount() {
    // console.log('I render 😡')
    // console.log(this.props.fixture_lastupdate)
  }

  render() {
    return <div>DummyComponent</div>;
  }
}

export default DummyComponent;
