import { Utils } from 'Helpers';
import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { Trans } from 'react-i18next';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

class GlobalLoader extends Component {
  render() {
    const { isLoaderShow, isLoaderShowTimer } = this.props;
    return (
      isLoaderShow &&
      <div className="global-loader">
        <div className='page-loading'>
        {
          isLoaderShowTimer !== null ? (
            <div className="ml-3">
              <CountdownCircleTimer
                size={60}
                strokeWidth={5}
                isPlaying
                duration={isLoaderShowTimer}
                colors={[
                  ['#004777', 0.33],
                  ['#F7B801', 0.33],
                  ['#A30000', 0.33],
                ]}
              >
                {({ remainingTime }) => remainingTime}
              </CountdownCircleTimer>
            </div>
          ) : (
            <Fragment>
              <Utils.Spinner />
              <div className='page-loading-title'><Trans>Loading...</Trans></div>
            </Fragment>
          )
        }
        </div>
      </div>
    );
  }
}

GlobalLoader.defaultProps = {
  isLoaderShow: false,
  isLoaderShowTimer: null
}
function mapStateToProps(state) {
  const { app } = state;
  return {
    isLoaderShow: app.isLoaderShow,
    isLoaderShowTimer: app.isLoaderShowTimer
  };
}
export default connect(
  mapStateToProps
)(GlobalLoader);