import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { TitleComponent } from 'Components';
import { Utils } from "Helpers";
import { Auth } from "Service";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  gotoPath = (pathname = "/") => {
    this.props.history.push(pathname);
  };

  logout = () => {
    Utils.removeCasinoGameImage();
    Auth.logout();
    localStorage.removeItem('currentUser');
    localStorage.removeItem('masterData');
    this.props.UpdateProfile();
  };

  render() {
    return (
      <Container className="home-page">
        <TitleComponent title={"Home"} />
        <Row>
          <Col className="col-md-2 col-lg-2 col-sm-12 col-12"></Col>
          <Col className="col-md-4 col-lg-4 col-sm-12 col-12">
            <a onClick={() => this.gotoPath("/exchange")} className="card">
              <span className="icon-cricket" />
              IN PLAY
            </a>
          </Col>
          <Col className="col-md-4 col-lg-4 col-sm-12 col-12">
            <a onClick={() => this.gotoPath("/upcoming")} className="card">
              <span className="icon-calendar" />
              UPCOMING
            </a>
          </Col>
          <Col className="col-md-2 col-lg-2 col-sm-12 col-12"></Col>
        </Row>
        <Row>
          <Col className="col-md-2 col-lg-2 col-sm-12 col-12"></Col>
          <Col className="col-md-4 col-lg-4 col-sm-12 col-12">
            <a onClick={() => this.gotoPath("/casino_supernowa?sports=6")} className="card">
              <span className="icon-dollar" />
              CASINO
            </a>
          </Col>
          <Col className="col-md-4 col-lg-4 col-sm-12 col-12">
            <a onClick={() => this.gotoPath("/ledgers")} className="card">
              <span className="icon-presentation" />
              LEDGER
            </a>
          </Col>
          <Col className="col-md-2 col-lg-2 col-sm-12 col-12"></Col>
        </Row>
        <Row>
          <Col className="col-md-2 col-lg-2 col-sm-12 col-12"></Col>
          <Col className="col-md-4 col-lg-4 col-sm-12 col-12">
            <a onClick={() => this.gotoPath("/rules")} className="card">
              <span className="icon-info" />
              RULES
            </a>
          </Col>
          <Col className="col-md-4 col-lg-4 col-sm-12 col-12">
            <a onClick={() => this.gotoPath("/change-password")} className="card">
              <span className="icon-lock" />
              PASSWORD
            </a>
          </Col>
          <Col className="col-md-2 col-lg-2 col-sm-12 col-12"></Col>
        </Row>
        <Row>
          <Col className="col-md-2 col-lg-2 col-sm-12 col-12"></Col>
          <Col className="col-md-4 col-lg-4 col-sm-12 col-12">
            <a onClick={() => this.gotoPath("/settings")} className="card">
              <span className="icon-cog" />
              SETTINGS
            </a>
          </Col>
          <Col className="col-md-4 col-lg-4 col-sm-12 col-12">
            <a onClick={() => this.logout()} className="card">
              <span className="icon-logout" />
              LOGOUT
            </a>
          </Col>
          <Col className="col-md-2 col-lg-2 col-sm-12 col-12"></Col>
        </Row>
      </Container >
    )
  }
}

export default Home;
