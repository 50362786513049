import React, { Component } from 'react';
import { SiteContainer } from 'Components';
import { Container, Row, Col, Form, FormGroup, Input, Button } from 'reactstrap';
import { Utils } from 'Helpers';
import DatePicker from 'react-date-picker';
import Select from 'react-select';
import { CONSTANT } from 'Constants';
import moment from 'moment';
import { TitleComponent } from "Components";
class AccountInformation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      Username: '',
      dob: moment().toDate(),
      Address: '',
      SAddress: '',
      City: '',
      ZipCode: '',
      FilValue: [{ id: '0', name: 'India' }],
      MobileNumber: '',
    }
  }

  handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value }, this.validateForm);
  };

  onDateChange = (date) => {
    this.setState({ dob: date }, () => {
    })
  }

  handleFilterChange = (FilValue) => {
    this.setState({ FilValue: FilValue, Offset: CONSTANT.CURRENT_OFFSET }, () => {
    });
  }



  render() {
    const { Username, formValid, posting, dob, Address, SAddress, City, ZipCode, FilValue, MobileNumber } = this.state
    const options = [
      { id: '0', name: 'India' },
      { id: '1', name: 'England' },
      { id: '2', name: 'India' }
    ]
    return (
      <SiteContainer {...this.props}>
        <TitleComponent title={"Account Information"}/>
        <Container fluid className='gutters-container-7px'>
          <Row>
            <Col className='leftnav-col'>
              <div className="left-navigation">
                <div className="left-nav-item">Deposit</div>
                <div className="left-nav-item active">Withdraw</div>
                <div className="left-nav-item">Account statement</div>
                <div className="left-nav-item">Bets History</div>
                <div className="left-nav-item">Settings</div>
                <div className="left-nav-item">Account Information</div>
              </div>
            </Col>
            <Col>
              <div className="white-box">
                <div className="title">Account Information</div>
                <div className="sub-title">Please provide full personal information to confirm your identity. Owing to regulation, your access to some products, markets and commision discount may be restricted until you confirm your identity. You can provide personal document using our secure email service.</div>


                <Form className="deposit-form">
                  <Row>
                    <Col sm={6} xs={12}>
                      <FormGroup className='input-border-bottom' >
                        <span className='label'>Username</span>
                        <Input type="Username"
                          id='Username'
                          name='Username'
                          required
                          value={Username}
                          autoComplete="off"
                          onChange={this.handleChange}
                          invalid={Utils.isInvalid("reqiured", Username)}
                          valid={Utils.isValid("reqiured", Username)} />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6}>
                      <FormGroup className='input-border-bottom'>
                        <span className='label'>Date of Birth</span>
                        <DatePicker
                          onChange={this.onDateChange}
                          value={dob}
                          calendarIcon={<i className='icon-calendar' />}
                          className='form-control'
                          clearIcon={null}
                          format="d/M/yyyy"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="deposit-form">
                    <Col sm={6} xs={12}>
                      <FormGroup className='input-border-bottom' >
                        <span className='label'>Address</span>
                        <Input type="Address"
                          id='Address'
                          name='Address'
                          required
                          value={Address}
                          autoComplete="off"
                          onChange={this.handleChange}
                          invalid={Utils.isInvalid("reqiured", Address)}
                          valid={Utils.isValid("reqiured", Address)} />
                      </FormGroup>
                    </Col>
                    <Col sm={6} xs={12}>
                      <FormGroup className='input-border-bottom' >
                        <span className='label'></span>
                        <Input type="SAddress"
                          id='SAddress'
                          name='SAddress'
                          required
                          value={SAddress}
                          autoComplete="off"
                          onChange={this.handleChange}
                          invalid={Utils.isInvalid("reqiured", SAddress)}
                          valid={Utils.isValid("reqiured", SAddress)} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="deposit-form">
                    <Col sm={6} xs={12}>
                      <FormGroup className='input-border-bottom' >
                        <span className='label'>Zip Code</span>
                        <Input type="ZipCode"
                          id='ZipCode'
                          name='ZipCode'
                          required
                          value={ZipCode}
                          autoComplete="off"
                          onChange={this.handleChange}
                          invalid={Utils.isInvalid("reqiured", ZipCode)}
                          valid={Utils.isValid("reqiured", ZipCode)} />
                      </FormGroup>
                    </Col>
                    <Col sm={6} xs={12}>
                      <FormGroup className='input-border-bottom' >
                        <span className='label'>City</span>
                        <Input type="City"
                          id='City'
                          name='City'
                          required
                          value={City}
                          autoComplete="off"
                          onChange={this.handleChange}
                          invalid={Utils.isInvalid("reqiured", City)}
                          valid={Utils.isValid("reqiured", City)} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6} xs={12}>
                      <FormGroup  className='input-border-bottom'>
                        <span className='label'>Country</span>
                        <Select
                          className='react-select'
                          classNamePrefix="react-select"
                          isSearchable={false}
                          options={options}
                          value={FilValue}
                          onChange={this.handleFilterChange}
                          getOptionLabel={({ name }) => name}
                          getOptionValue={({ id }) => id}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={6} xs={12}>
                      <FormGroup className='input-border-bottom' >
                        <span className='label'>Mobile Number</span>
                        <Input type="MobileNumber"
                          id='MobileNumber'
                          name='MobileNumber'
                          required
                          value={MobileNumber}
                          autoComplete="off"
                          onChange={this.handleChange}
                          invalid={Utils.isInvalid("reqiured", MobileNumber)}
                          valid={Utils.isValid("reqiured", MobileNumber)} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup className='m-0 text-center'>
                    <Button disabled={!formValid || posting} type='submit' size="lg" color="primary" className='round btn-xlg'>Confirm Changes</Button>
                  </FormGroup>
                </Form>
              </div>
            </Col>
          </Row>

        </Container>
      </SiteContainer>
    )
  }
}

export default AccountInformation;


