import React, { Component, Fragment } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Notification from 'react-notify-toast';
import { withTranslation } from 'react-i18next';
import ScrollMemory from 'react-router-scroll-memory';

import './App.scss';
import { UserLayout } from "Layouts";
import { API, Http, Utils } from "Helpers";
import { LoadingView } from "Views";
import { BehaviorSubject } from 'rxjs';
// import { LanguageSwitchModal } from "Modals";
import { TitleComponent, CacheBuster } from "Components";
import { actionMasterData } from "./Redux/Reducers/ApplicationSlice";
import { connect } from "react-redux";
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Init: false,

    };
  }

  GetAppMasterList = () => {
    let s3_param = [
      API.S3_GET_APP_MASTER_LIST
    ]
    Http.s3(Utils.s3UrlConvert(s3_param), API.GET_APP_MASTER_LIST).then(response => {
      Utils.setMasterData(response.data)
      localStorage.setItem('masterData', JSON.stringify(response.data));
      let masterData = response.data
      this.props.actionMasterData(masterData)



      // Utils.setCookie('isThemeGradient', masterData && masterData.domain_detail.is_gradient && masterData.domain_detail.is_gradient === 1 ? true : false, 365)
      Utils.setCookie('isThemeGradient', masterData && masterData.domain_detail.is_gradient && masterData.domain_detail.is_gradient, 365)
      this.setState({
        Init: true
      });
    }).catch(error => {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('auth');
      currentUserSubject.next(null);
      window.location = '/login';
      //window.location.href = "/login";
    });
  }



  componentDidMount() {
    this.GetAppMasterList()
    const { i18n } = this.props;
    document.documentElement.setAttribute("lang", i18n.language);


  }


  render() {
    const { Init } = this.state;
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }
          return (
            !Init
              ?
              <LoadingView />
              :
              <Fragment>
                <Notification options={{ zIndex: 99999 }} />
                <BrowserRouter>
                  <ScrollMemory />
                  <TitleComponent />
                  <Switch>
                    {/* <OnlyPublicRoute path='/' exact={true} component={Home} /> */}
                    {/* <Route path='/' exact={true} component={Home} /> */}
                    <Route exact component={UserLayout} />
                    <Redirect from="*" to='/' />
                  </Switch>
                </BrowserRouter>
              </Fragment>
          )
        }}
      </CacheBuster>


    )
  }
}
const AppTrans = withTranslation()(App)

const mapDispatchToProps = (dispatch) => ({
  actionMasterData: (data) => dispatch(actionMasterData(data)),
});

// export default withTranslation()(App);
export default connect(null, mapDispatchToProps)(AppTrans);