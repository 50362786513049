import React, { Component } from 'react'
import { Modal, ModalHeader, ModalBody, Table } from 'reactstrap'
import { Utils, _, Http, API } from 'Helpers';
class DeclaredSessionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      declaredSession: [],
      totalAmount: 0,
    }
    this.getDeclaredSession();
  }

  getDeclaredSession = () => {
    const { SelectedSession } = this.props;
    let param = { event_id: SelectedSession.event_id, odd_id: SelectedSession.odd_id }
    this.setState({ posting: true, totalAmount: 0 })
    Http.post(SelectedSession.market_name && !_.isEmpty(SelectedSession.market_name) && (SelectedSession.market_name.toLowerCase() === "to win the toss") ? API.DECLARED_TOSS_DETAIL : API.DECLARED_SESSION_DETAIL, param).then(response => {
      _.map((SelectedSession.market_name && !_.isEmpty(SelectedSession.market_name) && (SelectedSession.market_name.toLowerCase() === "to win the toss") ? response.data.toss_bets : response.data), (declared) => {
        this.setState({
          totalAmount: Number(this.state.totalAmount) + Number(declared.profit_loss)
        })
      })
      this.setState({
        posting: false,
        declaredSession: SelectedSession.market_name && !_.isEmpty(SelectedSession.market_name) && (SelectedSession.market_name.toLowerCase() === "to win the toss") ? response.data.toss_bets : response.data
      })
    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  render() {
    const { isOpen, toggle, SelectedSession } = this.props;
    const { declaredSession } = this.state;
    const ModalProps = {
      isOpen,
      toggle,
      size: 'md',
      className: 'custom-modal2'
    }

    return (
      <Modal {...ModalProps}>
        <i className="close-btn icon-cancel" onClick={toggle} />
        <ModalHeader>DECLARED SESSIONS</ModalHeader>
        <ModalBody>
          <Table className="table-striped declared_session mt-2" responsive>
            <thead>
              <tr>
                <th className="sr_no">NO.</th>
                <th>SESSION</th>
                <th className="text-center">{SelectedSession.market_name && !_.isEmpty(SelectedSession.market_name) && (SelectedSession.market_name.toLowerCase() === "to win the toss") ? "PRICE" : "RUN"}</th>
                <th className="runs">{SelectedSession.market_name && !_.isEmpty(SelectedSession.market_name) && (SelectedSession.market_name.toLowerCase() === "to win the toss") ? "AMOUNT" : "MODE"}</th>
                <th className="status">STATUS</th>
              </tr>
            </thead>
            {
              !_.isEmpty(declaredSession) &&
              <tbody>
                {
                  _.map(declaredSession, (session, index) => {
                    return (
                      <tr key={index}>
                        <td className="sr_no text-center">{index + 1}</td>
                        <td>{session.market_name}</td>
                        <td className="text-center">{SelectedSession.market_name && !_.isEmpty(SelectedSession.market_name) && (SelectedSession.market_name.toLowerCase() === "to win the toss") ? Utils.TwoDecimalVal(session.display_price) : session.line}</td>
                        <td className="text-uppercase text-center">
                        {session.child_session_type_id==10?(session.betting_type == 2 ? "EVEN" : "ODD"):SelectedSession.market_name && !_.isEmpty(SelectedSession.market_name) && (SelectedSession.market_name.toLowerCase() === "to win the toss") ? Utils.TwoDecimalVal(session.stake) : (session.betting_type == 2 ? "No" : "Yes")}

                      

                        </td>
                        <td className="status">
                          <span className={(session.profit_loss != null) ? (Number(session.profit_loss) < 0 ? "loss" : "profit") : ""}>{(session.profit_loss != null) ? Utils.TwoDecimalVal(session.profit_loss) : ""}</span>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            }
            <tfoot>
              <tr>
                <th>Declared Session:</th>
                <th colSpan="2">{SelectedSession.market_name && !_.isEmpty(SelectedSession.market_name) && (SelectedSession.market_name.toLowerCase() === "to win the toss") ? (_.map(JSON.parse(SelectedSession.market_runners), (runner) => {
                  if (runner.selectionId == SelectedSession.selection_id)
                    return (
                      runner.runnerName
                    )

                })) : ""}</th>

                <th>Total</th>
                <th className="status">
                  <span className={(this.state.totalAmount != null) ? (Number(this.state.totalAmount) < 0 ? "loss" : "profit") : ""}>{(this.state.totalAmount != null) ? Utils.TwoDecimalVal(this.state.totalAmount) : ""}</span>
                </th>
              </tr>
            </tfoot>
          </Table>
        </ModalBody>
      </Modal>
    )
  }
}

DeclaredSessionModal.defaultProps = {
  isOpen: true,
  toggle: () => { }
}
export default DeclaredSessionModal;
