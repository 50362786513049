import { BET_RULES, CLEAR_BETSLIP_WINDOW } from './type';

const initialState = {
    betRuleData: {},
    hideBetSlipFun: ""
}

export const betRuleReducer = (state = initialState, action) => {
    switch (action.type) {
        case BET_RULES:
            return {
                ...state,
                betRuleData: action.payload
            }

        case CLEAR_BETSLIP_WINDOW:
            return {
                ...state,
                hideBetSlipFun: action.payload
            }



        default: return state;
    }
}