// wrapper for localstorage and auth object setting
import { Auth } from "Service";
const AuthHelp = {
  getAuth: () => {
    const auth = localStorage.getItem('auth');
    if (!auth) {
      return null;
    }
    return JSON.parse(auth);
  },
  setAuth: (auth) => {
    localStorage.setItem('auth', JSON.stringify(auth));
  },
  removeAuth: () => {
    localStorage.removeItem('auth');
  },
  checkAuth: (data) => {
    // eslint-disable-next-line
    if (data.response_code == 401) {
      Auth.logout()
      window.location.reload()
    }
  },
  getCurrentUser: () => {
    const currentUser = localStorage.getItem('currentUser');
    if (!currentUser) {
      return null;
    }
    return JSON.parse(currentUser);
  },
}
// console.log(AuthHelp)
export default { ...AuthHelp };