import React, { Component } from 'react';
import { Container, Row, Col, FormGroup, Button, Form, Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { Http, API, Utils } from 'Helpers';
import { SiteContainer, TitleComponent } from 'Components';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      password: '',
      OldPassword: '',
      ConfrimPassword: '',
    }
  }


  handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === 'password') {
      value = value.trim()
    }
    this.setState({ [name]: value }, this.validateForm);
  };

  validateForm() {
    const { password, OldPassword, ConfrimPassword } = this.state
    this.setState({
      formValid:
        password !== '' &&
        OldPassword !== '' &&
        ConfrimPassword !== '' &&
        password === ConfrimPassword &&
        Utils.isFormValid(['password'], this.state)
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { OldPassword, password } = this.state
    this.setState({ posting: true });
    let param = {
      "old_password": OldPassword,
      "password": password,
    }
    Http.post(API.CHANGE_PASSWORD, param).then(response => {
      this.setState({ posting: false, password: '', OldPassword: '', ConfrimPassword: '' });
      Utils.notify(response.message)
    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  render() {
    const { t } = this.props;
    const { password, OldPassword, ConfrimPassword, formValid, posting } = this.state
    return (
      <SiteContainer {...this.props}>
        <TitleComponent title={"Change Password"}/>
        <Container fluid className='chang-pass'>
          <Row className='mx-auto'>
            {/* <Col className='leftnav-col'>
              <div className="left-navigation">
                <div className="left-nav-item" onClick={() => this.props.history.push('bet-history')}>{t('Bets History')}</div>
                <div className="left-nav-item" onClick={() => this.props.history.push('profit-loss')}>{t('Profit & Loss')}</div>
                <div className="left-nav-item" onClick={() => this.props.history.push('account-statement')}>{t('Account Statement')}</div>
                <div className="left-nav-item active">{t('Change Password')}</div>
              </div>
            </Col> */}

            <div className="change-paswd-wrap float-right">
              <h1 className="login-head">Change Password</h1>
              <Form onSubmit={this.onSubmit}>

                <Row>

                  <Col className="col-lg-4 col-md-4 col-sm-6 col-xs-10">
                    <FormGroup>
                      <Input
                        id='OldPassword'
                        name='OldPassword'
                        required
                        type='password'
                        maxLength={50}
                        placeholder="OLD PASSWORD"
                        value={OldPassword}
                        autoComplete="off"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("password", OldPassword)}
                        valid={Utils.isValid("password", OldPassword)} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-lg-4 col-md-4 col-sm-6 col-xs-10">
                    <FormGroup>
                      <Input
                        id='password'
                        name='password'
                        required
                        type='password'
                        maxLength={16}
                        placeholder="NEW PASSWORD"
                        value={password}
                        autoComplete="off"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("PasswordCaps", password)}
                        valid={Utils.isValid("PasswordCaps", password)} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-lg-4 col-md-4 col-sm-6 col-xs-10">
                    <FormGroup>
                      <Input
                        id='ConfrimPassword'
                        name='ConfrimPassword'
                        required
                        type='password'
                        maxLength={16}
                        placeholder="CONFIRM PASSWORD"
                        value={ConfrimPassword}
                        autoComplete="off"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("PasswordCaps", ConfrimPassword)}
                        valid={Utils.isValid("PasswordCaps", ConfrimPassword)} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-lg-12 col-md-12 text-center">
                    <Button disabled={!formValid || posting} type='submit' size="sm" className='rectangle'>{t('Done')}</Button>
                  </Col>
                </Row>

              </Form>
            </div>
          </Row>
        </Container>
      </SiteContainer>
    )
  }
}

export default withTranslation()(ChangePassword);
