/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'reactstrap';
import BetModal from './BetModal';

function BetMsgPoPUP() {
    const BetPopState = useSelector((state) => { return (state.betRuleReducer.betRuleData.messageRule) })
    //const ClearBetSlipStateFun = useSelector((state) => { return (state.betRuleReducer.hideBetSlipFun) })

    const [BetState, setBetState] = useState(BetPopState);
    const [BetPopUPMsg, setStateBetPopUPMsg] = useState('');
    const [popShowHide, setStatePopShowHide] = useState(false);

    const betModalHandler = () => {
        setStatePopShowHide(false)
        setBetState("")
        //ClearBetSlipStateFun()
    }

    const betMszUpdate = () => {
        let betparm = BetState && BetState.message
        let responsecode = BetState && BetState.response_code

        if (betparm === "Bet(s) has been placed successfully") {
            setStateBetPopUPMsg(
                <BetModal
                    BetBalance={'Bet(s) has been placed successfully.'}
                    BetRule={''}
                    buttonColor={"bg4 color1"}
                    betModalHandler={betModalHandler}
                />
            )

            setTimeout(() => {
                setStatePopShowHide(false)
            }, 600000);

        }

        else if (betparm === "Betting is not allowed on this market") {
            setStateBetPopUPMsg(
                <BetModal
                    BetBalance={'Betting is not allowed on this market.'}
                    BetRule={'इस बाजार में सौदा की अनुमति नहीं है |'}
                    buttonColor={"bg4 color1"}
                    betModalHandler={betModalHandler}
                />
            )
        }

        else if (betparm === "Betting is not allowed on this match") {
            setStateBetPopUPMsg(
                <BetModal
                    BetBalance={'Betting is not allowed on this match.'}
                    BetRule={'इस मैच में सौदा की अनुमति नहीं है |'}
                    buttonColor={"bg4 color1"}
                    betModalHandler={betModalHandler}
                />
            )
        }

        else if (betparm === "Back Betting is not allowed on this match") {
            setStateBetPopUPMsg(
                <BetModal
                    BetBalance={'Back Betting is not allowed on this match.'}
                    BetRule={'Lagai के भाव इस मैच में बंद किए गये है |'}
                    buttonColor={"bg4 color1"}
                    betModalHandler={betModalHandler}
                />
            )
        }

        else if (betparm === 'Lay Betting is not allowed on this match') {
            setStateBetPopUPMsg(
                <BetModal
                    BetBalance={'Lay Betting is not allowed on this match.'}
                    BetRule={'Khai के भाव इस मैच में बंद किए गये है |'}
                    buttonColor={"bg4 color1"}
                    betModalHandler={betModalHandler}
                />
            )
        }

        else if (betparm === 'insufficient balance') {
            setStateBetPopUPMsg(
                <BetModal
                    BetBalance={"You don't have enough balance to Place the bet."}
                    BetRule={'सौदा करने के लिए आपके पास पर्याप्त बैलेन्स नही है |'}
                    buttonColor={"bg4 color1"}
                    betModalHandler={betModalHandler}
                />
            )
        }

        else if (betparm === 'Betting is blocked for this match by admin.') {
            setStateBetPopUPMsg(
                <BetModal
                    BetBalance={"Betting is blocked for this match by admin."}
                    BetRule={''}
                    buttonColor={"bg4 color1"}
                    betModalHandler={betModalHandler}
                />
            )
        }

        else if (betparm === 'Odds are changed. Refresh to continue.') {
            setStateBetPopUPMsg(
                <BetModal
                    BetBalance={'Odds are changed. Refresh to continue.'}
                    BetRule={'भाव बदल जाने के कारण आपका सौदा नहीं हुआ है कृपया फिर से कोशिश करे |'}
                    buttonColor={"bg4 color1"}
                    betModalHandler={betModalHandler}
                />
            )
        }

        else if (betparm === 'Invalid Bet') {
            setStateBetPopUPMsg(
                <BetModal
                    BetBalance={'Bet is Invalid'}
                    BetRule={''}
                    buttonColor={"bg4 color1"}
                    betModalHandler={betModalHandler}
                />
            )
        }


        else if (betparm === 'Betting is blocked from admin') {
            setStateBetPopUPMsg(
                <BetModal
                    BetBalance={'Betting is blocked from admin'}
                    BetRule={''}
                    buttonColor={"bg4 color1"}
                    betModalHandler={betModalHandler}
                />
            )
        }

        else if (betparm === 'Bet is locked for this match.') {
            setStateBetPopUPMsg(
                <BetModal
                    BetBalance={'Bet is locked for this match. \n Please Contact Upline.'}
                    BetRule={''}
                    buttonColor={"bg4 color1"}
                    betModalHandler={betModalHandler}
                />
            )
        }

        else if (betparm === 'Fancy is locked for this match.') {
            setStateBetPopUPMsg(
                <BetModal
                    BetBalance={'Fancy is locked for this match. \n Please Contact Upline.'}
                    BetRule={''}
                    buttonColor={"bg4 color1"}
                    betModalHandler={betModalHandler}
                />
            )
        }

        else

            if (betparm && betparm.includes('stake should not be less then')) {
                let priceSplit = betparm.split(' ')
                let data = priceSplit.filter((price) => price >= 0 && price)
                let maxprice = data.reduce((a, b) => Math.max(a, b))
                let minprice = data.reduce((a, b) => Math.min(a, b))

                setStateBetPopUPMsg(
                    <BetModal
                        BetBalance={`stake should not be less then ${minprice} and or more then ${maxprice}`}
                        BetRule={`${minprice} रूपेय से कम और ${maxprice} से अधिक का सौदा आप इस मार्केट में नही कर सकते |`}
                        buttonColor={"bg4 color1"}
                        betModalHandler={betModalHandler}
                    />
                )
            }

            else if (betparm && betparm.includes('You are Exceeding the Max Profit')) {
                let priceSplit = betparm.split(' ')
                let data = priceSplit.filter((price) => price >= 0 && price)
                let maxprice = data.reduce((a, b) => Math.max(a, b))

                if (betparm.endsWith('Market.'))
                    setStateBetPopUPMsg(
                        <BetModal
                            BetBalance={`You are Exceeding the Max Profit Limit. \n Your Max Profit Limit is ${maxprice} for this Market.`}
                            BetRule={`आप अधिकतम लाभ सीमा को पार कर रहे हैं। इस बाजार के लिए आपकी अधिकतम लाभ सीमा ${maxprice} है|`
                            }
                            buttonColor={"bg4 color1"}
                            betModalHandler={betModalHandler}
                        />
                    )

                else
                    setStateBetPopUPMsg(
                        <BetModal
                            BetBalance={`You are Exceeding the Max Profit Limit. \n Your Max Profit Limit is ${maxprice} for this Fancy.`}
                            BetRule={`आप अधिकतम लाभ सीमा को पार कर रहे हैं। इस बाजार के लिए आपकी अधिकतम लाभ सीमा ${maxprice} है|`
                            }
                            buttonColor={"bg4 color1"}
                            betModalHandler={betModalHandler}
                        />
                    )

            }


            else if (responsecode === 500) {
                setStateBetPopUPMsg(
                    <BetModal
                        BetBalance={betparm}
                        BetRule={``}
                        buttonColor={"bg4 color1"}
                        betModalHandler={betModalHandler}
                    />
                )
            }

            else if (responsecode === undefined && betparm == 'Bhav changed, try again') {
                setStateBetPopUPMsg(
                    <BetModal
                        BetBalance={betparm}
                        BetRule={``}
                        buttonColor={"bg4 color1"}
                        betModalHandler={betModalHandler}
                    />
                )
            }

            else {
                setStateBetPopUPMsg("")
            }
    }


    useEffect(() => {
        setBetState(BetPopState)
    }, [BetPopState])


    useEffect(() => {
        if (BetPopState !== "") {
            betMszUpdate()
            setStatePopShowHide(true)
        }
    }, [BetState])

    useEffect(() => {
        setStatePopShowHide(false)
    }, [])
    return (
        <Fragment>
            {popShowHide && BetPopUPMsg}
        </Fragment>
    )

};

export default BetMsgPoPUP;

