import React, { Component } from 'react';
import { Form, FormGroup, Input, Button } from 'reactstrap';
import { Auth } from 'Service';
import { Utils } from 'Helpers';
// import { CONSTANT } from 'Constants';
import md5 from 'md5';

class LoginComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      email: "",
      password: "",
    }
    if (Auth.currentUserValue) {
      this.props.history.push('/');
    }
  }


  handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === 'password') {
      value = value.trim()
    }
    this.setState({ [name]: value }, this.validateForm);
  };

  validateForm() {
    const { password, email } = this.state
    this.setState({
      formValid:
        password !== '' &&
        email !== '' &&
        Utils.isFormValid(['password', 'email'], this.state)
    });
  }

  componentDidMount() {
    if (Utils.isDev()) {
      this.setState({
        email: "admin@mailinator.com",
        password: "123456"
      }, this.validateForm)
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ posting: true });
    const { email, password } = this.state;

    // let param = {
    //   "email": email,
    //   "password": md5(password),
    //   "device_type": CONSTANT.DEVICE_TYPE,
    //   "device_id": CONSTANT.DEVICE_ID
    // };
    // console.log(param)

    Auth.login(email, md5(password))
      .then(
        user => {
          this.props.history.push('/user');
          // const { from } = this.props.location.state || { from: { pathname: "/" } };
          // console.log(from)
          // console.log(user)
        },
        error => {
          console.log(error)
        }
      );

  }

  render() {
    const {
      email,
      password,
      formValid,
      posting,
    } = this.state;
    return (
      <div className="login-container">
        <div className="title">Log In</div>
        <Form onSubmit={this.onSubmit}>
          <FormGroup className='input-border-bottom' >
            <span className='label'>Username</span>
            <Input type="email"
              id='email'
              name='email'
              required
              value={email}
              autoComplete="off"
              onChange={this.handleChange}
              invalid={Utils.isInvalid("email", email)}
              valid={Utils.isValid("email", email)} />
          </FormGroup>
          <FormGroup className='input-border-bottom m-0' >
            <span className='label'>Password</span>
            <Input
              id='password'
              name='password'
              required
              type='password'
              maxLength={50}
              value={password}
              autoComplete="off"
              onChange={this.handleChange}
              invalid={Utils.isInvalid("password", password)}
              valid={Utils.isValid("password", password)} />
          </FormGroup>
          <FormGroup className='m-0'>
            <Button disabled={!formValid || posting} type='submit' size="lg" color="primary" className='round' block>Login</Button>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

export default LoginComponent;
