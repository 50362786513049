import { createSlice } from "@reduxjs/toolkit";
import { Utils } from "Helpers";
import { Auth } from "Service";

function getCurrentUser() {
    const currentUser = localStorage.getItem('currentUser');
    if (!currentUser) {
        return null;
    }
    return JSON.parse(currentUser);
}

export const ApplicationSlice = createSlice({
    name: "Application",
    initialState: {
        isLoaderShow: false,
        isLoaderShowTimer: null,
        currentUser: getCurrentUser(),
        LangModalShow: Utils.getCookie('dreamxbet_lang') === "",
        openCollapse: '1',
        openBetslipDrawer: false,
        WinlossObject: {
            lastupdate: new Date().valueOf()
        },
        masterData: [],
        profileDetail: [],
    },
    reducers: {
        LoaderShow: (state, data) => {
            state.isLoaderShow = true;
            state.isLoaderShowTimer = data.payload ? data.payload.time : null;
        },
        LoaderHide: (state) => {
            state.isLoaderShow = false;
            state.isLoaderShowTimer = null;
        },
        UpdateProfile: (state) => {
            state.currentUser = Auth.currentUserValue
        },
        LangModalOpen: (state) => {
            state.LangModalShow = true
        },
        LangModalClose: (state) => {
            state.LangModalShow = false
        },
        SlipToggle: (state, { payload }) => {
            switch (true) {
                case (payload === 1 && state.openCollapse === 1) || (payload === 2 && state.openCollapse === 2):
                    state.openCollapse = ''
                    break;
                default:
                    state.openCollapse = payload
                    break;
            }

        },
        OpenBet: (state, data) => {
            const { type, bool } = data.payload
            state.openCollapse = bool ? '2' : '1'
            state.openBetslipDrawer = type;
        },
        updateWinloss: (state, { payload }) => {
            state.WinlossObject = payload
        },
        actionProfileDetail: (state, { payload }) => {
            state.profileDetail = payload
        },
        actionMasterData: (state, { payload }) => {
            state.masterData = payload
        }
    },
});

export const {
    LoaderShow,
    LoaderHide,
    UpdateProfile,
    LangModalOpen,
    LangModalClose,
    SlipToggle,
    OpenBet,
    updateWinloss,
    actionProfileDetail,
    actionMasterData,
} = ApplicationSlice.actions;

export default ApplicationSlice.reducer;
