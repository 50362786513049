import { createSlice } from "@reduxjs/toolkit";

export const LoginModalSlice = createSlice({
    name: "Modal",
    initialState: {
        isOpen: false
    },
    reducers: {
        loginOpen: (state) => {
            state.isOpen = true
        },
        loginClose: (state) => {
            state.isOpen = false
        }
    },
});

export const {
    loginOpen,
    loginClose
} = LoginModalSlice.actions;

export default LoginModalSlice.reducer;
