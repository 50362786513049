import React, { Component, Fragment } from 'react';
import { _, Utils } from 'Helpers';

class SportsBookBetItem extends Component {
  render() {
    const { 
      item,
      SelectedSports,
      _oddsActiveClass,
      handleOptionChange,
      fixtureSelectHandler,
      SocketHighlight,
      t
    } = this.props;

    const isDisabled = (obj) => {
      return obj.bet_status !== 1 || obj.admin_status !== 1
    }

    const BetTitle = ({home, away}) => {
      return (
        <div className="title">
          <span title={home.name}>{`${home.name}`}</span>
          <span title={away.name}>{` - ${away.name}`}</span>
        </div>
      )
    }

    return (
      <div className="game-event-item">
        <div className="event-details" onClick={() => fixtureSelectHandler(item)}>
          <div className="small">{item.league_name}</div>
          <BetTitle {...JSON.parse(item.runners)}/>
          <div className="timestamp">
            {
              item.is_live === 1 ? 
              <span className="is-live-lbl">In-Play</span>
              :
              <Fragment>
                <i className="icon-clock" /> {Utils.dateTimeFormat(item.open_date, 'HH:mm, DD/MM/YYYY')}
              </Fragment>
            }
          </div>
        </div>
        <div className="cell">
          {
            item.is_suspended === '1' ?
            <Fragment>
              <div className="game-status-suspended" onClick={() => fixtureSelectHandler(item)}><span>{t('SUSPENDED')}</span></div>
            </Fragment>
            :
            <Fragment>
              <div className="row-cell heading">
                <div {...{ className: `market-label`}}>1</div>
                  {
                    SelectedSports.sports_id !== 3 &&
                    <div {...{ className: `market-label` }}>X</div>
                  }
                <div {...{ className: `market-label`}}>2</div>
              </div>
              <div className="row-cell">
                <div {...{ className: `odd-trigger` }}>
                  {
                    _.map(JSON.parse(item.runners_odds), (odd, key) => {
                      return (
                        <Fragment {...{ key }}>
                          {
                            (key === 1 && SelectedSports.sports_id !== 3 && JSON.parse(item.runners_odds).length === 2) &&
                            <div className="odd-trigger-inner back vslign disabled">
                              <div className='price'>--</div>
                            </div>
                          }
                          <div 
                            onClick={isDisabled(item) ? null : () => handleOptionChange({ fixture: {...item, sports_id: SelectedSports.sports_id}, odd: { ...odd, price: odd.odds, market_bet_id: odd.selection_id, selectionId: odd.selection_id }, betting_type: 1, selected_odd: odd })}
                            {...{
                              key,
                              className:
                                `odd-trigger-inner back vslign ${isDisabled(item) ? 'disabled' : ''} ${_oddsActiveClass(odd.selection_id, item.market_event_id, 1) ? 'yellow' : ''} ${SocketHighlight({ id: odd.selection_id })}`
                            }}>
                            <div className='price'>{odd.odds || '--'}</div>
                          </div>
                        </Fragment>

                      )
                    })
                  } 
                </div>
              </div>
            </Fragment>
          }
        </div>
        <div className="last-cell" onClick={() => fixtureSelectHandler(item)}>
          <a>+{item.total_markets} <span className='mrkt-lbl'>Markets</span></a>
        </div>
      </div>
    );
  }
}
SportsBookBetItem.defaultProps = {
  item: {},
  SocketHighlight: () => {}
}
export default SportsBookBetItem;
