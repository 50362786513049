import { BET_RULES, CLEAR_BETSLIP_WINDOW } from './type';

// export const actionBetSlipRule = (betrule, minprice, maxprice, betAmoutValue) => async (dispatch) => {
//     let result = await { betrule, minprice, maxprice, betAmoutValue }
//     dispatch({
//         type: BET_RULES,
//         payload: result
//     });
// }


export const actionBetSlipRule = (messageRule) => async (dispatch) => {
    let result = await { messageRule}
    dispatch({
        type: BET_RULES,
        payload: result
    });
}




export const actionClearBetSlipWindow = (clearBet) => async (dispatch) => {
    let result = await clearBet
    dispatch({
        type: CLEAR_BETSLIP_WINDOW,
        payload: result
    });
}
