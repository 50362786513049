import React, { Component, Fragment } from 'react';
import { Container, Row, Col, FormGroup, Label, Button, Table } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import Select from 'react-select';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import { SiteContainer, MessageBox, TitleComponent } from 'Components';
import { CONSTANT } from 'Constants';
import { Http, API, Utils, _ } from 'Helpers';

class AccountStatement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasMore: false,
      posting: false,
      Offset: CONSTANT.CURRENT_OFFSET,
      from_date: moment().toDate(),
      to_date: moment().toDate(),
      StatementList: [],
      FilValue: [{ id: '0', name: 'All' }]
    }
  }

  //LIST API

  getStatemntList = () => {
    const { Offset, StatementList, from_date, to_date, FilValue } = this.state
    let param = {
      "limit": CONSTANT.ITEMS_PERPAGE,
      "offset": Offset,
      "from_date": moment.utc(from_date).format('Y-M-D'),
      "to_date": moment.utc(to_date).format('Y-M-D'),
      "filter": FilValue.id
    }

    this.setState({ posting: true })
    Http.post(API.ACCOUNTS_STATEMENT, param).then(response => {
      this.setState({
        posting: false,
        StatementList: Offset === CONSTANT.CURRENT_OFFSET ? response.data : [...StatementList, ...response.data],
        hasMore: !_.isEmpty(response.data),
      }, () => {

      })
    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  //Handlers

  handleFilterChange = (FilValue) => {
    this.setState({ FilValue: FilValue, Offset: CONSTANT.CURRENT_OFFSET }, () => {
      this.getStatemntList()
    });
  }

  validateForm() {
    const {
      from_date
    } = this.state;
    this.setState({
      formValid:

        Utils.isInvalid("date", from_date) === false

    });
  }

  fetchMoreData = () => {
    let offset = this.state.Offset + CONSTANT.ITEMS_PERPAGE;
    this.setState({ Offset: offset }, () => {
      this.getStatemntList()
    });
  }

  SearchData = () => {
    this.setState({
      Offset: CONSTANT.CURRENT_OFFSET
    }, () => {
      this.getStatemntList()
    })

  }

  onFromDateChange = (date) => {
    this.setState({ from_date: date }, this.validateForm)
  }

  onToDateChange = (date) => {
    this.setState({ to_date: date }, this.validateForm)
  }

  ResetData = () => {
    this.setState({
      from_date: moment().toDate(),
      to_date: moment().toDate(),
      FilValue: [{ id: '0', name: 'All' }],
      Offset: CONSTANT.CURRENT_OFFSET
    }, () => {
      this.getStatemntList()
    })

  }

  // LIFE CYLCE METHODS
  componentDidMount() {
    this.getStatemntList()
  }

  render() {
    const { t } = this.props;
    const { from_date, to_date, StatementList, hasMore, FilValue } = this.state
    const options = [
      { id: '0', name: 'All' },
      { id: '1', name: 'Chips' },
      { id: '2', name: 'Games' }
    ]
    return (
      <SiteContainer {...this.props}>
        <TitleComponent title={"Account Statement"}/>
        <Container fluid className='gutters-container-7px'>
          <Row>
            <Col className='leftnav-col'>
              <div className="left-navigation">
                <div className="left-nav-item" onClick={() => this.props.history.push('ledgers')}>{t('Ledgers')}</div>
                <div className="left-nav-item" onClick={() => this.props.history.push('bet-history')}>{t('Bets History')}</div>
                <div className="left-nav-item" onClick={() => this.props.history.push('profit-loss')}>{t('Profit & Loss')}</div>
                <div className="left-nav-item active">{t('Account Statement')}</div>
              </div>
            </Col>
            <Col>
              <div className="container">
                <div className="odds-padding-row heading">
                  <div className="title">{t('Account Statement')}</div>
                </div>


                {<Row className="inline-form gutters-10px">
                  <Col sm={2}>
                    <FormGroup>
                      <Label>{t('Start date')}</Label>
                      <DatePicker
                        onChange={this.onFromDateChange}
                        onCalendarOpen={this.onCalendarOpen}
                        value={from_date}
                        calendarIcon={<i className='icon-calendar' />}
                        className='form-control'
                        clearIcon={null}
                        format="d/M/yyyy"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={2}>
                    <FormGroup>
                      <Label>{t('End date')}</Label>
                      <DatePicker
                        onChange={this.onToDateChange}
                        onCalendarOpen={this.onCalendarOpen}
                        value={to_date}
                        minDate={from_date}
                        calendarIcon={<i className='icon-calendar' />}
                        className='form-control'
                        clearIcon={null}
                        format="d/M/yyyy"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={2}>
                    <FormGroup>
                      <Label>{t('Filter')}</Label>
                      <Select
                        className='react-select'
                        classNamePrefix="react-select"
                        isSearchable={false}
                        options={options}
                        value={FilValue}
                        onChange={this.handleFilterChange}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <Button color="primary" size="sm" onClick={() => this.SearchData()}>{t('Search')}</Button>
                    <Button color="primary" size="sm" onClick={() => this.ResetData()}>{t('Reset')}</Button>
                    {/* <Button color="primary" size="sm">Download CSV</Button> */}
                  </Col>
                </Row>}
                <Row>
                  <Col style={{ minHeight: StatementList.length >= 1 ? 550 : 'auto' }}>
                    {StatementList.length > 0 ?
                      <InfiniteScroll
                        dataLength={StatementList.length}
                        next={this.fetchMoreData}
                        hasMore={hasMore}
                        //  loader={<Utils.FlexboxLoader className='show m-2 no-margin-h' />}
                        hasChildren={false}
                        className=''
                      >
                        <Table className="table-primary mt20 statement-responise-tbl">
                          <thead>
                            <tr>
                              <th>{t('S.No')}</th>
                              <th>{t('Date')}</th>
                              <th>{t('Type')}</th>
                              <th>{t('Description')}</th>
                              <th>{t('Lena / Dena')}</th>
                              <th>{t('Balance')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              _.map(StatementList, (item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{Utils.dateTimeFormat(item.date_added, 'DD/MM/YYYY, h:mm A')}</td>
                                    <td>{item.source >= 5 ? 'Games' : 'Chips'}</td>
                                    <td>
                                      {
                                        item.source === '3' ? " Amount deducted for user's deposit "
                                          : item.source === '4' ? " Amount received from user's withdrawal "
                                            : item.source === '5' ? ' Stake for '
                                              : item.source === '7' ? ' LayBetSettlement'
                                                : item.source === '8' ? ' Bet Cancelled for '
                                                  : item.source === '9' ? ' Rollback Settlement for '
                                                    : item.source === '10' ? ' Commission Earned for  '
                                                      : item.source === '11' ? '  Commission Deducted for '
                                                        : item.source === '12' ? ' Commission Reversal Added for '
                                                          : item.source === '13' ? ' Commission Reversal Deducted for '
                                                            : item.source === '14' ? ' Refund Processed for '
                                                              : item.source === '15' ? ' Cancelled by admin '
                                                                : ''
                                      }

                                      {
                                        item.source === '1' ? 'Deposited by Admin' :
                                          item.source === '2' ? 'Withdrawal by Admin' :
                                            !_.isEmpty(item.bet_data) ?
                                              <Fragment>
                                                {
                                                  item.bet_data.event_name
                                                } {' '}
                                                {item.source === '6' &&
                                                  item.bet_data.bookmaker_type === 2 ?
                                                  <Fragment>
                                                    {item.bet_data.market_name}
                                                    {' '}
                                                    {
                                                      item.bet_data.winning > 0 ?
                                                        'Profit' : 'Loss'
                                                    }
                                                    {' '}
                                                    {
                                                      !_.isNull(item.bet_data.market_runners) &&
                                                      <span>
                                                        [ Winner : {_.map(JSON.parse(item.bet_data.market_runners), (itm, indx) => {
                                                        return (
                                                          item.bet_data.settlement_result === itm.selectionId &&
                                                          itm.runnerName
                                                        )
                                                      })} ]
                                                  </span>

                                                    }

                                                  </Fragment> :
                                                  <Fragment>
                                                    {item.bet_data.market_name}
                                                    {' '}
                                                  Result({item.bet_data.settlement_result})
                                                </Fragment>

                                                }
                                                {item.source !== '6' && <span className="block">
                                                  Bet ID: {item.bet_data.betslip_uid}

                                                </span>}
                                              </Fragment>
                                              : ''

                                      }



                                    </td>
                                    <td>
                                      {item.real_amount !== 0 ? <span className={item.type === 2 ? 'danger-color' : 'green-color'}>
                                        {item.type === 2 ? '-' : ''}
                                        {
                                          item.real_amount
                                        }

                                      </span>
                                        :
                                        <span>
                                          {item.real_amount}
                                        </span>
                                      }
                                    </td>
                                    <td>{item.user_balance}</td>
                                  </tr>
                                )
                              })
                            }


                          </tbody>

                        </Table>
                      </InfiniteScroll>
                      :
                      <MessageBox heading={t(CONSTANT.FIXTURE_BLANK_HEADING)} />
                    }
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </SiteContainer>
    )
  }
}

export default withTranslation()(AccountStatement);
