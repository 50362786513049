import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import Select from 'react-select';
import { Http, API, Utils, _ } from 'Helpers';
export class OddSetingModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            odd_diff: { value: 0, label: 0 },
            odd_diff_options: [
                { value: 0.00, label: 0.00 },
                { value: 0.01, label: 0.01 },
                { value: 0.02, label: 0.02 },
                { value: 0.03, label: 0.03 },
                { value: 0.04, label: 0.04 },
                { value: 0.05, label: 0.05 },
            ]
        };

    }
    componentDidMount() {
        this.onGetOddSettings()
    }

    onGetOddSettings = () => {
        this.setState({ posting: true });
        const _this = this;
        let param = {}
        Http.post(API.GET_USER_BALANCE, param).then(response => {
            _.map(_this.state.odd_diff_options, (odd) => {
                if (Number(response.data.odd_diff) === Number(odd.value)) {
                    _this.setState({
                        odd_diff: odd
                    })
                }
            })
            _this.setState({ posting: false });
        }).catch(error => {
            this.setState({ posting: false })
        });
    }


    changeOdd = (odd_diff) => {
        this.setState({ odd_diff });
    }

    onSaveData = () => {
        this.setState({ posting: true });
        let param = {
            odd_diff: this.state.odd_diff.value
        }
        Http.post(API.UPDATE_ODD_DIFF, param).then(response => {
            Utils.notify(response.message)
            this.setState({ posting: false }, () => { this.props.toggle() });
        }).catch(error => {
            this.setState({ posting: false })
        });
    }

    render() {
        const { odd_diff_options, odd_diff } = this.state;
        const { isOpen, toggle } = this.props
        const ModalProps = {
            isOpen,
            toggle,
            size: 'sm',
            className: 'custom-modal-3'
        }
        return (
            <Modal {...ModalProps}>
                <ModalHeader className="odds_modal">Odd Setting<span className="x-close" onClick={toggle}>X</span></ModalHeader>
                <ModalBody className="odds_modal_body text-center">
                    <Select
                        id="odd_diff"
                        name="odd_diff"
                        className="text-dark select-font-weight"
                        classNamePrefix="react-select"
                        value={odd_diff_options.find(option => {
                            return option.value === odd_diff.value
                        })}
                        onChange={this.changeOdd}
                        options={odd_diff_options}
                    />
                    <Button color="primary" size="sm" className="mt-3" onClick={() => { this.onSaveData() }}>Save</Button>
                </ModalBody>
            </Modal>
        )
    }
}

export default OddSetingModal