import React, { Component, Fragment } from 'react';
import { Utils, _ } from 'Helpers';
class TemplateTotalSB extends Component {
  render() {
    const { 
      SelectedSports,
      item,
      // Mehtods
      handleOptionChange,
      _oddsActiveClass,
      SocketHighlight
    } = this.props;
    const isDisabled = () => {
      return item.bet_status !== 1 
      // || item.admin_status !== 1
    }
    return (
      <div {...{ className: `odd-market-collapse active` }}>
        <div className="heading">
          {item.market_name}
          {
            Utils.isDev() &&
            <b>{" --- "}{_.toUpper(item.template_name)}</b>
          }
          </div>
        <div className="odd-market-body">
          {
            !_.isEmpty(JSON.parse(item.runners_odds)) &&
            <div {...{ className: `odd-market-row head` }}>
              <div {...{ className: `odd-market-line` }} />
              {
                _.map(JSON.parse(item.runners_odds), (o, key) => {
                  return (
                    <div {...{ className: `odd-market-line`, key}}>{o.header}</div>
                  )
                })
              }
            </div>
          }
          <div className="odd-market-row">
            {
              _.map(JSON.parse(item.runners_odds), (object, key) => {
                return (
                  <Fragment {...{ key }}>
                    {
                      key === 0 &&
                      <Fragment>
                        {
                          object.is_suspended === '1' &&
                          <div className="game-status-suspended template">SUSPENDED</div>
                        }
                        <div className="odd-market-line text-center">{object.name || object.header}</div>
                      </Fragment>
                    }
                    <div {...{ className: `odd-market-line text-center odd-btn ${isDisabled() ? 'disabled' : ''} ${_oddsActiveClass(object.selection_id, item.market_event_id, 1) ? 'yellow' : ''} ${SocketHighlight({ id: object.selection_id })}`, key }}
                      onClick={isDisabled() ? null : () => handleOptionChange({ fixture: {...item, sports_id: SelectedSports.sports_id}, odd: { ...object, price: object.odds, market_bet_id: object.selection_id, selectionId: object.selection_id }, betting_type: 1, selected_odd: object })}
                      >
                      {object.odds}
                    </div>
                  </Fragment>
                )
              })
            }
          </div>
          {
            _.isEmpty(JSON.parse(item.runners_odds)) &&
            <div className="odd-market-row">
              <div className="odd-market-line text-center">Odds Not available</div>
            </div>
          }
        </div>
      </div>

    );
  }
}

export default TemplateTotalSB;
