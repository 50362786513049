import React, { Component } from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { SiteContainer, TitleComponent } from 'Components';
import { Images } from 'Constants';
import { Utils } from 'Helpers';
class Withdraw extends Component {

  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      Wallet: '',
    }
  }

  handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value }, this.validateForm);
  };

  onDrop(e) {
    e.preventDefault();
    let reader = new FileReader();
    let mfile = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: mfile,
        //  image: reader.result
      }, () => {
        this.uploadImage()
      });
    }
    // reader.readAsDataURL(mfile)
  }


  render() {
    const {t} = this.props
    const { Wallet, formValid, posting } = this.state
    return (
      <SiteContainer {...this.props}>
        <TitleComponent title={"Withdraw"}/>
        <Container fluid className='gutters-container-7px'>
          <Row>
            <Col className='leftnav-col'>
              <div className="left-navigation">
                <div className="left-nav-item">{t('Deposit')}</div>
                <div className="left-nav-item active">{t('Withdraw')}</div>
                <div className="left-nav-item">{t('Account statement')}</div>
                <div className="left-nav-item">{t('Bets History')}</div>
                <div className="left-nav-item">{t('Settings')}</div>
                <div className="left-nav-item">{t('Account Information')}</div>
              </div>
            </Col>
            <Col>
              <div className="white-box">
                <div className="title">{t('Select withdraw method')}</div>

                <ul className="payment-option-row">
                  <li>
                    <img src={Images.BANK_TRANSFER} alt="" />
                  </li>
                  <li>
                    <img src={Images.PAYTM_LOGO} alt="" />
                  </li>
                  <li className="acitve">
                    <img src={Images.GOOGLE_PAY} alt="" />
                  </li>
                  <li>
                    <img src={Images.PHONEPE_LOGO} alt="" />
                  </li>
                </ul>

                <Row className="deposit-form">
                  <Col sm={6} xs={12}>
                    <Form>
                      <FormGroup className='input-border-bottom' >
                        <span className='label'>{t('Withdraw Amount')}</span>
                        <Input type="Wallet"
                          id='Wallet'
                          name='Wallet'
                          required
                          value={Wallet}
                          autoComplete="off"
                          onChange={this.handleChange}
                          invalid={Utils.isInvalid("reqiured", Wallet)}
                          valid={Utils.isValid("reqiured", Wallet)} />
                      </FormGroup>

                      <ul className="list-widthdraw-amount">
                        <li>1000</li>
                        <li>2000</li>
                        <li>5000</li>
                        <li>8000</li>
                      </ul>

                      <FormGroup className='m-0 text-center'>
                        <Button disabled={!formValid || posting} type='submit' size="lg" color="primary" className='round'>{t('Request Withdraw')}</Button>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

        </Container>
      </SiteContainer>
    )
  }
}

export default withTranslation()(Withdraw);

