import UIfx from 'uifx'
import beepMp3 from 'Assets/audio/beep.mp3'
const beep = new UIfx(beepMp3, {
  volume: 0.3, // value must be between 0.0 ⇔ 1.0
  // throttleMs: 50
})


function setMute(bool) {
  localStorage.setItem('is_mute', JSON.stringify(bool));
}

function getMuteStatus(){
  const mute = localStorage.getItem('is_mute');
  if (!mute) {
    return null;
  }
  return JSON.parse(mute);
}

// function removeMute(){
//   localStorage.removeItem('is_mute');
// }

export function play() {
  if(!getMuteStatus())
  beep.play()
}
export function Mute(bool) {
  setMute(bool)
}
export function IsMute() {
  return getMuteStatus()
}
export default {
  play, Mute, IsMute
};
