import {EDIT_ITEM} from './type';


// State 
const initialState = {
    data: {},
    }


    //Reducers
    export  const setmarketReducer = (state=initialState,action)=> {
        switch (action.type) {
            case EDIT_ITEM:
                return {
                    ...state,
                    data: action.payload
                }   
            default: return state;
        }
    }