import React, { Component } from 'react'
import {
  Container, Card, Row, Col, CardHeader, CardBody
} from 'reactstrap';
import { Wave } from 'react-animated-text';
import { Images } from 'Constants';
import { TitleComponent } from 'Components';
class Games extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    const {
    } = this.state
    return (
      <Container className='gutters-container-7px'>
        <TitleComponent title={"Games"}/>
        <Row>
          <Col>
            <Col className='mt-3'>
              <div className="casino_wall">
                {/* <span><h1>1</h1></span> */}
              </div>
            </Col>
          </Col>
        </Row>
        <div className="comming_soon mt-1">
          <h1>
            <Wave text="COMING SOON!!" effect="jump" effectChange={0.5} />
          </h1>
        </div>
        <Row>
          <Col className=" md-5 col-12 col-sm-12 col-md-6 col-lg-3">
            <Card className="crd_gms">
              <CardHeader className="gms_name"><h5>Roulette</h5></CardHeader>
              <CardBody className="gams_img">
                <img src={Images.ROULETTE} alt="roulette" />
              </CardBody>
            </Card>
          </Col>
          <Col className=" md-5 col-12 col-sm-12 col-md-6 col-lg-3">
            <Card className="crd_gms">
              <CardHeader className="gms_name"><h5>Teen Patti</h5></CardHeader>
              <CardBody className="gams_img">
                <img src={Images.TEEN_PATTI} alt="3_patti" />
              </CardBody>
            </Card>
          </Col>
          <Col className=" md-5 col-12 col-sm-12 col-md-6 col-lg-3">
            <Card className="crd_gms">
              <CardHeader className="gms_name"><h5>Andar Bahar</h5></CardHeader>
              <CardBody className="gams_img">
                <img src={Images.ANDAR_BAHAR} alt="andar_bahar" />
              </CardBody>
            </Card>
          </Col>
          <Col className=" md-5 col-12 col-sm-12 col-md-6 col-lg-3">
            <Card className="crd_gms">
              <CardHeader className="gms_name"><h5>Satta Matka</h5></CardHeader>
              <CardBody className="gams_img">
                <img src={Images.SATTA_MATKA} alt="andar_bahar" />
              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container >
    )
  }
}

export default Games
