import React, { Component } from 'react';
import Helmet from 'react-helmet';
import config from "config";
import { _, Utils } from "Helpers";
import { connect } from 'react-redux';

class TitleComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AppMasterData: Utils.getMasterData(),
    };
  }
  render() {
    const { title } = this.props;
    const { AppMasterData } = this.state;
    const AppName = !_.isUndefined(AppMasterData) && !_.isNull(AppMasterData) && !_.isUndefined(AppMasterData.site_title) && !_.isNull(AppMasterData.site_title) && (AppMasterData.site_title !== '') ? AppMasterData.site_title : ''
    // const defaultTitle = AppName + ' | ' + process.env.REACT_APP_WEBSITE_DESCRIPTION;
    const defaultTitle = AppName;
    return (
      <Helmet>
        {
          !_.isUndefined(AppMasterData) && !_.isNull(AppMasterData) && !_.isUndefined(AppMasterData.domain_detail) && !_.isNull(AppMasterData.domain_detail) && !_.isUndefined(AppMasterData.domain_detail.fav_icon) && !_.isNull(AppMasterData.domain_detail.fav_icon) && (AppMasterData.domain_detail.fav_icon !== '') &&
          <link rel="icon" type="image/png" sizes="32x32" href={AppMasterData.domain_detail.fav_icon} />
        }
        <title>{title ? (title + ' | ' + AppName) : defaultTitle}</title>
        <meta property="og:title" content={this.props.masterData && this.props.masterData.domain_detail ? this.props.masterData.domain_detail.website_name : ""} />
        <meta property="og:url" content={this.props.masterData && this.props.masterData.domain_detail ? this.props.masterData.domain_detail.domain : ""} />
        <meta name="description" content={this.props.masterData && this.props.masterData.domain_detail ? this.props.masterData.domain_detail.domain : ""} />
      </Helmet>
    )
  }
}

function mapStateToProps(state) {
  const { login, app } = state;
  return {
    masterData: app.masterData,
  };
}

export default connect(mapStateToProps, null)(TitleComponent);