/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { API, Http, Socket, Utils, _ } from 'Helpers';
import { withTranslation } from 'react-i18next';
import validator from "validator";
import { connect } from "react-redux";
import { Row, Col, Button, Collapse } from "reactstrap";
import { CONSTANT, SocketEvent, Images, } from 'Constants';
import { Betslip, } from 'Components';
import { Auth } from 'Service';
import { ExposureDetailsModal } from 'Modals';
class FancyFixtureDetailList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_betslip_drawer: true,
      running: false,
      Init: true,
      posting: false,
      odd_id: "",
      betslip_lastupdate: new Date().valueOf(),
      ExposureDetailsModalShow: false,
      FancyData: this.props.FancyData,
      SelectedFixture: {},
      SelectedOddsFancy: {},
      EventDetails: {},
      recently_changed: [],
      AppMasterData: Utils.getMasterData(),
      fancyMarketShow: true,
      fancyMarketStackMin: this.props.fancyMarketStackMin,
      fancyMarketStackMax: this.props.fancyMarketStackMax,
      selectedBetslip: '',
      selectedBetslipType: '',
      selectedMarketID: '',
      button_threenine: false,
      child_session_type_id: '',
      isThemeGradient: Utils.getCookie('isThemeGradient') ? Number(Utils.getCookie('isThemeGradient')) == 1 ? true : false : false,
      showFancyMarket: true,

    }
    this.refreshComponent = this.refreshComponent.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.FancyData !== prevProps.FancyData) {
      if (!_.isUndefined(this.props.FancyData) && !_.isNull(this.props.FancyData)) {
        this.setState({ FancyData: this.props.FancyData })
      }
    }
    if (this.state.EventDetails !== prevProps.EventDetails) {
      this.setState({ EventDetails: this.props.EventDetails })
    }
  }

  switchmodal = (name, market_event_id, isFor) => {
    this.setState({
      [name]: true,
      market_event_id: market_event_id,
      isFor: isFor,
    })
  }

  setSecTimeout(res) {
    const { AppMasterData } = this.state;
    if (Number(res.commentary_button_id) == 39) {
      let change = AppMasterData;
      this.setState({ button_threenine: true })
      _.map(change.commentary_buttons, (item, index) => {
        if (res.commentary_button_id == item.button_id) {
          setTimeout(() =>
            this.setState({ button_threenine: false })
            , Number(item.bet_close_time) * 1000)
        }
      })
    }
  }
  updateStackMinMax = () => {
    const { EventDetails, AppMasterData } = this.state;
    if (AppMasterData && !_.isEmpty(AppMasterData) && AppMasterData.sports_list && !_.isEmpty(AppMasterData.sports_list) && AppMasterData.sports_list.exchange_betting && !_.isEmpty(AppMasterData.sports_list.exchange_betting) && EventDetails && !_.isEmpty(EventDetails) && EventDetails.sports_id && !_.isEmpty(EventDetails.sports_id)) {
      _.forEach(AppMasterData.sports_list.exchange_betting, item => {
        if (item.min_fancy_stake && !_.isEmpty(item.min_fancy_stake))
          this.setState({ fancyMarketStackMin: Number(item.min_fancy_stake) });
        if (item.max_fancy_stake && !_.isEmpty(item.max_fancy_stake))
          this.setState({ fancyMarketStackMax: Number(item.max_fancy_stake) });
      })
    }
    if (EventDetails && !_.isEmpty(EventDetails)) {
      if (EventDetails.min_fancy_stake && !_.isEmpty(EventDetails.min_fancy_stake))
        this.setState({ fancyMarketStackMin: Number(EventDetails.min_fancy_stake) });
      if (EventDetails.max_fancy_stake && !_.isEmpty(EventDetails.max_fancy_stake))
        this.setState({ fancyMarketStackMax: Number(EventDetails.max_fancy_stake) });
    }
  }

  modalHide = () => {
    this.setState({
      ExposureDetailsModalShow: false,
      child_session_type_id: '',
      LDO_fancy: false
    })
  }
  modalShow = (name, odd_id, type, child_session_type_id) => {
    this.setState({
      odd_id: odd_id,
      LDO_fancy: type,
      child_session_type_id: child_session_type_id,
      ExposureDetailsModalShow: true
    })
  }

  modalUpdate = () => {
    this.setState({
      ExposureDetailsModalShow: false,
    })
  }

  updateDimensions = () => {
    this.setState({
      is_betslip_drawer: window.innerWidth < 850
    });
  }

  SocketGlobalSettingHandler = (res = {}) => {
    const { AppMasterData } = this.state;
    this.setState({ AppMasterData: { ...AppMasterData, ...res } });
  }

  SocketBetCLoseTimeUpdate = (res) => {
    const { AppMasterData } = this.state;
    if (Number(res.button_id) == 39) {
      let change = AppMasterData;
      _.map(change.commentary_buttons, (item, index) => {
        if (res.button_id == item.button_id) {
          item.bet_close_time = res.bet_close_time
          this.setState({ AppMasterData: change })
        }
      })
    }
  }

  SocketDiamondMarketUpdated = (res = {}) => {
    if (!_.isEmpty(res)) {
      const _this = this;
      _.map(res.data, (item) => {
        _this.SocketFancyhandler(item)
      })
    }
  }

  SocketMatchCommentaryUpdated = (res = {}) => {
    if (!_.isEmpty(res)) {
      const { EventDetails } = this.state;

      if (EventDetails.event_id == res.event_id) {
        this.setState({
          EventDetails: { ...EventDetails, ...res }
        }, () => { this.updateStackMinMax(); });

      }
    }
  }

  callSoketEvents = () => {
    const { MarketRoomEmit, EventDetails } = this.props;
    const _this = this;

    Socket.on(SocketEvent.FANCY, function (res) {
      // if (!_.isEmpty(res) && (res.child_session_type_id == 0) && (res.event_id == EventDetails.event_id)) {
      if (!_.isEmpty(res) && (Number(res.session_type_id) == 1) && (res.event_id == EventDetails.event_id)) {
        MarketRoomEmit(res.session_id);
        _this.SocketAddFancyhandler(res);
      }
    })
    Socket.on(SocketEvent.RESULTFANCY, function (res) {
      if (!_.isEmpty(res) && (res.child_session_type_id == 0)) {
        const { FancyData } = _this.state;
        let newFancyData = _.filter(FancyData, (item) => {
          if (item.child_session_type_id == res.child_session_type_id) {
            item = _.filter(item.sessions, (session) => {
              if (session.session_id != res.session_id) {
                return session;
              }
            });
          }
          return item;
        })
        _this.setState({ FancyData: newFancyData })
      }
    })
    // Socket.on(SocketEvent.STATUSUPDATEDMK, function (res) {
    //   if (!_.isEmpty(res)) {
    //     _this.SocketSwitchHandler(res)
    //   }
    // })
    Socket.on(SocketEvent.GLOBAL_SETTING, function (res) {
      if (!_.isEmpty(res)) {
        _this.SocketGlobalSettingHandler(res)
      }
    })
    Socket.on(SocketEvent.DIAMOND_MARKET_UPDATED, function (res) {
      if (!_.isEmpty(res)) {
        _this.SocketDiamondMarketUpdated(res)
      }
    })
    Socket.on(SocketEvent.MATCH_COMMENTARY_UPDATED, function (res) {
      if (!_.isEmpty(res)) {
        _this.SocketMatchCommentaryUpdated(res)
        if (Number(res.commentary_button_id) == 39) {
          _this.setSecTimeout(res)
        }
      }
    })
    Socket.on(SocketEvent.CENTRAL_FANCY_MARKET_UPDATED, function (res) {
      // if (!_.isEmpty(res) && (res.child_session_type_id == 0) && (res.event_id == EventDetails.event_id)) {
      if (!_.isEmpty(res) && (res.event_id == EventDetails.event_id)) {
        MarketRoomEmit(res.session_id);
        _this.SocketFancyhandler(res)
      }
    })
    Socket.on(SocketEvent.BET_CLOSE_TIME, function (res) {
      if (!_.isEmpty(res))
        _this.SocketBetCLoseTimeUpdate(res)
    })
    Socket.on(SocketEvent.SESSION_COMMENTRY, function (res) {
      if (!_.isEmpty(res) && (Number(res.session_type_id) == 1)) {
        _this.SocketSessionComm(res)
      }
    })
  }

  // Life cycle
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.callSoketEvents();
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    if (!_.isUndefined(this.props.mkt_rooms) && !_.isNull(this.props.mkt_rooms)) {
      Socket.emit('unsubscribe', this.props.mkt_rooms);
    }
    Socket.off(SocketEvent.FANCY)
    Socket.off(SocketEvent.SCORE)
    //Socket.off(SocketEvent.STATUSUPDATEDMK)
    Socket.off(SocketEvent.GLOBAL_SETTING)
    Socket.off(SocketEvent.DIAMOND_MARKET_UPDATED)
    Socket.off(SocketEvent.MATCH_COMMENTARY_UPDATED)
    Socket.off(SocketEvent.CENTRAL_FANCY_MARKET_UPDATED)
    Socket.off(SocketEvent.EVENT_CENTRAL_SCORE_UPDATED)
    Socket.off(SocketEvent.MK_STATUS_UPDATED)
    Socket.off(SocketEvent.MARKET_SETTLE)
    Socket.off(SocketEvent.BET_CLOSE_TIME)
    Socket.off(SocketEvent.SESSION_COMMENTRY);

    this.setState = () => {
      return;
    };
  }

  SocketSwitchHandler = (res = {}) => {
    const { event_id } = this.props.match.params;
    if (!_.isNull(res.event_id)) {
      if (res.event_id == event_id) {
        this.refreshComponent();
      }
    }
  }

  SocketAddFancyhandler = (res = {}) => {
    const { EventDetails } = this.props;
    const { FancyData } = this.state;
    if (EventDetails.event_id == res.event_id) {
      if (FancyData.length > 0) {
        if ((res.admin_status == 1) && (res.settlement_status == 0) && (res.is_suspend == 0) && (res.is_active == 1)) {
          let FancyExist = false;
          let newFancyData = _.map(FancyData, (item) => {
            if (!_.isUndefined(item) && !_.isNull(item) && !_.isUndefined(item.sessions) && !_.isNull(item.sessions) && (item.child_session_type_id == res.child_session_type_id)) {
              FancyExist = true;
              let sessionExist = false;
              let newSessions = _.map(item.sessions, (sessionItem) => {

                if ((Number(sessionItem.selection_id) == Number(res.selection_id))) {
                  if ((Number(sessionItem.lay_size_1) != Number(res.lay_size_1)) || (Number(sessionItem.back_size_1) != Number(res.back_size_1))) {
                    if ((Number(res.lay_size_1) != 0 || Number(res.back_size_1) != 0)) {
                      this.getSessionDetail(res.session_id, res.event_id);
                    }
                  }
                }

                if ((sessionItem.child_session_type_id == res.child_session_type_id) && (sessionItem.selection_id == res.selection_id)) {
                  sessionExist = true;
                  // sessionItem = res;
                  sessionItem = { ...res, fexposure: sessionItem.fexposure, bet_place: sessionItem.bet_place };
                }
                return sessionItem;
              })
              if (!sessionExist) {
                newSessions.push(res);
              }
              item.sessions = newSessions;
            }
            return item;
          });
          if (!FancyExist) {
            newFancyData.push({
              child_session_type_id: res.child_session_type_id,
              is_active: res.is_active,
              max_fancy_stake: res.max_fancy_stake,
              min_fancy_stake: res.min_fancy_stake,
              max_profit: res.max_profit,
              session_type: res.session_type,
              sessions: [{ ...res }]
            })
          }
          this.setState({ FancyData: newFancyData })
        }
        else if ((res.admin_status == 0) || (res.settlement_status == 1) || (res.is_suspend == 1)) {
          let newFancyData = _.map(FancyData, (item) => {
            if (item.child_session_type_id == res.child_session_type_id) {
              item.sessions = item.sessions.filter((session) => { return (session.selection_id != res.selection_id) });
            }
            return item;
          });
          this.setState({ FancyData: newFancyData.filter((item) => { return item.sessions.length > 0 }) })
        }
        else if (res.is_active == 0) {
          let newFancyData = _.map(FancyData, (item) => {
            if (!_.isUndefined(item) && !_.isNull(item) && !_.isUndefined(item.sessions) && !_.isNull(item.sessions) && (item.child_session_type_id == res.child_session_type_id)) {
              let newSessions = _.map(item.sessions, (sessionItem) => {
                if ((sessionItem.child_session_type_id == res.child_session_type_id) && (sessionItem.selection_id == res.selection_id)) {
                  sessionItem = res;
                }
                return sessionItem;
              })
              item.sessions = newSessions;
            }
            return item;
          });
          this.setState({ FancyData: newFancyData })
        }
      }
      else {
        if ((res.admin_status == 1) && (res.settlement_status == 0) && (res.is_suspend == 0)) {
          let newFancyData = [{
            child_session_type_id: res.child_session_type_id,
            is_active: res.is_active,
            max_fancy_stake: res.max_fancy_stake,
            min_fancy_stake: res.min_fancy_stake,
            max_profit: res.max_profit,
            session_type: res.session_type,
            sessions: [{ ...res }]
          }]
          this.setState({ FancyData: newFancyData })
        }
      }
    }
  }

  SocketFancyhandler = (res = {}) => {
    const { EventDetails } = this.props;
    const { FancyData } = this.state;
    if (EventDetails.event_id == res.event_id) {
      let newFancyData = _.map(FancyData, (item) => {
        let sessions = _.map(item.sessions, (session) => {
          if (session.selection_id == res.selection_id) {

            if ((Number(res.lay_price_1) !== 0) && (Number(res.back_price_1) !== 0)) {
              if (Number(res.lay_price_1) !== 0)
                res = { ...res, ...{ pre_lay_price_1: res.lay_price_1 } }
              if (Number(res.lay_price_1) !== 0)
                res = { ...res, ...{ pre_back_price_1: res.back_price_1 } }
              if ((Number(session.lay_size_1) != Number(res.lay_size_1)) || (Number(session.back_size_1) != Number(res.back_size_1))) {
                if ((Number(res.lay_size_1) != 0 || Number(res.back_size_1) != 0)) {
                  this.getSessionDetail(res.session_id, res.event_id);
                }
              }

            }

            return { ...session, ...res }
          }
          return session;
        });
        item.sessions = sessions;
        return item;
      })
      this.setState({ FancyData: newFancyData });
    }
  }

  // SocketFancyhandler = (res = {}) => {
  //   console.log("is =========>>>>")
  //   const { EventDetails } = this.props;
  //   const { FancyData } = this.state;
  //   if (EventDetails.event_id == res.event_id) {
  //     console.log("is one")
  //     if (Utils.containsObject(res, FancyData)) {
  //       console.log("is two")
  //       let newFancyData = _.map(FancyData, (item) => {
  //         console.log("is three")
  //         let sessions = _.map(item.sessions, (session) => {
  //           console.log("is four")
  //           if (session.selection_id == res.selection_id) {
  //             console.log("is five")
  //             if ((Number(res.lay_price_1) !== 0) && (Number(res.back_price_1) !== 0)) {
  //               if (Number(res.lay_price_1) !== 0)
  //                 res = { ...res, ...{ pre_lay_price_1: res.lay_price_1 } }
  //               if (Number(res.lay_price_1) !== 0)
  //                 res = { ...res, ...{ pre_back_price_1: res.back_price_1 } }

  //               if ((session.lay_size_1 != res.lay_size_1) || (session.back_size_1 != res.back_size_1))
  //                 console.log("is six")
  //               this.getSessionDetail(res.session_id, res.event_id);

  //             }
  //             return { ...session, ...res }

  //           }
  //           return session;
  //         });
  //         item.sessions = sessions;
  //         return item;
  //       })
  //       this.setState({ FancyData: newFancyData });
  //     }

  //   }
  // }

  getSessionDetail = (session_id, event_id) => {
    const { FancyData } = this.state;
    let param = {
      session_id: session_id,
      event_id: event_id
    }
    this.setState({ posting: true })
    Http.post(API.SESSION_DETAILS, param).then(response => {
      if (!_.isEmpty(response.data)) {
        let newFancyData = _.map(FancyData, (item) => {
          let sessions = _.map(item.sessions, (session) => {
            if (session.selection_id == response.data.selection_id) {
              return { ...session, ...response.data }
            }
            return session
          });
          item.sessions = sessions;
          return item
        })
        this.setState({ FancyData: newFancyData })

      }
      this.setState({
        posting: false,
      })
    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  SocketHighlight = ({ id }) => {
    const { recently_changed } = this.state
    if (_.isEmpty(recently_changed)) return ''
    let _id = _.isString(id) ? id : JSON.stringify(id)
    if (_.includes(recently_changed, _id)) {
      this.cleanSocketHighlight()
      return ' blink '
    }
    else return '';
  }

  cleanSocketHighlight = (timer = 50) => {
    setInterval(() => {
      if (!_.isEmpty(this.state.recently_changed)) {
        this.setState({ recently_changed: [] })
      }
    }, timer)
  }

  refreshComponent = () => {
    const { MarketRoomEmit } = this.props;
    const _this = this;
    Socket.on(SocketEvent.FANCY, function (res) {
      const { EventDetails } = _this.state;
      // if (!_.isEmpty(res) && (res.child_session_type_id == 0) && (res.event_id == EventDetails.event_id)) {
      if (!_.isEmpty(res) && (Number(res.session_type_id) == 1) && (res.event_id == EventDetails.event_id)) {

        MarketRoomEmit(res.session_id);
        _this.SocketAddFancyhandler(res);
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isUndefined(nextProps.WinlossObject) && !_.isNull(nextProps.WinlossObject) && !_.isUndefined(nextProps.WinlossObject.lastupdate) && !_.isNull(nextProps.WinlossObject.lastupdate)) {
      const { WinlossObject } = this.props
      if (nextProps.WinlossObject.lastupdate !== WinlossObject.lastupdate) {
        if (!nextProps.WinlossObject.is_place_bet) {
          if (nextProps.WinlossObject.type == 0) {
            this.UpdateWinloss(nextProps.WinlossObject)
          }
        }
      }
    }
  }

  UpdateWinloss = (obj) => {
    if (Number(obj.bookmaker_type) === 3) {
      const { FancyData } = this.state
      if (!_.isUndefined(FancyData) && !_.isNull(FancyData) && FancyData.length > 0) {
        let newFancyData = _.map(FancyData, (item) => {
          if (!_.isUndefined(item) && !_.isNull(item) && !_.isUndefined(item.sessions) && !_.isNull(item.sessions) && (item.sessions.length > 0)) {
            let sessions = _.map(item.sessions, (session) => {
              if (session.session_id == obj.session_id) {
                return { ...session, bet_place: obj.bet_place, fexposure: { yes: obj.fexposure.yes, no: obj.fexposure.no } }
              }
              return session;
            })
            item.sessions = sessions;
            return item;
          }
          return item;
        })
        this.setState({ FancyData: newFancyData })
      }
    }
  }

  reloadMatchDetailsPage = () => {
    window.location.reload()
  }

  closeMarketBetSlip = () => {
    this.setState({ selectedBetslip: '', selectedBetslipType: '', selectedMarketID: '' }, () => {
      const { reloadBetslipOpenBet, openBetSlipMarketId } = this.props;
      reloadBetslipOpenBet();
      openBetSlipMarketId('');
    })
  }

  openMarketBetSlip = (selectionId, type) => {
    const { openBetSlipMarketId } = this.props;
    openBetSlipMarketId(selectionId);
    this.setState({
      selectedBetslip: selectionId,
      selectedBetslipType: type,
    })
  }

  _fancyOddsActiveClass = (market_bet_id, selection_id, betting_type) => {
    let { FancyData } = this.state
    return _.some(FancyData, (odd) => {
      return odd.picked_odd_id === market_bet_id + '_' + selection_id + '_' + betting_type
    })
  }

  _fancyHandleOptionChange = (option) => {
    let { SelectedOddsFancy } = this.state;
    const { fixture, odd, betting_type, selected_odd } = option;
    SelectedOddsFancy[0] = {
      event_id: odd.event_id,
      picked_odd_id: odd.market_bet_id + '_' + odd.selection_id + '_' + betting_type,
      market_bet_id: odd.market_bet_id,
      picked_odd_price: betting_type === 1 ? odd.price : odd.lay_price,
      picked_odd: { ...odd, betting_type, name: odd.runner_name, ...selected_odd },
      stack_value: '',
      ...fixture,
    }
    this.setState({
      SelectedOddsFancy,
      betslip_lastupdate: new Date().valueOf()
    }, () => {
      Utils.setBetSlip(CONSTANT.BETSLIP['fancy_betting'], this.state.SelectedOddsFancy)
    })
  }

  _fancyHandleRemoveSlip = (key) => {
    let SelectedOddsFancy = Utils.getBetSlip('fancy_betting');
    let newSelectedOddsFancy = _.omit(SelectedOddsFancy, key)
    this.setState({
      SelectedOddsFancy: newSelectedOddsFancy,
      betslip_lastupdate: new Date().valueOf()
    }, () => {
      Utils.setBetSlip('fancy_betting', this.state.SelectedOddsFancy)
    })
  }

  SocketSessionComm = (res = {}) => {
    const { EventDetails } = this.props;
    const { FancyData } = this.state;
    if (EventDetails.event_id == res.event_id) {
      // if (Utils.containsObject(res, FancyData)) {
      let newFancyData = _.map(FancyData, (item) => {
        if (item.child_session_type_id == res.child_session_type_id) {
          let sessions = _.map(item.sessions, (session) => {
            if (session.selection_id == res.selection_id) {
              return { ...session, ...res }
            }
            return session;
          });
          item.sessions = sessions;
          return item;
        }
        return item;
      })
      this.setState({ FancyData: newFancyData })
      // }
    }
  }

  handleRemoveSlip = (key) => {
    this.setState({
      SelectedOddsFancy: {},
      betslip_lastupdate: new Date().valueOf()
    }, () => {
      Utils.setBetSlip(CONSTANT.BETSLIP['fancy_betting'], this.state.SelectedOddsFancy)
    })
  }

  clearAllBet = (BetslipType) => {
    this.setState({
      SelectedOddsFancy: {},
      betslip_lastupdate: new Date().valueOf()
    }, () => {
      Utils.removeBetSlip(CONSTANT.BETSLIP[BetslipType])
      Utils.removeMultiBetSlip(CONSTANT.BETSLIP[BetslipType])
    })
  }



  render() {
    const {
      t,
      openBetSlipid,
    } = this.props
    const {
      FancyData,
      SelectedFixture,
      SelectedOddsFancy,
      betslip_lastupdate,
      EventDetails,
      is_betslip_drawer,
      odd_id,
      ExposureDetailsModalShow,
      AppMasterData,
      fancyMarketStackMin,
      fancyMarketStackMax,
      child_session_type_id,
      button_threenine,
      isThemeGradient
    } = this.state


    const ExposureDetailsModalProps = {
      odd_id: odd_id,
      child_session_type_id: child_session_type_id,
      event_id: EventDetails.event_id,
      isOpen: ExposureDetailsModalShow,
      toggle: this.modalHide,
      callBack: this.modalUpdate,
      LDO_fancy: this.state.LDO_fancy
    }

    const BetslipProps = {
      EventDetails,
      SelectedOdds: SelectedOddsFancy,
      betslip_lastupdate,
      page_id: 'fancy_betting',
      handleRemoveSlip: this.handleRemoveSlip,
      clearAllBet: this.clearAllBet,
      closeMarketBetSlip: this.closeMarketBetSlip,
    }
    return (
      <Fragment>
        {
          !_.isUndefined(FancyData) && !_.isNull(FancyData) && (FancyData.length > 0) && (FancyData.filter(fancyItem => { return (fancyItem.sessions.filter((session) => { return session.game_status !== 'CLOSED' }).length > 0) }).length > 0) &&
          <div className="mb-2 market-container-new layback-odd-new">
            <Row className={"justify-content-center align-items-center py-2 heading " + (isThemeGradient ? " bg-gradient-3 " : "")}>
              <Col className='d-flex align-items-center col-12 justify-content-between'>
                <div className={"d-block text-uppercase text-nowrap  text-white ml-4 " + (is_betslip_drawer ? "font11" : "font18")}>Fancy market</div>
                <div className='mr-3'>
                  <span className={this.state.showFancyMarket ? "icon-up" : "icon-down"} onClick={() => { this.setState({ showFancyMarket: !this.state.showFancyMarket }) }}></span>
                </div>
              </Col>
            </Row>
            <Collapse isOpen={this.state.showFancyMarket}>

              {_.map(FancyData, (fancyItem, fancyIndex) => {
                return (
                  !_.isUndefined(fancyItem) && !_.isNull(fancyItem) &&
                  <Fragment key={'fancyfancy-odd-' + fancyIndex}>
                    <div className="mb-4 market-container-new layback-odd-new">
                      <Row className={"justify-content-center align-items-center py-2 heading " + (isThemeGradient ? " bg-gradient-3 " : "")}>
                        <Col className='d-flex align-items-center col-8'>
                          <div className="col-sm-11 col-md-12 col-lg-">
                            <div className={"d-block text-uppercase text-nowrap  text-white " + (is_betslip_drawer ? "font11" : "font18")}>{fancyItem.session_type}</div>
                            <div className={"min_max_price  " + (is_betslip_drawer ? "font9" : "font13")}>(Min : {fancyItem.min_fancy_stake} Max : {fancyItem.max_fancy_stake})</div>
                          </div>
                        </Col>

                        <Col className={"text-white d-flex align-items-center justify-content-around  col-2 pr-5 " + (is_betslip_drawer ? "font11" : "font18 ")}>
                          <span>{Number(fancyItem.child_session_type_id) === 10 ? 'EVEN' : 'NO'}</span>
                        </Col>
                        <Col className={"text-white d-flex align-items-center justify-content-around  col-2 pr-5 " + (is_betslip_drawer ? "font11" : "font18 ")}>
                          <span>{Number(fancyItem.child_session_type_id) === 10 ? 'ODD' : 'YES'}</span>

                        </Col>
                      </Row>

                      {
                        this.state.fancyMarketShow &&
                        _.map(fancyItem.sessions, (item, key) => {
                          return (
                            // (Number(item.session_type_id) === 1) && (item.game_status.toLowerCase() != 'closed') && (Number(item.settlement_status) === 0) &&
                            (Number(item.session_type_id) === 1) && (item.game_status != 'Closed') && (Number(item.settlement_status) === 0) &&

                            <Row className={"bg-white  mx-0 border-new layback-row align-items-center " + (isThemeGradient ? "bg-gradient-white " : "")} {...{ key }}>
                              <Col className='d-flex align-items-center col-6 p-0'>
                                <div className="col-sm-11 col-md-12 col-lg-8">
                                  <div className={"d-block font-weight-bold text-dark " + (is_betslip_drawer ? "font11" : "font16")}>{item.runner_name} </div>

                                  {/* <div className={" text-nowrap font-weight-bold text-dark " + (is_betslip_drawer ? "font8 " : "font14 ")}>Pos. No : <span className={(item.fexposure && item.fexposure.no && !_.isUndefined(item.fexposure.no) && (item.fexposure.no < 0) ? " loss " : " profit ") + (is_betslip_drawer ? " font10 " : " font17 ")}>{item.fexposure && item.fexposure.no && !_.isUndefined(item.fexposure.no) ? Number(item.fexposure.no).toFixed(2) : 0} </span>Pos. Yes :<span className={(item.fexposure && item.fexposure.yes && !_.isUndefined(item.fexposure.yes) && (item.fexposure.yes < 0) ? " loss " : " profit ") + (is_betslip_drawer ? " font10 " : " font17 ")}> {item.fexposure && item.fexposure.yes && !_.isUndefined(item.fexposure.yes) ? Number(item.fexposure.yes).toFixed(2) : 0}</span></div> */}
                                </div>
                              </Col>
                              <Col className='col-2 p-1'>
                                {/* {item.exposure !== 0 && */}
                                {item.bet_place &&
                                  <div className='float-right'>
                                    <Button color="primary" className={"book_btn btn-sm  " + (is_betslip_drawer ? " mob_btn ml-1 mr-1 " : " ml-2 mr-4 ") + (isThemeGradient ? " bg-gradient-1 " : "")} onClick={() => this.modalShow('ExposureDetailsModalShow', item.session_id, item.child_session_type_id == 10 ? true : false, item.child_session_type_id)}>BOOK</Button>
                                  </div>

                                }
                              </Col>
                              <Col className={"col-2 cell p-1 " + (!is_betslip_drawer && "pr-3")}>
                                <div className={" lay-odd text-center p-0 " + (is_betslip_drawer && " h30 ") + (isThemeGradient ? " bg-gradient-lay " : "")}>
                                  <div className={`my-auto ${this._fancyOddsActiveClass(item.market_bet_id, item.selection_id, 2) ? 'yellow' : ''}`} onClick={() => Number(item.lay_price_1) > 0 ? [this._fancyHandleOptionChange({ fixture: { ...SelectedFixture, sports_id: EventDetails.sports_id }, odd: { ...item }, betting_type: 2, selected_odd: { price: item.lay_price_1, size: '' }, EventDetails: EventDetails }), this.openMarketBetSlip(item.session_id, 'back')] : null}>
                                    <div className={is_betslip_drawer ? "font11" : ""}>{item.lay_price_1}</div>
                                    <div className={is_betslip_drawer ? "font8" : "font12 mneg5"}>{item.lay_size_1}</div>
                                  </div>
                                </div>
                              </Col>
                              <Col className="col-2 p-1 cell">
                                <div className={"back-odd text-center p-0 " + (is_betslip_drawer && " h30 ") + (isThemeGradient ? " bg-gradient-odd " : "")}>


                                  <div className={`my-auto back ${this._fancyOddsActiveClass(item.market_bet_id, item.selection_id, 1) ? 'yellow' : ''}`} onClick={() => Number(item.back_price_1) > 0 ? [this._fancyHandleOptionChange({ fixture: { ...SelectedFixture, sports_id: EventDetails.sports_id }, odd: { ...item }, betting_type: 1, selected_odd: { price: item.back_price_1, size: '' }, EventDetails: EventDetails }), this.openMarketBetSlip(item.session_id, 'lay')] : null}>
                                    <div className={is_betslip_drawer ? "font11" : ""}>{item.back_price_1}</div>
                                    <div className={is_betslip_drawer ? "font8" : "font12 mneg5"}>{item.back_size_1}</div>
                                  </div>
                                </div>
                              </Col>
                              <Col className='col-12'>
                                <div className={" text-nowrap font-weight-bold text-dark " + (is_betslip_drawer ? "font8 " : "font14 ")}>Pos. No : <span className={(item.fexposure && item.fexposure.no && !_.isUndefined(item.fexposure.no) && (item.fexposure.no < 0) ? " loss " : " profit ") + (is_betslip_drawer ? " font10 " : " font17 ")}>{item.fexposure && item.fexposure.no && !_.isUndefined(item.fexposure.no) ? Number(item.fexposure.no).toFixed(2) : 0} </span>Pos. Yes :<span className={(item.fexposure && item.fexposure.yes && !_.isUndefined(item.fexposure.yes) && (item.fexposure.yes < 0) ? " loss " : " profit ") + (is_betslip_drawer ? " font10 " : " font17 ")}> {item.fexposure && item.fexposure.yes && !_.isUndefined(item.fexposure.yes) ? Number(item.fexposure.yes).toFixed(2) : 0}</span></div>
                              </Col>

                              {
                                (!_.isUndefined(item.appMarketStatus) && !_.isNull(item.appMarketStatus) && ((Number(item.appMarketStatus) === 2) || (Number(item.appMarketStatus) === 3) || (Number(item.appMarketStatus) === 4) || (Number(item.appMarketStatus) === 9))) ?
                                  <div className="game-status mneg26-mob16"><span>{(Number(item.appMarketStatus) === 2 ? "MARKTE INACTIVE" : ((Number(item.appMarketStatus) === 3 ? "MARKET SUSPENDED" : (Number(item.appMarketStatus) === 4 ? "Closed" : "Ball Start"))))}</span></div>
                                  :
                                  (
                                    (!_.isUndefined(item.game_status) && !_.isNull(item.game_status) && ((!validator.isEmpty(item.game_status) && item.game_status.toLowerCase() != 'suspended' && item.game_status.toLowerCase() != 'open') && Number(item.is_suspend) === 0)) ?
                                      <div className="game-status mneg26-mob16"><span>{item.game_status}</span></div>
                                      :
                                      (
                                        (!_.isUndefined(item.is_active) && !_.isNull(item.is_active) && (Number(item.is_active) === 0)) ?
                                          <div className="game-status mneg26-mob16"><span>BET NOT ALLOWED</span></div>
                                          :
                                          (
                                            (!_.isUndefined(item.back_price_1) && !_.isNull(item.back_price_1) && !_.isUndefined(item.lay_price_1) && !_.isNull(item.lay_price_1) && (Number(item.back_price_1) == 0) && (Number(item.lay_price_1) == 0)) ?
                                              <div className="game-status mneg26-mob16"><span>BET NOT ALLOWED</span></div>
                                              :
                                              (
                                                (!_.isUndefined(item.commentary_btnid) && !_.isNull(item.commentary_btnid) && (Number(item.commentary_btnid) == 7)) ?
                                                  <div className="game-status mneg26-mob16"><span>Ball Start</span></div>
                                                  :
                                                  (
                                                    (!_.isUndefined(item.commentary_btnid) && !_.isNull(item.commentary_btnid) && (Number(item.commentary_btnid) == 3)) ?
                                                      <div className="game-status mneg26-mob16"><span>Bet Close</span></div>
                                                      :
                                                      (!_.isUndefined(EventDetails.sports_id) && !_.isNull(EventDetails.sports_id) && (Number(EventDetails.sports_id) === 2) && !_.isUndefined(AppMasterData.commentary_buttons) && !_.isNull(AppMasterData.commentary_buttons) && !_.isEmpty(AppMasterData.commentary_buttons) && !_.isUndefined(EventDetails.commentary_button_id) && !_.isNull(EventDetails.commentary_button_id)) &&
                                                      <Fragment>
                                                        {
                                                          _.map(AppMasterData.commentary_buttons, (button) => {
                                                            return (
                                                              ((Number(EventDetails.commentary_button_id) !== 3) && (Number(EventDetails.commentary_button_id) === Number(button.button_id)) && (Number(button.bet_status) === 0) && (Number(button.button_id) === 37 || Number(button.button_id) === 38)) &&
                                                              <div className="game-status mneg26-mob16"><span>SESSION CLOSE</span></div>
                                                            )
                                                          })
                                                        }
                                                      </Fragment>
                                                  )
                                              )
                                          )
                                      )
                                  )
                              }

                              {
                                this.state.selectedBetslip == item.session_id && item.session_id == openBetSlipid &&
                                <Betslip {...BetslipProps} min={item.min_fancy_stake} max={item.max_fancy_stake} delay={item.fancy_delay} maxProfit={item.max_profit} />
                              }



                            </Row>

                          )
                        })}


                      {!_.isEmpty(fancyItem.announcement) && <Row className={"justify-content-center align-items-center p-2 announcement animate-pulse " + (isThemeGradient ? "bg-radial-1" : "")}>
                        <Col className="col-12">
                          <marquee scrollamount="3">{fancyItem.announcement}</marquee>
                        </Col>
                      </Row>}
                    </div>

                  </Fragment>
                )
              })}
            </Collapse>
          </div>

        }
        {ExposureDetailsModalShow && <ExposureDetailsModal {...ExposureDetailsModalProps} />}
      </Fragment>
    );
  }
}
const FancyFixtureDetailListTrans = withTranslation()(FancyFixtureDetailList)

function mapStateToProps(state) {
  const { app } = state;
  return {
    WinlossObject: app.WinlossObject
  };
}

export default connect(
  mapStateToProps
)(FancyFixtureDetailListTrans);
