/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { API, Http, Socket, Utils, _ } from 'Helpers';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import { CONSTANT, SocketEvent } from 'Constants';
import { MessageBox, Scoreboard, TemplateOptionSB, TemplateTotalSB, TitleComponent } from 'Components';
import { Auth } from 'Service';

class SportsBookFixtureDetailList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Init: true,
      FixturePosting: true,
      isFixtureDetailsChanged: false,

      ScoresData: [],
      MarketList: [],
      SelectedFixture: {},
      EventDetails: {},
      fancyChanged: false,
      recently_changed: []
    }
  }

  // Fetch Request(s) 
  GetFixtureMarket = () => {
    const { match } = this.props;
    let param = {
      "event_id": match.params.event_id
    }
    this.setState({
      Init: false,
      FixturePosting: true,
    })
    Http.post(API.GET_FIXTURE_MARKETS_SP, param).then(response => {






      this.setState({
        FixturePosting: false,
        EventDetails: response.data.event,
        MarketList: response.data.markets,
        // ScoresData: response.data.scores,
      }, () => {
        this.setState({
          ...(!_.isEmpty(this.state.MarketList) && { SelectedFixture: this.state.MarketList[0] })
        })

        if (this.state.EventDetails.is_live === 1) {

        }

      })
    }).catch(error => {
      this.setState({ FixturePosting: false })
    });
  }



  // Life cycle
  componentDidMount() {
    this.GetFixtureMarket()
    const _this = this;
    Socket.on(SocketEvent.MARKET_SP, function (res) {
      if (!_.isEmpty(res)) {
        _this.SocketMarkethandler(res[0])
      }
    })
  }

  componentWillUnmount() {
    Socket.off(SocketEvent.MARKET_SP)
    this.setState = () => {
      return;
    };
  }

  // Other Handler
  SocketMarkethandler = (res = {}) => {
    const { MarketList, EventDetails, recently_changed } = this.state;

    // if(!_.isEmpty(MarketList)) {
    let newMarketList = _.map(MarketList, item => {
      if (item.market_event_id === res.market_event_id) {
        let ex_obj = {}
        let new_recently_changed = recently_changed;
        let item_runners_odds = JSON.parse(item.runners_odds)

        _.forEach(JSON.parse(res.runners_odds), (obj, idx) => {
          if ((item_runners_odds[idx].is_suspended !== obj.is_suspended) || (item_runners_odds[idx].selection_id !== obj.selection_id) || (item_runners_odds[idx].odds !== obj.odds)) {
            new_recently_changed = [...new_recently_changed, JSON.stringify(obj.selection_id)]
            ex_obj = { ...res }
            this.setState({
              isChanged: true,
              recently_changed: new_recently_changed
            })
          }
        })

        return {
          ...item,
          ...ex_obj,
        };
      }
      return item;
    })
    if (this.state.isChanged && !this.state.FixturePosting) {
      this.setState({ MarketList: newMarketList }, () => {
        this.setState({ isChanged: false });
      })
    }
    // } else if(EventDetails.event_id ===  res.event_id) {
    //   this.setState({MarketList: [...MarketList, res]})
    // }
  }

  SocketHighlight = ({ id }) => {
    const { recently_changed } = this.state
    let _id = _.isString(id) ? id : JSON.stringify(id)
    if (_.includes(recently_changed, _id)) {
      this.cleanSocketHighlight()
      return ' blink '
    }
    else return '';
  }

  cleanSocketHighlight = (timer = 50) => {
    setInterval(() => {
      if (!_.isEmpty(this.state.recently_changed)) {
        this.setState({ recently_changed: [] })
      }
    }, timer)
  }
  reinitiateComponent = () => {
    const { reinitiateComponent, location, is_inplay } = this.props
    const searchParams = new URLSearchParams(location.search);
    if (is_inplay) {
      reinitiateComponent(searchParams.get('sports'), true)
    } else {
      reinitiateComponent(searchParams.get('sports'))
    }
  }


  render() {
    const {
      SelectedSports,
      // Methods
      t
    } = this.props

    const {
      MarketList,
      EventDetails,
      FixturePosting,
      ScoresData
    } = this.state

    const currentUser = Auth.currentUserValue

    const isDisabled = (admin_status) => {
      return admin_status !== 1
    }

    return (
      <Fragment>
        <TitleComponent title={EventDetails.event_name} />
        <Scoreboard {...{
          sports_id: SelectedSports.sports_id,
          ScoresData: ScoresData,
          EventDetails: EventDetails
        }} is_sportsbook />
        {
          FixturePosting ?
            <div className="m-4"><Utils.BoxLoader /></div>
            :
            <Fragment>
              {
                !_.isEmpty(EventDetails) &&
                <div className="odds-padding-row heading">

                  <i className="back-btn icon-previous" onClick={() => this.reinitiateComponent()} />
                  <div className="overflow">
                    <div className="small">{EventDetails.league_name || 'League Name'}</div>
                    <div className="title">
                      {EventDetails.event_name}
                    </div>
                    <div className="timestamp">
                      {
                        EventDetails.is_live === 1 ?
                          <span className="is-live-lbl">{t('In-Play')}</span>
                          :
                          <Fragment>
                            <i className="icon-clock" /> {Utils.dateTimeFormat(EventDetails.open_date, 'HH:mm, DD/MM/YYYY')}
                          </Fragment>
                      }
                    </div>
                  </div>
                </div>
              }


              {
                _.map(MarketList, (item, key) => {
                  switch (item.template_name) {
                    case "options":
                      return <TemplateOptionSB {...{ key, item, ...this.props, SocketHighlight: this.SocketHighlight }} />
                    case "total":
                      return <TemplateTotalSB {...{ key, item, ...this.props, SocketHighlight: this.SocketHighlight }} />

                    default:
                      break;
                  }
                }
                )
              }


              {
                _.isEmpty(MarketList) &&
                <MessageBox paragraph={t(CONSTANT.MARKET_BLANK_PARAGRAPH)} />
              }

            </Fragment>
        }
      </Fragment>
    );
  }
}

const SportsBookFixtureDetailListTrans = withTranslation()(SportsBookFixtureDetailList)

function mapStateToProps(state) {
  const { app } = state;
  return {
    WinlossObject: app.WinlossObject
  };
}

const mapDispatchToProps = dispatch => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SportsBookFixtureDetailListTrans);
