import React, { Component, Fragment } from 'react';
import { Row, Col, Button, Alert, Collapse } from 'reactstrap';

import { Auth } from 'Service';
import { _, Utils, Http, API, AuthHelp, Socket } from 'Helpers';
import { MessageBox } from 'Components';
import { CONSTANT, SocketEvent } from 'Constants';

import { connect } from "react-redux";
import { loginOpen, LoaderShow, LoaderHide, UpdateProfile, SlipToggle, updateWinloss } from '../../Redux/Reducers'

import { withTranslation } from 'react-i18next';
import { BetSettingModal, ErrorMessageModal } from 'Modals';
import { Clock } from 'Components';
class MobileBetslip extends Component {
  clockRef = null;
  constructor(props) {
    super(props);
    const { page_id } = props;
    this.state = {
      posting: false,
      BetslipType: page_id,
      isSingleBet: true,
      AppMasterData: Utils.getMasterData(),
      SelectedOdds: {},
      MultipleBetStake: Utils.getMultiBetSlip(CONSTANT.BETSLIP[page_id]),
      BetPosting: false,
      global_error_msz: '',
      BetSettingModalShow: false,
      showTimeOnLoader: 0,
      userSpoartSettings: [],
      defaultSecond: 10000,
      defaultSecondShow: false,
      successMessage: '',
      changedefaultSecond: 10000,
    }
    this.winlossHandler = this.winlossHandler.bind(this);
    this.setClockRef = this.setClockRef.bind(this);
    // this.winlossHandler = _.debounce(this.winlossHandler.bind(this), 0);
  }

  setClockRef(ref) {
    // When the `Clock` (and subsequently `Countdown` mounts
    // this will give us access to the API
    this.clockRef = ref;
  }

  sockeListener = () => {
    const _this = this
    Socket.on(SocketEvent.MARKET, function (res) {
      _this.SocketMarkethandler(res)
    })
  }
  removeSockeListener = () => {
    Socket.off(SocketEvent.MARKET)
  }

  SocketMarkethandler = (res = {}) => {
    const { SelectedOdds } = this.state


    if (!_.isEmpty(SelectedOdds)) {
      let newSelectedOdds = {}
      _.map(SelectedOdds, (item, idx) => {
        let new_odd_list = item.odd_list;
        let new_picked_odd = item.picked_odd
        if (!item.is_unmatch_bet && item.fixture_id === res.fixture_id) {
          if (new_picked_odd.bet_id === res.bet_id) {
            new_picked_odd = { ...new_picked_odd, ...res }
          }
          newSelectedOdds = {
            ...newSelectedOdds, [idx]: {
              ...item,
              odd_list: new_odd_list,
              picked_odd: new_picked_odd
            }
          }
        } else {
          newSelectedOdds = { ...newSelectedOdds, [idx]: item }
        }
      })
      if (!_.isEmpty(newSelectedOdds)) {
        this.setState({
          SelectedOdds: newSelectedOdds
        }, () => {
          Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
        })
      }
    }
  }

  SocketGlobalSettingHandler = (res = {}) => {
    const { AppMasterData } = this.state;
    this.setState({
      AppMasterData: { ...AppMasterData, ...res }
    });
  }

  SocketSettingSportHandler = (res = {}) => {
    const { AppMasterData } = this.state;
    _.map(AppMasterData.sports_list.exchange_betting, (item, index) => {
      if (Number(item.sports_id) === Number(res.sports_id)) {
        AppMasterData.sports_list.exchange_betting[index].fancy_delay = res.fancy_delay;
        AppMasterData.sports_list.exchange_betting[index].match_delay = res.match_delay;
      }
    })
    this.setState({
      AppMasterData: AppMasterData
    })
  }

  SocketUserSettingUpdate = (res = {}) => {
    this.setState({ userSpoartSettings: res })
  }

  // Life cycle
  componentDidMount() {
    const { SelectedOdds } = this.props;
    const { AppMasterData } = this.state;
    const currentUser = Auth.currentUserValue;
    const _this = this;
    if (currentUser && currentUser.sport_settings)
      this.setState({ userSpoartSettings: currentUser.sport_settings })

    this.setState({
      SelectedOdds: SelectedOdds,
      successMessage: '',
    }, () => {

      const currentUser = Auth.currentUserValue;
      if ((this.props.fancy_betslip == "fancy_betting") && currentUser && currentUser.fancy_stack)
        this.setState({ BetslipStack: currentUser.fancy_stack.split(',') });
      else if ((this.props.bookmaker_betslip == "bookmaker_betslip") && currentUser && currentUser.bookmaker_stack)
        this.setState({ BetslipStack: currentUser.bookmaker_stack.split(',') });
      else if (currentUser && currentUser.odds_stack)
        this.setState({ BetslipStack: currentUser.odds_stack.split(',') });

      if (this.props.fancy_betslip == "fancy_betting")
        this.switchBetting(CONSTANT.FANCY_BETTING)

      const { SelectedOdds } = this.state
      if (!_.isEmpty(SelectedOdds)) {
        setTimeout(() => {
          this.winlossHandler(0)
        }, 500)
      }
    })
    Socket.on(SocketEvent.GLOBAL_SETTING, function (res) {
      if (!_.isEmpty(res)) {
        _this.SocketGlobalSettingHandler(res)
      }
    })
    Socket.on(SocketEvent.SETTING_SPORTS, function (res) {
      if (!_.isEmpty(res)) {
        _this.SocketSettingSportHandler(res)
      }
    })
    Socket.on(SocketEvent.GLOBAL_SEARCH_UPDATED, function (res) {
      if (!_.isEmpty(res)) {
        _this.SocketUserSettingUpdate(res)
      }
    })
    // this.sockeListener()
    // setTimeout(() => {
    //   this.SocketMarkethandler()
    // }, 3000)

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.betslip_lastupdate !== this.props.betslip_lastupdate) {
      const { page_id } = this.props;
      const { AppMasterData } = this.state;

      this.setState({
        SelectedOdds: nextProps.fancy_betslip === CONSTANT.FANCY_BETTING ? nextProps.SelectedOddsFancy : nextProps.SelectedOdds,
        BetslipType: nextProps.fancy_betslip === CONSTANT.FANCY_BETTING ? nextProps.fancy_betslip : page_id,
        }, () => {
        const currentUser = Auth.currentUserValue;
        if ((nextProps.fancy_betslip == "fancy_betting") && currentUser && currentUser.fancy_stack)
          this.setState({ BetslipStack: currentUser.fancy_stack.split(',') });
        else if ((nextProps.bookmaker_betslip == "bookmaker_betslip") && currentUser && currentUser.bookmaker_stack)
          this.setState({ BetslipStack: currentUser.bookmaker_stack.split(',') });
        else if (currentUser && currentUser.odds_stack)
          this.setState({ BetslipStack: currentUser.odds_stack.split(',') });
      });
    }
  }

  // Handlers
  switchBetting = (BetslipType) => {
    this.setState({
      BetslipType,
      isSingleBet: true
    }, () => {
      const currentUser = Auth.currentUserValue;
      if ((BetslipType == "fancy_betting") && currentUser && currentUser.fancy_stack)
        this.setState({ BetslipStack: currentUser.fancy_stack.split(',') });
      else if ((BetslipType == "bookmaker_betslip") && currentUser && currentUser.bookmaker_stack)
        this.setState({ BetslipStack: currentUser.bookmaker_stack.split(',') });
      else if (currentUser && currentUser.odds_stack)
        this.setState({ BetslipStack: currentUser.odds_stack.split(',') });

      this.setState({
        SelectedOdds: Utils.getBetSlip(BetslipType)
      })
    })
  }
  // Single Bet handler
  stackChangeHandle = idx => evt => {
    const re = /^\d*\.?(?:\d{1,2})?$/;

    let { SelectedOdds } = this.state
    let value = evt.target.value;
    if (!re.test(value)) return SelectedOdds[idx].stack_value.stack_value
    SelectedOdds[idx].stack_value = (value === '' || re.test(value)) ? value : SelectedOdds[idx].stack_value.stack_value
    SelectedOdds[idx].invalid_bet = 0
    SelectedOdds[idx].return_stake_msz = ''
    SelectedOdds[idx].return_msz = ''
    this.setState({ SelectedOdds }, () => {
      Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
    });
  }


  winlossHandler(idx) {

    const { updateWinloss } = this.props
    let { SelectedOdds, BetslipType } = this.state
    let selected_odds = SelectedOdds[idx]
    let stack_value = selected_odds.stack_value
    // if(stack_value === '') return

    let bookmaker_type = CONSTANT.BOOKMAKER_TYPE[BetslipType];
    let betting_type = selected_odds.picked_odd.betting_type;   // 1 = back , 2= Lay
    let price = selected_odds.picked_odd.price;
    let stake = stack_value;
    var payout = stake * price;

    if (bookmaker_type === 2) { // In case Fancy bet
      //pp =  Potential Profit
      var pp = payout - stake; // in case of back betting;
      var liability = stake; // in case of back
      if (betting_type === 2 && bookmaker_type !== 3)   // In case OF lay
      {
        pp = stake;
        liability = payout - stake;
      }
      updateWinloss({
        lastupdate: new Date().valueOf(),
        type: 1,
        market_event_id: selected_odds.market_event_id,
        session_id: selected_odds.picked_odd.session_id,
        selectionId: selected_odds.picked_odd.selectionId,
        stack: stack_value,
        betting_type: selected_odds.picked_odd.betting_type,
        pp: pp,
        liability: liability,
        bookmaker_type: bookmaker_type,
        is_unmatch_bet: selected_odds.is_unmatch_bet ? selected_odds.is_unmatch_bet : false,
        is_place_bet: false
      })
    }
  }

  handleKey = (evt, idx) => {
    let { SelectedOdds } = this.state
    const input = evt.target;
    const BaseValue = 10;
    const value = input.value === '' ? 0 : input.value;

    let item = SelectedOdds[idx]
    if (evt.keyCode === 38) {
      let newValue = item.stack_value === '' || item.stack_value < BaseValue ? BaseValue : (value === 0 ? BaseValue : Number(value) + 1)
      SelectedOdds[idx].stack_value = newValue

      SelectedOdds[idx].invalid_bet = 0
      SelectedOdds[idx].return_stake_msz = ''
      SelectedOdds[idx].return_msz = ''

      this.setState({ SelectedOdds }, () => {
        Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
      });
    } else if (evt.keyCode === 40) {
      let newValue = item.stack_value <= BaseValue ? item.stack_value : Number(value) - 1
      SelectedOdds[idx].stack_value = newValue;

      SelectedOdds[idx].invalid_bet = 0
      SelectedOdds[idx].return_stake_msz = ''
      SelectedOdds[idx].return_msz = ''

      this.setState({ SelectedOdds }, () => {
        Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
      });
    }
  }

  marketChangeHandle = idx => evt => {
    // const re = /^[0-9\.\b]+$/;
    const re = /^\d*\.?(?:\d{1,2})?$/;

    let { SelectedOdds } = this.state;
    let value = evt.target.value;
    let item = SelectedOdds[idx]
    item.picked_odd['price'] = (value === '' || re.test(value)) ? value : (item.picked_odd.price)

    item.invalid_bet = 0
    item.return_stake_msz = ''
    item.return_msz = ''

    let newSelectedOdds = {
      ...item,
      is_unmatch_bet: Number(item.picked_odd_price) === Number(value) ? false : true
    };
    SelectedOdds[idx] = newSelectedOdds
    this.setState({ SelectedOdds }, () => {
      Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
    });
  }

  StackClickHandle = (idx, value) => {
    let { SelectedOdds } = this.state;
    let item = SelectedOdds[idx]

    item.stack_value = _.isUndefined(item.stack_value) ? Number(value) : Number(item.stack_value) + Number(value)

    item.invalid_bet = 0
    item.return_stake_msz = ''
    item.return_msz = ''

    SelectedOdds[idx] = item;
    this.setState({ SelectedOdds }, () => {
      this.winlossHandler(0)
      Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
    });
  }

  // Multiple Bet handler
  multipleBetStackHandle = evt => {
    const { page_id } = this.props
    let { MultipleBetStake } = this.state
    let value = evt.target.value;
    const re = /^[0-9\.\b]+$/;
    MultipleBetStake = (value === '' || re.test(value)) ? value : MultipleBetStake
    this.setState({ MultipleBetStake }, () => {
      Utils.setMultiBetSlip(CONSTANT.BETSLIP[page_id], this.state.MultipleBetStake)
    });
  }

  multipleBetHandleKey = (evt) => {
    const { page_id } = this.props
    let { MultipleBetStake } = this.state
    const input = evt.target;
    const BaseValue = 10;
    const value = input.value === '' ? 0 : input.value;
    if (evt.keyCode === 38) {
      let newValue = (MultipleBetStake === '' || MultipleBetStake < BaseValue) ? BaseValue : (value === 0 ? BaseValue : Number(value) + 1)
      this.setState({ MultipleBetStake: newValue }, () => {
        Utils.setMultiBetSlip(CONSTANT.BETSLIP[page_id], this.state.MultipleBetStake)
      });
    } else if (evt.keyCode === 40) {
      let newValue = MultipleBetStake <= BaseValue ? MultipleBetStake : Number(value) - 1
      this.setState({ MultipleBetStake: newValue }, () => {
        Utils.setMultiBetSlip(CONSTANT.BETSLIP[page_id], this.state.MultipleBetStake)
      });
    }
  }

  multipleBetStackClickHandle = (value) => {
    const { page_id } = this.props
    let { MultipleBetStake } = this.state;
    let newValue = _.isUndefined(MultipleBetStake) ? Number(value) : Number(MultipleBetStake) + Number(value)
    this.setState({ MultipleBetStake: newValue }, () => {
      Utils.setMultiBetSlip(CONSTANT.BETSLIP[page_id], this.state.MultipleBetStake)
    });
  }

  // Other
  ModeUpdate = () => {
    this.setState({ global_error_msz: '' })
    setTimeout(() => {
      const { SelectedOdds } = this.props
      if (_.size(SelectedOdds) === 1) {
        this.setState({ isSingleBet: true });
      }
    }, 100)
  }

  totalValue = (type) => {
    const { SelectedOdds } = this.state
    let obj = {
      stake: [],
      odd: []
    }
    if (type === 'stake') {
      _.forEach(SelectedOdds, item => {
        obj.stake.push(_.isUndefined(item.stack_value) ? 0 : Number(item.stack_value))
      })
    } else if (type === 'odd') {
      _.forEach(SelectedOdds, item => {
        obj.odd.push(Number(item.picked_odd.price))
      })
    }
    return _.round(_.sum(obj[type]), 2)
  }


  isDuplicateBetSlip = () => {
    let { SelectedOdds } = this.state;
    let _arr = []
    _.forEach(SelectedOdds, item => {
      _arr.push(item.fixture_id)
    })
    return !(_arr.length === new Set(_arr).size);
  }

  isLayMultiBet = () => {
    let { SelectedOdds } = this.state;
    let _lay = []
    _.forEach(SelectedOdds, item => {
      if (item.picked_odd.betting_type === 2) {
        _lay.push(item.picked_odd.betting_type)
      }
    })
    return _lay.length > 0;
  }

  isValidate = () => {
    const { SelectedOdds, isSingleBet, MultipleBetStake, BetslipType } = this.state;
    let arr = []
    _.forEach(SelectedOdds, item => {
      if (isSingleBet) {
        if (Number(item.market_type) === 2 && Number(Utils.TwoDecimalVal(item.picked_odd.price)) === 1)
          item.picked_odd.price = Number(Utils.TwoDecimalVal(item.picked_odd.price)) - 1;
        if (Number(item.is_manual_odds) === 1 && Number(Utils.TwoDecimalVal(item.picked_odd.price)) === 1)
          item.picked_odd.price = Number(Utils.TwoDecimalVal(item.picked_odd.price)) - 1;

        if ((!_.isUndefined(item.stack_value) && Number(item.stack_value) > 0) && Number(Utils.TwoDecimalVal(item.picked_odd.price)) > 0) {
          arr.push(item)
        }
      } else {
        if (Number(item.picked_odd.price) > 0) {
          arr.push(item)
        }
      }
    })
    return isSingleBet ? (arr.length === 0) : (arr.length === 0 || (MultipleBetStake === '' || MultipleBetStake === 0))
  }

  PlaceBetHandlerPrev = () => {
    const { LoaderShow, LoaderHide, UpdateProfile } = this.props
    let { SelectedOdds, isSingleBet, MultipleBetStake, BetslipType } = this.state;
    let bet_data = []
    let not_stack = {}
    _.forEach(SelectedOdds, (item, key) => {
      if (
        ((isSingleBet && Number(item.picked_odd.price) > 0 && (!_.isUndefined(item.stack_value) && Number(item.stack_value) > 0))
          ||
          (!isSingleBet && Number(item.picked_odd.price) > 0))
      ) {
        if (item.invalid_bet !== 1) {
          bet_data = [...bet_data, {
            "fixture_id": item.event_id,
            "market_bet_id": item.market_bet_id,
            "betting_type": item.picked_odd.betting_type,
            "is_custom_odds": item.is_unmatch_bet ? 1 : 0,
            ...(BetslipType === CONSTANT.FANCY_BETTING ? { "line": item.picked_odd.price } : { "line": item.picked_odd.line }),
            ...(BetslipType === CONSTANT.FANCY_BETTING ? { "price": "2" } : { "price": item.picked_odd.price }),
            ...(isSingleBet && { "stake": item.stack_value })
          }]
        } else {
          not_stack = { ...not_stack, [key]: { ...item } }
        }
      } else {
        not_stack = { ...not_stack, [key]: { ...item, return_stake_msz: CONSTANT.BLANK_STAKE, return_msz: '' } }
      }
    })
    let param = {
      bet_data,
      "bookmaker_type": CONSTANT.BOOKMAKER_TYPE[BetslipType], // 1 = Sportsdata, 2 = Exchange, 3 = Fancy
      "bet_type": isSingleBet ? "1" : "2",          // 1 = Single,  2= Multiple
      ...(!isSingleBet && { "stake": MultipleBetStake })
    }
    this.setState({ BetPosting: true, global_error_msz: '' }, () => {
      LoaderShow()
    })
    this.getMaxDelay().then(res => {
      Http.post(API.PLACE_BET, param, true).then(response => {
        Utils.notify(response.message)

        this.setState({
          BetPosting: false,
        }, () => {
          Auth.updateBalance(response.data.balance, response.data.exposure)
          UpdateProfile()
          this.clearAllBet(BetslipType)
          setTimeout(() => {
            if (!_.isEmpty(not_stack)) {
              this.setState({ SelectedOdds: not_stack }, () => {
                Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
              })
            }
          }, 100)

        })
        LoaderHide()
        this.setState({
          defaultSecond: this.state.changedefaultSecond,
          defaultSecondShow: true,
        })
      }).catch(err => {
        Utils.notify(!_.isEmpty(err.message) ? err.message : err.global_error, 'error')
        if (err.response_code === 500) {
          let newSelectedOdds = {}
          _.forEach(SelectedOdds, (item, key) => {
            _.forEach(err.error.odds_changed, obj => {
              if (item.market_bet_id === obj.market_bet_id) {
                item.picked_odd.price = obj.price
                // item.return_msz = obj.return_msz
                item.return_stake_msz = ''
                item.return_msz = CONSTANT.BETSLIP_RETURN_MSZ
                newSelectedOdds = { ...newSelectedOdds, [key]: item }
                Utils.notify(CONSTANT.BETSLIP_RETURN_MSZ, 'error')
              }
            })
            _.forEach(err.error.invalid_bet, obj => {
              if (item.market_bet_id === obj.market_bet_id) {
                item.picked_odd.price = obj.price
                item.invalid_bet = 1
                item.return_msz = obj.return_msz
                item.return_stake_msz = ''
                // item.return_msz = CONSTANT.BETSLIP_INVALID_BET_MSZ
                newSelectedOdds = { ...newSelectedOdds, [key]: item }
                Utils.notify(obj.return_msz, 'error')
              }
            })
            if (
              !(((isSingleBet && Number(item.picked_odd.price) > 0 && (!_.isUndefined(item.stack_value) && Number(item.stack_value) > 0))
                ||
                (!isSingleBet && Number(item.picked_odd.price) > 0)))
            ) {
              item.return_stake_msz = CONSTANT.BLANK_STAKE
              Utils.notify(CONSTANT.BLANK_STAKE, 'error')
            }

            newSelectedOdds = { ...newSelectedOdds, [key]: item }
          })
          if (_.isEmpty(newSelectedOdds)) {
            Utils.notify(err.message, 'error')
          } else {
            // Utils.notify(CONSTANT.PARTIALLY_SUBMIT_BETSLIP, '')
            this.setState({ SelectedOdds: newSelectedOdds }, () => {
              Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
            })
          }
        } else {
          Utils.notify(err.global_error, 'error')
          AuthHelp.checkAuth(err)
        }
        LoaderHide()
        this.setState({
          BetPosting: false,
          defaultSecond: this.state.changedefaultSecond,
          defaultSecondShow: true,
          global_error_msz: err.global_error
        })
      });
    })
  }
  parseErrorMessage = (response) => {
    var errorMsg = response.message !== "" ? response.message : response.global_error;
    if (errorMsg === "") {
      for (var key in response.error) {
        if (key === 'odds_changed' || key === 'invalid_bet') {
          break;
        }
        errorMsg = response.error[key];
        if (errorMsg !== "") {
          break;
        }
      }
    }
    return errorMsg
  }
  PlaceBetHandler = () => {
    const { LoaderShow, LoaderHide, UpdateProfile, updateWinloss } = this.props
    let { SelectedOdds, BetslipType } = this.state;
    let param = {}
    let new_selectedodds = _.map(SelectedOdds, item => {
      param = {
        "event_id": item.event_id,
        "betting_type": item.picked_odd.betting_type,
        "is_custom_odds": item.is_unmatch_bet ? 1 : 0,
        "stake": item.stack_value,
        "bookmaker_type": CONSTANT.BOOKMAKER_TYPE[BetslipType],     // 1 = Sportsdata, 2 = Exchange, 3 = Fancy
        "market_event_id": item.market_event_id,
        ...(BetslipType === CONSTANT.FANCY_BETTING ? { "market_event_id": item.picked_odd.session_id } : { "market_event_id": item.market_event_id }),
        ...(BetslipType !== CONSTANT.FANCY_BETTING && { "selection_id": item.picked_odd.selectionId }),
        ...(BetslipType === CONSTANT.FANCY_BETTING ? { "price": "2" } : { "price": item.picked_odd.price }),
        ...(BetslipType === CONSTANT.FANCY_BETTING ? { "line": item.picked_odd.price } : { "line": "" }),
      }
      return { ...item, return_msz: '' }
    })


    this.setState({
      SelectedOdds: new_selectedodds,
      BetPosting: true,
      global_error_msz: ''
    }, () => {
      Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
      LoaderShow()
    })

    this.getMaxDelay().then(res => {
      Http.post(API.PLACE_BET, param, true).then(response => {
        Utils.notify(response.message)

        this.setState({
          BetPosting: false,
        }, () => {
          Auth.updateBalance(response.data.balance, response.data.exposure)
          updateWinloss({
            lastupdate: new Date().valueOf(),
            type: 0,
            win_loss: response.data.win_loss,
            fexposure: response.data.fexposure,
            market_event_id: param.market_event_id,
            session_id: param.market_event_id,
            bookmaker_type: CONSTANT.BOOKMAKER_TYPE[BetslipType],
            is_unmatch_bet: param.is_custom_odds === 1 ? true : false,
            event_id: param.event_id,
            is_place_bet: param.is_custom_odds === 1 ? true : false,
          })
          UpdateProfile()
          this.clearAllBet(BetslipType)
          this.props.parentMethod();
        })
        LoaderHide()
        this.setState({
          defaultSecond: this.state.changedefaultSecond,
          defaultSecondShow: false,
        })
      }).catch(err => {
        Utils.notify(!_.isEmpty(err.message) ? err.message : err.global_error, 'error')
        if (!_.isEmpty(err.error)) {
          const { odds_changed = {}, invalid_bet = {} } = err.error
          if (err.response_code === 500) {
            let newSelectedOdds = {}
            _.forEach(SelectedOdds, (item, key) => {

              if (odds_changed !== '' && item.market_event_id === odds_changed.market_event_id) {
                item.picked_odd.price = odds_changed.price
                item.return_msz = odds_changed.return_msz
                newSelectedOdds = { ...newSelectedOdds, [key]: item }
                Utils.notify(odds_changed.return_msz, 'error')
              }

              if (invalid_bet !== '' && item.market_event_id === invalid_bet.market_event_id) {
                item.return_msz = invalid_bet.return_msz
                newSelectedOdds = { ...newSelectedOdds, [key]: item }
                Utils.notify(invalid_bet.return_msz, 'error')
              }
              newSelectedOdds = { ...newSelectedOdds, [key]: item }
            })
            if (_.isEmpty(newSelectedOdds)) {
              Utils.notify(err.message, 'error')
            } else {
              this.setState({ SelectedOdds: newSelectedOdds }, () => {
                Utils.setBetSlip(this.state.BetslipType, this.state.SelectedOdds)
              })
            }
          } else {
            Utils.notify(this.parseErrorMessage(err), 'error')
            AuthHelp.checkAuth(err)
          }
        }
        LoaderHide()
        this.setState({
          BetPosting: false,
          defaultSecond: this.state.changedefaultSecond,
          defaultSecondShow: false,
          global_error_msz: this.parseErrorMessage(err)
        })

      });
    })
  }

  clearAllBet = (BetslipType) => {
    const { clearAllBet } = this.props
    Utils.setBetSlip(BetslipType, {})
    clearAllBet(BetslipType)
    this.setState({
      MultipleBetStake: '',
      global_error_msz: '',
    }, () => {
      this.setState({
        SelectedOdds: Utils.getBetSlip(BetslipType),
        BetslipType: BetslipType
      })
    })
  }
  handleRemoveSlip = (key) => {
    const { handleRemoveSlip, _fancyHandleRemoveSlip, updateWinloss } = this.props
    const { BetslipType, SelectedOdds } = this.state
    this.setState({
      global_error_msz: '',
    })
    if (BetslipType !== CONSTANT.FANCY_BETTING) {
      let selected_odds = SelectedOdds[key]
      updateWinloss({
        lastupdate: new Date().valueOf(),
        type: 2,
        market_event_id: selected_odds.market_event_id,
        session_id: selected_odds.picked_odd.session_id,
        bookmaker_type: CONSTANT.BOOKMAKER_TYPE[BetslipType],
        is_unmatch_bet: selected_odds.is_unmatch_bet ? selected_odds.is_unmatch_bet : false,
        is_place_bet: false
      })

      handleRemoveSlip(key)
      this.ModeUpdate()
    } else {
      _fancyHandleRemoveSlip(key)
    }
  }

  clearBetSlipWindow = () => {
    this.handleRemoveSlip(0)
    const { closeBetSlipWindow } = this.props;
    closeBetSlipWindow();
  }

  //
  getMaxDelay = () => {
    const { EventDetails } = this.props;
    const { BetslipType, AppMasterData, SelectedOdds, userSpoartSettings } = this.state;
    let timeArr = 0;
    _.forEach(SelectedOdds, slip => {
      _.forEach(AppMasterData.sports_list.exchange_betting, item => {
        if (Number(item.sports_id) === Number(slip.sports_id)) {
          if (Number(slip.market_type) === 2)
            timeArr = Number(item.bookmaker_delay);
          else if (BetslipType === CONSTANT.FANCY_BETTING)
            timeArr = Number(item.fancy_delay);
          else if (Number(slip.market_type) === 1)
            timeArr = Number(item.match_delay);
          else
            timeArr = Number(item.match_delay);
        }
      })
      if (!_.isEmpty(EventDetails)) {
        if (Number(slip.sports_id) === Number(EventDetails.sports_id)) {
          if ((BetslipType === CONSTANT.FANCY_BETTING) && EventDetails.fancy_delay)
            timeArr = Number(EventDetails.fancy_delay)
          else if ((Number(slip.market_type) === 2) && EventDetails.bookmaker_delay)
            timeArr = Number(EventDetails.bookmaker_delay)
          else if (EventDetails.match_delay)
            timeArr = Number(EventDetails.match_delay)
        }
      }
      if (!_.isEmpty(userSpoartSettings)) {
        _.forEach(userSpoartSettings, setting => {
          if ((Number(setting.sports_id) === Number(slip.sports_id)) && (Number(setting.setting_type) === 1)) {
            if (BetslipType === CONSTANT.FANCY_BETTING)
              timeArr = Number(setting.fancy_delay);
            else if (Number(slip.market_type) === 2)
              timeArr = Number(setting.bookmaker_delay);
            else
              timeArr = Number(setting.match_delay);
          }
        })
      }
    })

    this.setState({ showTimeOnLoader: timeArr, changedefaultSecond: timeArr * 1000 })

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true)
      }, timeArr * 1000)
    })
  }
  modalHide = () => {
    this.clockRef.start();
    this.setState({
      BetSettingModalShow: false,
    })
  }
  modalShow = (name) => {
    this.clockRef.pause();
    this.setState({
      BetSettingModalShow: true
    })
  }

  modalUpdate = () => {
    this.setState({
      BetSettingModalShow: false,
    }, () => {
      this.clockRef.start();
    })
  }

  render() {
    const {
      loginOpen,
      openCollapse,
      SlipToggle,
      // i18n,
      t,
      refCallback
    } = this.props;

    const {
      isSingleBet,
      SelectedOdds,
      BetslipStack,
      BetslipType,
      MultipleBetStake,
      BetPosting,
      successMessage,
      global_error_msz,
      BetSettingModalShow,
      defaultSecond,
      defaultSecondShow
    } = this.state
    const BetSettingModalProps = {
      isOpen: BetSettingModalShow,
      BetslipStack: BetslipStack,
      stake_type: this.props.SelectedOdds && !_.isEmpty(this.props.SelectedOdds) && this.props.SelectedOdds[0].is_bookmaker && Number(this.props.SelectedOdds[0].is_bookmaker) === 1 ? "bookmaker_betslip" : BetslipType,
      toggle: this.modalHide,
      callBack: this.modalUpdate
    }

    const currentUser = Auth.currentUserValue

    const AvalStacks = ({ idx }) => {
      return (
        <div className="betslip--footer">
          {
            _.map(BetslipStack, (item, key) => {
              return (
                item !== null &&
                <a className="my-1 mobile-bet-button" onClick={() => this.StackClickHandle(idx, item)} {...{ key }}>{item}</a>
              )
            })

          }
          <a className="my-1 mobile-bet-button" onClick={() => this.modalShow('BetSettingModalProps')}><span className="icon-cog" /></a>
          {BetSettingModalShow && <BetSettingModal {...BetSettingModalProps} />}
        </div >
      )
    }

    const BetTitle = ({ home, away }) => {
      return (
        (home && away) ?
          <Fragment>
            <span title={home.name}>{`${home.name}`}</span>
            <span title={away.name}>{` - ${away.name}`}</span>
          </Fragment> : null
      )
    }

    return (
      <div {...{ className: `betslip-wrap${_.isEmpty(SelectedOdds) ? ' blank' : ''}${CONSTANT.ONLY_SINGLE_BET ? ' only-single-bet' : ''} ` }}>
        <div className="betslip-container-mobile">
          <a onClick={() => this.clearBetSlipWindow()} className="closeBetSlipWindow">X</a>
          <div className="betslip-header-mobile">
            <Row>
              <Col className="col-12"><b className="d-block bet-slip">{t('Bet Slip')}</b></Col>
            </Row>
            <Row>
              <Col className="col-10">
                {
                  !_.isEmpty(SelectedOdds) &&
                  _.map(SelectedOdds, (item, key) => {
                    return (
                      <Fragment key={key}>
                        <b className="d-block match-name">
                          {
                            BetslipType === CONSTANT.SPORTBOOK_BETTING ?
                              <Fragment>
                                <BetTitle {...JSON.parse(item.runners)} />
                              </Fragment>
                              :
                              <Fragment>
                                {
                                  _.map(_.orderBy(JSON.parse(item.runners), [function (o) { return Number(o.sortPriority) }], ['asc']), (participant, key) => {
                                    return (
                                      participant.sortPriority !== 3 &&
                                      <span {...{ key }} title={participant.runnerName}>{`${key === 0 ? '' : ' vs '} ${participant.runnerName} `}</span>
                                    )
                                  })
                                }
                              </Fragment>
                          }
                        </b>
                        <b className="d-block market-name">{item.market_name}</b>
                        <div className="text-truncate">
                          {
                            BetslipType !== CONSTANT.FANCY_BETTING ?
                              <Fragment>
                                {
                                  BetslipType === CONSTANT.SPORTBOOK_BETTING ?
                                    item.picked_odd.name
                                    :
                                    Utils.getMarketName(item.picked_odd.selectionId, item.runners)
                                }
                              </Fragment>
                              :
                              item.picked_odd.name
                          }
                        </div>
                      </Fragment>
                    )
                  })
                }
              </Col>
              <Col className="col-2 my-auto">
                <Clock defaultSecond={defaultSecond} defaultSecondShow={defaultSecondShow} refCallback={this.setClockRef} parentMethod={this.clearBetSlipWindow} />
              </Col>
            </Row>
          </div>
          {/* <Collapse isOpen={openCollapse === 1}> */}
          {/* <div className="betslip-tabs">
            <span onClick={() => this.switchBetting(CONSTANT.EXCHANGE_BETTING)} {...{ className: BetslipType === CONSTANT.EXCHANGE_BETTING ? 'active' : '' }}>{t('Exchange')}</span>
            <span onClick={() => this.switchBetting(CONSTANT.FANCY_BETTING)} {...{ className: BetslipType === CONSTANT.FANCY_BETTING ? 'active' : '' }}>{t('Fancy + Session')}</span>
          </div> */}
          {
            global_error_msz !== '' &&
            <Alert color="danger" className='betslip-common-error'>{t(global_error_msz)}</Alert>
          }
          {
            (this.isDuplicateBetSlip() && !isSingleBet && _.isEmpty(global_error_msz)) &&
            <Alert color="danger" className='betslip-common-error'>{t(CONSTANT.DUPLICATE_BETITEM)}</Alert>
          }
          {
            (this.isLayMultiBet() && !isSingleBet && _.isEmpty(global_error_msz)) &&
            <Alert color="danger" className='betslip-common-error'>{t(CONSTANT.LAY_BETITEM)}</Alert>
          }
          {
            _.isEmpty(SelectedOdds) ?
              <MessageBox heading={t(CONSTANT.BETSLIP_BLANK_HEADING)} paragraph={t(CONSTANT.BETSLIP_BLANK_PARAGRAPH)} />
              :
              <div {...{ className: `betslip - list ${CONSTANT.IS_MULTIBET_ALLOW ? '' : 'm-t'} ${(!_.isEmpty(SelectedOdds) && !isSingleBet) ? 'mulitple-bet' : ''} ` }}>
                {
                  _.map(SelectedOdds, (item, key) => {
                    return (
                      <div  {...{ className: `betslip - item ${(item.picked_odd.betting_type === 2) ? (BetslipType === CONSTANT.FANCY_BETTING ? '' : 'lay-slip') : (BetslipType === CONSTANT.FANCY_BETTING ? 'lay-slip' : '')} `, key }}>
                        <div className="betslip--body">
                          {
                            (_.isEmpty(global_error_msz) && item.return_msz) &&
                            <Row className="gutters-10px">
                              <Col xs={12} className="small danger-color">
                                <Alert color="danger" className='betslip-common-error'>{item.return_msz}</Alert>
                              </Col>
                            </Row>
                          }
                          {
                            (_.isEmpty(global_error_msz) && isSingleBet && item.return_stake_msz) &&
                            <Row className="gutters-10px">
                              <Col xs={12} className="small danger-color">
                                <Alert color="danger" className='betslip-common-error'>{item.return_stake_msz}</Alert>
                              </Col>
                            </Row>
                          }
                          {
                            isSingleBet ?
                              <Row className="gutters-10px">
                                <Col xs={12}>
                                  <input
                                    className={'stake-input ' + (BetslipType === CONSTANT.FANCY_BETTING ? (Number(item.picked_odd.betting_type) === 1 ? "khai" : "lagai") : (Number(item.picked_odd.betting_type) === 1 ? "lagai" : "khai"))}
                                    type="text"
                                    placeholder='Rates'
                                    value={BetslipType !== CONSTANT.FANCY_BETTING ? (Number(item.is_bookmaker) === 1 ? Utils.ThreeDecimalVal((Number(item.picked_odd.price) > 0 ? (item.picked_odd.price - 1) : item.picked_odd.price)) : Utils.TwoDecimalVal((Number(item.picked_odd.price) > 0 ? (item.picked_odd.price - 1) : item.picked_odd.price))) : item.picked_odd.price}
                                    disabled={BetslipType === CONSTANT.FANCY_BETTING}
                                    // disabled={(currentUser && currentUser.lock_um_betting !== 0) || BetslipType === CONSTANT.FANCY_BETTING}
                                    onChange={this.marketChangeHandle(key)}
                                    onKeyUp={() => this.winlossHandler(key)}
                                  />

                                  <input
                                    type="text"
                                    placeholder='Amount'
                                    className='stake-input'
                                    value={item.stack_value}
                                    onKeyDown={(e) => this.handleKey(e, key)}
                                    onChange={this.stackChangeHandle(key)}
                                    onKeyUp={() => this.winlossHandler(key)}
                                  />

                                  {
                                    item.picked_odd.betting_type === 1 ?
                                      <div className='return-value-col'>
                                        <div className="return--small">{t('Return')}</div>
                                        <div className="return-val">
                                          {
                                            BetslipType !== CONSTANT.FANCY_BETTING ?
                                              <Fragment>
                                                {
                                                  Number(item.is_bookmaker) === 1 ? (
                                                    _.round(Number(Utils.ThreeDecimalVal((Number(item.picked_odd.price) > 0 ? (item.picked_odd.price - 1) : item.picked_odd.price))) * Number(item.stack_value), 3) || '--'
                                                  ) : (
                                                    _.round(Number(Utils.TwoDecimalVal((Number(item.picked_odd.price) > 0 ? (item.picked_odd.price - 1) : item.picked_odd.price))) * Number(item.stack_value), 2) || '--'
                                                  )
                                                }
                                              </Fragment>
                                              :
                                              <Fragment>{_.round((Number(item.stack_value) * (Number(item.picked_odd.back_size_1) / 100)), 2) || '--'}</Fragment>
                                          }
                                        </div>
                                      </div>
                                      :
                                      <div className='return-value-col'>
                                        <div className="return--small">{t('Liability')}</div>
                                        <div className="return-val">
                                          {
                                            BetslipType !== CONSTANT.FANCY_BETTING ?
                                              <Fragment>
                                                {
                                                  Number(item.is_bookmaker) === 1 ? (
                                                    _.round((Number(Utils.ThreeDecimalVal(item.picked_odd.price)) * Number(item.stack_value)) - Number(item.stack_value), 3)
                                                    || '--'
                                                  ) : (
                                                    _.round((Number(Utils.TwoDecimalVal(item.picked_odd.price)) * Number(item.stack_value)) - Number(item.stack_value), 2)
                                                    || '--'
                                                  )
                                                }
                                              </Fragment>
                                              :
                                              <Fragment>
                                                {
                                                  _.round((Number(item.stack_value) * (Number(item.picked_odd.lay_size_1) / 100)), 2)
                                                  || '--'
                                                }
                                              </Fragment>
                                          }
                                        </div>
                                      </div>
                                  }
                                </Col>
                              </Row>
                              :
                              <Row className="gutters-10px">
                                <Col xs={12}>
                                  <input
                                    className='stake-input'
                                    type="text"
                                    placeholder='Rates'
                                    value={BetslipType !== CONSTANT.FANCY_BETTING ? Utils.TwoDecimalVal((Number(item.picked_odd.price) > 0 ? (item.picked_odd.price - 1) : item.picked_odd.price)) : item.picked_odd.price}
                                    // disabled={currentUser && currentUser.lock_um_betting !== 0}
                                    onChange={this.marketChangeHandle(key)} />

                                  {
                                    item.picked_odd.betting_type === 1 ?
                                      <div className='return-value-col'>
                                        <div className="return--small">{t('Return')}</div>
                                        <div className="return-val">{
                                          _.round(Number((BetslipType !== CONSTANT.FANCY_BETTING ? Utils.TwoDecimalVal((Number(item.picked_odd.price) > 0 ? (item.picked_odd.price - 1) : item.picked_odd.price)) : item.picked_odd.price)) * Number(MultipleBetStake), 2)
                                          || '--'}</div>
                                      </div>
                                      :
                                      <div className='return-value-col'>
                                        <div className="return--small">{t('Liability')}</div>
                                        <div className="return-val">{
                                          _.round((Number((BetslipType !== CONSTANT.FANCY_BETTING ? Utils.TwoDecimalVal((Number(item.picked_odd.price) > 0 ? (item.picked_odd.price - 1) : item.picked_odd.price)) : item.picked_odd.price)) * Number(MultipleBetStake)) - Number(MultipleBetStake), 2)
                                          || '--'}</div>
                                      </div>
                                  }
                                </Col>
                              </Row>
                          }
                        </div>
                        {
                          isSingleBet &&
                          <AvalStacks {...{ idx: key }} />

                        }

                      </div>
                    )
                  })
                }

                {
                  (!_.isEmpty(SelectedOdds) && !isSingleBet) &&
                  <div className="betslip-list-footer">
                    <Row className="no-gutters">
                      <Col xs={12}>
                        <input
                          type="text"
                          placeholder='Amount'
                          className='stake-input'
                          value={MultipleBetStake}
                          onKeyDown={this.multipleBetHandleKey}
                          onChange={this.multipleBetStackHandle}
                        />
                        <div className='return-value-col'>
                          <div className="return--small">{t('Return')}</div>
                          <div className="return-val">{_.round(this.totalValue('odd') * Number(MultipleBetStake), 2)}</div>
                        </div>
                      </Col>
                    </Row>
                    <div className="betslip--footer">
                      {
                        _.map(BetslipStack, (item, key) => <a onClick={() => this.multipleBetStackClickHandle(item)} {...{ key }}>{item}</a>)
                      }
                    </div>
                  </div>
                }
              </div>
          }
          {
            !_.isEmpty(SelectedOdds) &&
            <div className="betslip-footer">
              {
                !CONSTANT.ONLY_SINGLE_BET &&
                <Fragment>
                  <i className="icon-trash" onClick={() => this.clearAllBet(BetslipType)} />
                  {
                    BetslipType !== CONSTANT.FANCY_BETTING &&
                    <Row>
                      <Col>{t('Total Rates')} : <b>{this.totalValue('odd')}</b></Col>
                    </Row>
                  }
                  <Row>
                    {
                      isSingleBet ?
                        <Col>{t('Total Amount')} : <b>{this.totalValue('stake') === 0 ? '0.00' : this.totalValue('stake')}</b></Col>
                        :
                        <Col>{t('Total Amount')} : <b>{MultipleBetStake === 0 ? '0.00' : MultipleBetStake}</b></Col>
                    }
                  </Row>
                </Fragment>
              }

              <Row>
                <Col>
                  {
                    currentUser ?
                      <Button
                        color="primary"
                        className="float-right betslip_submit"
                        block
                        disabled={
                          // (currentUser && currentUser.lock_betting !== 0) ||
                          BetPosting
                          || this.isValidate()
                          || (this.isDuplicateBetSlip() && !isSingleBet)
                          || (this.isLayMultiBet() && !isSingleBet)
                        }
                        onClick={() => this.PlaceBetHandler()}>{t('Place a Bet')}
                      </Button>
                      :
                      <Button
                        color="primary"
                        className={`round`}
                        block
                        onClick={() => loginOpen()}>{t('Login')}</Button>
                  }
                  {/* <Button
                    color="primary"
                    className="float-right mr-2 betslip_close"
                    onClick={() => this.clearBetSlipWindow()}
                  >
                    Close
                  </Button> */}
                </Col>
              </Row>
            </div>
          }
          {/* FOOTER: END */}
          {/* </Collapse> */}
        </div>
      </div>
    )
  }
}

MobileBetslip.defaultProps = {
  fancy_betslip: '',
  _fancyHandleRemoveSlip: () => { }
}
const MobileBetslipTrans = withTranslation()(MobileBetslip)

function mapStateToProps(state) {
  const { login, app } = state;
  return {
    isOpen: login.isOpen,
    openCollapse: app.openCollapse
  };
}

const mapDispatchToProps = dispatch => ({
  loginOpen: () => dispatch(loginOpen()),
  LoaderShow: (date) => dispatch(LoaderShow(date)),
  LoaderHide: () => dispatch(LoaderHide()),
  UpdateProfile: () => dispatch(UpdateProfile()),
  updateWinloss: (option) => dispatch(updateWinloss(option)),
  SlipToggle: (type) => dispatch(SlipToggle(type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileBetslipTrans);
