import React, { Component, Fragment } from 'react'

export class ScoreURL3 extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        document.addEventListener("visibilitychange", this.handleBrowswer);
    }
    componentWillUnmount() {
        document.removeEventListener("visibilitychange", this.handleBrowswer);
    }

    // brower change tabs and screen
    handleBrowswer = (e) => {
        if (e.type == "visibilitychange") {
            // window.location.reload(false);
            this.setState({
                data: true
            }, () => {
                setTimeout(() => { this.setState({ data: false }) }, 500)
            })
        }
    }


    render() {
        const { data } = this.state;
        const { is_betslip_drawer, double_call } = this.props;
        let ED = this.props.EventDetails;
        return (
            <Fragment>
                <iframe src={data ? ED.scoreUrl3 : this.props.EventDetails.scoreUrl3} className={!double_call ? (is_betslip_drawer ? "new_score_board_live" : "desktop_new_score_board_live") : "new_score_board_live"} title="PD Score Card Active" />
            </Fragment>
        )
    }
}

export default ScoreURL3