import React, { Component } from 'react';
import { Utils } from 'Helpers'

export class Bubble extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isThemeGradient: Utils.getCookie('isThemeGradient') ? Number(Utils.getCookie('isThemeGradient')) == 1 ? true : false : false,
        }
    }
    render() {
        let data = this.props.data;
        const { isThemeGradient } = this.state
        return (

            <span className={"market-numb " + (Number(data) <= 9 && 'one_digit') + (isThemeGradient ? " bg-gradient-2 " : "")}>
                <span className="data">{data}</span>

            </span>
        )
    }
}

export default Bubble