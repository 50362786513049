import React, { Component } from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button } from 'reactstrap';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import { Utils } from 'Helpers';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      email: "",
      Name: "",
      Username: "",
      password: "",
      ConfirmPassword: "",
      ReferralCode: "",
      MobileNumber: "",
      Dob: moment().toDate(),
    }

  }
  handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === 'password') {
      value = value.trim()
    }
    this.setState({ [name]: value }, this.validateForm);
  };

  validateForm() {
    const { password, email } = this.state
    this.setState({
      formValid:
        password !== '' &&
        email !== '' &&
        Utils.isFormValid(['password', 'email'], this.state)
    });
  }
  render() {
    const {t} = this.props
    const { email, Name, Username, password, ConfirmPassword, Dob, ReferralCode, MobileNumber, posting, formValid  } = this.state
    return (
      <Container>
        <Row>
          <Col>
            <div className="onboarding-wrap">
              <div className="title text-center">{t('Sign up')}</div>
              <Form onSubmit={this.onSubmit} className="">
                <Row>
                  <Col sm={6}>
                    <FormGroup className='input-border-bottom' >
                      <span className='label'>{t('Name')}</span>
                      <Input type="Name"
                        id='Name'
                        name='Name'
                        required
                        value={Name}
                        autoComplete="off"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("email", Name)}
                        valid={Utils.isValid("email", Name)} />
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className='input-border-bottom' >
                      <span className='label'>{t('Username')}</span>
                      <Input type="Username"
                        id='Username'
                        name='Username'
                        required
                        value={Username}
                        autoComplete="off"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("Username", Username)}
                        valid={Utils.isValid("Username", Username)} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <FormGroup className='input-border-bottom' >
                      <span className='label'>{t('Password')}</span>
                      <Input
                        id='password'
                        name='password'
                        required
                        type='password'
                        maxLength={50}
                        value={password}
                        autoComplete="off"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("password", password)}
                        valid={Utils.isValid("password", password)} />
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className='input-border-bottom' >
                      <span className='label'>{t('Confirm Password')}</span>
                      <Input
                        id='ConfirmPassword'
                        name='ConfirmPassword'
                        required
                        type='ConfirmPassword'
                        maxLength={50}
                        value={ConfirmPassword}
                        autoComplete="off"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("password", ConfirmPassword)}
                        valid={Utils.isValid("password", ConfirmPassword)} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <FormGroup className='input-border-bottom' >
                      <span className='label'>{t('E-mail')}</span>
                      <Input type="email"
                        id='email'
                        name='email'
                        required
                        value={email}
                        autoComplete="off"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("email", email)}
                        valid={Utils.isValid("email", email)} />
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className='input-border-bottom'>
                      <span className='label'>{t('Date of Birth')}</span>
                      <DatePicker
                        onChange={this.onFromDateChange}
                        onCalendarOpen={this.onCalendarOpen}
                        value={Dob}
                        calendarIcon={<i className='icon-calendar' />}
                        className='form-control'
                        clearIcon={null}
                        format="d/M/yyyy"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <FormGroup className='input-border-bottom' >
                      <span className='label'>{t('Mobile Number')}</span>
                      <Input type="MobileNumber"
                        id='MobileNumber'
                        name='MobileNumber'
                        required
                        value={MobileNumber}
                        autoComplete="off"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("MobileNumber", MobileNumber)}
                        valid={Utils.isValid("MobileNumber", MobileNumber)} />
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className='input-border-bottom' >
                      <span className='label'>Referral Code</span>
                      <Input type="ReferralCode"
                        id='ReferralCode'
                        name='ReferralCode'
                        required
                        value={ReferralCode}
                        autoComplete="off"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("ReferralCode", ReferralCode)}
                        valid={Utils.isValid("ReferralCode", ReferralCode)} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup check  className='check check-primary'>
                      <Label check>
                        <Input type="checkbox" />
                        {t('I agree to all terms & conditions of  Ltd.')}
                        <span />
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <FormGroup className='m-0'>
                      <Button disabled={!formValid || posting} type='submit' size="lg" color="primary" className='round'>{t('Signup')}</Button>
                    </FormGroup>
                  </Col>
                </Row>

              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withTranslation()(Signup);
