// Config
import config from "../../config";
const SUCCESS_CODE = 200;

const API = {
    // AUTH
    LOGIN: `${config.apiGateway}/user/auth/login`,
    LOGOUT: `${config.apiGateway}/user/auth/logout`,

    GET_ALL_THEMES: `${config.apiGateway}/user/auth/get_all_themes`,
    SAVE_COLOR_THEME: `${config.apiGateway}/user/profile/save_color_theme`,

    // MASTER 
    GET_APP_MASTER_LIST: `${config.apiGateway}/user/auth/get_app_master_list`,
    S3_GET_APP_MASTER_LIST: `app_master_data`,                                                     // S3
    GET_SPORT_LEAGUES: `${config.apiGateway}/user/league/get_sport_leagues`,
    S3_GET_SPORT_LEAGUES: `all_leagues_by_sport`,                                                // S3


    // Fixture: Exchange
    GET_ACTIVE_LEAGUES_BY_SPORT_EXCHANGE_BETTING: `${config.apiGateway}/user/exfixture/get_active_leagues_by_sport`,
    GET_UPCOMING_FIXTURES: `${config.apiGateway}/user/exfixture/get_upcoming_fixtures`,
    GET_FIXTURE_MARKETS: `${config.apiGateway}/user/exfixture/get_fixture_markets`,
    GET_INPLAY_GAMES: `${config.apiGateway}/user/exfixture/get_inplay_games`,
    GET_USER_BALANCE: `${config.apiGateway}/user/profile/get_bal`,

    // Fixture: SPORTSBOOK
    GET_ACTIVE_LEAGUES_BY_SPORT_SPORTBOOK_BETTING: `${config.apiGateway}/user/spfixture/get_active_leagues_by_sport`,
    GET_UPCOMING_FIXTURES_SP: `${config.apiGateway}/user/spfixture/get_upcoming_fixtures`,
    GET_FIXTURE_MARKETS_SP: `${config.apiGateway}/user/spfixture/get_fixture_markets`,
    GET_INPLAY_GAMES_SP: `${config.apiGateway}/user/spfixture/get_inplay_games`,
    // cup winner
    CUP_WINNER: `${config.apiGateway}/user/exfixture/get_cup_matches`,



    GET_FIXTURE_MARKET_DETAIL: `${config.apiGateway}/user/fixture/get_fixture_market_detail`,
    FANCY_EVENT: `${config.apiGateway}/user/fancy/event`,
    BET365_EVENT: `${config.apiGateway}/user/fancy/bet365_event`,
    UPDATE_ODD_DIFF: `${config.apiGateway}/user/profile/update_odd_diff`,
    LDO_EXPOSURE_DATA: `${config.apiGateway}/user/betslip/ldo_exposure_data`,

    // USER BET
    PLACE_BET: `${config.apiGateway}/user/betslip/place_bet`,
    OPEN_BETS: `${config.apiGateway}/user/betslip/open_bets`,

    // BET HISTORY
    HISTORY: `${config.apiGateway}/user/betslip/history`,
    CANCEL_UM_BET: `${config.apiGateway}/user/betslip/cancel_um_bet`,

    //ACCOUNTS STATEMENT
    ACCOUNTS_STATEMENT: `${config.apiGateway}/user/accounts/statement`,
    //PROFIT LOSS
    PROFIT_LOSS: `${config.apiGateway}/user/accounts/profit_loss`,
    // CHANGE PASSWORD
    CHANGE_PASSWORD: `${config.apiGateway}/user/profile/change_password`,
    //CLIENT NOTIFICATION
    GET_CLIENT_NOTIFICATION: `${config.apiGateway}/user/auth/get_client_announcement`,
    //NOTIFICATION
    GET_NOTIFICATION: `${config.apiGateway}/user/notification/get_notifications`,
    GET_UNREAD_NOTIFICATION: `${config.apiGateway}/user/notification/get_unread_notification`,
    //LEDGER DETAILS
    LEDGERS: `${config.apiGateway}/user/accounts/ledger`,
    LEDGERDETAILS: `${config.apiGateway}/user/accounts/ledger_detail`,
    EXPOSUREDETAILS: `${config.apiGateway}/user/betslip/exposure_data`,

    SESSION_DETAILS: `${config.apiGateway}/user/exfixture/session_detail`,

    //SCOREBOARD
    SCOREBOARD: `${config.apiGateway}/user/auth/get_scoreboard`,

    //CASINO SUPERNOWA
    CASINO_SUPERNOWA: `${config.apiGateway}/user/auth/redirect_to_supernowa`,
    CASINO_BET_DETAIL: `${config.apiGateway}/user/accounts/casino_bet_detail`,

    //FOOTBALL SCOREBOARD
    GET_SOCCER_MATCH_SCORE: `${config.apiGateway}/user/auth/get_soccermatch`,

    //DECLARED SESSION
    DECLARED_SESSION: `${config.apiGateway}/user/betslip/declared_session`,
    DECLARED_MARKET: `${config.apiGateway}/user/betslip/declared_market`,
    DECLARED_SESSION_DETAIL: `${config.apiGateway}/user/betslip/declared_session_detail`,
    DECLARED_TOSS_DETAIL: `${config.apiGateway}/user/betslip/declared_toss_detail`,

    //TOSS BETS
    EVENT_TOSS_BETS: `${config.apiGateway}/user/betslip/toss_bets`,

    //GET USER SPORT SETTINGS
    GET_USER_SPORT_SETTINGS: `${config.apiGateway}/user/auth/get_user_bet_delay`,

    //GET USER STAKE
    GET_USER_STAKE: `${config.apiGateway}/user/auth/get_user_stake`,

    //SET USER STAKE
    SET_USER_STAKE: `${config.apiGateway}/user/auth/set_user_stake`,
}

export default {
    SUCCESS_CODE,
    ...API
};
