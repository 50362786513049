import React, { Component, Fragment } from 'react';
import { Button, Row, Col, Card, CardBody, ListGroup, ListGroupItem, ButtonGroup } from 'reactstrap';
import { TitleComponent } from 'Components';
import { _, Utils } from "Helpers";
import { Auth } from "Service";

class Rules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hindiactive: true,
      AppMasterData: Utils.getMasterData(),
      isThemeGradient: Utils.getCookie('isThemeGradient') ? Number(Utils.getCookie('isThemeGradient')) == 1 ? true : false : false,
      mobileAppCharges: Auth.currentUserValue ? Auth.currentUserValue.mobile_app_charges : 0,
    };
    this.handleHindiClick = this.handleHindiClick.bind(this);
    this.handleEnglishClick = this.handleEnglishClick.bind(this);
  }

  handleHindiClick() {
    this.setState({
      hindiactive: true
    });
  }

  handleEnglishClick() {
    this.setState({
      hindiactive: false
    });
  }

  gotoPath = (pathname = "/") => {
    this.props.history.push(pathname);
  };

  render() {
    const { AppMasterData, isThemeGradient, mobileAppCharges } = this.state;
    return (
      <Fragment>
        <TitleComponent title={"Rules"} />
        <Row className="mx-0 mt-5">
          <Col className="text-center btn-in-rules">
            <Button size="lg" className={"btn_inplay " + (isThemeGradient ? "bg-gradient-3 " : "")} onClick={() => { this.gotoPath("/exchange") }}>IN PLAY</Button>
            {/*            <Button size="lg" className="btn_inplay" onClick={() => { this.gotoPath("/exchange?sports=2") }}>CRICKET</Button>
            <Button size="lg" className="btn_inplay" onClick={() => { this.gotoPath("/exchange?sports=1") }}>FOOTBALL</Button>
            <Button size="lg" className="btn_inplay" onClick={() => { this.gotoPath("/exchange?sports=3") }}>TENNIS</Button>
            <Button size="lg" className="btn_inplay" onClick={() => { this.gotoPath("/exchange?sports=4") }}>HORSE RACING</Button>
            <Button size="lg" className="btn_inplay" onClick={() => this.gotoPath("/casino_supernowa?sports=6")}>CASINO</Button>
            <Button size="lg" className="btn_inplay" onClick={() => { this.gotoPath("/comingsoon") }}>LIVE GAMES</Button>
            <Button size="lg" className="btn_inplay" onClick={() => { this.gotoPath("/comingsoon") }}>SATKA MATKA</Button>
            <Button size="lg" className="btn_inplay" onClick={() => { this.gotoPath("/comingsoon") }}>LUCKY 3</Button>*/}
            {/* <ButtonGroup className="hindiEnglishButton">
              <Button size="lg" className={this.state.hindiactive ? "active-button" : ""} onClick={this.handleHindiClick}>हिंदी</Button>
              <Button size="lg" className={!this.state.hindiactive ? "active-button" : ""} onClick={this.handleEnglishClick}>English</Button>
            </ButtonGroup> */}
          </Col>
        </Row>
        {
          this.state.hindiactive ? (
            <Row className="mx-0 mt-3 hindi-note-container">
              <Col>
                <Card className="hindi-note-card-container mx-auto">
                  <CardBody>
                    <Card className="card-header-container">
                      <CardBody className="p-0">
                        <ListGroup>
                          <ListGroupItem className="text-center"><b>!! {!_.isUndefined(AppMasterData) && !_.isNull(AppMasterData) && !_.isUndefined(AppMasterData.domain_detail) && !_.isNull(AppMasterData.domain_detail) && !_.isUndefined(AppMasterData.domain_detail.domain) && !_.isNull(AppMasterData.domain_detail.domain) && (AppMasterData.domain_detail.domain !== '') ? AppMasterData.domain_detail.domain : 'www.silvarbet.com'} में आपका स्वागत है !!</b></ListGroupItem>
                        </ListGroup>
                      </CardBody>
                    </Card>
                    <Row className="my-3">
                      <Col>
                        <ol className="hindi-rules-list">
                          <li>1. कृपया {!_.isUndefined(AppMasterData) && !_.isNull(AppMasterData) && !_.isUndefined(AppMasterData.domain_detail) && !_.isNull(AppMasterData.domain_detail) && !_.isUndefined(AppMasterData.domain_detail.website_name) && !_.isNull(AppMasterData.domain_detail.website_name) && (AppMasterData.domain_detail.website_name !== '') ? AppMasterData.domain_detail.website_name : 'SILVARBET'} के नियमों को समझने के लिए यहां कुछ मिनट दें, और अपने अनुसार समझ लें ||</li>
                          <li>2. लॉग इन करने के बाद अपना पासवर्ड बदल लें |</li>
                          <li>3. खेल रद्द या टाई होने पर सभी सौदे रद्द कर दिए जाएंगे और लेनदेन सेशन और फैंसी का किया जाएगा बेट फेर और रूल के हिसाब से |</li>
                          <li>4. मैच के दौरान भाव को देख और समझ कर ही सौदा करें | किये गए किसी भी सौदे को हटाया या बदला नहीं जायेगा | सभी सौदे के लिए आप स्वयं जिम्मेवार हैं |</li>
                          <li>5. यहाँ सभी सौदे लेजर से मान्य किये जायेंगे |</li>
                          <li>6. इंटरनेट कनेक्शन प्रॉब्लम की जिम्मेवारी आपकी रहेगी |</li>
                          <li>7. सर्वर या वेबसाइट में किसी तरह की खराबी आने या बंद हो जाने पर केवल किए गए सौदे ही मान्य होंगे | ऐसी स्तिथि में किसी तरह का वाद-विवाद मान्य नहीं होगा |</li>
                          <li>8. 1-1 मिनिट में किए गए खाई लगाई के सौदे मान्य नहीं किए जाएँगे ।  ऐसे सौदे किसी भी ID में दिखाई देते है तो उसके प्रोफ़िट के सौदे हटा दिए जाएँगे । </li>
                          <li>9. ID में किसी भी तरह कि चीटिंग के सौदे पकड़े जाने पे उनके प्लस के सौदे हटा दिए जाएँगे ।</li>
                          {(Number(mobileAppCharges) > 0) &&
                            <li>10. प्रत्येक गेम के लिए {mobileAppCharges}/- कॉइन्स चार्ज रहेगा सौदा करने पर |</li>
                          }

                        </ol>
                      </Col>
                    </Row>
                    {/* <Card className="card-header-container">
                      <CardBody className="p-0">
                        <ListGroup>
                          <ListGroupItem className="text-center"><b>नोट: सर्वर या वेबसाइट में किसी तरह की खराबी आने या बंद हो जाने पर केवल किए गए सौदे ही मान्य होंगे | ऐसी स्तिथि में किसी तरह का वाद-विवाद मान्य नहीं होगा |</b></ListGroupItem>
                        </ListGroup>
                      </CardBody>
                    </Card> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row className="mx-0 mt-3 english-note-container">
              <Col>
                <Card className="english-note-card-container mx-auto">
                  <CardBody>
                    <Card className="card-header-container">
                      <CardBody className="p-0">
                        <ListGroup>
                          <ListGroupItem className="text-center"><b>!! Welcome To {!_.isUndefined(AppMasterData) && !_.isNull(AppMasterData) && !_.isUndefined(AppMasterData.domain_detail) && !_.isNull(AppMasterData.domain_detail) && !_.isUndefined(AppMasterData.domain_detail.domain) && !_.isNull(AppMasterData.domain_detail.domain) && (AppMasterData.domain_detail.domain !== '') ? AppMasterData.domain_detail.domain : 'www.silvarbet.com'} !!</b></ListGroupItem>
                        </ListGroup>
                      </CardBody>
                    </Card>
                    <Row className="my-3">
                      <Col>
                        <ol className="english-rules-list">
                          <li>1. Please give a few minutes to understand rules of {!_.isUndefined(AppMasterData) && !_.isNull(AppMasterData) && !_.isUndefined(AppMasterData.domain_detail) && !_.isNull(AppMasterData.domain_detail) && !_.isUndefined(AppMasterData.domain_detail.website_name) && !_.isNull(AppMasterData.domain_detail.website_name) && (AppMasterData.domain_detail.website_name !== '') ? AppMasterData.domain_detail.website_name : 'SILVARBET'} here, as best as you can.</li>
                          <li>2. Change your password after you log in.</li>
                          <li>3. For every Match 15.0/- coins will be charged.</li>
                          <li>4. 15.0/- coins will be charged if user will not play any Market bet or Session bet in a match.</li>
                          <li>5. All the advance bets will be accepted after the toss.</li>
                          <li>6. If game is cancelled or tie then all the deals will be cancelled and the transactions will be done on session and fancy which are completed.</li>
                          <li>
                            7. Following Package Limits are applied for Matches:
                          </li>
                          <li>
                            <h4 className="match-name">
                              Bangladesh vs West Indies
                            </h4>
                            <div className="table-responsive">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>Market Type</th>
                                    <th>Minimum Bet Amount</th>
                                    <th>Maximum Bet Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Match Winner</td>
                                    <td>100</td>
                                    <td>500000</td>
                                  </tr>
                                  <tr>
                                    <td>Session</td>
                                    <td>100</td>
                                    <td>55000</td>
                                  </tr>
                                  <tr>
                                    <td>Even Odd</td>
                                    <td>100</td>
                                    <td>100000</td>
                                  </tr>
                                  <tr>
                                    <td>Ending Digit</td>
                                    <td>100</td>
                                    <td>100000</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </li>
                          <li>8. Even Odd has rate of 95.</li>
                          <li>9. Lottery draw has rate of 9.5</li>
                          <li>10. Live draw is settled on TV score, rate is never changed. This is good chance for users.</li>
                          <li>11. During the match, please bet only after confirming the deal. Once the deal is confirmed, it cannot be changed or removed. Responsibility of every deal is yours.</li>
                          <li>12. All transactions will be validated from ledger only.</li>
                          <li>13. It'll be user's responsibility for internet connection problem.</li>
                        </ol>
                      </Col>
                    </Row>
                    <Card className="card-header-container">
                      <CardBody className="p-0">
                        <ListGroup>
                          <ListGroupItem className="text-center"><b>Note: If some kind of breakdown occurs in server or website, only successful bets will be accepted. In such situation, any kind of debate will be invalid.</b></ListGroupItem>
                        </ListGroup>
                      </CardBody>
                    </Card>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )
        }
      </Fragment>
    )
  }
}

export default Rules;
