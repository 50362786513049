import React, { Component, Fragment } from 'react';
import { MessageBox, SportsBookBetItem } from 'Components';
import { Utils, _ } from 'Helpers';
import { CONSTANT } from 'Constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Collapse } from 'reactstrap';

import { withTranslation } from 'react-i18next';

class SportsBookFixtureListComponent extends Component {
  state = {
    CollapseItem: []
  }
  componentDidMount() {
    this.props.sockeListener()
    this.props.InitListToggle(true)
    setTimeout(() => {
      _.forEach(this.props.NavigationList, item => {
        this.setState({
          CollapseItem: [...this.state.CollapseItem, item.sports_id]
        })
      })
    }, 100)
  }
  componentWillUnmount() {
    this.props.removeSockeListener()
    this.props.InitListToggle(false)
  }


  toggleCollapse = (sports_id) => {
    const { CollapseItem } = this.state
    if (_.includes(CollapseItem, sports_id)) {
      _.remove(CollapseItem, (o) => o === sports_id)
    } else {
      CollapseItem.push(sports_id)
    }
    this.setState({ CollapseItem });
  }

  render() {
    const {
      // status,

      // scope
      NavigationList,
      SelectedSports,
      hasMore,
      FixtureList,
      FixturePosting,
      FixtureListBlank,
      page_id,
      InitList,
      is_inplay,

      // Methods
      handleSelectSport,
      fetchMoreData,
      _oddsActiveClass,
      handleOptionChange,
      fixtureSelectHandler,
      SocketHighlight,
      t
    } = this.props
    const { CollapseItem } = this.state
    const BetItemProps = {
      page_id,
      t,
      SocketHighlight,
      showSportName: false,
      _oddsActiveClass: _oddsActiveClass,
      handleOptionChange: handleOptionChange,
      fixtureSelectHandler: fixtureSelectHandler,
      SelectedSports: SelectedSports,
    }

    return (
      <Fragment>
        {
          !is_inplay &&
          <div className="odds-padding-row">

            <div className="odds-filter-pills">

              {
                _.map(NavigationList, (item, key) => <span onClick={() => handleSelectSport({ Sport: item, League: {}, unbind: true })} {...{ key, className: item.sports_id === SelectedSports.sports_id ? 'active' : '' }}> {item.sports_id !== '' ? <i className={`icon-${item.sports_id}`} /> : null} {item.sports_name}</span>)
              }
            </div>
          </div>
        }
        {
          InitList ?
            <Fragment>
              {
                is_inplay ?
                  <div className='game-event-list m-t-20'>
                    {
                      (FixturePosting && _.isEmpty(FixtureList)) ?
                        <Utils.BoxLoader />
                        :
                        <Fragment>
                          {
                            _.map(NavigationList, (sport) => {
                              return (
                                <Fragment key={sport.sports_id}>
                                  <h4 className='sport-title' onClick={() => this.toggleCollapse(sport.sports_id)}>
                                    {sport.sports_name}
                                    {
                                      _.includes(CollapseItem, sport.sports_id) ?
                                        <i className="icon-minus-border" />
                                        :
                                        <i className="icon-plus-border" />
                                    }
                                  </h4>
                                  <Collapse className='sport-title-collapsing' isOpen={_.includes(CollapseItem, sport.sports_id)}>
                                    {
                                      _.filter(FixtureList, function (o) { return o.sports_id === sport.sports_id; }).length > 0 &&
                                      <div className="game-event-item heading">
                                        <div className="event-details">{t('Match')}</div>
                                        <div className="cell">
                                          <div className="row-cell">
                                            <div {...{ className: `market-label` }}>1</div>
                                            {
                                              SelectedSports.sports_id !== 3 &&
                                              <div {...{ className: `market-label` }}>X</div>
                                            }
                                            <div {...{ className: `market-label` }}>2</div>
                                          </div>
                                        </div>
                                        <div className="last-cell p-0" />
                                      </div>
                                    }
                                    {
                                      _.map(_.filter(FixtureList, function (o) { return o.sports_id === sport.sports_id; }), (item, key) => {
                                        return (
                                          <SportsBookBetItem {...{ key, item, ...BetItemProps }} />
                                        )
                                      })
                                    }
                                    {
                                      _.filter(FixtureList, function (o) { return o.sports_id === sport.sports_id; }).length === 0 &&
                                      <MessageBox size='md' heading={t(sport.sports_name) + ' ' + t(CONSTANT.LIVEGAME_BLANK_HEADING)} paragraph={t(CONSTANT.LIVEGAME_BLANK_PARAGRAPH)} />
                                    }
                                  </Collapse>
                                </Fragment>
                              )
                            })
                          }
                        </Fragment>
                    }
                  </div>
                  :
                  <InfiniteScroll
                    dataLength={FixtureList.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={<Utils.FlexboxLoader className='show m-2 no-margin-h' />}
                    className='game-event-list'
                  >
                    {
                      !_.isEmpty(FixtureList) &&
                      <div className="game-event-item heading">
                        <div className="event-details">{t('Match')}</div>
                        <div className="cell">
                          <div className="row-cell">
                            <div {...{ className: `market-label` }}>1</div>
                            {
                              SelectedSports.sports_id !== 3 &&
                              <div {...{ className: `market-label` }}>X</div>
                            }
                            <div {...{ className: `market-label` }}>2</div>
                          </div>
                        </div>
                        <div className="last-cell p-0" />
                      </div>
                    }

                    {
                      (FixturePosting && _.isEmpty(FixtureList)) &&
                      <Utils.BoxLoader />
                    }
                    {
                      _.map(FixtureList, (item, key) => {
                        return (
                          <SportsBookBetItem {...{ key, item, ...BetItemProps }} />
                        )
                      })
                    }
                    {
                      FixtureListBlank &&
                      <MessageBox heading={t(CONSTANT.FIXTURE_BLANK_HEADING)} paragraph={t(CONSTANT.FIXTURE_BLANK_PARAGRAPH)} />
                    }
                  </InfiniteScroll>

              }
            </Fragment>
            :
            <Utils.BoxLoader />
        }
      </Fragment>

    )
  }
}
SportsBookFixtureListComponent.defaultProps = {
  status: '0' // 0 = Upcoming, 1 = Live
}
export default withTranslation()(SportsBookFixtureListComponent);