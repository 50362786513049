const Images = {
  Logo: require('../../Assets/img/heroic_logo.png'),
  LOGO_SM: require('../../Assets/img/logo.png'),
  THUMB: require('../../Assets/img/thumb.png'),
  // THUMB              : require('../../Assets/img/thumb.jpg'),
  THUMB_WIDE: require('../../Assets/img/thumb-wide.jpg'),
  THUMB_VERTICAL: require('../../Assets/img/thumb-vertical.png'),
  MGA: require('../../Assets/img/mga.png'),
  GAMBLING_COMMISSION: require('../../Assets/img/gambling-commission.png'),
  NET_BANKING: require('../../Assets/img/net-banking.png'),
  UPI: require('../../Assets/img/upi.png'),
  PAYTM: require('../../Assets/img/paytm.png'),
  GOOGLE_PAY: require('../../Assets/img/google-pay.png'),
  PHONE_PE: require('../../Assets/img/phone-pe.png'),
  BANNER_RIGHT: require('../../Assets/img/banner-right.png'),
  BANNER_GAME: require('../../Assets/img/banner-game.png'),
  IPL: require('../../Assets/img/ipl.svg'),
  ASIA: require('../../Assets/img/asia.svg'),
  BBL: require('../../Assets/img/bbl.svg'),
  ICC: require('../../Assets/img/icc.svg'),
  ASH: require('../../Assets/img/ash.svg'),
  PREMIER_LEAGUE: require('../../Assets/img/premier-league.svg'),
  UEFA: require('../../Assets/img/uefa.svg'),
  WIMBLEDON: require('../../Assets/img/Wimbledon.svg'),
  WTA: require('../../Assets/img/WTA.svg'),
  PASS: require('../../Assets/img/pass.svg'),
  CASINO: require('../../Assets/img/casino.jpg'),
  BANK_TRANSFER: require('../../Assets/img/bank_transfer.svg'),
  PAYTM_LOGO: require('../../Assets/img/Paytm_Logo.svg'),
  PHONEPE_LOGO: require('../../Assets/img/Phonepe.svg'),
  ROULETTE_LOGO: require('../../Assets/img/roulette.jpg'),
  CASINO_BANNER: require('../../Assets/img/casino_logo.jpg'),
  ROULETTE: require('../../Assets/img/roulette.jpg'),
  TEEN_PATTI: require('../../Assets/img/3patti.jpeg'),
  ANDAR_BAHAR: require('../../Assets/img/andar _bahar.jpg'),
  SATTA_MATKA: require('../../Assets/img/sattamatka.png'),
  PLAYER_JRY: require('../../Assets/img/player_unk.png'),
  BACK_ARROW: require('../../Assets/img/back_arrow.png'),

  HOME_CASINO: require('../../Assets/img/home-casino.png'),
  HOME_CRICKET: require('../../Assets/img/home-cricket.png'),
  HOME_FOOTBALL: require('../../Assets/img/home-football.png'),
  HOME_TENNIS: require('../../Assets/img/home-tennis.png'),
  REFRESH: require('../../Assets/img/refresh.png'),

  GOALS: require('../../Assets/img/football.png'),
  RED_CARD: require('../../Assets/img/red_card.svg'),
  YELLOW_CARD: require('../../Assets/img/yellow_card.svg'),
  CORNERS: require('../../Assets/img/corners.svg'),
  LIVE_TV: require('../../Assets/img/tv.png'),
  ANDROID: require('../../Assets/img/android.png'),

  INPLAY_GREEN_WEB: require('../../Assets/img/inplay_green_web.png'),
  INPLAY_WHITE_WEB: require('../../Assets/img/inplay_white_web.png'),
  INPLAY_GREEN_MOBILE: require('../../Assets/img/inplay_green_mobile.png'),
  INPLAY_WHITE_MOBILE: require('../../Assets/img/inplay_white_mobile.png'),
  CASINO_WHITE_WEB: require('../../Assets/img/casino_white_web.png'),
  CASINO_GREEN_WEB: require('../../Assets/img/casino_green_web.png'),
  CASINO_WHITE_MOBILE: require('../../Assets/img/casino_white_mobile.png'),
  CASINO_GREEN_MOBILE: require('../../Assets/img/casino_green_mobile.png'),
  UPCOMING_WHITE_WEB: require('../../Assets/img/upcoming_white_web.png'),
  UPCOMING_GREEN_WEB: require('../../Assets/img/upcoming_green_web.png'),
  UPCOMING_WHITE_MOBILE: require('../../Assets/img/upcoming_white_mobile.png'),
  UPCOMING_GREEN_MOBILE: require('../../Assets/img/upcoming_green_mobile.png'),


  CASINO_GAME_LIST: require('../../Assets/img/casino_game_list.png'),


  FOUR_RUN: require('../../Assets/img/four_run.gif'),
  SIX_RUN: require('../../Assets/img/six_run.gif'),
  OUT: require('../../Assets/img/out.gif'),
  NO_BALL: require('../../Assets/img/no_ball.gif'),
  WIDE_BALL: require('../../Assets/img/wide.gif'),
  FREE_HIT: require('../../Assets/img/freehit.gif'),
  OVER: require('../../Assets/img/over.gif'),

  CHECKMATE: require('../../Assets/img/checkmate.gif'),

  PAD_LOCK: require('../../Assets/img/pad-lock.png'),
  SCORE_BOARD: require("../../Assets/img/sb.png"),
  WHTSAPP: require('../../Assets/img/whatsapp.png'),

}

export default Images
