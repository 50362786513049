/* eslint-disable jsx-a11y/anchor-is-valid, eqeqeq */
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';

class BetSlipErrorMessageModal extends Component {

    render() {
        const { isOpen, toggle, message, title } = this.props;
        const ModalProps = {
            isOpen,
            toggle,
            size: 'sm',
            className: 'custom-modal'
        }

        return (
            <Modal {...ModalProps}>
                <i className="close-btn icon-cancel" onClick={toggle} />
                <ModalHeader>{title}</ModalHeader>
                <ModalBody>
                    <Row className="mx-2 col-container">
                        <Col className="col-12">
                            <p>{message}</p>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className="justify-content-center">
                    <Button color="primary" size='sm' onClick={toggle}>Ok</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

BetSlipErrorMessageModal.defaultProps = {
    isOpen: true,
    toggle: () => { }
}

export default BetSlipErrorMessageModal;
