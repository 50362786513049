import React, { Component } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Container, Row, Col } from 'reactstrap';
import { FixtureListComponent, FixtureDetailList, BetslipDrawer } from 'Components';
import { Http, API, Utils, _, Socket } from 'Helpers';
import { CONSTANT, SocketEvent } from 'Constants';
import { connect } from "react-redux";
import { SlipToggle } from '../../Redux/Reducers'

class ExchangeHome extends Component {
  constructor(props) {
    super(props);
    const { page_id, upcoming, cup_winner } = props;
    this.state = {
      upcoming: cup_winner == true ? false : upcoming,
      is_inplay: false,
      show_betslip: false,
      FixturePosting: false,
      FixtureListBlank: false,
      hasMore: false,
      AppMasterData: [],
      NavigationList: [],
      SelectedSports: {},
      SelectedLeague: {},
      FixtureList: [],
      Offset: CONSTANT.CURRENT_OFFSET,
      InitList: false,
      iswebwindow: true,
      cup_winner: cup_winner
    };
  }

  GetFixtures = () => {
    const { InitList, SelectedSports, SelectedLeague, Offset, FixtureList, is_inplay } = this.state;

    if (!InitList) return;
    let param = {
      // "sports_id": is_inplay ? "" : SelectedSports.sports_id,
      "sports_id": SelectedSports.sports_id ? SelectedSports.sports_id : "2",
      // "sports_id": window.location.pathname === "/exchange" ? "2" : window.location.pathname === "/upcoming" ? "2" : window.location.pathname === "/cup_winner" ? "2" : SelectedSports.sports_id,
      "league_uid": _.isEmpty(SelectedLeague) ? "" : SelectedLeague.league_uid,
      "limit": is_inplay ? 99999 : CONSTANT.ITEMS_PERPAGE_SM,
      "offset": Offset
    }

    this.setState({ FixturePosting: true })
    Http.post(this.state.cup_winner ? API.CUP_WINNER : (!this.state.upcoming ? API.GET_INPLAY_GAMES : API.GET_UPCOMING_FIXTURES), param).then(response => {
      this.setState({
        FixturePosting: false,
        FixtureList: Offset === CONSTANT.CURRENT_OFFSET ? response.data.matches : [...FixtureList, ...response.data.matches],
        hasMore: !_.isEmpty(response.data.matches) || (_.size(response.data.matches) === CONSTANT.ITEMS_PERPAGE_SM),
        fixture_lastupdate: new Date().valueOf()
      }, () => {
        this.setState({
          FixtureListBlank: _.isEmpty(response.data.matches) && _.isEmpty(this.state.FixtureList)
        })
      })
    }).catch(error => {
      this.setState({ FixturePosting: false })
    });

  }

  // Handlers
  InitListToggle = (bool) => {
    this.setState({ InitList: bool });
  }

  fetchMoreData = () => {
    if (!this.state.FixturePosting) {
      let offset = this.state.Offset + CONSTANT.ITEMS_PERPAGE_SM;
      this.setState({ Offset: offset }, () => {
        this.GetFixtures()
      });
    }
  }

  handleSelectSport = (option) => {
    // if (window.location.pathname == '/cup_winner') {
    if (option.Sport.sports_name == 'Casino') {
      this.props.history.push({
        pathname: "/casino_supernowa",
      })


      // }
      // else {
      //   this.setState({
      //     FixtureList: [],
      //     FixtureListBlank: false,
      //     Offset: CONSTANT.CURRENT_OFFSET,
      //     SelectedSports: option.Sport,
      //     SelectedLeague: option.League,
      //   }, () => {
      //     const { SelectedSports, SelectedLeague } = this.state
      //     this.props.history.push({
      //       pathname: "/exchange",
      //       ...(SelectedSports.sports_id !== '' && { search: `?sports=${SelectedSports.sports_id}` }),
      //       state: {
      //         SelectedSports,
      //         SelectedLeague,
      //         unbind: option.unbind
      //       }
      //     })
      //   })

      // }

    } else {
      this.setState({
        FixtureList: [],
        FixtureListBlank: false,
        Offset: CONSTANT.CURRENT_OFFSET,
        SelectedSports: option.Sport,
        SelectedLeague: option.League,
      }, () => {
        const { match } = this.props;
        const { SelectedSports, SelectedLeague, is_inplay } = this.state
        this.props.history.push({
          pathname: this.state.SelectedSports && !_.isEmpty(this.state.SelectedSports) && this.state.SelectedSports.sports_id && !_.isEmpty(this.state.SelectedSports.sports_id) && (Number(this.state.SelectedSports.sports_id) === 6) ? `/casino_supernowa` : (is_inplay ? `${match.url}/in-play` : `${match.url}`),
          ...(SelectedSports.sports_id !== '' && { search: `?sports=${SelectedSports.sports_id}` }),
          state: {
            SelectedSports,
            SelectedLeague,
            unbind: option.unbind
          }
        })
        // this.GetFixtures()
      })
    }


  }

  reinitiateComponent = (sports_id = '', is_bypass = false) => {
    const { match } = this.props
    if (is_bypass) {
      this.props.history.push({
        pathname: `${match.url}/in-play`
      })
    } else {
      this.props.history.push({
        pathname: `${match.url}`,
        ...((sports_id !== '') && { search: `?sports=${sports_id}` })
      })
    }
  }

  pageInit = ({ SelectedLeague = {}, SelectedSports = {}, unbind }) => {
    const { page_id } = this.props;
    const { is_inplay } = this.state
    this.setState({
      AppMasterData: Utils.getMasterData(),
      NavigationList: is_inplay ? [
        ...Utils.getMasterData().sports_list[page_id]
      ] : Utils.getMasterData().sports_list[page_id],
    }, () => {
      const { NavigationList, AppMasterData } = this.state
      const { location } = this.props
      const searchParams = new URLSearchParams(location.search);
      if (unbind) {
        this.setState({
          SelectedSports: SelectedSports,
          SelectedLeague: SelectedLeague,
        }, this.GetFixtures)
      }
      else {
        this.GetFixtures();
      }
    })
  }


  // Life cycle
  componentDidMount() {
    const { location } = this.props;
    this.setState({
      iswebwindow: window.innerWidth > 1000,
      is_inplay: _.includes(location.pathname, 'in-play'),
      show_betslip: (location.pathname.search(/details/gi) === -1 ? false : true)
    });
    this.pageInit(_.isUndefined(location.state) || _.isNull(location.state) ? {} : location.state)
  }

  componentWillMount() {
    this.setState({
      iswebwindow: window.innerWidth > 1000
    })
  }

  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }


  render() {
    const { page_id, match } = this.props
    const {
      NavigationList,
      SelectedSports,
      FixtureList,
      hasMore,
      FixturePosting,
      FixtureListBlank,
      InitList,
      is_inplay,
    } = this.state;

    const BetslipDrawerProps = {
      ...this.props,
    };
    const FixtureListProps = {
      ...this.props,
      NavigationList,
      SelectedSports,
      hasMore,
      FixtureList,
      FixturePosting,
      FixtureListBlank,
      InitList,
      is_inplay,
      BetslipDrawerProps,
      // Methods
      InitListToggle: this.InitListToggle,
      handleSelectSport: this.handleSelectSport,
      fetchMoreData: this.fetchMoreData,
      reinitiateComponent: this.reinitiateComponent,
    }
    return (
      <Container fluid className='gutters-container-7px'>
        <Switch>
          <Route
            path={[`${match.url}/details/:event_id`, `${match.url}/in-play/details/:event_id`]}
            exact
            render={(props) => <FixtureDetailList onRef={(ref) => (this.fixtureDetailList = ref)} {...FixtureListProps} {...props} />}
          />
          <Route
            path={[`${match.url}/`, `${match.url}/in-play`]}
            exact
            render={(props) => <FixtureListComponent {...FixtureListProps} {...props} />}
          />
          <Redirect from="*" exact to={match.url} />
        </Switch>
      </Container>
    );
  }
}
function mapStateToProps(state) {
  const { app } = state;
  return {
    openCollapse: app.openCollapse
  };
}
const mapDispatchToProps = dispatch => ({
  SlipToggle: (type) => dispatch(SlipToggle(type)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExchangeHome);
