import React from 'react'
import { Col, Row } from 'reactstrap'
import { Utils } from 'Helpers'



const GlobalMessage = ({ admin_agent_msg, isMobileView }) => {
    let isThemeGradient = Utils.getCookie('isThemeGradient') ? Number(Utils.getCookie('isThemeGradient')) == 1 ? true : false : false
    return (
        <div className={"upcomming_fixture_heading animate-pulse " + (isThemeGradient ? "bg-radial-1" : "")}>
            <Row className='align-items-center'>
                <Col className={isMobileView ? "col-3" : "col-2"}>
                    <div className='trangle'></div>
                    <p className={"position-relative f-cell " + (isMobileView ? "pl-0" : "pl-4")}>Announcement </p>
                </Col>
                <Col className={isMobileView ? "col-9 pt-1" : "col-10 pt-2"}>
                    <marquee>{admin_agent_msg && admin_agent_msg}</marquee>
                </Col>
            </Row>
        </div>
    )
}

export default GlobalMessage