import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap';
import { Utils, AuthHelp } from 'Helpers';
import { withTranslation } from 'react-i18next';

import { connect } from "react-redux";
import {
  LangModalClose,
} from '../../Redux/Reducers'
import { TitleComponent } from 'Components';


class LanguageSwitchModal extends Component {
  constructor(props) {

    super(props);
    this.state = {
      SelectedLanguage: Utils.getCookie('dreamxbet_lang')
    }
  }
  handleChange = (lang) => {
    this.setState({
      SelectedLanguage: lang
    })

    const { i18n } = this.props;
    i18n.changeLanguage(lang);
    Utils.setCookie('dreamxbet_lang', lang, 365)
    if (AuthHelp.getAuth()) {
      localStorage.setItem('lang_posting', JSON.stringify(true));
    }
    this.props.toggle()
    window.location.reload(false);
  }


  render() {

    const { SelectedLanguage } = this.state;
    return (
      <Container fluid>
        <TitleComponent title={"Language"}/>
        <Row>
          <Col>
            <ul className="mx-auto">
              <li className={`lang-btn ${SelectedLanguage === 'en' ? 'active' : ''}`} onClick={() => this.handleChange('en')}><span>English</span></li>
              <li className={`lang-btn ${SelectedLanguage === 'hi' ? 'active' : ''}`} onClick={() => this.handleChange('hi')}><span>हिंदी</span></li>
              <li className={`lang-btn ${SelectedLanguage === 'ta' ? 'active' : ''}`} onClick={() => this.handleChange('ta')}><span>తెలుగు</span></li>
              <li className={`lang-btn ${SelectedLanguage === 'mr' ? 'active' : ''}`} onClick={() => this.handleChange('mr')}><span>मराठी</span></li>
            </ul>
          </Col>
        </Row>
      </Container>
    )
  }
}

const LanguageSwitchTrans = withTranslation()(LanguageSwitchModal)
function mapStateToProps(state) {
  const { app } = state;
  return {
    isOpen: app.LangModalShow
  };
}

const mapDispatchToProps = dispatch => ({
  toggle: () => dispatch(LangModalClose()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSwitchTrans);
