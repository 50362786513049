import React, { Component, Fragment } from 'react';
import { _ } from 'Helpers';
import { withTranslation } from 'react-i18next';
import { Row, Col } from "reactstrap";


class Scoreboard extends Component {
  render() {
    const {
      ScoresData,
      EventDetails,
      sports_id,
      MarketList,
      // is_sportsbook,
      t 
    } = this.props
    const BlankBanner = () => ''
    // const BlankBanner = () => <div className="odds-banner" />
    // function NameAbbr(str) {
    //   var matches = str.match(/\b(\w)/g);
    //   return matches.join('')
    // }
    const CricketCell = ({ data, type }) => {
      data = JSON.parse(data)[type]
      let home = 'HOME';
      let away = 'AWAY';
      _.map(MarketList, (item, key) => {
        if (Number(item.is_main_market) === 1)
        {
          let i = 0;
          _.map(JSON.parse(item.runners), (runner, key) => {
            if (i === 0)
              home = runner.runnerName;
            if (i === 1)
              away = runner.runnerName;
            
            i++;
          })
        }
      })
      return (
        <Fragment>
            {
              type === 'home' ?
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 team_name text-center">
                    <strong>
                      {
                        (data.name.toLowerCase() == 'home') && (home.toLowerCase() != 'home') ? (
                          <Fragment>
                            {home}
                          </Fragment>
                        ) : (
                          data.name
                        )
                      }
                    </strong>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 team_name text-center">
                    {!_.isUndefined(data.inning1) ? `${data.inning1.runs}/${data.inning1.wickets}` : '0/0'}
                    {!_.isUndefined(data.inning1) ? `  (${data.inning1.overs} Ov)` : '  (0.0 Ov)'}
                    {!_.isUndefined(data.inning2) && <br/>}
                    {!_.isUndefined(data.inning2) ? (data.inning2.runs && !_.isUndefined(data.inning2.runs) ? `${data.inning2.runs}/${data.inning2.wickets}` : ' 0/0') : ''}
                    {!_.isUndefined(data.inning2) ? (data.inning2.overs && !_.isUndefined(data.inning2.overs) ? `  (${data.inning2.overs} Ov)` : '  (0.0 Ov)') : ''}
                  </div>
                </div>
                :
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 team_name text-center">
                    <strong className="mr-2">                      
                      {
                        (data.name.toLowerCase() == 'away') && (away.toLowerCase() != 'away') ? (
                          <Fragment>
                            {away}
                          </Fragment>
                        ) : (
                          data.name
                        )
                      }
                    </strong>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 team_name text-center">
                    {!_.isUndefined(data.inning1) ? `${data.inning1.runs}/${data.inning1.wickets}` : '0/0'}
                    {!_.isUndefined(data.inning1) ? `  (${data.inning1.overs} Ov)` : '  (0.0 Ov)'}
                    {data.inning2 && !_.isUndefined(data.inning2) && <br/>}
                    {data.inning2 && !_.isUndefined(data.inning2) ? (data.inning2.runs && !_.isUndefined(data.inning2.runs) ? `${data.inning2.runs}/${data.inning2.wickets}` : ' 0/0') : ''}
                    {data.inning2 && !_.isUndefined(data.inning2) ? (data.inning2.overs && !_.isUndefined(data.inning2.overs) ? `  (${data.inning2.overs} Ov)` : '  (0.0 Ov)') : ''}
                  </div>
                </div>

            }
        </Fragment>
      )
    }

    const SoccerCell = ({ data, type }) => {
      data = JSON.parse(data)[type]
      let home = 'HOME';
      let away = 'AWAY';
      _.map(MarketList, (item, key) => {
        if (Number(item.is_main_market) === 1)
        {
          let i = 0;
          _.map(JSON.parse(item.runners), (runner, key) => {
            if (i === 0)
              home = runner.runnerName;
            if (i === 1)
              away = runner.runnerName;
            
            i++;
          })
        }
      })
      return (
        <Fragment>
          {
            type === 'home' ?
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 team_name text-center">
                  <strong>
                    {
                      (data.name.toLowerCase() == 'home') && (home.toLowerCase() != 'home') ? (
                        <Fragment>
                          {home}
                        </Fragment>
                      ) : (
                        data.name
                      )
                    }
                  </strong>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 team_name text-center">
                  {data.score}
                </div>
              </div>
              :
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 team_name text-center">
                  <strong className="mr-2">                      
                    {
                      (data.name.toLowerCase() == 'away') && (away.toLowerCase() != 'away') ? (
                        <Fragment>
                          {away}
                        </Fragment>
                      ) : (
                        data.name
                      )
                    }
                  </strong>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 team_name text-center">
                  {data.score}
                </div>
              </div>
          }
        </Fragment>
      )
    }

    const TennisCell = ({ data, type }) => {
      data = JSON.parse(data)[type]
      return (
        <div className="row">
          <div className="col-12 team_name text-center">
            <strong className="mr-2">{data.name || 'Player Name'}</strong>
          </div>
        </div>
      )
    }
    const Template = () => {
      if(_.isEmpty(ScoresData)) return ''
      switch (sports_id) {
        case "2":
          return (
            <div className="row">
              <div className="col-5 col-sm-5 col-md-12 col-lg-12">
                <CricketCell data={ScoresData.score} type='home' />
              </div>
              <div className="col-2 col-sm-2 col-md-12 col-lg-12">
                <span className="vs">VS</span>
              </div>
              <div className="col-5 col-sm-5 col-md-12 col-lg-12">
                <CricketCell data={ScoresData.score} type='away' />
              </div>
            </div>
          )
        case "1":
          return (
            <div className="row">
              <div className="col-5 col-sm-5 col-md-12 col-lg-12">
              <SoccerCell data={ScoresData.score} type='home' />
              </div>
              <div className="col-2 col-sm-2 col-md-12 col-lg-12">
                <span className="vs">VS</span>
              </div>
              <div className="col-5 col-sm-5 col-md-12 col-lg-12">
              <SoccerCell data={ScoresData.score} type='away' />
              </div>
            </div>
          )
        case "3":
          return (
            <div className="row">
              <div className="col-5 col-sm-5 col-md-12 col-lg-12">
              <TennisCell data={ScoresData.score} type='home' />
              </div>
              <div className="col-2 col-sm-2 col-md-12 col-lg-12">
                <span className="vs">VS</span>
              </div>
              <div className="col-5 col-sm-5 col-md-12 col-lg-12">
              <TennisCell data={ScoresData.score} type='away' />
              </div>
            </div>
          )
        default:
          return '';
      }
    }

    return (
      !_.isEmpty(ScoresData) ?
          <Template />
        :
        <BlankBanner />
    )
  }
}
Scoreboard.defaultProps = {
  ScoresData: [],
  EventDetails: {},
  sports_id: '',
  MarketList: [],
  is_sportsbook: false
}
export default withTranslation()(Scoreboard);
