import React from "react";
import Loadable from "react-loadable";
import LoadingView from "Views/LoadingView";

function Loading(props) {
  if (props.error) {
    return (
      <div>
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    );
  } else if (props.timedOut) {
    return (
      <div>
        Taking a long time... <button onClick={props.retry}>Retry</button>
      </div>
    );
  } else if (props.pastDelay) {
    return <LoadingView />;
  } else {
    return null;
  }
}

/* 
  COMMON ROUTE 
****************/
const CommonRoute = [
  {
    path: "/offers",
    name: "CommonOffers",
    exact: true,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/CommonOffers"),
      loading: Loading,
    }),
  },
  {
    path: "/responsible-gambling",
    name: "CommonResponsibleGambling",
    exact: true,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/CommonResponsibleGambling"),
      loading: Loading,
    }),
  },
  {
    path: "/terms-and-conditions",
    name: "CommonTermsCondition",
    exact: true,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/CommonTermsCondition"),
      loading: Loading,
    }),
  },
];

/* 
  USER ROUTE 
****************/
const UserRoute = [
  {
    path: "casino_supernowa",
    name: "Casino Supernowa",
    exact: false,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/CasinoSupernowa"),
      loading: Loading,
    }),
  },
  {
    path: "ledgers",
    name: "Ledgers",
    exact: false,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/Ledgers"),
      loading: Loading,
    }),
  },
  {
    path: "ledgers-details",
    name: "Ledgers Details",
    exact: false,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/LedgersDetails"),
      loading: Loading,
    }),
  },
  {
    path: "exchange",
    name: "ExchangeLayout",
    exact: false,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/ExchangeLayout"),
      loading: Loading,
    }),
  },
  {
    path: "upcoming",
    name: "UpcomingLayout",
    exact: false,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/UpcomingLayout"),
      loading: Loading,
    }),
  },
  {
    path: "login",
    name: "Login",
    exact: true,
    isAuthenticate: false,
    component: Loadable({
      loader: () => import("./Views/Login"),
      loading: Loading,
    }),
  },
  {
    path: "sportsbook",
    name: "SportsbookLayout",
    exact: false,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/SportsbookLayout"),
      loading: Loading,
    }),
  },
  {
    path: "home",
    name: "Home",
    exact: false,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/Home"),
      loading: Loading,
    }),
  },
  {
    path: "rules",
    name: "Rules",
    exact: false,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/Rules"),
      loading: Loading,
    }),
  },
  {
    path: "casino",
    name: "Casino",
    exact: true,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/Casino"),
      loading: Loading,
    }),
  },
  {
    path: "guidelines",
    name: "Guidelines",
    exact: true,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Guidelines"),
      loading: Loading,
    }),
  },
  {
    path: "cup_winner",
    name: "Cup Winner",
    exact: false,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/WinnerLayout"),
      loading: Loading,
    }),
  },
  {
    path: "bet-history",
    name: "BetHistory",
    exact: true,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/BetHistory"),
      loading: Loading,
    }),
  },
  {
    path: "profit-loss",
    name: "ProfitLoss",
    exact: true,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/ProfitLoss"),
      loading: Loading,
    }),
  },
  {
    path: "account-statement",
    name: "AccountStatement",
    exact: true,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/AccountStatement"),
      loading: Loading,
    }),
  },
  {
    path: "change-password",
    name: "ChangePassword",
    exact: true,
    component: Loadable({
      loader: () => import("./Views/ChangePassword"),
      loading: Loading,
    }),
  },
  {
    path: "offers",
    name: "CommonOffers",
    exact: true,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/CommonOffers"),
      loading: Loading,
    }),
  },
  {
    path: "responsible-gambling",
    name: "CommonResponsibleGambling",
    exact: true,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/CommonResponsibleGambling"),
      loading: Loading,
    }),
  },
  {
    path: "terms-and-conditions",
    name: "CommonTermsCondition",
    exact: true,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/CommonTermsCondition"),
      loading: Loading,
    }),
  },
  {
    path: "notification",
    name: "Notification",
    exact: true,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/Notification"),
      loading: Loading,
    }),
  },
  {
    path: "signup",
    name: "Signup",
    exact: true,
    component: Loadable({
      loader: () => import("./Views/Signup"),
      loading: Loading,
    }),
  },
  {
    path: "deposit",
    name: "Deposit",
    exact: true,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/Deposit"),
      loading: Loading,
    }),
  },
  {
    path: "games",
    name: "Games",
    exact: false,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/Games"),
      loading: Loading,
    }),
  },
  {
    path: "withdraw",
    name: "Withdraw",
    exact: true,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/Withdraw"),
      loading: Loading,
    }),
  },
  {
    path: "account-information",
    name: "AccountInformation",
    exact: true,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/AccountInformation"),
      loading: Loading,
    }),
  },
  {
    path: "odd_settings",
    name: "Odd Settings",
    exact: true,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/OddSettings"),
      loading: Loading,
    }),
  },
  {
    path: "launguage",
    name: "Launguage",
    exact: true,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/Launguage"),
      loading: Loading,
    }),
  },

  {
    path: "poc/exchange/:event_id",
    name: "Poc",
    exact: true,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/Poc"),
      loading: Loading,
    }),
  },
  {
    path: "poc/sportsbook/:event_id",
    name: "Bet365Poc",
    exact: true,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/Bet365Poc"),
      loading: Loading,
    }),
  },
  {
    path: "settings",
    name: "Settings",
    exact: true,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/Settings"),
      loading: Loading,
    }),
  },
  {
    path: "othersettings",
    name: "Other Settings",
    exact: true,
    isAuthenticate: true,
    component: Loadable({
      loader: () => import("./Views/OtherSettings"),
      loading: Loading,
    }),
  },
  {
    path: "workinprogress",
    name: "Work In Progress",
    exact: true,
    isAuthenticate: false,
    component: Loadable({
      loader: () => import("./Views/WorkInProgress"),
      loading: Loading,
    }),
  },
  {
    path: "comingsoon",
    name: "Comimg Soon",
    exact: true,
    isAuthenticate: false,
    component: Loadable({
      loader: () => import("./Views/ComingSoon"),
      loading: Loading,
    }),
  },
];
const DefaultUserRoute = {
  path: "home",
};
const UserExchangeRoute = [
  {
    path: "/",
    name: "ExchangeHome",
    exact: false,
    isAuthenticate: true,
    page_id: "exchange_betting",
    component: Loadable({
      loader: () => import("./Views/ExchangeHome"),
      loading: Loading,
    }),
  },
  ...CommonRoute,
];
const UserSportsbookRoute = [
  {
    path: "/",
    name: "SportsbookHome",
    exact: false,
    isAuthenticate: true,
    page_id: "sportbook_betting",
    component: Loadable({
      loader: () => import("./Views/SportsbookHome"),
      loading: Loading,
    }),
  },
  ...CommonRoute,
];

export default {
  UserRoute,
  DefaultUserRoute,
  UserExchangeRoute,
  UserSportsbookRoute,
};
