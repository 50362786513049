import React, { Component, Fragment } from 'react';
import { MessageBox, BetItem, TitleComponent } from 'Components';
import { Utils, _ } from 'Helpers';
import { CONSTANT } from 'Constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Collapse } from 'reactstrap';


import { withTranslation } from 'react-i18next';

class FixtureListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_betslip_drawer: true,
      CollapseItem: [],
      eventName: "Event Name",
      isThemeGradient: Utils.getCookie('isThemeGradient') ? Number(Utils.getCookie('isThemeGradient')) == 1 ? true : false : false,
    }
  }

  updateDimensions = () => {
    this.setState({
      is_betslip_drawer: window.innerWidth < 850
    });
  }

  componentDidMount() {
    this.props.InitListToggle(true);
    window.addEventListener("resize", this.updateDimensions);
    setTimeout(() => {
      _.forEach(this.props.NavigationList, item => {
        this.setState({
          CollapseItem: [...this.state.CollapseItem, item.sports_id]
        })
        if (item.sports_id === this.props.SelectedSports.sports_id) {
          this.setState({ eventName: item.sports_name });
        }
      })
    }, 100)
  }

  componentWillUnmount() {
    this.props.InitListToggle(false);
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentWillMount() {
    this.updateDimensions();
  }

  toggleCollapse = (sports_id) => {
    const { CollapseItem } = this.state
    if (_.includes(CollapseItem, sports_id)) {
      _.remove(CollapseItem, (o) => o === sports_id)
    } else {
      CollapseItem.push(sports_id)
    }
    this.setState({ CollapseItem });
  }

  fixtureSelectHandler = (item = {}) => {
    const { match } = this.props
    const { is_inplay, SelectedLeague, SelectedSports, NavigationList } = this.state

    if (item && !_.isEmpty(item) && NavigationList && !_.isEmpty(NavigationList)) {
      if (_.isEmpty(SelectedLeague) && _.isEmpty(SelectedSports)) {
        _.forEach(NavigationList, navitem => {
          if (Number(navitem.sports_id) === Number(item.sports_id)) {
            this.setState({
              SelectedSports: navitem
            }, () => {
              this.props.history.push({
                pathname: is_inplay ? `${match.url}/in-play/details/${item.event_id}` : `${match.url}/details/${item.event_id}`,
                search: `?sports=${item.sports_id}&market=${item.market_event_id}`,
                sports_id: item.sports_id,
              })
            })
          }
        })
      }
      else {
        this.props.history.push({
          pathname: is_inplay ? `${match.url}/in-play/details/${item.event_id}` : `${match.url}/details/${item.event_id}`,
          search: `?sports=${item.sports_id}&market=${item.market_event_id}`,
          sports_id: item.sports_id,
        })
      }
    }
    else {
      this.props.history.push({
        pathname: is_inplay ? `${match.url}/in-play/details/${item.event_id}` : `${match.url}/details/${item.event_id}`,
        search: `?sports=${item.sports_id}&market=${item.market_event_id}`,
        sports_id: item.sports_id,
      })
    }
  }

  render() {
    const {
      NavigationList,
      SelectedSports,
      hasMore,
      FixtureList,
      FixturePosting,
      FixtureListBlank,
      page_id,
      InitList,
      is_inplay,
      handleSelectSport,
      fetchMoreData,
      t
    } = this.props;
    const { CollapseItem, eventName, is_betslip_drawer, isThemeGradient } = this.state
    const BetItemProps = {
      page_id,
      NavigationList,
      showSportName: false,
      fixtureSelectHandler: this.fixtureSelectHandler,
      SelectedSports: SelectedSports
    }


    return (
      <Fragment>
        <TitleComponent title={eventName} />
        <div className="col-12 col-sm-12 col-md-10 col-lg-7 mx-auto mobile-sports-list">
          {
            !is_inplay &&
            <div className={(is_betslip_drawer ? "mobile-menu-option" : "odds-padding-row mb-2")}>
              <div className={(is_betslip_drawer ? "mobile-odds-filter-pills" : "odds-filter-pills")}>
                {
                  <Fragment>
                    {/* {(window.location.pathname !== '/exchange' && window.location.pathname !== '/upcoming') && <span onClick={() => handleSelectSport({ Sport: {}, League: {}, unbind: true })} className={_.isEmpty(SelectedSports) ? (window.location.pathname !== '/cup_winner' && "active") : ""}>All</span>} */}
                    <Fragment>

                      {
                        _.map(NavigationList, (item, key) => {
                          return (
                            is_betslip_drawer ? (
                              Number(item.sports_id) !== 6 &&
                              <span onClick={() => handleSelectSport({ Sport: item, League: {}, unbind: true })} {...{ key, className: (_.isEmpty(SelectedSports) ? ((Number(item.sports_id) == 2) ? `active ${isThemeGradient && is_betslip_drawer ? " bg-gradient-2 " : ""}` : "") : item.sports_id === SelectedSports.sports_id ? `active ${isThemeGradient && is_betslip_drawer ? " bg-gradient-2 " : ""}` : '') + (isThemeGradient && !is_betslip_drawer ? " bg-gradient-2 " : "") }}>  {item.sports_name}</span>
                            ) : (
                              <Fragment {...{ key }}>
                                {window.location.pathname == '/cup_winner' && Number(item.sports_id) == 2 ?
                                  <span onClick={() => handleSelectSport({ Sport: item, League: {}, unbind: true })} {...{ key, className: (_.isEmpty(SelectedSports) ? ((Number(item.sports_id) == 2) ? "active" : "") : item.sports_id === SelectedSports.sports_id ? 'active' : '') + (isThemeGradient && !is_betslip_drawer ? " bg-gradient-2 " : "") }}>  {item.sports_name}</span>
                                  :
                                  <span onClick={() => handleSelectSport({ Sport: item, League: {}, unbind: true })} {...{ key, className: (_.isEmpty(SelectedSports) ? ((Number(item.sports_id) == 2) ? "active" : "") : item.sports_id === SelectedSports.sports_id ? 'active' : '') + (isThemeGradient && !is_betslip_drawer ? " bg-gradient-2 " : "") }}>  {item.sports_name}</span>
                                }
                              </Fragment>

                            )
                          )
                        })
                      }

                    </Fragment>
                  </Fragment>}

                {/* {!is_betslip_drawer && <span onClick={() =>
                  this.props.history.push({
                    pathname: "/cup_winner",
                  })
                } className={window.location.pathname == '/cup_winner' ? 'active' : ''}>{'Cup Winner'}</span>} */}
              </div>
            </div>
          }
          {
            InitList ?
              <Fragment>
                {
                  is_inplay ?
                    <div className='game-event-list m-t-20'>
                      {
                        (FixturePosting && _.isEmpty(FixtureList)) ?
                          <Utils.BoxLoader />
                          :
                          <Fragment>
                            {
                              _.map(NavigationList, (sport) => {
                                return (
                                  <Fragment key={sport.sports_id}>
                                    <h4 className='sport-title' onClick={() => this.toggleCollapse(sport.sports_id)}>
                                      {sport.sports_name}
                                      {
                                        _.includes(CollapseItem, sport.sports_id) ?
                                          <i className="icon-minus-border" />
                                          :
                                          <i className="icon-plus-border" />
                                      }
                                    </h4>
                                    <Collapse className='sport-title-collapsing' isOpen={_.includes(CollapseItem, sport.sports_id)}>
                                      {
                                        _.filter(FixtureList, function (o) { return o.sports_id === sport.sports_id; }).length > 0 &&
                                        <div className="game-event-item heading">
                                          <div className="event-details">{t('Match')}</div>
                                          <div className="cell">
                                            <div className="row-cell">
                                              <div {...{ className: `market-label` }}>1</div>
                                              {
                                                SelectedSports.sports_id !== 3 &&
                                                <div {...{ className: `market-label` }}>X</div>
                                              }
                                              <div {...{ className: `market-label` }}>2</div>
                                            </div>
                                          </div>
                                          <div className="last-cell p-0" />
                                        </div>
                                      }
                                      {
                                        _.map(_.filter(FixtureList, function (o) { return o.sports_id === sport.sports_id; }), (item, key) => {
                                          return (
                                            <BetItem {...{ key, item, ...BetItemProps }} />
                                          )
                                        })
                                      }
                                      {
                                        _.filter(FixtureList, function (o) { return o.sports_id === sport.sports_id; }).length === 0 &&
                                        <MessageBox size='md' heading={t(sport.sports_name) + ' ' + t(CONSTANT.LIVEGAME_BLANK_HEADING)} paragraph={t(CONSTANT.LIVEGAME_BLANK_PARAGRAPH)} />
                                      }
                                    </Collapse>
                                  </Fragment>
                                )
                              })
                            }
                          </Fragment>
                      }
                    </div>
                    :
                    <InfiniteScroll
                      dataLength={FixtureList.length}
                      next={fetchMoreData}
                      hasMore={hasMore}
                      loader={<Utils.FlexboxLoader className='show m-2 no-margin-h' />}
                      className={`game-event-list ${is_inplay ? 'm-t-20' : ''}`}
                    >
                      {
                        (FixturePosting && _.isEmpty(FixtureList)) &&
                        <Utils.BoxLoader />
                      }
                      {window.location.pathname == '/cup_winner' ?

                        <Fragment>
                          {
                            _.map(FixtureList, (item, key) => {

                              return (
                                <BetItem {...{ key, item, ...BetItemProps }} />
                              )
                            })
                          }
                          {/* {FixtureList.length == 0 &&
                            < MessageBox heading={t(CONSTANT.FIXTURE_BLANK_HEADING)} paragraph={t(CONSTANT.FIXTURE_BLANK_PARAGRAPH)} />
                          } */}
                        </Fragment>
                        :
                        <Fragment>
                          {_.map(NavigationList, (sport, index) => {
                            let event_exist = false;
                            return (
                              <Fragment key={index}>
                                {(window.location.pathname !== '/exchange' && window.location.pathname !== '/upcoming' && window.location.pathname !== '/cup_winner') &&
                                  <Fragment>
                                    {
                                      _.isUndefined(SelectedSports.sports_id) && sport.sports_id != 6 &&
                                      <div className="sports_name">
                                        <hr />
                                        <span>{sport.sports_name}</span>
                                        <hr />
                                      </div>
                                    }
                                  </Fragment>
                                }
                                {
                                  _.map(FixtureList, (item, key) => {
                                    if (sport.sports_id == item.sports_id)
                                      event_exist = true;
                                    return (
                                      sport.sports_id == item.sports_id &&
                                      <BetItem {...{ key, item, ...BetItemProps }} />
                                    )
                                  })
                                }
                                {(window.location.pathname !== '/exchange' && window.location.pathname !== '/upcoming' && window.location.pathname !== '/cup_winner') &&
                                  <Fragment>
                                    {
                                      !event_exist && _.isUndefined(SelectedSports.sports_id) && sport.sports_id != 6 &&
                                      <MessageBox heading={t(CONSTANT.FIXTURE_BLANK_HEADING)} paragraph={t(CONSTANT.FIXTURE_BLANK_PARAGRAPH)} />
                                    }
                                  </Fragment>}
                              </Fragment>
                            )
                          })}
                        </Fragment>


                      }
                      {
                        FixtureListBlank && SelectedSports.sports_id != 6 &&
                        <MessageBox heading={t(CONSTANT.FIXTURE_BLANK_HEADING)} paragraph={t(CONSTANT.FIXTURE_BLANK_PARAGRAPH)} />
                      }
                      {
                        SelectedSports.sports_id == 6 &&
                        <MessageBox heading={t(CONSTANT.COMING_SOON)} paragraph={t(CONSTANT.FIXTURE_BLANK_PARAGRAPH)} />
                      }
                    </InfiniteScroll>
                }

              </Fragment>
              :
              <Utils.BoxLoader />
          }
        </div>
      </Fragment >

    )
  }
}
FixtureListComponent.defaultProps = {
  status: '0' // 0 = Upcoming, 1 = Live
}
export default withTranslation()(FixtureListComponent);
