import React, { Fragment } from 'react'
import { Row, Col } from 'reactstrap'
import { _, Utils } from 'Helpers'

const MatchTitle = ({ isMobileView, EventDetails, goBackBtn }) => {
    let isThemeGradient = Utils.getCookie('isThemeGradient') ? Number(Utils.getCookie('isThemeGradient')) == 1 ? true : false : false

    return (

        <Fragment>
            <div className={'match_title ' + (isMobileView ? "my-2" : "mt-4 mb-2")}>
                <Row className={"align-items-center " + (isThemeGradient ? " bg-gradient-3" : "")}>
                    <Col className={" day " + (isMobileView ? "col-4" : "col-3")}>
                        <Row className='flex-nowrap'>
                            <i className='col-1 icon-previous mr-1 float-left pointer' onClick={goBackBtn}></i>
                            <div className='status-container col-auto'>
                                <span className='status text-uppercase'>
                                    {/* <i className='icon-previous mr-1 float-left pointer ' onClick={goBackBtn}></i> */}
                                    {Number(EventDetails.is_live) === 1 ? " Live"
                                        : Utils.checkedGameDateISTodayDate(EventDetails.open_date) ? " Today "
                                            : Utils.checkedGameDateISTomorrowDate(EventDetails.open_date) ? "Tommorow"
                                                : Utils.checkedGameDateISPreviousDate(EventDetails.open_date) ? "Live"
                                                    : Utils.dateTimeFormat(EventDetails.open_date, 'DD MMM')

                                    }


                                </span>
                                <span className='time'>{Utils.dateTimeFormat(EventDetails.open_date, 'hh:mm A')}</span>
                            </div>
                        </Row>
                    </Col>
                    <Col className={"series text-uppercase " + (isMobileView ? "col-5" : "col-6")}>
                        <span className='team_name text-truncate'>
                            {EventDetails.event_name}
                        </span>
                        <span className='league_name'>
                            {EventDetails.league_name}
                        </span>
                    </Col>
                    <Col className='col-3'>
                    </Col>
                </Row>
            </div>

            {
                EventDetails && !_.isEmpty(EventDetails) && EventDetails.announcement && !_.isEmpty(EventDetails.announcement) &&
                <div className={"pt-3 my-2 px-0 announcement animate-pulse " + (isThemeGradient ? "bg-gradient-4" : "")}>
                    <marquee scrollamount="3">{EventDetails.announcement}</marquee>
                </div>
            }


        </Fragment>
    )
}

export default MatchTitle