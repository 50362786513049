import React, { Component } from 'react';

class MessageBox extends Component {
  render() {
    const { heading, paragraph, size } = this.props;
    return (
      <div {...{ className: `message-box ${size}` }}>
        {
          heading &&
          <div className={(heading === "Coming soon...") ? "heading coming_soon" : "heading"}>{heading}</div>
        }
        {
          paragraph &&
          <div className="paragraph">{paragraph}</div>
        }
      </div>
    );
  }
}
MessageBox.defaultProps = {
  heading: '',
  paragraph: '',
  size: 'sm'
}
export default MessageBox;
