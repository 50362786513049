import React from 'react';
import Countdown from 'react-countdown-now';
export default class Clock extends React.Component {
    render() {
        const { refCallback, defaultSecond, defaultSecondShow } = this.props;

        return (
            <Countdown
                // When the component mounts, this will 
                // call `refCallback` in the parent component,
                // passing a reference to this `Countdown` component
                ref={refCallback} 
                date={Date.now() + defaultSecond}
                renderer={props => <span className={defaultSecondShow ? "float-right clock-timer" : "float-right clock-timer d-none"}>{props.minutes}:{props.seconds}</span>}
                onComplete={()=>{this.props.parentMethod()}}
            />
        );
    }
}