import React, { Component, Fragment } from 'react';
import Drawer from 'react-motion-drawer';
import { withTranslation } from 'react-i18next';
import { BetslipOpenBet, MobileBetslip, } from 'Components';
import { DeclaredSessionModal } from 'Modals';
import { Button, Col, Collapse, Table, Row } from 'reactstrap';
import Sticky from 'react-stickynode';
import { SocketEvent } from 'Constants'
import { Socket } from 'Helpers'
import { connect } from "react-redux";
import { Auth } from 'Service';
import { OpenBet } from '../../Redux/Reducers'
import { API, Http, Utils, _ } from 'Helpers';

class BetslipDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      DrawerBetslipOpen: false,
      is_drawer_active: false,
      is_betslip_drawer: true,
      isFooterShow: false,
      is_betslip_drawer_mobile: false,
      declaredSession: [],
      DeclaredSessionModalShow: false,
      SelectedSession: {},
      showLiveTV: true,
      tossProfitLossTeamName: '',
      showLiveTV: false,
    }
    this.openBetSlipWindow = this.openBetSlipWindow.bind(this);
    // this.closeBetSlipWindow = this.closeBetSlipWindow(this);
  }

  getdeclaredSession = () => {
    let param = {
      "event_id": window.location.pathname.split("/").pop(),
    }
    this.setState({ posting: true })
    Http.post(API.DECLARED_SESSION, param).then(response => {
      this.setState({
        declaredSession: response.data,
        posting: false,
      })
    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  FooterToggle = () => {
    let LockScreen = 'lock-screen'
    this.setState({
      isFooterShow: !this.state.isFooterShow
    }, () => {
      if (this.state.isFooterShow) {
        document.body.classList.add(LockScreen);
      } else {
        document.body.classList.remove(LockScreen);
      }
    })
  }


  updateDimensions = () => {
    this.setState({
      is_drawer_active: window.innerWidth < 850,
      is_betslip_drawer: window.innerWidth > 767.98
    });
  }
  updatePlaceholder = () => {
    if (this.divRef) {
      this.setState({
        placeholderHeight: this.divRef.clientHeight
      });
    }
  }

  openBetSlipWindow() {
    this.setState({ is_betslip_drawer_mobile: true })
  }

  closeBetSlipWindow = () => {
    this.setState({ is_betslip_drawer_mobile: false })
  }

  // recal declares seeion api
  recallDecAPI = (res = {}) => {
    if (!_.isEmpty(res)) {
      if (window.location.pathname.split("/").pop() == res.event_id) {
        this.getdeclaredSession()
      }
    }
  }

  componentDidMount() {
    const _this = this;
    this.props.onRef(this);
    this.getdeclaredSession();
    window.addEventListener("resize", this.updateDimensions);
    window.addEventListener("scroll", this.updatePlaceholder);
    Socket.on(SocketEvent.RESULTFANCY, function (res) {
      _this.recallDecAPI(res)
    })
    Socket.on(SocketEvent.MARKET_SETTLE, function (res) {
      if (!_.isEmpty(res))
        _this.recallDecAPI(res)
    })
  }

  componentWillMount() {
    this.updateDimensions();
    this.updatePlaceholder();
  }

  componentWillUnmount() {
    Socket.off(SocketEvent.RESULTFANCY);
    Socket.off(SocketEvent.MARKET_SETTLE);
    this.props.onRef(undefined)
    window.removeEventListener("resize", this.updateDimensions);
    window.removeEventListener("scroll", this.updatePlaceholder);
    this.setState = () => {
      return;
    };
  }

  refreshBetSlipOpenBet = () => {
    this.betslipOpenBet.getOpenBet();
    this.closeBetSlipWindow();
  }

  modalHide = () => {
    this.setState({
      DeclaredSessionModalShow: false,
    })
  }
  modalShow = (session) => {
    this.setState({
      SelectedSession: session,
      DeclaredSessionModalShow: true
    })
  }

  render() {
    const { openBetslipDrawer, OpenBet, t, streamingUrl } = this.props
    const currentUser = Auth.currentUserValue;
    let {
      is_drawer_active,
      is_betslip_drawer,
      placeholderHeight,
      is_betslip_drawer_mobile,
      declaredSession,
      DeclaredSessionModalShow,
      SelectedSession
    } = this.state

    const RosterDrawerProps = {
      ...this.props,
      overlayColor: "rgba(0,0,0,0.8)",
      width: 320,
      right: true,
      fadeOut: true,
      open: openBetslipDrawer,
      noTouchOpen: true,
      zIndex: 1030
    };

    const newBetSlipProps = {
      closeBetSlipWindow: this.closeBetSlipWindow
    }

    const DeclaredSessionModalProps = {
      isOpen: DeclaredSessionModalShow,
      SelectedSession: SelectedSession,
      toggle: this.modalHide,
    }

    return (
      <Fragment>
        {is_drawer_active ?
          <Fragment>
            {
              is_betslip_drawer &&
              <div className="betslip-drawer-toggle" onClick={() => OpenBet({ type: true, bool: false })}>{t('Bet Slip')}</div>
            }
            {
              is_betslip_drawer_mobile &&
              <Fragment>
                <div className="sticky-betslip-modal-backdrop" onClick={this.closeBetSlipWindow}></div>
                <div className="sticky-betslip" ref={element => this.divRef = element}>
                  <MobileBetslip {...this.props.BetslipProps}  {...newBetSlipProps} parentMethod={this.refreshBetSlipOpenBet} closeBetSlipWindow={this.closeBetSlipWindow} />
                </div>
                {
                  this.divRef &&
                  <div className="sticky-betslip-placeholder" style={{ height: placeholderHeight }} />
                }
              </Fragment>
            }

            <Drawer
              className='betslip-drawer'
              {...RosterDrawerProps}
              onChange={open => OpenBet({ type: open, bool: false })}
            >
              {
                is_betslip_drawer &&
                <MobileBetslip {...this.props.BetslipProps}  {...newBetSlipProps} parentMethod={this.refreshBetSlipOpenBet} />
              }
              {
                currentUser &&
                <BetslipOpenBet is_betslip_drawer={is_betslip_drawer} onRef={(ref) => (this.betslipOpenBet = ref)} />
              }
            </Drawer>
          </Fragment>
          :
          <Fragment>
            <Col className='betslip-col px-0' id='BetslipCol'>

              {
                streamingUrl && !_.isEmpty(streamingUrl) &&
                <div className="betslip-wrap betslip-open-bet only-single-bet">
                  <div className="betslip-container">
                    <div className="betslip-header">
                      Live TV
                      <i className={this.state.showLiveTV ? "icon-up float-right" : "icon-down float-right"} onClick={() => this.setState({ showLiveTV: !this.state.showLiveTV })}></i>
                    </div>
                    <div className="betslip-body live_tv">
                      <Collapse isOpen={this.state.showLiveTV}>
                        {
                          this.state.showLiveTV &&
                          <iframe src={streamingUrl} className="w-100"></iframe>
                        }
                      </Collapse>
                    </div>
                  </div>
                </div>
              }
              {
                currentUser &&
                <BetslipOpenBet is_betslip_drawer={is_betslip_drawer} onRef={(ref) => (this.betslipOpenBet = ref)} />
              }
              {/* <Table className="table-striped declared_session mt-2">
             <thead>
               <tr>
                 <th colSpan="5">DECLARED SESSIONS</th>
               </tr>
               <tr>
                 <th className="sr_no">NO.</th>
                 <th className="session_name">SESSION</th>
                 <th className="runs">SETTLED</th>
                 <th className="status">STATUS</th>
                 <th className="action">ACTION</th>
               </tr>
             </thead>
             {
               !_.isEmpty(declaredSession) &&
               <tbody>
                 {
                   _.map(declaredSession, (session, index) => {

                     return (
                       <tr key={index}>
                         <td className="sr_no">{index + 1}</td>
                         <td className="session_name">{session.market_name}</td>
                         <td>{session.market_name && !_.isEmpty(session.market_name) && (session.market_name.toLowerCase() === "to win the toss") ? (_.map(JSON.parse(session.market_runners), (runner) => {
                           if (runner.selectionId == session.selection_id)
                             return (
                               runner.runnerName
                             )

                         })) : ""}</td>
                         <td className="status">
                           <span className={(session.profit_loss != null) ? (Number(session.profit_loss) < 0 ? "loss" : "profit") : ""}>{(session.profit_loss != null) ? Utils.TwoDecimalVal(session.profit_loss) : ""}</span>
                         </td>
                         <td className="action">
                           <Button color="primary" size='sm' onClick={() => { this.modalShow(session) }}>Details</Button>
                         </td>
                       </tr >
                     )
                   })
                 }
               </tbody >
             }
           </Table >
          {DeclaredSessionModalShow && <DeclaredSessionModal {...DeclaredSessionModalProps} />} */}
            </Col>
          </Fragment>}
      </Fragment>
    )
  }
}

const BetslipDrawerTrans = withTranslation()(BetslipDrawer)

function mapStateToProps(state) {
  const { app } = state;
  return {
    openCollapse: app.openCollapse,
    openBetslipDrawer: app.openBetslipDrawer,
  };
}

const mapDispatchToProps = dispatch => ({
  OpenBet: (option) => dispatch(OpenBet(option))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BetslipDrawerTrans);
