import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import Store from 'Redux/Store/store.dev';

import { I18nextProvider } from "react-i18next";
import i18n from "./Locales/i18n";



// setup fake backend
// import { FakeBackend } from 'Service';
// FakeBackend();

ReactDOM.render(
  // <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={Store}><App /></Provider>
    </I18nextProvider>
  // </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
