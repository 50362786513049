import React, { Component } from 'react';
import { Utils } from 'Helpers';

class CurrentDate extends Component {
  constructor() {
    super();
    this.state = { time: new Date() }; // initialise the state
  }
  componentDidMount() { // create the interval once component is mounted
    this.update = setInterval(() => {
      this.setState({ time: new Date() });
    }, 1 * 1000); // every 1 seconds
  }
  componentWillUnmount() { // delete the interval just before component is removed
    clearInterval(this.update);
    this.setState = () =>{
      return;
    };
  }
  render() {
    const { time } = this.state; // retrieve the time from state

    const DateValue = () => {
      return (<span>{Utils.dateTimeFormat(time, 'ddd MMM DD YYYY')}</span>)
    }
    const TimeValue = () => {
      return (<span>{Utils.dateTimeFormat(time, 'hh:mm:ss A')}</span>)
    }
    return (
      <div className="header-current-timestamp"><i className="icon-clock" /><div className="overflow"> <DateValue /><i className='sap-i'>{', '}</i><TimeValue /></div></div>
    )
  }
}

export default CurrentDate;
