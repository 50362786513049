/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react';
import { API, Http, Socket, Utils, _ } from 'Helpers';
import { withTranslation } from 'react-i18next';
import validator from "validator";
import { connect } from "react-redux";
import { BetMsgPoPUP } from 'Components';
import { Button, Row, Col, Table, Collapse, CardBody, Card } from "reactstrap";
import { CONSTANT, SocketEvent, Images, } from 'Constants';
import { Scoreboard, TitleComponent, BetslipOpenBet, BetItemSec, BetslipDrawer, MatchScoreBoard, } from 'Components';
import { Auth } from 'Service';
import { ExposureDetailsModal, DeclaredSessionModal, SwitchModal } from 'Modals';
import InfiniteScroll from 'react-infinite-scroll-component';
import ScoreURL3 from './ScoreURL3';
import MarketFixtureDetailList from './MarketFixtureDetailList';
import FancyFixtureDetailList from './FancyFixtureDetailList';
import OtherFancyFixtureDetailList from './OtherFancyFixtureDetailList';
import OtherMarketFixtureDetailList from './OtherMarketFixtureDetailList';
import { actionBetSlipRule } from '../../Redux/FixtureList_Redux/BetsRule/action';


import MatchHeading from './MatchHeading'
import MatchTitle from './MatchTitle';



class FixtureDetailList extends Component {
  constructor(props) {
    super(props);
    const { SelectedSports } = this.props;
    this.state = {
      is_betslip_drawer: true,
      running: false,
      currentTimeMs: 0,
      currentTimeSec: 0,
      currentTimeMin: 0,
      mkt_rooms: [],
      Init: true,
      posting: false,
      FixturePosting: true,
      isFixtureDetailsChanged: false,
      odd_id: "",
      sports_id: this.props.location.sports_id && !_.isEmpty(this.props.location.sports_id) ? this.props.location.sports_id : 2,
      check_event_id: this.props.match.params.event_id,
      Offset: CONSTANT.CURRENT_OFFSET,
      FixtureList: [],
      FixtureListBlank: false,
      hasMore: false,
      fixture_lastupdate: new Date().valueOf(),
      ExposureDetailsModalShow: false,
      SwitchModalShow: false,
      ScoresData: [],
      CentralScore: [],
      FancyData: [],
      OtherFancyData: [],
      MarketList: [],
      EventDetails: {},
      fancyChanged: false,
      recently_changed: [],
      showbetlockmessage: [],
      AppMasterData: Utils.getMasterData(),
      matchScorBoard: [],
      declaredSession: [],
      declaredMarket: [],
      declaredSessionTotal: 0,
      declaredMarketTotal: 0,
      fancyMarketShow: true,
      exchangeMarketShow: [],
      SelectedSports: SelectedSports,
      TooltipGoals: false,
      TooltipRedCard: false,
      TooltipYellowCard: false,
      TooltipCorners: false,
      showScoreData: "away",
      DeclaredSessionModalShow: false,
      showLiveTV: false,
      toggleLiveTV: true,
      toggleScoreBoard: true,
      marketStackMin: 100,
      marketStackMax: 1000000,
      bookMakerStackMin: 100,
      bookMakerStackMax: 1000000,
      fancyMarketStackMin: 100,
      fancyMarketStackMax: 1000000,
      selectedBetslip: '',
      selectedBetslipType: '',
      selectedMarketID: '',
      activeTab: 'score1',
      hdliveScore: true,
      button_threenine: false,
      client_msg: '',
      child_session_type_id: '',


      showDeclaredSession: false,
      showDeclaredMarket: false,
      CombineMarketShowHide: false,
      showGrandTotal: false,
      openBetSlipid: '',
      isThemeGradient: Utils.getCookie('isThemeGradient') ? Number(Utils.getCookie('isThemeGradient')) == 1 ? true : false : false,
      scrollIframe: false,



    }
  }

  openBetSlipMarketId = (id) => {
    this.setState({ openBetSlipid: id })
  }

  MarketRoomEmit = (res) => {
    if (this.state.mkt_rooms.filter(item => { return item == res }).length === 0) {
      let mkt_rooms = this.state.mkt_rooms;
      mkt_rooms.push(res);
      this.setState({ mkt_rooms: mkt_rooms });
      Socket.emit('market_room', res);
    }
  };

  switchmodal = (name, market_event_id, isFor) => {
    this.setState({
      [name]: true,
      market_event_id: market_event_id,
      isFor: isFor,
    })
  }

  callback2 = () => {
    if (this.state.isFor == "one") {
      this.changeMarketstatus(this.state.market_event_id)
    } else if (this.state.isFor == "two") {
      this.changeMarketstatus(this.state.market_event_id)
    } else if (this.state.isFor == "three") {
      this.changeMarketstatus(this.state.market_event_id)
    } else if (this.state.isFor == "four") {
      this.changeMarketstatus(this.state.market_event_id)
    }
    this.switchmodalClose()
  }
  switchmodalClose = () => {
    this.setState({ SwitchModalShow: false })
  }



  setSecTimeout(res) {
    const { AppMasterData, button_threenine } = this.state;
    if (Number(res.commentary_button_id) == 39) {
      let change = AppMasterData;
      this.setState({ button_threenine: true })
      _.map(change.commentary_buttons, (item, index) => {
        if (res.commentary_button_id == item.button_id) {
          setTimeout(() =>
            this.setState({ button_threenine: false })
            , Number(item.bet_close_time) * 1000)
        }
      })
    }
  }
  updateStackMinMax = () => {
    const { EventDetails, AppMasterData } = this.state;
    if (AppMasterData && !_.isEmpty(AppMasterData) && AppMasterData.sports_list && !_.isEmpty(AppMasterData.sports_list) && AppMasterData.sports_list.exchange_betting && !_.isEmpty(AppMasterData.sports_list.exchange_betting) && EventDetails && !_.isEmpty(EventDetails) && EventDetails.sports_id && !_.isEmpty(EventDetails.sports_id)) {
      _.forEach(AppMasterData.sports_list.exchange_betting, item => {
        if (Number(item.sports_id) === Number(EventDetails.sports_id)) {
          if (item.min_odd_limit && !_.isEmpty(item.min_odd_limit))
            this.setState({ marketStackMin: Number(item.min_odd_limit) });
          if (item.max_odd_limit && !_.isEmpty(item.max_odd_limit))
            this.setState({ marketStackMax: Number(item.max_odd_limit) });
          if (item.min_bookmaker_stake && !_.isEmpty(item.min_bookmaker_stake))
            this.setState({ bookMakerStackMin: Number(item.min_bookmaker_stake) });
          if (item.max_bookmaker_stake && !_.isEmpty(item.max_bookmaker_stake))
            this.setState({ bookMakerStackMax: Number(item.max_bookmaker_stake) });
          if (item.min_fancy_stake && !_.isEmpty(item.min_fancy_stake))
            this.setState({ fancyMarketStackMin: Number(item.min_fancy_stake) });
          if (item.max_fancy_stake && !_.isEmpty(item.max_fancy_stake))
            this.setState({ fancyMarketStackMax: Number(item.max_fancy_stake) });
        }
      })
    }
    if (EventDetails && !_.isEmpty(EventDetails)) {
      if (EventDetails.min_odd_limit && !_.isEmpty(EventDetails.min_odd_limit))
        this.setState({ marketStackMin: Number(EventDetails.min_odd_limit) });
      if (EventDetails.max_odd_limit && !_.isEmpty(EventDetails.max_odd_limit))
        this.setState({ marketStackMax: Number(EventDetails.max_odd_limit) });
      if (EventDetails.min_bookmaker_stake && !_.isEmpty(EventDetails.min_bookmaker_stake))
        this.setState({ bookMakerStackMin: Number(EventDetails.min_bookmaker_stake) });
      if (EventDetails.max_bookmaker_stake && !_.isEmpty(EventDetails.max_bookmaker_stake))
        this.setState({ bookMakerStackMax: Number(EventDetails.max_bookmaker_stake) });
      if (EventDetails.min_fancy_stake && !_.isEmpty(EventDetails.min_fancy_stake))
        this.setState({ fancyMarketStackMin: Number(EventDetails.min_fancy_stake) });
      if (EventDetails.max_fancy_stake && !_.isEmpty(EventDetails.max_fancy_stake))
        this.setState({ fancyMarketStackMax: Number(EventDetails.max_fancy_stake) });
    }
  }

  start = () => {
    if (!this.state.running) {
      this.setState({ running: true });
      this.watch = setInterval(() => this.pace(), 10);
    }
  };

  stop = () => {
    this.setState({ running: false });
    clearInterval(this.watch);
  };

  pace = () => {
    this.setState({ currentTimeMs: this.state.currentTimeMs + 10 });
    if (this.state.currentTimeMs >= 1000) {
      this.setState({ currentTimeSec: this.state.currentTimeSec + 1 });
      this.setState({ currentTimeMs: 0 });
    }
    if (this.state.currentTimeSec >= 60) {
      this.setState({ currentTimeMin: this.state.currentTimeMin + 1 });
      this.setState({ currentTimeSec: 0 });
    }
  };

  reset = () => {
    this.setState({
      currentTimeMs: 0,
      currentTimeSec: 0,
      currentTimeMin: 0,
    });
  };

  getdeclaredSession = () => {
    let param = { "event_id": this.props.match.params.event_id }
    this.setState({ posting: true })
    Http.post(API.DECLARED_SESSION, param).then(response => {
      this.setState({ declaredSessionTotal: 0 })
      // _.map(response.data.data, (session) => {
      //   this.setState({ declaredSessionTotal: Number(this.state.declaredSessionTotal) + Number(session.profit_loss) })
      // })
      this.setState({
        declaredSession: response.data.data,
        declaredSessionTotal: response.data.grand_total,
        posting: false,
      })
    }).catch(error => {
      this.setState({ posting: false })
    });
  }
  getdeclaredMarket = () => {
    let param = { "event_id": this.props.match.params.event_id }
    this.setState({ d_Fancyposting: true })
    Http.post(API.DECLARED_MARKET, param).then(response => {
      this.setState({ declaredMarketTotal: 0 })
      // _.map(response.data.data, (session) => {
      //   this.setState({ declaredMarketTotal: Number(this.state.declaredMarketTotal) + Number(session.profit_loss) })
      // })
      this.setState({
        declaredMarket: response.data.data,
        declaredMarketTotal: response.data.grand_total,
        d_Fancyposting: false,
      })
    }).catch(error => {
      this.setState({ d_Fancyposting: false })
    });
  }

  modalHide = () => {
    this.setState({
      ExposureDetailsModalShow: false,
      child_session_type_id: '',
      LDO_fancy: false
    })
  }
  modalShow = (name, odd_id, type, child_session_type_id) => {
    this.setState({
      odd_id: odd_id,
      LDO_fancy: type,
      child_session_type_id: child_session_type_id,
      ExposureDetailsModalShow: true
    })
  }

  declaredSessionmodalHide = () => {
    this.setState({
      DeclaredSessionModalShow: false,
    })
  }
  declaredSessionmodalShow = (session) => {
    this.setState({
      SelectedSession: session,
      DeclaredSessionModalShow: true
    })
  }

  modalUpdate = () => {
    this.setState({
      ExposureDetailsModalShow: false,
    })
  }

  updateDimensions = () => {
    this.setState({
      is_betslip_drawer: window.innerWidth < 850
    });
  }

  fetchMoreData = () => {
    if (!this.state.FixturePosting) {
      let offset = this.state.Offset + CONSTANT.ITEMS_PERPAGE_SM;
      this.setState({ Offset: offset }, () => {
        this.GetFixtures()
      });
    }
  }

  GetScoarBoard = () => {
    let param = { "event_id": this.props.match.params.event_id }

    this.setState({ posting: true })
    Http.post(API.SCOREBOARD, param).then(response => {
      if (!_.isEmpty(response.data) && !_.isEmpty(response.data[0]) && !_.isEmpty(response.data[0].scoreboard)) {
        this.setState({ matchScorBoard: response.data[0].scoreboard })
      }
      else if (!_.isEmpty(response.data) && !_.isEmpty(response.data[0]) && !_.isEmpty(response.data[0].score)) {
        this.setState({ matchScorBoard: response.data[0].score })
      }

      this.setState({ posting: false })
    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  getLiveMatchFootBallScoreBoard = () => {
    let param = { event_id: this.props.match.params.event_id };
    this.setState({ posting: true });
    Http.post(API.GET_SOCCER_MATCH_SCORE, param)
      .then((response) => {
        if (response.data && (response.data.length > 0)) {
          if (response.data[0].match_info) {
            let currentTimeSec = 0;
            let currentTimeMin = 0;
            if (response.data[0].match_info.inning && (Number(response.data[0].match_info.inning) === 1)) {
              if (response.data[0].match_info.first_half) {
                let first_half = response.data[0].match_info.first_half.split(":");
                currentTimeSec = first_half.length > 1 ? Number(first_half[1]) : 0;
                currentTimeMin = first_half.length > 1 ? Number(first_half[0]) : 0;
              }
            }
            else if (response.data[0].match_info.inning && (Number(response.data[0].match_info.inning) === 2)) {
              if (response.data[0].match_info.second_half) {
                let second_half = response.data[0].match_info.second_half.split(":");
                currentTimeSec = second_half.length > 1 ? Number(second_half[1]) : 0;
                currentTimeMin = second_half.length > 1 ? Number(second_half[0]) : 0;
              }
            }
            else if (response.data[0].match_info.inning && (Number(response.data[0].match_info.inning) === 3)) {
              if (response.data[0].match_info.extra_time) {
                let extra_time = response.data[0].match_info.extra_time.split(":");
                currentTimeSec = extra_time.length > 1 ? Number(extra_time[1]) : 0;
                currentTimeMin = extra_time.length > 1 ? Number(extra_time[0]) : 0;
              }
            }
            this.setState({ matchScorBoard: response.data[0].match_info, currentTimeSec: currentTimeSec, currentTimeMin: currentTimeMin }, () => {
              if (response.data[0].match_info.match_status && (response.data[0].match_info.match_status == "start"))
                this.start()
              else if (response.data[0].match_info.match_status && (response.data[0].match_info.match_status == "pause"))
                this.stop()
              else if (response.data[0].match_info.match_status && (response.data[0].match_info.match_status == "stop"))
                this.reset()
            })
          }
        }
        this.setState({ posting: false });
      })
      .catch(() => {
        this.setState({ posting: false, matchScorBoard: [] });
      });
  }


  GetFixtures = () => {
    const { FixtureList, Offset } = this.state;

    let param = {
      "sports_id": "",
      "limit": CONSTANT.ITEMS_PERPAGE_SM,
      "offset": Offset
    }

    this.setState({ FixturePosting: true })
    Http.post(API.GET_INPLAY_GAMES, param).then(response => {
      this.setState({
        FixturePosting: false,
        FixtureList: Offset === CONSTANT.CURRENT_OFFSET ? response.data.matches : [...FixtureList, ...response.data.matches],
        hasMore: !_.isEmpty(response.data.matches) || (_.size(response.data.matches) === CONSTANT.ITEMS_PERPAGE_SM),
        fixture_lastupdate: new Date().valueOf()
      }, () => {
        this.setState({ FixtureListBlank: _.isEmpty(response.data.matches) && _.isEmpty(this.state.FixtureList) })
      })
    }).catch(error => {
      this.setState({ FixturePosting: false })
    });

  }

  // Fetch Request(s)
  GetFixtureMarket = () => {
    const { match } = this.props;
    let param = { "event_id": match.params.event_id }
    this.setState({ Init: false, FixturePosting: true })
    Http.post(API.GET_FIXTURE_MARKETS, param).then(response => {
      if (response.data.event.sports_id)
        this.setState({ sports_id: response.data.event.sports_id }, () => {
          this.GetFixtures()
          this.callSoketEvents()

          if (Number(this.state.sports_id) === 2)
            this.GetScoarBoard();

          if (Number(this.state.sports_id) === 1)
            this.getLiveMatchFootBallScoreBoard();
        });

      let market_list = [];
      _.map(response.data.markets, market => {
        market_list.push({ market_event_id: market.market_event_id, show: true });
      })
      if (market_list.length > 0)
        this.setState({ exchangeMarketShow: market_list });

      let new_market_list = _.map(response.data.markets, market => {
        this.MarketRoomEmit(market.market_id);
        let new_runners_odds = _.map(JSON.parse(market.runners_odds), item => {
          let blankObj = { price: '', size: '' }
          switch (item.ex.availableToBack.length) {
            case 0:
              item.ex.availableToBack = [...item.ex.availableToBack, blankObj, blankObj, blankObj]
              break;
            case 1:
              item.ex.availableToBack = [...item.ex.availableToBack, blankObj, blankObj]
              break;
            case 2:
              item.ex.availableToBack = [...item.ex.availableToBack, blankObj]
              break;
            default:
              break;
          }
          switch (item.ex.availableToLay.length) {
            case 0:
              item.ex.availableToLay = [...item.ex.availableToLay, blankObj, blankObj, blankObj]
              break;
            case 1:
              item.ex.availableToLay = [...item.ex.availableToLay, blankObj, blankObj]
              break;
            case 2:
              item.ex.availableToLay = [...item.ex.availableToLay, blankObj]
              break;
            default:
              break;
          }
          return item
        })
        return { ...market, runners_odds: JSON.stringify(new_runners_odds) }
      })
      this.setState({
        FixturePosting: false,
        EventDetails: response.data.event,
        ScoresData: response.data.scores,
        MarketList: new_market_list,
        FancyData: response.data.fancy.fancy,
        OtherFancyData: response.data.fancy.other_fancy,
        CentralScore: response.data.central_score,
      }, () => {
        if (!_.isUndefined(this.state.FancyData) && !_.isNull(this.state.FancyData) && (this.state.FancyData.length > 0)) {
          _.map(this.state.FancyData, (fancy) => {
            if (!_.isUndefined(fancy) && !_.isNull(fancy) && !_.isUndefined(fancy.sessions) && !_.isNull(fancy.sessions)) {
              _.map(fancy.sessions, (session) => {
                this.MarketRoomEmit(session.session_id);
              })
            }
          });
        }
        if (!_.isUndefined(this.state.OtherFancyData) && !_.isNull(this.state.OtherFancyData) && (this.state.OtherFancyData.length > 0)) {
          _.map(this.state.OtherFancyData, (fancy) => {
            if (!_.isUndefined(fancy) && !_.isNull(fancy) && !_.isUndefined(fancy.sessions) && !_.isNull(fancy.sessions)) {
              _.map(fancy.sessions, (session) => {
                this.MarketRoomEmit(session.session_id);
              })
            }
          });
        }
        this.updateStackMinMax();
        if (this.state.EventDetails.streamingUrl && !_.isEmpty(this.state.EventDetails.streamingUrl))
          this.setState({ streamingUrl: this.state.EventDetails.streamingUrl })

        if (this.state.EventDetails.is_live == 1) {
          let _this = this
          Socket.on(SocketEvent.SCORE, function (res) {
            _this.SocketScorehandler(res)
          })
        }
        if (this.state.EventDetails && (Number(this.state.sports_id) === 3) && !_.isEmpty(this.state.ScoresData) && !_.isEmpty(this.state.ScoresData.score)) {
          let i = 0;
          _.map(JSON.parse(this.state.ScoresData.score), (score, index) => {
            if (i === 0) {
              this.setState({ showScoreData: index });
              i = i + 1;
            }
          })
        }

      })
    }).catch(error => {
      this.setState({ FixturePosting: false })
    });
  }

  SocketGlobalSettingHandler = (res = {}) => {
    const { AppMasterData } = this.state;
    this.setState({
      AppMasterData: { ...AppMasterData, ...res }
    });
  }

  SocketBetCLoseTimeUpdate = (res) => {
    const { AppMasterData } = this.state;
    if (Number(res.button_id) == 39) {
      let change = AppMasterData;
      _.map(change.commentary_buttons, (item, index) => {
        if (res.button_id == item.button_id) {
          item.bet_close_time = res.bet_close_time
          this.setState({ AppMasterData: change })
        }
      })
    }
  }

  SocketMatchCommentaryUpdated = (res = {}) => {
    if (!_.isEmpty(res)) {
      const { EventDetails } = this.state;
      if (EventDetails.event_id == res.event_id) {
        this.setState({ EventDetails: { ...EventDetails, ...res } }, () => { this.updateStackMinMax(); });
      }
    }
  }

  SocketMatchScoreBoardUpdated = (res = {}) => {
    if (!_.isEmpty(res)) {
      if (res.event_id == this.props.match.params.event_id) {
        const { matchScorBoard } = this.state;
        this.setState({ matchScorBoard: { ...matchScorBoard, ...res.scoreboard } })
      }
    }
  }

  SocketMatchEntityScoreBoardUpdated = (res = {}) => {
    if (!_.isEmpty(res)) {
      if (res.betfaireventID == this.props.match.params.event_id) {
        const { matchScorBoard } = this.state;
        this.setState({ matchScorBoard: { ...matchScorBoard, ...res.score } })
      }
    }
  }

  SocketScoreMatchScoreBoardUpdated = (res = {}) => {
    if (!_.isEmpty(res)) {
      if (res.event_id == this.props.match.params.event_id) {
        const { matchScorBoard } = this.state;
        let currentTimeSec = 0;
        let currentTimeMin = 0;
        if (res.match_info) {
          if (res.match_info.inning && (Number(res.match_info.inning) === 1)) {
            if (res.match_info.first_half) {
              let first_half = res.match_info.first_half.split(":");
              currentTimeSec = first_half.length > 1 ? Number(first_half[1]) : 0;
              currentTimeMin = first_half.length > 1 ? Number(first_half[0]) : 0;
            }
          }
          else if (res.match_info.inning && (Number(res.match_info.inning) === 2)) {
            if (res.match_info.second_half) {
              let second_half = res.match_info.second_half.split(":");
              currentTimeSec = second_half.length > 1 ? Number(second_half[1]) : 0;
              currentTimeMin = second_half.length > 1 ? Number(second_half[0]) : 0;
            }
          }
          else if (res.match_info.inning && (Number(res.match_info.inning) === 3)) {
            if (res.match_info.extra_time) {
              let extra_time = res.match_info.extra_time.split(":");
              currentTimeSec = extra_time.length > 1 ? Number(extra_time[1]) : 0;
              currentTimeMin = extra_time.length > 1 ? Number(extra_time[0]) : 0;
            }
          }
        }
        this.setState({ matchScorBoard: { ...matchScorBoard, ...res.match_info }, currentTimeSec: currentTimeSec, currentTimeMin: currentTimeMin }, () => {

          if (res.match_info.match_status && (res.match_info.match_status == "start"))
            this.start();
          else if (res.match_info.match_status && (res.match_info.match_status == "pause"))
            this.stop();
          else if (res.match_info.match_status && (res.match_info.match_status == "stop"))
            this.reset();
        })
      }
    }
  }

  SocketEventCentralScoreUpdate = (res = {}) => {
    if (!_.isEmpty(res)) {
      if (res.event_id == this.props.match.params.event_id) {
        const { CentralScore } = this.state;
        this.setState({ CentralScore: { ...CentralScore, ...res.data } })
      }
    }
  }

  SocketMarketSettle = (res = {}) => {
    if (!_.isEmpty(res)) {
      if (res.event_id === this.props.match.params.event_id) {
        this.GetFixtureMarket()
        // this.getdeclaredSession()
      }
    }
  }

  SockeDecAPI = (res = {}) => {
    if (res.event_id == this.props.match.params.event_id) {
      // this.getdeclaredSession()
    }
  }

  callSoketEvents = () => {
    const _this = this;
    Socket.on(SocketEvent.GLOBAL_SETTING, function (res) {
      if (!_.isEmpty(res)) {
        _this.SocketGlobalSettingHandler(res)
      }
    })
    Socket.on(SocketEvent.MATCH_COMMENTARY_UPDATED, function (res) {
      if (!_.isEmpty(res)) {
        _this.SocketMatchCommentaryUpdated(res)
        if (Number(res.commentary_button_id) == 39) {
          _this.setSecTimeout(res)
        }
      }

    })
    if (this.state.sports_id && !_.isEmpty(this.state.sports_id) && Number(this.state.sports_id) === 2) {
      Socket.on(SocketEvent.SCOREBOARD_UPDATED, function (res) {
        if (!_.isEmpty(res)) {
          _this.SocketMatchScoreBoardUpdated(res)
        }
      })
    }
    Socket.on(SocketEvent.ENTITY_SPORT_SCORE_UPDATED, function (res) {
      if (!_.isEmpty(res)) {
        _this.SocketMatchEntityScoreBoardUpdated(res)
      }
    })
    if (this.state.sports_id && !_.isEmpty(this.state.sports_id) && Number(this.state.sports_id) === 1) {
      Socket.on(SocketEvent.SCOREMATCH_UPDATED, function (res) {
        if (!_.isEmpty(res)) {
          _this.SocketScoreMatchScoreBoardUpdated(res)
        }
      })
    }

    Socket.on(SocketEvent.EVENT_CENTRAL_SCORE_UPDATED, function (res) {
      if (!_.isEmpty(res))
        _this.SocketEventCentralScoreUpdate(res)
    })
    Socket.on(SocketEvent.MARKET_SETTLE, function (res) {
      if (!_.isEmpty(res))
        _this.SocketMarketSettle(res)
    })
    Socket.on(SocketEvent.BET_CLOSE_TIME, function (res) {
      if (!_.isEmpty(res))
        _this.SocketBetCLoseTimeUpdate(res)
    })
  }


  getClientNotification = () => {
    let param = {};
    // this.setState({ posting: true });
    Http.post(API.GET_CLIENT_NOTIFICATION, param)
      .then((response) => {
        this.setState({
          // posting: false,
          client_msg: response.data.client_msg,
        });
      })
      .catch((error) => {
        // this.setState({ posting: false });
      });
  }

  recon_socket = (res = {}) => {
    _.map(this.state.mkt_rooms, (market, index) => {
      if (!_.isUndefined(market) && !_.isNull(market)) {
        Socket.emit('market_room', market);
      }
    })
  }

  EventDetailsSetting = (res = {}) => {
    if (!_.isEmpty(res)) {
      const { EventDetails } = this.state;
      if (EventDetails.event_id == res.event_id) {
        this.GetFixtureMarket();
      }
    }
  }

  // Life cycle
  componentDidMount() {
    this.GetFixtureMarket()
    // this.getdeclaredSession()
    this.getClientNotification()
    this.props.onRef(this);
    const _this = this;
    Socket.on(SocketEvent.EVENT_ANNOUNCEMENT_UPDATE, function (res) {
      const { EventDetails } = _this.state;
      if (!_.isEmpty(res) && !_.isUndefined(res.event_id) && !_.isNull(res.event_id) && (res.event_id == EventDetails.event_id)) {
        _this.GetFixtureMarket();
      }
    })
    Socket.on(SocketEvent.RECONNECT_SOCKET, function (res) {
      _this.recon_socket(res)
    })
    Socket.on(SocketEvent.STATUSUPDATEDMK, function (res) {
      if (!_.isEmpty(res)) {
        _this.EventDetailsSetting(res)
      }
    });
    Socket.on(SocketEvent.MATCH_SETTING, function (res) {
      if (!_.isEmpty(res)) {
        _this.EventDetailsSetting(res)
      }
    })
    Socket.on(SocketEvent.RELOAD_EVENT_PAGE, function (res) {
      const { EventDetails } = _this.state;
      if (!_.isEmpty(res) && (res.event_id == EventDetails.event_id)) {
        window.location.reload();
      }
    })
    window.addEventListener("resize", this.updateDimensions);
    document.addEventListener("visibilitychange", this.handleBrowswer);
    window.addEventListener("scroll", this.scrollView);
    if (this.state.sports_id && !_.isEmpty(this.state.sports_id) && Number(this.state.sports_id) === 1)
      window.addEventListener("focus", this.getLiveMatchFootBallScoreBoard);
  }

  componentWillMount() {
    this.updateDimensions();

  }

  componentWillUnmount() {
    this.setState({ mkt_rooms: [] });
    Socket.emit('unsubscribe', this.state.mkt_rooms);
    this.props.onRef(undefined)
    document.removeEventListener("visibilitychange", this.handleBrowswer);
    window.removeEventListener("resize", this.updateDimensions);
    window.removeEventListener("scroll", this.scrollView);
    Socket.off(SocketEvent.FANCY)
    Socket.off(SocketEvent.SCORE)
    Socket.off(SocketEvent.STATUSUPDATEDMK)
    Socket.off(SocketEvent.BOOKMARKER_MARKET_UPDATED)
    Socket.off(SocketEvent.GLOBAL_SETTING)
    Socket.off(SocketEvent.MATCH_SETTING)
    Socket.off(SocketEvent.DIAMOND_MARKET_UPDATED)
    Socket.off(SocketEvent.MATCH_COMMENTARY_UPDATED)
    Socket.off(SocketEvent.CENTRAL_FANCY_MARKET_UPDATED)
    Socket.off(SocketEvent.EVENT_CENTRAL_SCORE_UPDATED)
    Socket.off(SocketEvent.ENTITY_SPORT_SCORE_UPDATED)
    Socket.off(SocketEvent.MK_STATUS_UPDATED)
    Socket.off(SocketEvent.MARKET_SETTLE)
    Socket.off(SocketEvent.BET_CLOSE_TIME)
    Socket.off(SocketEvent.RECONNECT_SOCKET)
    Socket.off(SocketEvent.RELOAD_EVENT_PAGE)
    if (this.state.sports_id && !_.isEmpty(this.state.sports_id) && Number(this.state.sports_id) === 2)
      Socket.off(SocketEvent.SCOREBOARD_UPDATED)
    if (this.state.sports_id && !_.isEmpty(this.state.sports_id) && Number(this.state.sports_id) === 1)
      Socket.off(SocketEvent.SCOREMATCH_UPDATED)

    this.setState = () => {
      return;
    };
  }

  // brower change tabs and screen
  handleBrowswer = (e) => {
    if (e.type == "visibilitychange") {
      this.GetFixtureMarket();
    }
  }

  // Other Handler
  SocketScorehandler = (res = {}) => {
    const { ScoresData, EventDetails } = this.state;
    if (EventDetails.event_id == res.event_id) {
      this.setState({
        ScoresData: { ...ScoresData, ...res }
      }, () => {
        if (this.state.EventDetails && (Number(this.state.sports_id) === 3) && !_.isEmpty(this.state.ScoresData) && !_.isEmpty(this.state.ScoresData.score)) {
          let i = 0;
          _.map(JSON.parse(this.state.ScoresData.score), (score, index) => {
            if (i === 0) {
              this.setState({ showScoreData: index });
              i = i + 1;
            }
          })
        }
      });
    }
  }

  SocketHighlight = ({ id }) => {
    const { recently_changed } = this.state
    if (_.isEmpty(recently_changed)) return ''
    let _id = _.isString(id) ? id : JSON.stringify(id)
    if (_.includes(recently_changed, _id)) {
      this.cleanSocketHighlight()
      return ' blink '
    }
    else return '';
  }

  cleanSocketHighlight = (timer = 50) => {
    setInterval(() => {
      if (!_.isEmpty(this.state.recently_changed)) {
        this.setState({ recently_changed: [] })
      }
    }, timer)
  }

  reinitiateComponent = () => {
    const { reinitiateComponent, location, is_inplay } = this.props
    const searchParams = new URLSearchParams(location.search);
    if (is_inplay) {
      reinitiateComponent(searchParams.get('sports'), true)
    } else {
      reinitiateComponent(searchParams.get('sports'))
    }
  }

  changeMarketstatus = (market_event_id) => {
    const { exchangeMarketShow } = this.state;
    let newExchangeMarketShow = exchangeMarketShow;
    _.map(exchangeMarketShow, (item, i) => {
      if (item.market_event_id == market_event_id)
        newExchangeMarketShow[i] = { ...item, show: !item.show }
    })
    this.setState({ exchangeMarketShow: newExchangeMarketShow })
  }

  reloadMatchDetailsPage = () => {
    window.location.reload()
  }
  isToolTipOpenGoals = targetName => {
    return this.state[targetName] ? this.state[targetName].TooltipGoals : false;
  };
  isToolTipOpenRedCard = targetName => {
    return this.state[targetName] ? this.state[targetName].TooltipRedCard : false;
  };
  isToolTipOpenYellowCard = targetName => {
    return this.state[targetName] ? this.state[targetName].TooltipYellowCard : false;
  };
  isToolTipOpenCorners = targetName => {
    return this.state[targetName] ? this.state[targetName].TooltipCorners : false;
  };
  toggleToolGoals = targetName => {
    if (!this.state[targetName]) {
      this.setState({
        ...this.state,
        [targetName]: {
          TooltipGoals: true
        }
      });
    } else {
      this.setState({
        ...this.state,
        [targetName]: {
          TooltipGoals: !this.state[targetName].TooltipGoals
        }
      });
    }
  };
  toggleToolRedCard = targetName => {
    if (!this.state[targetName]) {
      this.setState({
        ...this.state,
        [targetName]: {
          TooltipRedCard: true
        }
      });
    } else {
      this.setState({
        ...this.state,
        [targetName]: {
          TooltipRedCard: !this.state[targetName].TooltipRedCard
        }
      });
    }
  };
  toggleToolYellowCard = targetName => {
    if (!this.state[targetName]) {
      this.setState({
        ...this.state,
        [targetName]: {
          TooltipYellowCard: true
        }
      });
    } else {
      this.setState({
        ...this.state,
        [targetName]: {
          TooltipYellowCard: !this.state[targetName].TooltipYellowCard
        }
      });
    }
  };
  toggleToolCorners = targetName => {
    if (!this.state[targetName]) {
      this.setState({
        ...this.state,
        [targetName]: {
          TooltipCorners: true
        }
      });
    } else {
      this.setState({
        ...this.state,
        [targetName]: {
          TooltipCorners: !this.state[targetName].TooltipCorners
        }
      });
    }
  };
  showLiveScore = () => {
    this.setState({ hdliveScore: !this.state.hdliveScore })
  }

  fixtureSelectHandler = (item = {}) => {
    const { match } = this.props
    const { is_inplay, SelectedLeague, SelectedSports, NavigationList } = this.state

    if (item && !_.isEmpty(item) && NavigationList && !_.isEmpty(NavigationList)) {
      if (_.isEmpty(SelectedLeague) && _.isEmpty(SelectedSports)) {
        _.forEach(NavigationList, navitem => {
          if (Number(navitem.sports_id) === Number(item.sports_id)) {
            this.setState({
              SelectedSports: navitem
            }, () => {
              this.props.history.push({
                pathname: is_inplay ? `${match.url}/in-play/details/${item.event_id}` : `${match.url}/details/${item.event_id}`,
                search: `?sports=${item.sports_id}&market=${item.market_event_id}`,
                sports_id: item.sports_id,
              })
            })
          }
        })
      }
      else {
        this.props.history.push({
          pathname: is_inplay ? `${match.url}/in-play/details/${item.event_id}` : `${match.url}/details/${item.event_id}`,
          search: `?sports=${item.sports_id}&market=${item.market_event_id}`,
          sports_id: item.sports_id,
        })
      }
    }
    else {
      this.props.history.push({
        pathname: is_inplay ? `${match.url}/in-play/details/${item.event_id}` : match.url === `/cup_winner/details/${match.params.event_id}` ? `/cup_winner/details/${item.event_id}` : match.url === `/upcoming/details/${match.params.event_id}` ? `/upcoming/details/${item.event_id}` : `/exchange/details/${item.event_id}`,
        search: `?sports=${item.sports_id}&market=${item.market_event_id}`,
        sports_id: item.sports_id,
      })
    }
  }











  reloadBetslipOpenBet = () => {
    if (!this.state.is_betslip_drawer)
      this.betslipDrawer.refreshBetSlipOpenBet();
  }

  // ================== fancy and market accordion start
  marketsCobineAccordion = () => {
    const { CombineMarketShowHide } = this.state
    this.setState({
      CombineMarketShowHide: !CombineMarketShowHide,
      showDeclaredSession: false,
      showDeclaredMarket: false,
      showGrandTotal: false
      // showNoBetDeclaredSession: false,
      // showNoBetDeclaredMarket: false,
    })

    if (!CombineMarketShowHide) {
      this.getdeclaredSession()
      this.getdeclaredMarket()
      // this.getNoBetDeclaredSessionList();
      // this.getNoBetDeclaredMarketList();
    }
  }

  scrollView = () => {
    if (window.scrollY >= 600) {
      this.setState({ scrollIframe: true });
    } else if (window.scrollY < 600) {
      this.setState({ scrollIframe: false });
    }

  }




  render() {

    const {
      SelectedSports,
      t,
      page_id,
    } = this.props
    const {
      check_event_id,
      FixtureList,
      hasMore,
      MarketList,
      FancyData,
      OtherFancyData,
      EventDetails,
      ScoresData,
      is_betslip_drawer,
      odd_id,
      ExposureDetailsModalShow,
      AppMasterData,
      matchScorBoard,
      declaredSession,
      DeclaredSessionModalShow,
      SelectedSession,
      showLiveTV,
      marketStackMin,
      marketStackMax,
      bookMakerStackMin,
      bookMakerStackMax,
      fancyMarketStackMin,
      fancyMarketStackMax,
      declaredSessionTotal,
      CentralScore,
      SwitchModalShow,
      button_threenine,
      child_session_type_id,
      client_msg,
      showDeclaredSession,
      CombineMarketShowHide,

      declaredMarket,
      showDeclaredMarket,
      declaredMarketTotal,
      showGrandTotal,
      openBetSlipid,
      isThemeGradient,
      scrollIframe
    } = this.state

    const BetslipDrawerProps = {
      ...this.props.BetslipDrawerProps,
      EventDetails: EventDetails
    }
    const SwitchModalProps = {
      UserProps: '',
      isOpen: SwitchModalShow,
      message: "Are you sure you want to proceed?",
      title: "Confirm",
      callback: () => this.callback2(),
    }

    const ExposureDetailsModalProps = {
      odd_id: odd_id,
      child_session_type_id: child_session_type_id,
      event_id: EventDetails.event_id,
      isOpen: ExposureDetailsModalShow,
      toggle: this.modalHide,
      callBack: this.modalUpdate,
      LDO_fancy: this.state.LDO_fancy
    }
    const BetItemSecProps = {
      page_id,
      showSportName: true,
      fixtureSelectHandler: this.fixtureSelectHandler,
      SelectedSports: SelectedSports
    }
    const MarketProps = {
      openBetSlipid: openBetSlipid,
      mkt_rooms: this.state.mkt_rooms,
      MarketList: MarketList,
      EventDetails: EventDetails,
      marketStackMin: marketStackMin,
      marketStackMax: marketStackMax,
      bookMakerStackMin: bookMakerStackMin,
      bookMakerStackMax: bookMakerStackMax,
      reloadBetslipOpenBet: this.reloadBetslipOpenBet,
      MarketRoomEmit: this.MarketRoomEmit,
      openBetSlipMarketId: this.openBetSlipMarketId,
    }
    const FancyProps = {
      openBetSlipid: openBetSlipid,
      mkt_rooms: this.state.mkt_rooms,
      FancyData: FancyData,
      EventDetails: EventDetails,
      fancyMarketStackMin: fancyMarketStackMin,
      fancyMarketStackMax: fancyMarketStackMax,
      reloadBetslipOpenBet: this.reloadBetslipOpenBet,
      MarketRoomEmit: this.MarketRoomEmit,
      openBetSlipMarketId: this.openBetSlipMarketId,
    }
    const OtherFancyProps = {
      openBetSlipid: openBetSlipid,
      mkt_rooms: this.state.mkt_rooms,
      OtherFancyData: OtherFancyData,
      EventDetails: EventDetails,
      fancyMarketStackMin: fancyMarketStackMin,
      fancyMarketStackMax: fancyMarketStackMax,
      reloadBetslipOpenBet: this.reloadBetslipOpenBet,
      MarketRoomEmit: this.MarketRoomEmit,
      openBetSlipMarketId: this.openBetSlipMarketId,
    }
    const currentUser = Auth.currentUserValue;
    const DeclaredSessionModalProps = {
      isOpen: DeclaredSessionModalShow,
      SelectedSession: SelectedSession,
      toggle: this.declaredSessionmodalHide,
    }

    return (
      <Fragment>
        {/* <MatchHeading client_msg={client_msg} isMobileView={is_betslip_drawer} /> */}
        <BetMsgPoPUP />
        <Row>
          <Col>
            <div className="match-details">
              <MatchTitle
                isMobileView={is_betslip_drawer}
                EventDetails={EventDetails}
                goBackBtn={() => this.reinitiateComponent()}
              />
              <TitleComponent title={EventDetails.event_name} />
              {
                <Fragment>
                  {
                    showLiveTV && is_betslip_drawer && EventDetails.streamingUrl && !_.isEmpty(EventDetails.streamingUrl) &&
                    <div className="row mt-2">
                      <div className="col-12">
                        <div className={"mobile-view-liveTV"}>
                          <b className="col-title">Live TV
                          </b>
                          {
                            EventDetails.streamingUrl && !_.isEmpty(EventDetails.streamingUrl) && this.state.toggleLiveTV &&
                            <iframe src={EventDetails.streamingUrl} title="" className="w-100"></iframe>
                          }
                        </div>
                      </div>
                    </div>
                  }
                  {is_betslip_drawer && <MatchScoreBoard EventDetails={EventDetails} scrollIframe={scrollIframe} />}
                  {
                    !_.isUndefined(EventDetails.event_id) && !_.isNull(EventDetails.event_id) &&
                    <Fragment>
                      <MarketFixtureDetailList {...MarketProps} EventDetails={EventDetails} onRef={(ref) => (this.matchOddMarket = ref)} />
                      <FancyFixtureDetailList {...FancyProps} EventDetails={EventDetails} onRef={(ref) => (this.fancyOddMarket = ref)} />
                      <OtherFancyFixtureDetailList {...OtherFancyProps} EventDetails={EventDetails} onRef={(ref) => (this.otherFancyOddMarket = ref)} />
                      <OtherMarketFixtureDetailList {...MarketProps} EventDetails={EventDetails} onRef={(ref) => (this.otherOddMarket = ref)} />
                    </Fragment>
                  }

                  {is_betslip_drawer &&
                    <Fragment>
                      <div className='declare-cover mx-3 my-1'>
                        <Row className={"title  align-items-center " + (isThemeGradient ? "bg-gradient-4" : "")}>
                          <Col className="col-10 name">
                            Dec. Fancy+ODD Mkt.
                          </Col>
                          <Col className='col-2 icon'>

                            <i className={this.state.CombineMarketShowHide ? "float-right icon-up pointer" : "float-right icon-down pointer"} onClick={() => this.marketsCobineAccordion()} ></i>
                          </Col>
                        </Row>
                      </div>
                      <div className={`${this.state.CombineMarketShowHide ? "" : "d-none"}`}>


                        {/* Declared Session end */}
                        {
                          !_.isEmpty(declaredSession) ?
                            <div className={'declared_market_new mb-1  ' + (is_betslip_drawer ? "mx-3" : "mx-3")}>
                              <Row className={"title  align-items-center " + (isThemeGradient ? "bg-gradient-2" : "")}>
                                <Col className="col-10 name">
                                  Declared Session
                                </Col>
                                <Col className='col-2 icon'>
                                  <i className={this.state.showDeclaredSession ? "float-right icon-up pointer" : "float-right icon-down pointer"} onClick={() => this.setState({ showDeclaredSession: !this.state.showDeclaredSession })}></i>
                                </Col>
                              </Row>
                              <div className={this.state.showDeclaredSession ? " declared_market col-12 declaredSessionShow px-0" : " declared_marketcol-12 declaredSessionHide px-0"} >
                                <Row className='headings'>
                                  <Col className='col-2'>Sr No.</Col>
                                  <Col>Sessions</Col>
                                  <Col> Settled</Col>
                                  {
                                    (Number(currentUser.role) !== 5) &&
                                    <Col> Status</Col>
                                  }
                                  <Col> Action</Col>
                                </Row>
                                {
                                  _.map(declaredSession, (session, index) => {
                                    let showDeclaredSession = false;
                                    FancyData.map((id) => {
                                      if (id.session_id == session.odd_id) {
                                        if (id.settlement_status == '0') {
                                          showDeclaredSession = true;
                                        }
                                      }
                                      return id;
                                    })
                                    return (
                                      <Row key={index} className={((index % 2) === 0 ? 'even' : 'odd') + " body"}>
                                        <Col className='col-2'>{index + 1}</Col>
                                        <Col >{session.market_name}</Col>
                                        <Col className=''>
                                          {
                                            !_.isUndefined(session.market_runners) ?
                                              _.map(JSON.parse(session.market_runners), (team, index) => {
                                                if (team.selectionId == session.settlement_result)
                                                  return (
                                                    team.runnerName
                                                  )
                                              })
                                              : (session.child_session_type_id == 14 ? (session.settlement_result == 1 ? "YES" : "NO") : session.settlement_result)}
                                        </Col>
                                        {
                                          (Number(currentUser.role) !== 5) &&
                                          <Col className="run_status">

                                            <span className={(session.profit_loss != null) ? (Number(session.profit_loss > 0 ? (Number(session.profit_loss) - Number(session.commission)) : (Number(session.profit_loss) + Number(session.commission))) < 0 ? "loss" : "profit") : ""}>{(session.profit_loss != null) ? Utils.TwoDecimalVal(session.profit_loss > 0 ? (Number(session.profit_loss) - Number(session.commission)) : (Number(session.profit_loss) + Number(session.commission))) : ""}</span>
                                          </Col>
                                        }
                                        <Col><Button color="primary" size='sm' onClick={() => { this.declaredSessionmodalShow(session) }}>Details</Button></Col>
                                      </Row>)
                                  })}
                                {DeclaredSessionModalShow && <DeclaredSessionModal {...DeclaredSessionModalProps} />}


                                <Row className={"declared_market_footer " + (isThemeGradient ? "bg-gradient-1" : "")}>
                                  <Col className="run_status col-8">Declared Session Total</Col>
                                  {
                                    (Number(currentUser.role) !== 5) &&
                                    <Col className="run_status">

                                      <span className={(declaredSessionTotal != null) ? (Number(declaredSessionTotal) < 0 ? "loss  ml-4 pl-2" : "profit  ml-4 pl-2") : ""}>{(declaredSessionTotal != null) ? Utils.TwoDecimalVal(declaredSessionTotal) : ""}</span>
                                    </Col>
                                  }
                                </Row>
                              </div>
                              <Row className={"declared_market_footer " + (isThemeGradient ? "bg-gradient-1" : "")}>
                                <Col>Total  <span className={" ml-2"}>{declaredSession.length}</span></Col>
                                <Col className='col-8' >Declared Session Grand Total <span className={(declaredSessionTotal != null) ? (Number(declaredSessionTotal) < 0 ? "loss" : "profit") : ""}> {(declaredSessionTotal != null) ? Utils.TwoDecimalVal(declaredSessionTotal) : ""}</span></Col>
                              </Row>
                            </div>
                            :
                            <div className={'declared_market_new mb-1  ' + (is_betslip_drawer ? "mx-3" : "mx-3")}>
                              <Row className={"title  align-items-center " + (isThemeGradient ? "bg-gradient-2" : "")}>
                                <Col className="col-10 name">
                                  Declared Session
                                </Col>
                                <Col className='col-2 icon'>
                                  <i className={this.state.showDeclaredSession ? "float-right icon-up pointer" : "float-right icon-down pointer"} onClick={() => this.setState({ showDeclaredSession: !this.state.showDeclaredSession })}></i>
                                </Col>
                              </Row></div>
                        }
                        {/* Declared Session end */}



                        {
                          !_.isEmpty(declaredMarket) ?
                            <div className={'declared_market_new mb-1  ' + (is_betslip_drawer ? "mx-3" : "mx-3")}>
                              <Row className={"title  align-items-center " + (isThemeGradient ? "bg-gradient-2" : "")}>
                                <Col className="col-10 name">
                                  Declared Market
                                </Col>
                                <Col className='col-2 icon'>
                                  <i className={this.state.showDeclaredMarket ? "float-right icon-up pointer" : "float-right icon-down pointer"} onClick={() => this.setState({ showDeclaredMarket: !this.state.showDeclaredMarket })}></i>
                                </Col>
                              </Row>
                              <div className={this.state.showDeclaredMarket ? " declared_market col-12 declaredSessionShow px-0" : " declared_marketcol-12 declaredSessionHide px-0"} >
                                <Row className='headings'>
                                  <Col className='col-2'>Sr No.</Col>
                                  <Col>Sessions</Col>
                                  <Col> Settled</Col>
                                  {
                                    (Number(currentUser.role) !== 5) &&
                                    <Col> Status</Col>
                                  }
                                </Row>
                                {_.map(declaredMarket, (item, index) => {
                                  return (
                                    <Fragment key={index}>
                                      <Row key={index} className={((index % 2) === 0 ? 'even' : 'odd') + " body"}>
                                        <Col className='col-2'>{index + 1}</Col>
                                        <Col >{item.market_name}</Col>
                                        <Col className=''>
                                          {item.settlement_result !== 'Abandoned' ? _.map(JSON.parse(item.market_runners), (runner) => {
                                            if (runner.selectionId == item.settlement_result)
                                              return (
                                                runner.runnerName
                                              )
                                          }) : item.settlement_result}
                                        </Col>
                                        {
                                          (Number(currentUser.role) !== 5) &&
                                          <Col className=''>
                                            <span className={(item.profit_loss != null) ? (Number(item.profit_loss) < 0 ? "loss" : "profit") : ""}>{(item.profit_loss != null) ? Utils.TwoDecimalVal(item.profit_loss) : ""}</span>

                                          </Col>
                                        }
                                      </Row>

                                    </Fragment>
                                  )
                                })}



                                <Row className={"declared_market_footer " + (isThemeGradient ? "bg-gradient-1" : "")}>
                                  <Col className="run_status col-8">Declared Market Total</Col>
                                  {
                                    (Number(currentUser.role) !== 5) &&
                                    <Col className="run_status">

                                      <span className={(declaredMarketTotal != null) ? (Number(declaredMarketTotal) < 0 ? "loss  ml-4 pl-2" : "profit  ml-4 pl-2") : ""}>{(declaredMarketTotal != null) ? Utils.TwoDecimalVal(declaredMarketTotal) : ""}</span>
                                    </Col>
                                  }
                                </Row>
                              </div>
                              <Row className={"declared_market_footer " + (isThemeGradient ? "bg-gradient-1" : "")}>

                                <Col>Total  <span className={" ml-2"}>{declaredMarket.length}</span></Col>
                                <Col className='col-8' >Declared Market Grand Total <span className={(declaredMarketTotal != null) ? (Number(declaredMarketTotal) < 0 ? "loss" : "profit") : ""}> {(declaredMarketTotal != null) ? Utils.TwoDecimalVal(declaredMarketTotal) : ""}</span></Col>

                              </Row>
                            </div>
                            :
                            <div className={'declared_market_new mb-1  ' + (is_betslip_drawer ? "mx-3" : "mx-3")}>
                              <Row className={"title  align-items-center " + (isThemeGradient ? "bg-gradient-2" : "")}>
                                <Col className="col-10 name">
                                  Declared Market
                                </Col>
                                <Col className='col-2 icon'>
                                  <i className={this.state.showDeclaredMarket ? "float-right icon-up pointer" : "float-right icon-down pointer"} onClick={() => this.setState({ showDeclaredMarket: !this.state.showDeclaredMarket })}></i>
                                </Col>
                              </Row></div>
                        }

                        {/* grand total */}
                        {(!declaredMarket || declaredMarket) && <div className={'declared_market_new mb-1  ' + (is_betslip_drawer ? "mx-3" : "mx-3")}>
                          <Row className={"title  align-items-center " + (isThemeGradient ? "bg-gradient-2" : "")}>
                            <Col className="col-10 name">
                              Grand Total
                            </Col>
                            <Col className='col-2 icon'>
                              <i className={this.state.showGrandTotal ? "float-right icon-up pointer" : "float-right icon-down pointer"} onClick={() => this.setState({ showGrandTotal: !this.state.showGrandTotal })}></i>
                            </Col>
                          </Row>

                          <div className={this.state.showGrandTotal ? " declared_market col-12 declaredSessionShow px-0" : " declared_marketcol-12 declaredSessionHide px-0"} >

                            <Row className="even  body">
                              <Col className='col-8 text-left'>Declared Fancy Grand Total</Col>
                              <Col className='col-4 text-right'><span className={(declaredSessionTotal != null) ? (Number(declaredSessionTotal) < 0 ? "loss" : "profit") : ""}> {(declaredSessionTotal != null) ? Utils.TwoDecimalVal(declaredSessionTotal) : ""}</span></Col>
                            </Row>
                            <Row className="odd  body">
                              <Col className='col-8 text-left'>Declared Market Grand Total</Col>
                              <Col className='col-4 text-right'><span className={(declaredMarketTotal != null) ? (Number(declaredMarketTotal) < 0 ? "loss" : "profit") : ""}> {(declaredMarketTotal != null) ? Utils.TwoDecimalVal(declaredMarketTotal) : ""}</span></Col>
                            </Row>
                          </div>
                          <Row className={"declared_market_footer " + (isThemeGradient ? "bg-gradient-1" : "")}>
                            <Col className='col-8 text-left' >Grand Total</Col>
                            <Col className='col-4 text-right' ><span className={(declaredMarketTotal != null) ? ((Number(declaredSessionTotal) + Number(declaredMarketTotal)) < 0 ? "loss" : "profit") : ""}>{Utils.TwoDecimalVal(Number(declaredSessionTotal) + Number(declaredMarketTotal))}</span></Col>
                          </Row>

                        </div>
                        }
                      </div>
                    </Fragment>
                  }
                  {currentUser && is_betslip_drawer && <BetslipOpenBet is_betslip_drawer={is_betslip_drawer} onRef={(ref) => (this.betslipOpenBet = ref)} />}

                </Fragment>
              }
              <InfiniteScroll
                dataLength={FixtureList.length}
                next={this.fetchMoreData}
                hasMore={hasMore}
                loader={<Utils.FlexboxLoader className='show m-2 no-margin-h' />}
                className="game-event-list mt-2"
              >
                {
                  _.map(FixtureList, (item, key) => {
                    return (
                      item.event_id != check_event_id &&
                      <BetItemSec {...{ key, item, ...BetItemSecProps }} />
                    )
                  })
                }
              </InfiniteScroll>
              {ExposureDetailsModalShow && <ExposureDetailsModal {...ExposureDetailsModalProps} />}
              {SwitchModalShow && <SwitchModal {...SwitchModalProps} />}
            </div>
          </Col>
          {
            !this.state.is_betslip_drawer &&


            <Fragment>
              {
                <div className="betslip-col ">
                  <div className="co l-12">
                    <MatchScoreBoard EventDetails={EventDetails} scrollIframe={scrollIframe} />
                  </div>
                  <BetslipDrawer {...BetslipDrawerProps} parentMethod={this.refreshFixtureDetailsList} onRef={(ref) => (this.betslipDrawer = ref)} />

                  <div className='declare-cover mx-3 my-1'>
                    <Row className={"title  align-items-center " + (isThemeGradient ? "bg-gradient-4" : "")}>
                      <Col className="col-10 name">
                        Dec. Fancy+ODD Mkt.
                      </Col>
                      <Col className='col-2 icon'>

                        <i className={this.state.CombineMarketShowHide ? "float-right icon-up pointer" : "float-right icon-down pointer"} onClick={() => this.marketsCobineAccordion()} ></i>
                      </Col>
                    </Row>
                  </div>
                  <div className={`${this.state.CombineMarketShowHide ? "" : "d-none"}`}>


                    {/* Declared Session end */}
                    {
                      !_.isEmpty(declaredSession) ?
                        <div className={'declared_market_new mb-1  ' + (is_betslip_drawer ? "mx-0" : "mx-3")}>
                          <Row className={"title  align-items-center " + (isThemeGradient ? "bg-gradient-2" : "")}>
                            <Col className="col-10 name">
                              Declared Session
                            </Col>
                            <Col className='col-2 icon'>
                              <i className={this.state.showDeclaredSession ? "float-right icon-up pointer" : "float-right icon-down pointer"} onClick={() => this.setState({ showDeclaredSession: !this.state.showDeclaredSession })}></i>
                            </Col>
                          </Row>
                          <div className={this.state.showDeclaredSession ? " declared_market col-12 declaredSessionShow px-0" : " declared_marketcol-12 declaredSessionHide px-0"} >
                            <Row className='headings'>
                              <Col className='col-2'>Sr No.</Col>
                              <Col>Sessions</Col>
                              <Col> Settled</Col>
                              {
                                (Number(currentUser.role) !== 5) &&
                                <Col> Status</Col>
                              }
                              <Col> Action</Col>
                            </Row>
                            {
                              _.map(declaredSession, (session, index) => {
                                let showDeclaredSession = false;
                                FancyData.map((id) => {
                                  if (id.session_id == session.odd_id) {
                                    if (id.settlement_status == '0') {
                                      showDeclaredSession = true;
                                    }
                                  }
                                  return id;
                                })
                                return (
                                  <Row key={index} className={((index % 2) === 0 ? 'even' : 'odd') + " body"}>
                                    <Col className='col-2'>{index + 1}</Col>
                                    <Col >{session.market_name}</Col>
                                    <Col className=''>
                                      {
                                        !_.isUndefined(session.market_runners) ?
                                          _.map(JSON.parse(session.market_runners), (team, index) => {
                                            if (team.selectionId == session.settlement_result)
                                              return (
                                                team.runnerName
                                              )
                                          })
                                          : (session.child_session_type_id == 14 ? (session.settlement_result == 1 ? "YES" : "NO") : session.settlement_result)}
                                    </Col>
                                    {
                                      (Number(currentUser.role) !== 5) &&
                                      <Col className="run_status">

                                        <span className={(session.profit_loss != null) ? (Number(session.profit_loss > 0 ? (Number(session.profit_loss) - Number(session.commission)) : (Number(session.profit_loss) + Number(session.commission))) < 0 ? "loss" : "profit") : ""}>{(session.profit_loss != null) ? Utils.TwoDecimalVal(session.profit_loss > 0 ? (Number(session.profit_loss) - Number(session.commission)) : (Number(session.profit_loss) + Number(session.commission))) : ""}</span>
                                      </Col>
                                    }
                                    <Col><Button color="primary" size='sm' onClick={() => { this.declaredSessionmodalShow(session) }}>Details</Button></Col>
                                  </Row>)
                              })}
                            {DeclaredSessionModalShow && <DeclaredSessionModal {...DeclaredSessionModalProps} />}


                            <Row className={"declared_market_footer " + (isThemeGradient ? "bg-gradient-1" : "")}>
                              <Col className="run_status col-8">Declared Session Total</Col>
                              {
                                (Number(currentUser.role) !== 5) &&
                                <Col className="run_status">

                                  <span className={(declaredSessionTotal != null) ? (Number(declaredSessionTotal) < 0 ? "loss  ml-4 pl-2" : "profit  ml-4 pl-2") : ""}>{(declaredSessionTotal != null) ? Utils.TwoDecimalVal(declaredSessionTotal) : ""}</span>
                                </Col>
                              }
                            </Row>
                          </div>
                          <Row className={"declared_market_footer " + (isThemeGradient ? "bg-gradient-1" : "")}>
                            <Col >Total  <span className={"ml-2"}>{declaredSession.length}</span></Col>
                            <Col >Declared Session Grand Total  <span className={(declaredSessionTotal != null) ? (Number(declaredSessionTotal) < 0 ? "loss" : "profit") : ""}>{(declaredSessionTotal != null) ? Utils.TwoDecimalVal(declaredSessionTotal) : ""}</span></Col>
                          </Row>
                        </div>
                        :
                        <div className={'declared_market_new mb-1  ' + (is_betslip_drawer ? "mx-0" : "mx-3")}>
                          <Row className={"title  align-items-center " + (isThemeGradient ? "bg-gradient-2" : "")}>
                            <Col className="col-10 name">
                              Declared Session
                            </Col>
                            <Col className='col-2 icon'>
                              <i className={this.state.showDeclaredSession ? "float-right icon-up pointer" : "float-right icon-down pointer"} onClick={() => this.setState({ showDeclaredSession: !this.state.showDeclaredSession })}></i>
                            </Col>
                          </Row></div>
                    }
                    {/* Declared Session end */}



                    {
                      !_.isEmpty(declaredMarket) ?
                        <div className={'declared_market_new mb-1  ' + (is_betslip_drawer ? "mx-0" : "mx-3")}>
                          <Row className={"title  align-items-center " + (isThemeGradient ? "bg-gradient-2" : "")}>
                            <Col className="col-10 name">
                              Declared Market
                            </Col>
                            <Col className='col-2 icon'>
                              <i className={this.state.showDeclaredMarket ? "float-right icon-up pointer" : "float-right icon-down pointer"} onClick={() => this.setState({ showDeclaredMarket: !this.state.showDeclaredMarket })}></i>
                            </Col>
                          </Row>
                          <div className={this.state.showDeclaredMarket ? " declared_market col-12 declaredSessionShow px-0" : " declared_marketcol-12 declaredSessionHide px-0"} >
                            <Row className='headings'>
                              <Col className='col-2'>Sr No.</Col>
                              <Col>Sessions</Col>
                              <Col> Settled</Col>
                              {
                                (Number(currentUser.role) !== 5) &&
                                <Col> Status</Col>
                              }
                            </Row>
                            {_.map(declaredMarket, (item, index) => {
                              return (
                                <Fragment key={index}>
                                  <Row key={index} className={((index % 2) === 0 ? 'even' : 'odd') + " body"}>
                                    <Col className='col-2'>{index + 1}</Col>
                                    <Col >{item.market_name}</Col>
                                    <Col className=''>
                                      {item.settlement_result !== 'Abandoned' ? _.map(JSON.parse(item.market_runners), (runner) => {
                                        if (runner.selectionId == item.settlement_result)
                                          return (
                                            runner.runnerName
                                          )
                                      }) : item.settlement_result}
                                    </Col>
                                    {
                                      (Number(currentUser.role) !== 5) &&
                                      <Col className=''>
                                        <span className={(item.profit_loss != null) ? (Number(item.profit_loss) < 0 ? "loss" : "profit") : ""}>{(item.profit_loss != null) ? Utils.TwoDecimalVal(item.profit_loss) : ""}</span>

                                      </Col>
                                    }
                                  </Row>

                                </Fragment>
                              )
                            })}



                            <Row className={"declared_market_footer " + (isThemeGradient ? "bg-gradient-1" : "")}>
                              <Col className="run_status col-8">Declared Market Total</Col>
                              {
                                (Number(currentUser.role) !== 5) &&
                                <Col className="run_status">

                                  <span className={(declaredMarketTotal != null) ? (Number(declaredMarketTotal) < 0 ? "loss  ml-4 pl-2" : "profit  ml-4 pl-2") : ""}> {(declaredMarketTotal != null) ? Utils.TwoDecimalVal(declaredMarketTotal) : ""}</span>
                                </Col>
                              }
                            </Row>
                          </div>
                          <Row className={"declared_market_footer " + (isThemeGradient ? "bg-gradient-1" : "")}>

                            <Col>Total  <span className={" ml-2"}>{declaredMarket.length}</span></Col>
                            <Col >Declared Market Grand Total <span className={(declaredMarketTotal != null) ? (Number(declaredMarketTotal) < 0 ? "loss" : "profit") : ""}> {(declaredMarketTotal != null) ? Utils.TwoDecimalVal(declaredMarketTotal) : ""}</span></Col>

                          </Row>
                        </div>
                        :
                        <div className={'declared_market_new mb-1  ' + (is_betslip_drawer ? "mx-0" : "mx-3")}>
                          <Row className={"title  align-items-center " + (isThemeGradient ? "bg-gradient-2" : "")}>
                            <Col className="col-10 name">
                              Declared Market
                            </Col>
                            <Col className='col-2 icon'>
                              <i className={this.state.showDeclaredMarket ? "float-right icon-up pointer" : "float-right icon-down pointer"} onClick={() => this.setState({ showDeclaredMarket: !this.state.showDeclaredMarket })}></i>
                            </Col>
                          </Row>
                        </div>
                    }

                    {/* grand total */}
                    {(!declaredMarket || declaredMarket) && <div className={'declared_market_new mb-1  ' + (is_betslip_drawer ? "mx-3" : "mx-3")}>
                      <Row className={"title  align-items-center " + (isThemeGradient ? "bg-gradient-2" : "")}>
                        <Col className="col-10 name">
                          Grand Total
                        </Col>
                        <Col className='col-2 icon'>
                          <i className={this.state.showGrandTotal ? "float-right icon-up pointer" : "float-right icon-down pointer"} onClick={() => this.setState({ showGrandTotal: !this.state.showGrandTotal })}></i>
                        </Col>
                      </Row>

                      <div className={this.state.showGrandTotal ? " declared_market col-12 declaredSessionShow px-0" : " declared_marketcol-12 declaredSessionHide px-0"} >

                        <Row className="even  body">
                          <Col className='col-8 text-left'>Declared Fancy Grand Total</Col>
                          <Col className='col-4 text-right'><span className={(declaredSessionTotal != null) ? (Number(declaredSessionTotal) < 0 ? "loss" : "profit") : ""}> {(declaredSessionTotal != null) ? Utils.TwoDecimalVal(declaredSessionTotal) : ""}</span></Col>
                        </Row>
                        <Row className="odd  body">
                          <Col className='col-8 text-left'>Declared Market Grand Total</Col>
                          <Col className='col-4 text-right'><span className={(declaredMarketTotal != null) ? (Number(declaredMarketTotal) < 0 ? "loss" : "profit") : ""}> {(declaredMarketTotal != null) ? Utils.TwoDecimalVal(declaredMarketTotal) : ""}</span></Col>
                        </Row>
                      </div>
                      <Row className={"declared_market_footer " + (isThemeGradient ? "bg-gradient-1" : "")}>
                        <Col className='col-8 text-left' >Grand Total</Col>
                        <Col className='col-4 text-right' ><span className={(declaredMarketTotal != null) ? ((Number(declaredSessionTotal) + Number(declaredMarketTotal)) < 0 ? "loss" : "profit") : ""}>{Utils.TwoDecimalVal(Number(declaredSessionTotal) + Number(declaredMarketTotal))}</span></Col>
                      </Row>

                    </div>
                    }

                  </div>
                </div>
              }

            </Fragment>
          }
        </Row>
      </Fragment>
    );
  }
}
FixtureDetailList.defaultProps = {
  _fancyOddsActiveClass: () => { },
  _fancyHandleOptionChange: () => { }
}
const FixtureDetailListTrans = withTranslation()(FixtureDetailList)

function mapStateToProps(state) {
  const { app } = state;
  return {
    WinlossObject: app.WinlossObject
  };
}

const mapDispatchToProps = dispatch => ({
  actionBetSlipRule: (messageRule) => dispatch(actionBetSlipRule(messageRule)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FixtureDetailListTrans);
