import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Table, FormGroup, Label, Button } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import { Http, API, Utils, _ } from 'Helpers';
import { CONSTANT } from 'Constants';
import { SiteContainer, MessageBox, TitleComponent } from 'Components';


class BetHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasMore: false,
      posting: false,
      Offset: CONSTANT.CURRENT_OFFSET,
      HistoryList: [],
      from_date: moment().toDate(),
      to_date: moment().toDate(),
      TotalProfit: '',
      TotalLiability: '',
      filter: 1
    }
  }

  // LIST API 

  getHistoryList = () => {
    const { filter, Offset, HistoryList, from_date, to_date } = this.state
    let param = {
      "limit": CONSTANT.ITEMS_PERPAGE_SM,
      "offset": Offset,
      "from_date": moment.utc(from_date).format('Y-M-D'),
      "to_date": moment.utc(to_date).format('Y-M-D'),
      "filter": filter
    }

    this.setState({ posting: true })
    Http.post(API.HISTORY, param).then(response => {
      this.setState({
        posting: false,
        HistoryList: (Offset === CONSTANT.CURRENT_OFFSET ? response.data.bet_data : [...HistoryList, ...response.data.bet_data]),
        hasMore: !_.isEmpty(response.data.bet_data),
      }, () => {
        if (Offset === CONSTANT.CURRENT_OFFSET) {
          this.setState({
            TotalLiability: !_.isUndefined(response.data.profit_liability) ? response.data.profit_liability.total_liability : '',
            TotalProfit: !_.isUndefined(response.data.profit_liability) ? response.data.profit_liability.total_pl : ''
          })
        }


      })
    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  // Handlers

  onFromDateChange = (date) => {
    this.setState({ from_date: date }, () => {
      this.getHistoryList()
    })
  }

  onToDateChange = (date) => {
    this.setState({ to_date: date }, () => {
      this.getHistoryList()
    })
  }

  fetchMoreData = () => {
    let offset = this.state.Offset + CONSTANT.ITEMS_PERPAGE_SM;
    this.setState({ Offset: offset }, () => {
      this.getHistoryList()
    });
  }

  deleteUnmatchBet = (betslip_uid, index) => {

    this.setState({ posting: true })
    let param = {
      "betslip_uid": betslip_uid,
    }
    Http.post(API.CANCEL_UM_BET, param).then(response => {
      let tempArr = this.state.HistoryList
      tempArr.splice(index, 1);
      this.setState({
        posting: false,
        HistoryList: tempArr,
      })
      Utils.notify(response.message)
    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  SearchData = () => {
    this.setState({
      Offset: CONSTANT.CURRENT_OFFSET
    }, () => {
      this.getHistoryList()
    })

  }

  FilterData = (filter) => {
    this.setState({ filter, HistoryList: [], Offset: CONSTANT.CURRENT_OFFSET }, () => {
      this.getHistoryList()
    })
  }

  ResetData = () => {
    this.setState({
      filter: 1,
      from_date: moment().toDate(),
      to_date: moment().toDate(),
      TotalProfit: '',
      TotalLiability: '',
      Offset: CONSTANT.CURRENT_OFFSET,
    }, () => {
      this.getHistoryList()
    })
  }

  // LIFE CYLCE METHODS
  componentDidMount() {
    this.getHistoryList()
  }

  render() {
    const { t } = this.props;
    const { HistoryList, hasMore, from_date, to_date, TotalLiability, TotalProfit, filter } = this.state
    return (
      <SiteContainer {...this.props}>
        <TitleComponent title={"Bets History"}/>
        <Container fluid className='gutters-container-7px'>
          <Row>
            <Col className='leftnav-col'>
              <div className="left-navigation">
                <div className="left-nav-item" onClick={() => this.props.history.push('ledgers')}>{t('Ledgers')}</div>
                <div className="left-nav-item active">{t('Bets History')}</div>
                <div className="left-nav-item" onClick={() => this.props.history.push('profit-loss')}>{t('Profit & Loss')}</div>
                <div className="left-nav-item" onClick={() => this.props.history.push('account-statement')}>{t('Account Statement')}</div>
              </div>
            </Col>
            <Col>

              <div className="odds-padding-row heading">
                <div className="title">{t('Bets History')}</div>
              </div>
              <Row className="inline-form sm gutters-10px">
                <Col xs={6} sm={2}>
                  <FormGroup>
                    <Label>{t('From Date')}</Label>
                    <DatePicker
                      onChange={this.onFromDateChange}
                      value={from_date}
                      calendarIcon={<i className='icon-calendar' />}
                      className='form-control'
                      clearIcon={null}
                      format="d/M/yyyy"
                    />
                  </FormGroup>
                </Col>
                <Col xs={6} sm={2}>
                  <FormGroup>
                    <Label>{t('To Date')}</Label>
                    <DatePicker
                      onChange={this.onToDateChange}
                      value={to_date}
                      minDate={from_date}
                      calendarIcon={<i className='icon-calendar' />}
                      className='form-control'
                      clearIcon={null}
                      format="d/M/yyyy"
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} sm={5}>
                  <Button color="primary" className={filter === 1 ? 'active' : ''} size="sm" onClick={() => this.FilterData(1)}>{t('Matched')}</Button>
                  <Button color="primary" className={filter === 2 ? 'active' : ''} size="sm" onClick={() => this.FilterData(2)}>{t('Unmatched')}</Button>
                  <Button color="primary" className={filter === 3 ? 'active' : ''} size="sm" onClick={() => this.FilterData(3)}>{t('Past')}</Button>
                  <Button color="primary" className={filter === 4 ? 'active' : ''} size="sm" onClick={() => this.FilterData(4)}>{t('Fancy')}</Button>
                  <Button color="primary" size="sm" onClick={() => this.ResetData()}>{t('Reset')}</Button>
                </Col>
                <Col className="total-block" xs={12} sm={3}>
                  {
                    (filter !== 3 && !_.isEmpty(TotalLiability)) &&
                    <div>
                      <h4>{t('Total Liability')}</h4>
                      <p className={TotalLiability === 0 ? '' : 'danger-color'}>{TotalLiability > 0 ? '-' : ''}{TotalLiability}</p>

                    </div>
                  }
                  {
                    !_.isEmpty(TotalProfit) &&
                    <div>
                      <h4>{t('Total Profit')}</h4>
                      <p className={TotalProfit > 0 ? "green-color" : TotalProfit < 0 ? 'danger-color' : ''}>{TotalProfit}</p>
                    </div>
                  }
                </Col>

              </Row>

              {!_.isEmpty(HistoryList) ? <InfiniteScroll
                dataLength={HistoryList.length}
                next={this.fetchMoreData}
                hasMore={hasMore}
                hasChildren={false}
                className=''
              >
                <Table className={"table-primary mt20 bethistory-responise-tbl " + (filter === 3 ? 'past' : '')}>
                  <thead>
                    <tr className="text-uppercase">
                      <th>{t('Placed')}</th>
                      <th style={{ width: '22%' }}>{t('Description')}</th>
                      <th>{t('Bet Type')}</th>
                      <th>{t('Type')}</th>
                      <th>{t('Rates')}</th>
                      <th>{t('Amount')}</th>
                      {filter !== 3 && <th>{t('Liability')}</th>}
                      <th>
                        {filter !== 3 ?
                          <span>
                            {t('Potential')}<br />{t('Profit')}

                          </span> :
                          <span>
                            {t('Profit/Loss')}

                          </span>}

                      </th>
                      <th>{t('Ip Address')}</th>
                      <th className="text-center">{t('Result')}</th>
                      <th>{t('Action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      _.map(HistoryList, (item, index) => {
                        return (
                          <Fragment key={index}>
                            <tr key={index}>
                              <td>
                                <span className="block">{Utils.dateTimeFormat(item.created_date, 'DD/MM/YYYY,')}</span>

                                <span className="block">{' '}{Utils.dateTimeFormat(item.created_date, 'h:mm A')}</span>
                              </td>
                              <td>
                                <span className="block">{item.event_name}</span>



                                {Number(item.bookmaker_type) !== 3 ?
                                  <span className="block">
                                    {item.market_name} {' - '}
                                    {_.map(item.market_runners, (ritm, rid) => {
                                      return (
                                        item.selection_id === ritm.selectionId ? ritm.runnerName : ''
                                      )
                                    })}
                                  </span>
                                  :
                                  <span className="block">
                                    {item.market_name} {' - '}
                                    {item.line}
                                  </span>
                                }
                                {
                                  Number(item.bookmaker_type) === 1 &&
                                  <span className="block">
                                    {item.odd_name}
                                  </span>
                                }
                                <span className="block">
                                  {' '}
                                    Bet ID: {item.betslip_uid}
                                </span>
                              </td>
                              <td>
                                {CONSTANT.GET_BOOKMAKER_TYPE[item.bookmaker_type]}
                              </td>
                              <td>
                                {
                                  Number(item.betting_type) === 1 ?
                                    <span className="type-color back">LAGAI</span>
                                    :
                                    <span className="type-color lay">
                                      KHAI
                                  </span>
                                }
                              </td>
                              <td>
                                {Number(item.bookmaker_type) !== 3 ?
                                  item.price :
                                  item.line}

                              </td>
                              <td>{item.stake}</td>
                              {filter !== 3 && <td>
                                <span className={item.liability === 0 ? '' : "danger-color"}>
                                  {item.liability === 0 ? '' : '-'}{item.liability}

                                </span>
                              </td>}
                              <td>
                                {
                                  filter !== 3 ?
                                    <span className="green-color">
                                      {filter === 1 ?

                                        item.pp
                                        :
                                        item.payout
                                      }
                                    </span>
                                    :

                                    item.winning_status === '3' ?
                                      <span>0</span>
                                      : <span className={Number(item.winning) - Number(item.liability) > 0 ? "green-color" : Number(item.winning) - Number(item.liability) < 0 ? "danger-color" : ''}>{Utils.TwoDecimalVal(Number(item.winning) - Number(item.liability))}</span>

                                }

                                {/* item.winning_status === '0' ? item.payout : 


                                  item.winning */}
                              </td>
                              <td>{item.ip_address}</td>
                              <td className="text-center">
                                {
                                  item.winning_status === '0' ?
                                    <span className="history-results pending">Pending</span> :
                                    item.winning_status === '1' ?
                                      <span className="history-results won">Won</span> :
                                      item.winning_status === '2' ?
                                        <span className="history-results loss">Loss</span> :
                                        item.winning_status === '3' ?
                                          <span className="history-results cancel">Cancelled</span> : ''

                                }
                              </td>
                              <td className="text-center">
                                {filter === 2 && <a onClick={() => { if (window.confirm(t('Are you sure you want to delete this bet?'))) { this.deleteUnmatchBet(item.betslip_uid, index) } }} className="delete-unmatch-row"><i className="icon-trash"></i></a>}
                              </td>
                            </tr>

                          </Fragment>
                        )
                      })
                    }





                  </tbody>
                </Table>
              </InfiniteScroll> :
                <MessageBox paragraph={t(CONSTANT.BLANK_HISTORY)} />
              }
            </Col>
          </Row>
        </Container>
      </SiteContainer>
    )
  }
}

export default withTranslation()(BetHistory);
