import React, { Component, Fragment } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { SiteContainer, TitleComponent } from 'Components';
import { _, Utils } from 'Helpers';
import router from "../../Router";

const { UserExchangeRoute } = router;
class WinnerLayout extends Component {
  render() {
    const { match } = this.props;

    return (
      <Fragment>
        <TitleComponent title={"Cup Winner"} />
        <SiteContainer {...this.props} header>
          <Switch>
            {
              _.map(UserExchangeRoute, (route, idx) => {
                return route.component ?
                  <Route key={idx}
                    path={match.url + route.path}
                    exact={route.exact}
                    name={route.name}

                    component={(props) => <route.component {...props}
                      page_id={route.page_id || Utils.CreateUUID()}
                      upcoming={false}
                      cup_winner={true}
                      ComponentName="CUP WINNER"
                    />}

                  />
                  :
                  null
              })
            }
            <Redirect from="*" to={match.url} />
          </Switch>
        </SiteContainer>
      </Fragment>
    );
  }
}

export default WinnerLayout;
