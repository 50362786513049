/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import { Modal, Form, FormGroup, Input, Button } from 'reactstrap'
import { withTranslation } from 'react-i18next';
import { Auth } from 'Service';

import { Http, Utils, GoogleMapsApi } from 'Helpers';
import config from 'config';

import { connect } from "react-redux";
import {
  loginClose,
  UpdateProfile
} from '../../Redux/Reducers'


class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      init: false,
      posting: false,
      formValid: false,
      username: "",
      password: "",
      location: '',
      ipApi: {}
    }
    if (Auth.currentUserValue) {
      // this.props.history.push('/');
    }
  }

  handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === 'password') {
      value = value.trim()
    }
    this.setState({ [name]: value }, this.validateForm);
  };

  validateForm() {
    const { password, username } = this.state
    this.setState({
      formValid:
        password !== '' &&
        username !== '' &&
        Utils.isFormValid(['password', 'username'], this.state)
    });
  }


  // GOOGLE : START
  _geoCodeCallback = (results, status, event) => {
    
    const google = window.google; // eslint-disable-line
    if (status === google.maps.GeocoderStatus.OK) {
      if (results[0]) {
        const add = results[0].formatted_address;
        const value = add.split(",");
        const count = value.length;
        const city = value[count - 3];
        // here we can dispatch action to search by city name and autofill the autocomplete
        this.setState({location: city.trim()})
      } else {
        console.log("address not found");
      }
    } else {
      console.log(status);
    }
  }
  _geocodeAddress = (geocoder, latlng) => {
    
    geocoder.geocode({ location: latlng }, this._geoCodeCallback);
  }
  _detectUserLocation = (event) => {
    // check for Geolocation support
    const google = window.google; // eslint-disable-line
    if (navigator.geolocation) {
      this._displayLocation = (latitude, longitude) => {
        const geocoder = new google.maps.Geocoder();
        const latlng = new google.maps.LatLng(latitude, longitude);
        this._geocodeAddress(geocoder, latlng);
      };
      this.geoSuccess = (position) => {
        this._displayLocation(position.coords.latitude, position.coords.longitude);
      };
      this.geoError = (error) => {
        switch (error.code) {
          case error.TIMEOUT:
            console.log("Browser geolocation error !\n\nTimeout.");
            break;
          case error.PERMISSION_DENIED:
            console.log("Only secure origins are allowed");
            break;
          case error.POSITION_UNAVAILABLE:
            console.log("Browser geolocation error !\n\nPosition unavailable.");
            break;
          default:
            console.log(error.code);
            break;
        }
      };
      navigator.geolocation.getCurrentPosition(this.geoSuccess, this.geoError);
    } else {
      console.log("Geolocation is not supported for this Browser/OS.");
    }
  }
  // GOOGLE : END

  componentDidMount() {
    // const gmapApi = new GoogleMapsApi(config.cognito.GOOGLE_API_KEY)
    // gmapApi.load().then(() => {
    //   this._detectUserLocation()
    // })
    if (Utils.isDev()) {
      this.setState({
        username: "dharmendra",
        password: "12345678"
      }, this.validateForm)
    }

    Http.getFatch('https://pro.ip-api.com/json/8.8.8.8?key=UmLe8ivIr3Qifq7').then(res => {
      // console.log(res, 'res')
      this.setState({
        ipApi: res,
        init: true
      });
    }).catch(error => {
      console.info(error, 'error')
    });


  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ posting: true });
    const { UpdateProfile, toggle } = this.props;
    const { username, password, 
      // location, 
      ipApi } = this.state;
      let location = ipApi.city;
      let isp = ipApi.isp
    Auth.login(username, password, location, isp )
    // Auth.login(username, password, location)
      .then(
        user => {
          this.setState({ posting: false });
          UpdateProfile()
          toggle()
          setTimeout(() => {
            window.location.reload(false);
          }, 100)
          
        },
        error => {
          this.setState({ posting: false });
        }
      );
  }

  render() {
    const {
      isOpen,
      toggle,
      t
    } = this.props;

    const ModalProps = {
      isOpen,
      toggle,
      size: 'sm',
      centered: true,
      backdrop: true,
      className: 'custom-modal'
    }

    const {
      username,
      password,
      formValid,
      posting,
      init
    } = this.state;

    return ( 
      <Modal {...ModalProps} className="custom-modal login-container">
        <div className="modal-title">{t('Log In')}</div>
        <Form onSubmit={this.onSubmit}>
          <FormGroup className='input-border-bottom' >
            <span className='label'>{t('Username')}</span>
            <Input type="text"
              id='username'
              name='username'
              required
              value={username}
              autoComplete="off"
              onChange={this.handleChange}
              invalid={Utils.isInvalid("username", username)}
              valid={Utils.isValid("username", username)} />
          </FormGroup>
          <FormGroup className='input-border-bottom m-0' >
            <span className='label'>{t('Password')}</span>
            <Input
              id='password'
              name='password'
              required
              type='password'
              maxLength={50}
              value={password}
              autoComplete="off"
              onChange={this.handleChange}
              invalid={Utils.isInvalid("password", password)}
              valid={Utils.isValid("password", password)} />
          </FormGroup>
          <FormGroup className='m-0'>
            <Button disabled={!formValid || posting || !init} type='submit' size="lg" color="primary" className='round' block>{t('Login')}</Button>
          </FormGroup>
        </Form>
      </Modal>
    )
  }
}
LoginModal.defaultProps = {
  isOpen: false,
  toggle: () => { }
}

const LoginModalTrans = withTranslation()(LoginModal)

function mapStateToProps(state) {
  const { login } = state;
  return {
    isOpen: login.isOpen
  };
}
const mapDispatchToProps = dispatch => ({
  toggle: () => dispatch(loginClose()),
  UpdateProfile: () => dispatch(UpdateProfile())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginModalTrans);
