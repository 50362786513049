import ApplicationSlice from './ApplicationSlice';
import LoginModalReducer from './LoginModalSlice';
import { betRuleReducer} from '../FixtureList_Redux/BetsRule/reducer';
import {setmarketReducer} from '../Market/reducer';
const rootReducer = {
    login: LoginModalReducer,
    app: ApplicationSlice,
    betRuleReducer:betRuleReducer,
    setmarketReducer: setmarketReducer,
};
export default rootReducer;