import React, { Component } from 'react';
import { Utils, _ } from 'Helpers';
class TemplateOptionSB extends Component {
  render() {
    const { 
      SelectedSports,
      item,
      // Mehtods
      handleOptionChange,
      _oddsActiveClass,
      SocketHighlight
    } = this.props;

    const isDisabled = () => {
      return item.bet_status !== 1 
      // ||  item.admin_status !== 1
    }
    return (
      <div {...{ className: `odd-market-collapse active` }} >
        <div className="heading">
          {item.market_name}
          {
            Utils.isDev() &&
            <b>{" --- "}{_.toUpper(item.template_name)}</b>
          }
          </div>
          <div className="odd-market-body">
            {
              _.map(JSON.parse(item.runners_odds), (obj, key) => {
                return (
                  <div className="cell" {...{ key }}>
                    {
                      obj.is_suspended === '1' &&
                      <div className="game-status-suspended template">SUSPENDED</div>
                    }

                    <div className='text-cell'>{obj.name}</div>
                    <div className='odd-market-last'>
                      <a {...{ className: `odd-market-btn ${isDisabled() ? 'disabled' : ''} ${_oddsActiveClass(obj.selection_id, item.market_event_id, 1) ? 'yellow' : ''} ${SocketHighlight({ id: obj.selection_id })}`}}
                        onClick={isDisabled() ? null : () => handleOptionChange({ fixture: {...item, sports_id: SelectedSports.sports_id}, odd: { ...obj, price: obj.odds, market_bet_id: obj.selection_id, selectionId: obj.selection_id }, betting_type: 1, selected_odd: obj })}
                      >
                        {obj.odds}
                      </a>
                    </div>
                  </div>
                )
              })
            }
            {
              _.isEmpty(JSON.parse(item.runners_odds)) &&
              <div className="odd-market-row">
                <div className="odd-market-line text-center">Odds Not available</div>
              </div>
            }
          </div>
      
      </div>
    );
  }
}
export default TemplateOptionSB;
