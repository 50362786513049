/* eslint-disable jsx-a11y/anchor-is-valid, eqeqeq */
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Row, Col, FormGroup, Input, Label } from 'reactstrap';

import { Utils, Http, API, _ } from 'Helpers';
import { Auth } from "Service";

class BetSettingModal extends Component {
    constructor(props) {
        super(props);
        const { BetslipStack, stake_type } = this.props;
        this.state = {
            posting: false,
            BetslipStack: BetslipStack,
            stake_type: stake_type
        }
    }
    handleChange = (index, e) => {
        const { value, min, max } = e.target;
        if (Number(value) > Number(max))
            return;

        const { BetslipStack } = this.state;
        if (!_.isEmpty(BetslipStack)) {
            _.map(BetslipStack, (item, key) => {
                if (key == index)
                    BetslipStack[key] = value;
            })
        }
        this.setState({ BetslipStack: BetslipStack });
    };

    onSubmit = (e) => {
        e.preventDefault();
        const { BetslipStack, stake_type } = this.state;
        this.setState({ posting: true }, this.props.callBack);
        let param = { stake_data: BetslipStack, stake_type: (stake_type == "fancy_betting" ? "fancy_stack" : (stake_type == "bookmaker_betslip" ? "bookmaker_stack" : "odds_stack")) };
        Http.post(API.SET_USER_STAKE, param)
            .then((response) => {
                Auth.updateUserStackSetting((stake_type == "fancy_betting" ? "fancy_stack" : (stake_type == "bookmaker_betslip" ? "bookmaker_stack" : "odds_stack")), BetslipStack.toString(','));
                this.setState({ posting: false });
                // this.props.callBack();
                Utils.notify(response.message);
            })
            .catch((error) => {
                this.setState({ posting: false });
            });
    }

    render() {
        const { isOpen, toggle } = this.props;
        const { BetslipStack } = this.state;
        const ModalProps = {
            isOpen,
            toggle,
            size: 'md',
            className: 'custom-modal2'
        }

        return (
            <Modal {...ModalProps}>
                <i className="close-btn icon-cancel" onClick={toggle} />
                <ModalHeader>Odds Stake</ModalHeader>
                <Form onSubmit={this.onSubmit} >
                    <ModalBody>
                        <Row className="mx-2 col-container">
                            <Col className="col-12">
                                <Label><h6><b>Customize odds stake</b></h6></Label>
                            </Col>
                            {
                                !_.isEmpty(BetslipStack) &&
                                _.map(BetslipStack, (item, key) => {
                                    return (
                                        item !== null &&
                                        <Col className="mx-0 col-12 col-sm-12 col-md-6 col-lg-4" key={key}>
                                            <FormGroup>
                                                <Input
                                                    type="number"
                                                    name="odds_stack"
                                                    min={1}
                                                    max={10000000}
                                                    value={item}
                                                    onChange={this.handleChange.bind(this, key)}
                                                    autoComplete="off"
                                                />
                                            </FormGroup>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <Button color="primary" size='sm' type='submit'>Update</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}

BetSettingModal.defaultProps = {
    UserProps: {},
    isOpen: true,
    toggle: () => { }
}

export default BetSettingModal;
