import validator from "validator";

// console.log(validator)
class Validation {
  static init(type, value) {
    let isValidate = null;
    if (value !== '' && value !== null && (value.length >= 0 || Date.parse(value))) {
      switch (type) {
        case "required":
          isValidate = validator.isEmpty(value) ? true : false;
          break;
        case "securityanswerone":
          isValidate = validator.isEmpty(value) ? true : false;
          break;
        case "securityanswertwo":
          isValidate = validator.isEmpty(value) ? true : false;
          break;

        case "address":
        case "address2":
          isValidate = !validator.isLength(value, { min: 3, max: 250 }) ? true : false;
          // if (value != '') {
          // } else {
          //   isValidate = false
          // }
          break;
        case "amount":
          isValidate = validator.isEmpty(value) ? true : false;
          break;

        case "aptnumber":
          isValidate = validator.isEmpty(value) ? true : false;
          break;

        case "zipcode":
        case "zip_code":
          isValidate = validator.isEmpty(value) ? true : false;
          // if (value != '') {
          // } else {
          //   isValidate = false
          // }
          break;

        case "city":
          isValidate = validator.isEmpty(value) ? true : false;
          // if (value != '') {
          // } else {
          //   isValidate = false
          // }
          break;

        case "phonenumber":
          isValidate = validator.isEmpty(value) ? true : false;
          break;

        case "ssnnumber":
          isValidate = value.length === 4 && value.match(/[^a-z ]\ *([.0-9])*\d/g) ? false : true;
          break;

        case "user_name":
          isValidate = validator.blacklist(value, '_.-') && validator.isLength(value, { min: 1, max: 50 }) ? false : true;
          break;

        case "date":
        case "dob":
          var someDate = Date.parse(value);
          isValidate = isNaN(someDate) ? true : false;
          break;

        case "email":
        case "Email":
          isValidate = validator.isEmail(value) ? false : true;
          break;

        case "password":
        case "old_password":
          isValidate = value.length >= 1 && value.length <= 16 ? false : true;
          break;
        case "PasswordCaps":
          isValidate = value.match(/^[A-Z](?=.*\d)[a-zA-Z\d]{8,16}$/g)
            ? false
            : true;
          break;
        case "FirstName":
        case "LastName":
        case "first_name":
        case "last_name":
        case "name":
          isValidate =
            validator.isLength(value, { min: 3, max: 100 }) &&
              value.match(/(([A-Za-z'-]+)?)$/)
              ? false
              : true;
          // if (value != '') {
          // } else {
          //   isValidate = false
          // }
          break;
        case "team_name":
          isValidate = value.length >= 4 ? false : true;
          break;
        case "game_name":
          isValidate = (validator.isLength(value, { min: 3, max: 50 }))
            ? false
            : true;
          break;
        case "username":
          isValidate = (validator.isLength(value, { min: 1, max: 50 }))
            ? false
            : true;
          break;
        case "phone_no":
        case "mobile":
          isValidate = value.match(
            /^(?:(\+)?([0-9]{1,3})?[-.● ]?)?\(?([0-9]{3})\)?[-.● ]?([0-9]{3})[-.● ]?([0-9]{4})$/
          )
            ? false
            : true;
          // if (value != '') {
          // } else {
          //   isValidate = false
          // }
          break;
        default:
          break;
      }
    } else {
      isValidate = false
    }
    return isValidate;
  } 
}

export default Validation;


/* 
  First name – Max character limit 100
  Last name – Max character limit 100
  Username – Min-Max character limit 3-50
  Street address – Max character limit 250
*/