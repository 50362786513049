import React, { Component, Fragment } from 'react';
import { _, Utils } from 'Helpers';
import { CONSTANT, Images } from 'Constants';
import moment from 'moment';
import Bubble from './Bubble';

class BetItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_betslip_drawer: true,
      isThemeGradient: Utils.getCookie('isThemeGradient') ? Number(Utils.getCookie('isThemeGradient')) == 1 ? true : false : false,
    }
  }

  updateDimensions = () => {
    this.setState({
      is_betslip_drawer: window.innerWidth < 850
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const {
      NavigationList,
      item,
      fixtureSelectHandler,
      showSportName,
    } = this.props;

    const { is_betslip_drawer, isThemeGradient } = this.state;

    return (
      is_betslip_drawer ? (
        <div className="mobile-game-event-item">
          <div className="row" onClick={() => fixtureSelectHandler(item)}>
            <div className="col-2 px-2 my-auto">
              <div className={"date-month " + (isThemeGradient ? "bg-gradient-2" : "")}>
                {moment(item.open_date).isValid() ? Utils.dateTimeFormat(item.open_date, 'DD') : Utils.dateTimeFormat(item.open_date, 'DD')}
                <span>{moment(item.open_date).isValid() ? Utils.dateTimeFormat(item.open_date, 'MMM') : Utils.dateTimeFormat(item.open_date, 'MMMM')}</span>
              </div>
              {
                Number(item.is_live) === 1 &&
                <div className="live-tv">LIVE</div>
              }
            </div>
            <div className="col-9 pr-1 my-auto">
              <div className="league-name">
                {item.league_name}
                {
                  _.map(NavigationList, (sport_item, key) => {
                    return (
                      (Number(sport_item.sports_id) === Number(item.sports_id)) &&
                      <span className="is-sport-name" key={key}>{sport_item.sports_name}</span>
                    )
                  })
                }
              </div>
              <div className="event-name my-1">{item.event_name}</div>
              <div className="event-time d-flex row">
                <div>
                  <i className="icon-clock mr-1"></i>{moment(item.open_date).isValid() ? Utils.dateTimeFormat(item.open_date, 'hh:mm A') : Utils.dateTimeFormat(item.open_date, 'hh:mm A')}
                </div>
                {/* <span className='markets ml-4'>Markets &nbsp;&nbsp;&nbsp;+{item.total_markets}</span> */}

                <div className="ml-1 all-market-details">
                  {
                    // item.event_type == "1" && 
                    < span className={"tv-icon " + (isThemeGradient ? "bg-gradient-2" : "")} onClick={() => fixtureSelectHandler(item)} />
                  }
                  {
                    // item.event_type == "1" &&
                    !_.isUndefined(item.market_count) && !_.isNull(item.market_count) && !_.isEmpty(item.market_count) && !_.isUndefined(item.market_count) && !_.isNull(item.market_count) && !_.isEmpty(item.market_count) &&
                    <img src={Images.SCORE_BOARD} alt="score_bord" width='18px' className={"bg-secondary-color p-1 " + (isThemeGradient ? "bg-gradient-2" : "")} onClick={() => fixtureSelectHandler(item)} />}
                  {!_.isUndefined(item.market_count) && !_.isNull(item.market_count) && !_.isEmpty(item.market_count) &&
                    <Fragment>
                      {
                        !_.isUndefined(item.market_count.match_odds_count) && !_.isNull(item.market_count.match_odds_count) && (Number(item.market_count.match_odds_count) > 0) &&
                        <Fragment>
                          <span className="mo" onClick={() => fixtureSelectHandler(item)}>MO</span>
                          <Bubble
                            onClick={() => fixtureSelectHandler(item)}
                            data={item.market_count.match_odds_count}
                          />
                        </Fragment>
                      }
                      {!_.isUndefined(item.market_count.bookmaker_count) && !_.isNull(item.market_count.bookmaker_count) && (Number(item.market_count.bookmaker_count) > 0) &&
                        <Fragment>
                          <span className="mo" onClick={() => fixtureSelectHandler(item)}>BM</span>
                          <Bubble
                            onClick={() => fixtureSelectHandler(item)}
                            data={item.market_count.bookmaker_count}
                          />
                        </Fragment>
                      }
                      {!_.isUndefined(item.market_count.fancy_count) && !_.isNull(item.market_count.fancy_count) && (Number(item.market_count.fancy_count) > 0) &&
                        <Fragment>
                          <span className="mo" onClick={() => fixtureSelectHandler(item)}>F</span>
                          <Bubble
                            onClick={() => fixtureSelectHandler(item)}
                            data={item.market_count.fancy_count}
                          />
                        </Fragment>
                      }
                      {!_.isUndefined(item.market_count.other_fancy_count) && !_.isNull(item.market_count.other_fancy_count) && (Number(item.market_count.other_fancy_count) > 0) &&
                        <Fragment>
                          <span className="mo" onClick={() => fixtureSelectHandler(item)}>OF</span>
                          <Bubble
                            onClick={() => fixtureSelectHandler(item)}
                            data={item.market_count.other_fancy_count}
                          />
                        </Fragment>
                      }
                      {!_.isUndefined(item.market_count.other_market_count) && !_.isNull(item.market_count.other_market_count) && (Number(item.market_count.other_market_count) > 0) &&
                        <Fragment>
                          <span className="mo" onClick={() => fixtureSelectHandler(item)}>OM</span>
                          <Bubble
                            onClick={() => fixtureSelectHandler(item)}
                            data={item.market_count.other_market_count}
                          />
                        </Fragment>
                      }
                    </Fragment>
                  }
                </div>


              </div>
            </div>
            <div className="col-1 px-0">
              <i className="icon-next mt-2"></i>
            </div>
          </div>
          {
            item.status === 'SUSPENDED' &&
            <div className="game-status-suspended">
              <span>{item.status}</span>
            </div>
          }
        </div>
      ) : (
        <div className={"game-event-item " + (isThemeGradient ? " bg-gradient-white " : "")}>
          <div className="event-details" onClick={() => fixtureSelectHandler(item)}>
            <div className="title">
              {
                showSportName &&
                <span>{Number(item.sports_id) === 2 ? "Cricket : " : (Number(item.sports_id) === 1 ? "Soccer : " : (Number(item.sports_id) === 3 ? "Tennis : " : " : "))}</span>
              }
              {
                item.event_name
              }
            </div>
            <div className="timestamp d-flex">
              {
                item.is_live === 1 ?
                  <span className="is-live-lbl">In-Play</span>
                  :
                  <Fragment>
                    <i className="icon-clock" /> {Utils.dateTimeFormat(item.open_date, 'HH:mm, DD/MM/YYYY')}
                  </Fragment>
              }
              {
                _.map(NavigationList, (sport_item, key) => {
                  return (
                    (Number(sport_item.sports_id) === Number(item.sports_id)) &&
                    <span className="is-sport-name" key={key}>{sport_item.sports_name}</span>
                  )
                })
              }
            </div>
            <div className="small my-1">{item.league_name}</div>
            <div className="small">
              {/* <a>+{item.total_markets} <span className='markets ml-4'>Markets</span></a> */}

              {/* market count start */}


              <div className="all-market-details mr-2">
                {
                  // item.event_type == "1" && 
                  < span className={"tv-icon " + (isThemeGradient ? " bg-gradient-2 " : "")} onClick={() => fixtureSelectHandler(item)} />}
                {
                  // item.event_type == "1" &&
                  !_.isUndefined(item.market_count) && !_.isNull(item.market_count) && !_.isEmpty(item.market_count) && !_.isUndefined(item.market_count) && !_.isNull(item.market_count) && !_.isEmpty(item.market_count) &&
                  <img src={Images.SCORE_BOARD} alt="score_bord" width='18px' className={"bg-secondary-color p-1 " + (isThemeGradient ? "bg-gradient-2" : "")} onClick={() => fixtureSelectHandler(item)} />}
                {!_.isUndefined(item.market_count) && !_.isNull(item.market_count) && !_.isEmpty(item.market_count) &&
                  <Fragment>
                    {
                      !_.isUndefined(item.market_count.match_odds_count) && !_.isNull(item.market_count.match_odds_count) && (Number(item.market_count.match_odds_count) > 0) &&
                      <Fragment>
                        <span className="mo" onClick={() => fixtureSelectHandler(item)}>MO</span>
                        <Bubble
                          onClick={() => fixtureSelectHandler(item)}
                          data={item.market_count.match_odds_count}
                        />
                      </Fragment>
                    }
                    {!_.isUndefined(item.market_count.bookmaker_count) && !_.isNull(item.market_count.bookmaker_count) && (Number(item.market_count.bookmaker_count) > 0) &&
                      <Fragment>
                        <span className="mo" onClick={() => fixtureSelectHandler(item)}>BM</span>
                        <Bubble
                          onClick={() => fixtureSelectHandler(item)}
                          data={item.market_count.bookmaker_count}
                        />
                      </Fragment>
                    }
                    {!_.isUndefined(item.market_count.fancy_count) && !_.isNull(item.market_count.fancy_count) && (Number(item.market_count.fancy_count) > 0) &&
                      <Fragment>
                        <span className="mo" onClick={() => fixtureSelectHandler(item)}>F</span>
                        <Bubble
                          onClick={() => fixtureSelectHandler(item)}
                          data={item.market_count.fancy_count}
                        />
                      </Fragment>
                    }
                    {!_.isUndefined(item.market_count.other_fancy_count) && !_.isNull(item.market_count.other_fancy_count) && (Number(item.market_count.other_fancy_count) > 0) &&
                      <Fragment>
                        <span className="mo" onClick={() => fixtureSelectHandler(item)}>OF</span>
                        <Bubble
                          onClick={() => fixtureSelectHandler(item)}
                          data={item.market_count.other_fancy_count}
                        />
                      </Fragment>
                    }
                    {!_.isUndefined(item.market_count.other_market_count) && !_.isNull(item.market_count.other_market_count) && (Number(item.market_count.other_market_count) > 0) &&
                      <Fragment>
                        <span className="mo" onClick={() => fixtureSelectHandler(item)}>OM</span>
                        <Bubble
                          onClick={() => fixtureSelectHandler(item)}
                          data={item.market_count.other_market_count}
                        />
                      </Fragment>
                    }
                  </Fragment>
                }
              </div>





              {/* market count end */}
            </div>

          </div>
          <div className={"last-cell betitem " + (isThemeGradient ? "bg-gradient-2" : "")} onClick={() => fixtureSelectHandler(item)}>
            <div>
              <span className="mb-0">{moment(item.open_date).isValid() ? Utils.dateTimeFormat(item.open_date, 'DD') : Utils.dateTimeFormat(item.open_date, 'DD')}</span>&nbsp;
              <span className="mb-0">{moment(item.open_date).isValid() ? Utils.dateTimeFormat(item.open_date, 'MMMM') : Utils.dateTimeFormat(item.open_date, 'MMMM')}</span>&nbsp;
              <span className="mb-0">{moment(item.open_date).isValid() ? Utils.dateTimeFormat(item.open_date, 'h:m a') : Utils.dateTimeFormat(item.open_date, 'h:m a')}</span>
            </div>
          </div>
          {
            item.status === 'SUSPENDED' &&
            <div className="game-status-suspended">
              <span>{item.status}</span>
            </div>
          }
        </div>
      )
    );
  }
}
BetItem.defaultProps = {
  item: {},
  SocketHighlight: () => { }
}
export default BetItem;
