import React, { Component } from 'react'
import { Container, Table, Row, Col, Card, CardBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { Http, Utils, _, API } from "Helpers";
import moment from 'moment';
import { TitleComponent } from 'Components';
class Ledgers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ledgerData: [],
      posting: false,
      is_betslip_drawer: true,
      isThemeGradient: Utils.getCookie('isThemeGradient') ? Number(Utils.getCookie('isThemeGradient')) == 1 ? true : false : false,
    }
    this.getLedgerList();

  }

  updateDimensions = () => {
    this.setState({
      is_betslip_drawer: window.innerWidth < 850
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  redirect = (item) => {
    if (item.source == 3) {
      this.props.history.push({
        pathname: "/ledgers-details",
        state: { LedgerDetails: item },
      })
    }
  }

  //LIST LEDGERS API
  getLedgerList = () => {
    let param = {}
    this.setState({ posting: true })
    Http.post(API.LEDGERS, param).then(response => {
      this.setState({
        posting: false,
        ledgerData: response.data
      }, () => {

      })
    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  render() {
    const { ledgerData, is_betslip_drawer, isThemeGradient } = this.state;
    return (
      <Container className='gutters-container-7px'>
        <TitleComponent title={"Ledger"} />
        {
          is_betslip_drawer ? (
            <Row>
              <Col className="col-12 mobile-ledger-page-title"><h3>Ledger</h3></Col>
              <Col className="px-0 col-12">
                <Card className="mobile-ledger-page-card">
                  <CardBody className="px-0">
                    <Table className="mobile-ledger-table">
                      <thead className={isThemeGradient ? "bg-gradient-3" : ""}>
                        <tr>
                          <th className="text-left">DATE</th>
                          <th className="text-left">ENTRY</th>
                          <th>DENA</th>
                          <th>LENA</th>
                          <th>BALANCE</th>
                        </tr>
                      </thead>
                      {ledgerData.length > 0 ? (
                        <tbody>
                          {
                            _.map(ledgerData, (item, index) => {
                              return (
                                <tr key={index}>
                                  <td className="text-left">{moment(item.date_added, 'YYYY-MM-DD HH:mm:ss').tz('UTC').format("DD MMM YY")}</td>
                                  <td className="text-left" onClick={() => { this.redirect(item); }}>{item.source == 4 ? item.remark : item.source == 3 ? item.detail : item.source == 2 ? "Cash Received" : "Cash Paid"}</td>
                                  <td>{item.type == 2 ? Math.abs(Utils.TwoDecimalVal(item.amount)) : 0}</td>
                                  <td>{item.type == 1 ? Math.abs(Utils.TwoDecimalVal(item.amount)) : 0}</td>
                                  <td>{Utils.TwoDecimalVal(item.ledger_balance)}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="5">No record to display</td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <div className="container px-0">
                  <div className="led_tab mx-0">
                    <Table striped bordered responsive className="mx-0 my-2">
                      <thead className={isThemeGradient ? "bg-gradient-3" : ""}>
                        <tr>
                          <th>DATE</th>
                          <th>ENTRY</th>
                          <th>DENA</th>
                          <th>LENA</th>
                          <th>BALANCE</th>
                        </tr>
                      </thead>
                      {ledgerData.length > 0 ? (
                        <tbody>
                          {
                            _.map(ledgerData, (item, index) => {
                              return (
                                <tr key={index}>
                                  <td><span className="icon-clock"></span> {moment(item.date_added, 'YYYY-MM-DD HH:mm:ss').tz('UTC').format("DD MMM YY")}</td>
                                  <td onClick={() => { this.redirect(item); }} className="spt_nm">{item.source == 4 ? item.remark : item.source == 3 ? item.detail : item.source == 2 ? "Cash Received" : "Cash Paid"}</td>
                                  <td>{item.type == 2 ? Math.abs(Utils.TwoDecimalVal(item.amount)) : 0}</td>
                                  <td>{item.type == 1 ? Math.abs(Utils.TwoDecimalVal(item.amount)) : 0}</td>
                                  <td>{Utils.TwoDecimalVal(item.ledger_balance)}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="5">No record to display</td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
          )
        }
      </Container>
    )
  }
}

export default withTranslation()(Ledgers)
