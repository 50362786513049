import React, { Component, Fragment } from 'react';
import { Container, Row, Col, FormGroup, Label, Button, Table } from 'reactstrap';
import DatePicker from 'react-date-picker';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import { SiteContainer, MessageBox, TitleComponent } from 'Components';
import { CONSTANT } from 'Constants';
import { Http, API, Utils, _ } from 'Helpers';

class ProfitLoss extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasMore: false,
      posting: false,
      Offset: CONSTANT.CURRENT_OFFSET,
      from_date: moment().toDate(),
      to_date: moment().toDate(),
      activeId: '',
      ProfitList: []
    }
  }

  // LIST API 

  getProfitList = () => {
    const { Offset, ProfitList, from_date, to_date } = this.state
    let param = {
      "limit": CONSTANT.ITEMS_PERPAGE_SM,
      "offset": Offset,
      "from_date": moment.utc(from_date).format('Y-MM-DD'),
      "to_date": moment.utc(to_date).format('Y-MM-DD'),
    }

    this.setState({ posting: true })
    Http.post(API.PROFIT_LOSS, param).then(response => {
      this.setState({
        posting: false,
        ProfitList: Offset === CONSTANT.CURRENT_OFFSET ? response.data : [...ProfitList, ...response.data],
        hasMore: !_.isEmpty(response.data),
      }, () => {

      })
    }).catch(error => {
      this.setState({ posting: false })
    });
  }

  // Handlers

  SearchData = () => {
    this.setState({
      Offset: CONSTANT.CURRENT_OFFSET
    }, () => {
      this.getProfitList()
    })

  }

  onFromDateChange = (date) => {
    this.setState({ from_date: date }, this.validateForm)
  }

  onToDateChange = (date) => {
    this.setState({ to_date: date }, this.validateForm)
  }

  validateForm() {
    const {
      from_date
    } = this.state;
    this.setState({
      formValid:

        Utils.isInvalid("date", from_date) === false

    });
  }

  showMore = (id) => {
    if (id === this.state.activeId) {
      this.setState({ activeId: '' })
    } else {

      this.setState({ activeId: id })
    }
  }

  fetchMoreData = () => {
    let offset = this.state.Offset + CONSTANT.ITEMS_PERPAGE_SM;
    this.setState({ Offset: offset }, () => {
      this.getProfitList()
    });
  }


  // LIFE CYLCE METHODS
  componentDidMount() {
    this.getProfitList()
  }

  render() {
    const { t } = this.props;
    const { from_date, to_date, activeId, ProfitList, hasMore } = this.state
    return (
      <SiteContainer {...this.props}>
        <TitleComponent title={"Profit & Loss"}/>
        <Container fluid className='gutters-container-7px'>
          <Row>
            <Col className='leftnav-col'>
              <div className="left-navigation">
                <div className="left-nav-item" onClick={() => this.props.history.push('ledgers')}>{t('Ledgers')}</div>
                <div className="left-nav-item" onClick={() => this.props.history.push('bet-history')}>{t('Bets History')}</div>
                <div className="left-nav-item active">{t('Profit & Loss')}</div>
                <div className="left-nav-item" onClick={() => this.props.history.push('account-statement')}>{t('Account Statement')}</div>
              </div>
            </Col>
            <Col>

              <div className="container">
                <div className="odds-padding-row heading">
                  <div className="title">{t('Profit & Loss')}</div>
                </div>

                <Row className="inline-form gutters-10px">
                  <Col sm={2}>
                    <FormGroup>
                      <Label>{t('Opening Date')}</Label>
                      <DatePicker
                        onChange={this.onFromDateChange}
                        onCalendarOpen={this.onCalendarOpen}
                        value={from_date}
                        calendarIcon={<i className='icon-calendar' />}
                        className='form-control'
                        clearIcon={null}
                        format="d/M/yyyy"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={2}>
                    <FormGroup>
                      <Label>{t('End Date')}</Label>
                      <DatePicker
                        onChange={this.onToDateChange}
                        onCalendarOpen={this.onCalendarOpen}
                        value={to_date}
                        minDate={from_date}
                        calendarIcon={<i className='icon-calendar' />}
                        className='form-control'
                        clearIcon={null}
                        format="d/M/yyyy"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <Button color="primary" size="sm" onClick={() => this.SearchData()}>{t('Search')}</Button>
                    {/* <Button color="primary" size="sm">Download CSV</Button> */}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {ProfitList.length > 0 ?
                      <InfiniteScroll
                        dataLength={ProfitList.length}
                        next={this.fetchMoreData}
                        hasMore={hasMore}
                        hasChildren={false}
                        className=''
                      >
                        <Table className="table-primary mt20 pl-responise-tbl">
                          <thead>
                            <tr>
                              <th>{t('Date')}</th>
                              <th style={{ width: '30%' }}>{t('Game Name')}</th>
                              <th>{t('Sport')}</th>
                              <th>{t('Stake')}</th>
                              <th>{t('Gross P/L')}</th>
                              <th>Match Comission</th>
                              <th>Session Comission</th>
                              <th>Other Charges</th>
                              <th>{t('Net Profit')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              _.map(ProfitList, (item, index) => {
                                return (
                                  <Fragment key={index}>


                                    <tr key={index}>
                                      <td>{Utils.dateTimeFormat(item.game_start_date, 'DD/MM/YYYY')}</td>
                                      <td>

                                        <span>
                                          <a onClick={() => this.showMore(item.event_id)} className="expand-bets">{activeId === item.event_id ? '-' : '+'}</a>

                                        </span>

                                        <span>
                                          {
                                            item.event_name
                                          }
                                        </span>


                                      </td>
                                      <td>
                                        {Number(item.bet_type) !== 2 &&
                                          item.sports_name
                                        }
                                      </td>
                                      <td>{item.total_stake}</td>

                                      <td> <span className={item.pl === 0 ? '' : item.pl > 0 ? 'green-color' : 'danger-color'}> {Utils.TwoDecimalVal(item.pl)}</span></td>
                                      <td>{item.match_commission}</td>
                                      <td>{item.session_commission}</td>
                                      <td>{item.other_charges}</td>
                                      {/* <td><span className={item.admin_commission === 0 ? '' : 'danger-color'}>{item.admin_commission !== 0 ? '-' : ''}{Utils.TwoDecimalVal(item.admin_commission)}</span></td> */}
                                      <td><span className={item.net_pl === 0 ? '' : item.net_pl > 0 ? 'green-color' : 'danger-color'}> {Utils.TwoDecimalVal(item.net_pl)}</span></td>
                                    </tr>
                                    {activeId === item.event_id &&
                                      <Fragment>
                                        {
                                          _.map(item.detail, (itm, idx) => {
                                            return (
                                              <tr key={idx} className={activeId === item.event_id ? 'active-row' : 'none'}>
                                                <td></td>
                                                <td>
                                                  {
                                                    itm.market_name
                                                  }
                                                </td>
                                                <td></td>
                                                <td>{itm.stake}</td>
                                                <td><span className={itm.pl === 0 ? '' : itm.pl > 0 ? 'green-color' : 'danger-color'}>{Utils.TwoDecimalVal(itm.pl)}</span></td>
                                                <td></td>
                                                <td></td>

                                                {/* <td><span className={itm.admin_commission === 0 ? '' : 'danger-color'}>{itm.admin_commission !== 0 ? '-' : ''}{Utils.TwoDecimalVal(itm.admin_commission)}</span></td> */}
                                                {/* <td><span className={itm.net_pl === 0 ? '' : itm.net_pl > 0 ? 'green-color' : 'danger-color'}> {Utils.TwoDecimalVal(itm.net_pl)}</span></td> */}
                                                <td></td>
                                                <td></td>
                                              </tr>
                                            )
                                          }
                                          )}
                                      </Fragment>


                                    }
                                  </Fragment>

                                )
                              })
                            }

                          </tbody>

                        </Table>

                      </InfiniteScroll>
                      :
                      <MessageBox heading={t(CONSTANT.FIXTURE_BLANK_HEADING)} />

                    }

                  </Col>
                </Row>



              </div>


            </Col>
          </Row>
        </Container>
      </SiteContainer>
    )
  }
}

export default withTranslation()(ProfitLoss);
