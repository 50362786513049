const EVENT = {
  "MARKET": "marketUpdated",
  "MARKET_SP": "marketUpdatedSP",
  "FANCY": "fancySessionUpdated",
  "FANCY_REPLACED": "fancySessionReplaced",
  "SCORE": "scoreUpdated",
  "AMOUNT_DW": "AmountDW",
  "BALANCE_UPDATED": "balanceUpdated",
  "SPORTS_SETTING_UPDATED": "sportsSettingUpdated",
  "STATUSUPDATEDMK": "StatusUpdatedMk",
  "GLOBAL_SETTING": "global_setting",
  "SETTING_SPORTS": "settingSports",
  "BOOKMARKER_MARKET_UPDATED": "bookmakerMarketUpdated",
  "DIAMOND_MARKET_UPDATED": "diamondMarketUpdated",
  "MATCH_COMMENTARY_UPDATED": "commentaryUpdated",
  "ANNOUNCEMENT_UPDATED": "announcementUpdated",
  "SCOREBOARD_UPDATED": "scoreboardUpdated",
  "SCOREMATCH_UPDATED": "soccermatchUpdated",
  "CENTRAL_MARKET_UPDATED": "centralMarketUpdated",
  "CENTRAL_FANCY_MARKET_UPDATED": "centralFancyMarketUpdated",
  "GLOBAL_SEARCH_UPDATED": "globalsearchUpdated",
  "EVENT_ANNOUNCEMENT_UPDATED": "eventannouncementUpdated",
  "EVENT_ANNOUNCEMENT_UPDATE": "eventAnnouncementUpdate",
  "EVENT_CENTRAL_SCORE_UPDATED": "centralScoreUpdated",
  "ENTITY_SPORT_SCORE_UPDATED": "entitySportScoreUpdated",
  "USER_LOCK_STATUS_UPDATE": "userLockStatusUpdate",
  "RESULTFANCY": "resultFancy",
  "MARKET_SETTLE": "marketSettlementDone",
  "MK_STATUS_UPDATED": "mkStatusUpdated",
  "BET_CLOSE_TIME": "closeBetTime",
  "MATCH_SETTING": "matchSetting",
  "RECONNECT_SOCKET": "reconnectSocket",
  "RELOAD_EVENT_PAGE": "reloadEventPage",
  "MARKET_ROLLBACK": "marketRollback",
  "DOMAIN_SETTING_UPDATED": "domain_setting_updated",
  "WA_UPDATED": "wa_updated",
  "SESSION_COMMENTRY": "sessionCommentary",
  "MARKET_COMMENTRY": "marketCommentary",
}

export default { ...EVENT }
