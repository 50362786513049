/* eslint-disable jsx-a11y/anchor-is-valid, eqeqeq */
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col } from 'reactstrap';

import { Utils, Http, API, _ } from 'Helpers';
import { Fragment } from 'react';
// import { ConfirmationModal } from 'Modals';

class ApplyColorThemeModal extends Component {
  constructor(props) {
    super(props);
    const { MatchProps, UserProps } = this.props
    this.state = {
      posting: false,
      formValid: false,
      Themes: [],
      currentUser: JSON.parse(localStorage.getItem('currentUser')),
      // curruntColor: UserProps ? UserProps.domain_detail.theme.primary ? UserProps.domain_detail.theme.primary : "#2E4150" : "#2E4150",
      // curruntColor: Utils.getCookie('dreamxbet_theme_bg_primary'),
      curruntColor: localStorage.getItem('PrimaryColor'),
      selectedColor: null,
      color1: "",
      color2: "",
      ConfirmationModalShow: false
      // user_guid: currentUser.user_guid

    }
    this.getColorThemes()
  }

  getColorThemes = () => {
    Http.post(API.GET_ALL_THEMES)
      .then((response) => {
        this.setState({ Themes: response.data });
      })
      .catch(() => { });
  }

  modalShow = (name) => {
    this.setState({
      [name]: true
    })
  }

  modalHide = (name) => {
    this.setState({
      ConfirmationModalShow: false
    })
  }


  onSelectColor = (primary, secondary) => {
    this.setState({
      selectedColor: {
        primary,
        secondary
      },
      curruntColor: primary
    },
      () => this.onSubmit())
  }

  onSubmit = () => {
    this.setState({ posting: true })
    const { selectedColor } = this.state;

    const { UserProps, callBack } = this.props;

    let param = {
      user_guid: UserProps.user_guid,
      theme: selectedColor
    }

    if (selectedColor === null) {
      this.setState({
        formValid: false,
        // confirmPopupEvent: "CA",
        error: true,
        errorbuttom: "Ok",
        confirmTitle: 'Error',
        confirmMessage: 'Please Select Color Theme',
        posting: false,
      }, () => { this.modalShow('ConfirmationModalShow') })
    } else {
      document.body.style.setProperty('--main-color', selectedColor.primary);
      document.body.style.setProperty('--secondary-color', selectedColor.secondary);
      localStorage.setItem('PrimaryColor', selectedColor.primary);

      Http.post(API.SAVE_COLOR_THEME, param).then(response => {
        this.setState({ posting: false }, this.props.callBack)
        this.setState({ posting: false })
        Utils.notify(response.message);
      }).catch(error => {
        this.setState({ posting: false })
      });
    }

  }
  callback = () => {
    this.modalHide();
    this.props.history.push("/admin");
  }

  render() {
    const { isOpen, toggle } = this.props
    const ModalProps = {
      isOpen,
      toggle,
      size: 'md',
      className: 'custom-modal custom-logo-upload-modal'
    }
    const { posting, Themes, curruntColor, selectedColor, ConfirmationModalShow } = this.state

    const ConfirmationModalProps = {
      isOpen: ConfirmationModalShow,
      toggle: this.modalHide,
      message: this.state.confirmMessage,
      title: this.state.confirmTitle,
      error: this.state.error,
      errorbuttom: this.state.errorbuttom,
      callback: () => this.callback(),
    }


    return (
      <Modal {...ModalProps}>
        <i className="close-btn icon-cancel" onClick={toggle} />
        <ModalHeader className='text-center font-weight-bold'>{"APPLY THEME"}</ModalHeader>
        <div>
          <ModalBody>
            <Row className="mt-1">
              {_.map(Themes, (item, index) => {
                return (
                  <Col className='col-3 p-0 my-1' key={index} >
                    <div className={'e-tile ' + (item.color_code.primary === curruntColor && "selected-color")} key={index} style={{ backgroundColor: item.color_code.primary, }} onClick={() => this.onSelectColor(item.color_code.primary, item.color_code.secondary)}></div>
                  </Col>
                )
              })}
            </Row>
          </ModalBody>
        </div>
      </Modal>
    );
  }
}

ApplyColorThemeModal.defaultProps = {
  MatchProps: {},
  isOpen: true,
  toggle: () => { }
}

export default ApplyColorThemeModal;

