import React, { Component } from 'react'
import { Modal } from 'reactstrap'
import { Utils, AuthHelp } from 'Helpers';
import { withTranslation } from 'react-i18next';

import { connect } from "react-redux";
import {
  LangModalClose,
} from '../../Redux/Reducers'

class LanguageSwitchModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedLanguage: Utils.getCookie('dreamxbet_lang')
    }
  }
  handleChange = (lang) => {
    this.setState({
      SelectedLanguage: lang
    })
  }

  onSubmit = () => {
    const { SelectedLanguage } = this.state;
    const { i18n } = this.props;
    i18n.changeLanguage(SelectedLanguage);
    Utils.setCookie('dreamxbet_lang', SelectedLanguage, 365)
    if (AuthHelp.getAuth()) {
      localStorage.setItem('lang_posting', JSON.stringify(true));
    }
    this.props.toggle()
    window.location.reload(false);
  }

  render() {
    const { isOpen, toggle } = this.props;
    const { SelectedLanguage } = this.state;
    return (
      <Modal isOpen={isOpen} toggle={toggle} className='language-dialog' size={'sm'} backdrop='static'>
        <h2 className='title'>Select Language</h2>
        <div className="lang-btn-row">
          <div className={`lang-btn ${SelectedLanguage === 'en' ? 'active' : ''}`} onClick={() => this.handleChange('en')}>
            <h3 className='text'>English</h3>
            <h3 className='text'>English</h3>
          </div>
          <div className={`lang-btn ${SelectedLanguage === 'hi' ? 'active' : ''}`} onClick={() => this.handleChange('hi')}>
            <h3 className='text'>Hindi</h3>
            <h3 className='text'>हिंदी</h3>
          </div>
          <div className={`lang-btn ${SelectedLanguage === 'ben' ? 'active' : ''}`} onClick={() => this.handleChange('ben')}>
            <h3 className='text'>Bengali</h3>
            <h3 className='text'>বাংলা</h3>
          </div>
        </div>
        <div className="lang-modal-footer">
          <button disabled={SelectedLanguage === ''} className="confirm-btn" onClick={() => this.onSubmit()} />
        </div>
      </Modal>
    )
  }
}

const LanguageSwitchTrans = withTranslation()(LanguageSwitchModal)
function mapStateToProps(state) {
  const { app } = state;
  return {
    isOpen: app.LangModalShow
  };
}

const mapDispatchToProps = dispatch => ({
  toggle: () => dispatch(LangModalClose()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSwitchTrans);
