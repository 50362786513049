import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { SiteContainer, TitleComponent } from 'Components';
import { _, Utils } from "Helpers";

class CommonResponsibleGambling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AppMasterData: Utils.getMasterData(),
    };
  }
  render() {
    const { t } = this.props;
    const { AppMasterData } = this.state;
    return (
      <SiteContainer {...this.props} header staticPage>
        <TitleComponent title={"Common Offers"} />
        <Container fluid className='gutters-container-7px'>

          <Row>
            <Col>
              <div className="odds-padding-row heading">
                <h4 className="title">{t('Policy')}</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="static-container">
                <h4 className="s-head mb30">{t((!_.isUndefined(AppMasterData) && !_.isNull(AppMasterData) && !_.isUndefined(AppMasterData.domain_detail) && !_.isNull(AppMasterData.domain_detail) && !_.isUndefined(AppMasterData.domain_detail.website_name) && !_.isNull(AppMasterData.domain_detail.website_name) && (AppMasterData.domain_detail.website_name !== '') ? AppMasterData.domain_detail.website_name : 'SILVARBET')+' is committed to doing everything possible to give its Customers an enjoyable gaming experience, whilst recognizing that gambling can cause problems for a minority of individuals. To ensure that you continue to enjoy safe and manageable play we fully support Responsible Gaming and have numerous measures in place to offer checks.')}</h4>
                <h4 className="s-head mb30">{t('1. Gambling Self-Assessment and Help Organizations')}</h4>
                <ul className="unorderd-list">
                  <li>{t('For assistance in identifying whether to modify or seek help for your gambling behavior, we suggest you complete the NODS Self-Assessment in order to more impartially assess and better understand your current levels of play.')}</li>
                  <li>{t('If you or someone you know has a gambling problem we advise you consider assistance from the below recognized organization')}:</li>
                  <li>{t('Gamblers Anonymous')} </li>
                  <li>{t('Website')}:  http://www.gamblersanonymous.org</li>
                </ul>
                <h4 className="s-head">{t('2. Identity Checks')}</h4>
                <ul className="unorderd-list">
                  <li>{t("We take stringent steps to ensure that only people of legal age participate and enter "+(!_.isUndefined(AppMasterData) && !_.isNull(AppMasterData) && !_.isUndefined(AppMasterData.domain_detail) && !_.isNull(AppMasterData.domain_detail) && !_.isUndefined(AppMasterData.domain_detail.website_name) && !_.isNull(AppMasterData.domain_detail.website_name) && (AppMasterData.domain_detail.website_name !== '') ? AppMasterData.domain_detail.website_name : 'SILVARBET')+". Such checks include requesting the Customer to submit a copy of a photographic identification documents (such as a driver's license, a passport or a government-issued identity card), in order to verify both age and identity.")}</li>
                  <li>{t('The legal age for gaming online varies from country to country and so we advise that you check the rules of your jurisdiction before you play.')}</li>
                </ul>

              </div>
            </Col>
          </Row>
        </Container>
      </SiteContainer>
    )
  }
}

export default withTranslation()(CommonResponsibleGambling);
