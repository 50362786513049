import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import { Images } from 'Constants';

class DefaultFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Init: false
    }
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        Init: true
      });
    }, 500)

  }
  componentWillUnmount() {
    this.setState = () => {
      return;
    };
  }

  render() {
    const { t } = this.props;
    const { Init } = this.state;
    return (
      Init &&
      <div className="app-footer" id='AppFooter'>
        <Fragment>
          <Container fluid>
            <Row>
              <Col sm={12} lg={3}>
                <img src={Images.Logo} alt="" className="logo" />
                <div className="web-description">{t('DefaultFooterTxt')}</div>
              </Col>
              <Col sm={3} lg={2}>
                <div className="footer-heading"></div>
                {/* <Row className='logo-list'>
                  <Col sm={12} xs={7}><img src={Images.MGA} width='146' alt=""/></Col>
                  <Col sm={12} xs={5}><img src={Images.GAMBLING_COMMISSION} width='92' alt=""/></Col>
                </Row> */}
              </Col>
              <Col sm={5} lg={3}>
                <div className="footer-heading">{t('payment methods')}</div>
                {/* <Row className='logo-list'>
                  <Col xs={6}><img src={Images.UPI} width='80' alt=""/></Col>
                  <Col xs={6}><img src={Images.NET_BANKING} width='138' alt=""/></Col>
                  <Col xs={6}><img src={Images.PAYTM} width='85' alt=""/></Col>
                  <Col xs={6}><img src={Images.GOOGLE_PAY} width='80' alt=""/></Col>
                  <Col xs={6}><img src={Images.PHONE_PE} width='86' alt=""/></Col>
                </Row> */}
              </Col>
              <Col sm={4} lg={4}>
                <div className="footer-heading">{t('support')}</div>
                <ul className="support-list">
                  {/* <li>
                    <i className="icon-envelope"/>
                    <div className="overflow-hidden">
                    support@dreamxbet.com
                    </div>
                  </li>
                  <li>
                    <i className="icon-phone"/>
                    <div className="overflow-hidden">
                      <div className='phone'>+44 7537121156</div>
                    </div>
                  </li>
                  <li>
                    <i className="icon-whatsapp"/>
                    <div className="overflow-hidden">
                      <div className='phone'>+44 7863910533</div>
                    </div>
                  </li> */}
                </ul>
                <nav className="social-list">
                  {/* <a className="icon-facebook" />
                  <a className="icon-whatsapp" />
                  <a className="icon-email" />
                  <a className="icon-youtube" /> */}
                </nav>
              </Col>
            </Row>
          </Container>
          <div className="footer-copy-right">{process.env.REACT_APP_WEBSITE_COPYRIGHT}</div>
        </Fragment>
      </div>
    )
  }
}

export default withTranslation()(DefaultFooter);
