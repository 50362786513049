import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Auth } from 'Service';

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route {...rest} render={props => {
      const currentUser = Auth.currentUserValue;
      if (!currentUser) {
          // not logged in so redirect to login page with the return url
          return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      }

      // check if route is restricted by role
      if (roles && roles.indexOf(currentUser.role) === -1) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: '/home'}} />
      }

      // authorised so return component
      return <Component {...props} />
  }} />
)
export default PrivateRoute;
