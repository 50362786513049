import _ from 'Helpers/_/_';

const DevHostname = [
    'localhost',
    '128.199.27.56',
    '172.20.10.4',
    'bsf222.com',
    'bsf222.com',
    'staging.bsf222.com',
    'silvarbet.com',
    'white.bsf222.com',
    'stagwhite.bsf222.com'
]

const FeedHostname = [
    'feed.bsf222.com'
]
const StagingHostname = [
    'bsf222.com'
]
const BSFStagingHostname = [
    'staging.bsf222.com'
]
const Qa_white_label = ['white.bsf222.com'];

const Staging_white_label = ["stagwhite.bsf222.com"];

const prodHostname = [
    'oldskr.bsf222.com'
]

const liveHostname = [
    'silvarbet.com'
]

const regex = /(192)\.(168)(\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])){2}$/gm
const hostname = window.location.hostname;
const protocol = window.location.protocol;
const origin = window.location.origin;

const development = {
    s3: {
        BUCKET: "https://diamond-image.s3.ap-south-1.amazonaws.com/appstatic/",
        BUCKET_DATA_PREFIX: "dev",
        BUCKET_STATIC_DATA_ALLOWED: "1",
    },
    apiGateway: "https://bsf222.com", //protocol + "//bsf222.com",//"//dreamxbet.com",//
    Socket: {
        URL: "https://node.bsf222.com:2222", //protocol + "//3.7.233.236:4444", //"//bsf222.com:4000",//"//3.108.145.190:4000",//
        Enable: true
    },
    cognito: {
        GOOGLE_API_KEY: "6LenKZ0aAAAAAL1EB1WwVIYVlgHWUAzqWGkrckYP",
        WEB_SITE_NAME: "BSF222"
    },
    REDUX_LOGGER: false
};

const feedIntegration = {
    s3: {
        BUCKET: "https://diamond-image.s3.ap-south-1.amazonaws.com/appstatic/",
        BUCKET_DATA_PREFIX: "dev",
        BUCKET_STATIC_DATA_ALLOWED: "1",
    },
    apiGateway: protocol + "//feed.bsf222.com",
    Socket: {
        URL: protocol + "//13.235.89.233:4000",
        Enable: true
    },
    cognito: {
        GOOGLE_API_KEY: "6LenKZ0aAAAAAL1EB1WwVIYVlgHWUAzqWGkrckYP",
        WEB_SITE_NAME: "SILVARBET"
    },
    REDUX_LOGGER: false
};

const staging = {
    s3: {
        BUCKET: "https://diamond-image.s3.ap-south-1.amazonaws.com/appstatic/",
        BUCKET_DATA_PREFIX: "dev",
        BUCKET_STATIC_DATA_ALLOWED: "1",
    },
    apiGateway: protocol + "//bsf222.com",
    Socket: {
        URL: "https://node.bsf222.com:2222",//protocol + "//3.7.233.236:4444",
        Enable: true
    },
    cognito: {
        GOOGLE_API_KEY: "6LenKZ0aAAAAAL1EB1WwVIYVlgHWUAzqWGkrckYP",
        WEB_SITE_NAME: "DREAMXBET"
    },
    REDUX_LOGGER: false
};

const BSFstaging = {
    s3: {
        BUCKET: "https://diamond-image.s3.ap-south-1.amazonaws.com/appstatic/",
        BUCKET_DATA_PREFIX: "dev",
        BUCKET_STATIC_DATA_ALLOWED: "1",
    },
    apiGateway: protocol + "//staging.bsf222.com",
    Socket: {
        URL: "https://stagingnode.bsf222.com",//protocol + "//3.7.233.236:4444",
        Enable: true
    },
    cognito: {
        GOOGLE_API_KEY: "6LenKZ0aAAAAAL1EB1WwVIYVlgHWUAzqWGkrckYP",
        WEB_SITE_NAME: "DREAMXBET"
    },
    REDUX_LOGGER: false
};

const prod = {
    s3: {
        BUCKET: "https://diamond-image.s3.ap-south-1.amazonaws.com/appstatic/",
        BUCKET_DATA_PREFIX: "dev",
        BUCKET_STATIC_DATA_ALLOWED: "1",
    },
    apiGateway: protocol + "//oldskr.bsf222.com",
    Socket: {
        URL: protocol + "//oldskr.bsf222.com:4000",
        Enable: true
    },
    cognito: {
        GOOGLE_API_KEY: "6LenKZ0aAAAAAL1EB1WwVIYVlgHWUAzqWGkrckYP",
        WEB_SITE_NAME: "SILVARBET"
    },
    REDUX_LOGGER: false
};

const live = {
    s3: {
        BUCKET: "https://diamond-image.s3.ap-south-1.amazonaws.com/appstatic/",
        BUCKET_DATA_PREFIX: "live",
        BUCKET_STATIC_DATA_ALLOWED: "1",
    },
    apiGateway: origin,
    Socket: {
        URL: "https://node.silvarbet.com:2222/",
        Enable: true
    },
    cognito: {
        GOOGLE_API_KEY: "6LenKZ0aAAAAAL1EB1WwVIYVlgHWUAzqWGkrckYP",
        WEB_SITE_NAME: "SILVARBET"
    },
    REDUX_LOGGER: false
};


const qaWhiteLabelURL = {
    s3: {
        BUCKET: "https://diamond-image.s3.ap-south-1.amazonaws.com/appstatic/",
        BUCKET_DATA_PREFIX: "dev",
        BUCKET_STATIC_DATA_ALLOWED: "1",
    },
    apiGateway: "https://white.bsf222.com",
    Socket: {
        URL: "https://node.bsf222.com:2222",
        Enable: true,
    },
    cognito: {
        GOOGLE_API_KEY: "6LenKZ0aAAAAAL1EB1WwVIYVlgHWUAzqWGkrckYP",
        WEB_SITE_NAME: "silvarbet"
    }
};


const stagingWhiteLabelURL = {
    s3: {
        BUCKET: "https://diamond-image.s3.ap-south-1.amazonaws.com/appstatic/",
        BUCKET_DATA_PREFIX: "dev",
        BUCKET_STATIC_DATA_ALLOWED: "1",
    },
    apiGateway: "https://stagwhite.bsf222.com",

    Socket: {
        URL: "https://stagingnode.bsf222.com",
        Enable: true,
    },
    cognito: {
        GOOGLE_API_KEY: "6LenKZ0aAAAAAL1EB1WwVIYVlgHWUAzqWGkrckYP",
        WEB_SITE_NAME: "silvarbet"
    }
};

const dynamicServerURL = {
    s3: {
        BUCKET: "https://diamond-image.s3.ap-south-1.amazonaws.com/appstatic/",
        BUCKET_DATA_PREFIX: "live",
        BUCKET_STATIC_DATA_ALLOWED: "1",
    },
    apiGateway: window.location.origin,
    Socket: {
        URL: "https://node.silvarbet.com:2222/",
        Enable: true
    },
    cognito: {
        GOOGLE_API_KEY: "6LenKZ0aAAAAAL1EB1WwVIYVlgHWUAzqWGkrckYP",
        WEB_SITE_NAME: ""
    },
    REDUX_LOGGER: false
};

const config = _.includes(DevHostname, hostname) || hostname.match(regex)
    ?
    (_.includes(StagingHostname, hostname) ? staging :
        (_.includes(BSFStagingHostname, hostname) ? BSFstaging :
            (_.includes(FeedHostname, hostname) ? feedIntegration :
                (_.includes(prodHostname, hostname) ? prod :
                    _.includes(Qa_white_label, hostname) ? qaWhiteLabelURL
                        : _.includes(Staging_white_label, hostname) ? stagingWhiteLabelURL
                            :
                            (_.includes(liveHostname, hostname) ? live : development)
                )
            )
        )
    )
    : dynamicServerURL;

export default {
    ...config
};